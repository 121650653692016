import { INIT_REGISTER, REGISTER } from '../../../constant/actionTypes/auth'
import { authService } from '../../../services'

export const initRegisterScreen = () => {
  return {
    type: INIT_REGISTER
  }
}

export const registerStart = (payload) => {
  return {
    type: REGISTER.START,
    payload
  }
}

export const registerSuccess = (payload) => {
  return {
    type: REGISTER.SUCCESS,
    payload
  }
}

export const registerError = (payload) => {
  return {
    type: REGISTER.ERROR,
    payload
  }
}

export const register = (payload) => {
  return (dispatch) => {
    dispatch(registerStart(payload))
    authService
      .postRegister(payload)
      .then((userData) => {
        if (!userData.isError) {
          dispatch(registerSuccess(userData))
        } else {
          dispatch(registerError(userData))
        }
      })
      .catch((error) => {
        dispatch(registerError(error))
      })
  }
}
