import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import EditOrgnizationComponent from '../../../components/organization/editOrganization'
import { MzToast, TOAST_SEVERITY } from '../../../common/MzToast'
import { ProgressBar } from 'primereact/progressbar'
import {
  fetchOrganizationRecord,
  updateOrganizationRecord
} from '../../../redux/action/organization'

const EditOrgnizationScreen = (props) => {
  const {
    isCreateOrganizationSuccess,
    isCreateOrganizationError,
    isOrganizationDetailSuccess,
    isOrganizationDetailError,
    isEditOrganizationSuccess,
    isEditOrganizationError,
    isEdit,
    error,
    isLoading,
    formFieldValueMap,
    isPageLevelError,
    fetchOrganizationRecord,
    updateOrganizationRecord
  } = props

  const [showSuccessToast, setShowSuccessToast] = useState(false)

  useEffect(() => {
    if (isCreateOrganizationSuccess || isEditOrganizationSuccess) {
      setShowSuccessToast(true)
    }
  }, [isCreateOrganizationSuccess, isEditOrganizationSuccess])

  const getToastProps = () => {
    if ((isCreateOrganizationSuccess || isEditOrganizationSuccess) && showSuccessToast) {
      const toastTitle = isEdit
        ? 'Organization Updated Successfully'
        : 'Organization Created Successfully'
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
        onClose: () => setShowSuccessToast(false) 
      }
    }

    if (
      isCreateOrganizationError ||
      isOrganizationDetailError ||
      isEditOrganizationError
    ) {
      let toastTitle = error ? error : 'Error while Creating Orgnization'
      if (isEditOrganizationError) {
        toastTitle = error ? error : 'Error while updating Orgnization'
      } else if (isOrganizationDetailError) {
        toastTitle =
          'Error while performing operation. Please refresh your browser'
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
    return { shouldShowToast: false } 
  }
  const handlefetchOrganizationRecord = (payload) => {
    fetchOrganizationRecord(payload)
  }
  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }

  const addOrganizationProps = {
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    isEdit,
    isCreateOrganizationSuccess,
    isEditOrganizationSuccess,
    isOrganizationDetailSuccess,
    handlefetchOrganizationRecord,
    updateOrganizationRecord
  }
  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <EditOrgnizationComponent addOrganizationProps={addOrganizationProps} />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateOrganizationRecord: (payload, id) =>
      dispatch(updateOrganizationRecord(payload, id)),
    fetchOrganizationRecord: (payload) =>
      dispatch(fetchOrganizationRecord(payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    formFieldValueMap: selectFormFieldValueMap(state.organizationReducer),
    isPageLevelError: state.organizationReducer.isPageLevelError,
    isLoading: state.organizationReducer.isLoading,
    error: state.organizationReducer.error,
    isCreateOrganizationSuccess:
      state.organizationReducer.isCreateOrganizationSuccess,
    isCreateOrganizationError:
      state.organizationReducer.isCreateOrganizationError,
    isEditOrganizationSuccess:
      state.organizationReducer.isEditOrganizationSuccess,
    isEditOrganizationError: state.organizationReducer.isEditOrganizationError,
    isOrganizationDetailError:
      state.organizationReducer.isOrganizationDetailError
  }
}

const selectFormFieldValueMap = (organizationReducer) => {
  return organizationReducer.formFieldValueMap
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditOrgnizationScreen)
