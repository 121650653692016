import axios from 'axios'
import { API_PATH } from '../../constant/urlConstant'
import { handleAPIError } from '../common/errorHandler'
import { baseUrl } from '../PostAPI'
import { getDecoratedUrl } from '../common/urlService'
import { USER_ROLE } from '../../constant/actionTypes/role'
export const fetchEmployeeList = async (payload) => {
  const { tenantId, pageSize, pageNumber } = payload ?? {}

  let url = `${baseUrl}${API_PATH.EMPOLYEE.GETALL}`
  let finalUrl = getDecoratedUrl({
    url,
    payload: { pageSize, pageNumber },
    shouldSort: false,
    shouldFilter: false
  })
  if (tenantId) {
    finalUrl = `${finalUrl}&tenantId.equals=${tenantId}`
  }
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(finalUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const fetchEmployeeRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.EMPOLYEE.FETCH}/${payload}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const createEmployeeRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.EMPOLYEE.ADD}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    if (result?.data?.error || result.status !== 201) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const updateEmployeeRecord = async (payload, id) => {
  const url = `${baseUrl}${API_PATH.EMPOLYEE.EDIT}/${id}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.put(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const deleteEmployee = async (employeeId) => {
  const url = `${baseUrl}${API_PATH.EMPOLYEE.DELETE}/${employeeId}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 204) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const getEmployeeCount = async (role, payload) => {
  let url = ''
  if (USER_ROLE.ADMIN === role || role === USER_ROLE.USER) {
    url = `${baseUrl}${API_PATH.EMPOLYEE.COUNT}?tenantId.equals=${payload}`
  } else if (role === USER_ROLE.SUPER_ADMIN) {
    url = `${baseUrl}${API_PATH.EMPOLYEE.COUNT}`
  }

  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.status !== 200) {
      return handleAPIError(result?.data?.detail ?? '')
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}
