import {
  INIT_CONTACT,
  FETCH_CONTACT_LIST,
  FETCH_CONTACT_RECORD,
  CREATE_CONTACT_RECORD,
  DELETE_CONTACT,
  UPDATE_CONTACT_RECORD,
  GET_CONTACT_COUNT
} from '../../../constant/actionTypes/contact'
const formFieldValueMap = {
  name: '',
  phone: '',
  email: '',
  address: '',
  user: {
    id: ''
  }
}

const initialState = {
  contactsList: null,
  contactCount: 0,
  selectedContact: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateContactSuccess: false,
  isEditContactSuccess: false,
  isContactDetailSuccess: false,
  isDeleteContactSuccess: false,
  isDeleteContactError: false,
  isContactDetailError: false,
  isCreateContactError: false,
  isEditContactError: false
}

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_CONTACT:
      return {
        ...state,
        contactsList: null,
        formFieldValueMap: null,
        selectedContact: null,
        isLoading: false,
        error: null
      }
    case FETCH_CONTACT_LIST.START:
    case FETCH_CONTACT_RECORD.START:
    case CREATE_CONTACT_RECORD.START:
    case DELETE_CONTACT.START:
    case UPDATE_CONTACT_RECORD.START:
    case GET_CONTACT_COUNT.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreateContactSuccess: false,
        isEditContactSuccess: false,
        isContactDetailSuccess: false,
        isDeleteContactSuccess: false,
        isDeleteContactError: false,
        isContactDetailError: false,
        isCreateContactError: false,
        // isContactDetailError: false,
        isEditContactError: false
      }
    case FETCH_CONTACT_LIST.SUCCESS:
      return {
        ...state,
        contactsList: {
          ...action.payload,
          content: action.payload.content.map((contact) => ({
            ...contact,
            fullName: `${contact.user.firstName} ${contact.user.lastName}`,
          }))
        },
        isLoading: false,
        error: null
      }

      case FETCH_CONTACT_RECORD.SUCCESS:
        return {
          ...state,
          selectedContact: action.payload, 
          formFieldValueMap: {
            tenant: action?.payload?.user?.tenant?.id ?? '',
            id: action?.payload?.id ?? '', 
            name: action?.payload?.name ?? '', 
            email: action?.payload?.email ?? '',
            phone: action?.payload?.phone ?? '', 
            address: action?.payload?.address ?? '', 
            active: action?.payload?.active ?? false,
            userId: action?.payload?.user?.id ?? '' 
          },
          isLoading: false,
          error: null,
          isContactDetailSuccess: true
        };
      
      case CREATE_CONTACT_RECORD.SUCCESS:
        return {
          ...state, 
          contactsList: {
            ...state.contactsList, 
            content: state.contactsList?.content 
              ? [...state.contactsList.content, action.payload]
              : [action.payload], 
          },
          isLoading: false,
          error: null,
          isCreateContactSuccess: true
        };
      
    case UPDATE_CONTACT_RECORD.SUCCESS:
      return {
        ...state,
        contactsList: {
          ...state.contactsList, 
          content: state.contactsList?.content.map((contact) => contact.id === action.payload.id ? action.payload : contact)
        },
        isLoading: false,
        error: null,
        isEditContactSuccess: true
      }
    case DELETE_CONTACT.SUCCESS:
      return {
        ...state,
        ...state.contactsList,
        content: state.contactsList.content.filter(
          (contact) => contact.id !== action.payload.contactId
        ),
        isLoading: false,
        error: null,
        isDeleteContactSuccess: true
      }
    case GET_CONTACT_COUNT.SUCCESS:
      return {
        ...state,
        contactCount: action.payload,
        isLoading: false,
        error: null
      }
    case FETCH_CONTACT_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case FETCH_CONTACT_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isContactDetailError: true
      }
    case GET_CONTACT_COUNT.ERROR:
      return {
        ...state,
        contactCount: 0,
        isLoading: false,
        error: null
      }
    case UPDATE_CONTACT_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isEditContactError: true
      }
    case DELETE_CONTACT.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDeleteContactError: true
      }
    case CREATE_CONTACT_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isCreateContactError: true
      }
    default:
      return state
  }
}

export default contactReducer
