import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { FORM_FIELDS_NAME } from './constant'
import MzInput from '../../../common/MzForm/MzInput'
import { Button } from 'primereact/button'
import { BreadCrumb } from 'primereact/breadcrumb'
import { ROUTE_PATH } from '../../../constant/urlConstant'
import { useNavigate, useParams } from 'react-router-dom'
import { USER_ROLE } from '../../../constant/actionTypes/role'
import { Fieldset } from 'primereact/fieldset'
import MzAutoComplete from '../../../common/MzForm/MzAutoComplete'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
const AddCategoryComponent = (props) => {
  const {
    createCategoryRecord,
    formFieldValueMap,
    isLoading,
    isCreateCategorySuccess,
    isEditCategorySuccess,
    isCreateCategoryError,
    categoryError,
    isCategoryDetailSuccess,
    isPageLevelError,
    user,
    isEdit,
    userRole,
    tenantList,
    fetchCategoryRecord,
    navigatetoCategory,
    openCategoryDialog,
    updateCategoryRecord,
    setAddCategoryDaialogue,
  } = props.addCategoryProps

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap
    }, [formFieldValueMap]),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const history = useNavigate()
  const { id } = useParams()
  const { t } = useTranslation()
  useEffect(() => {
    if (isCreateCategorySuccess || isEditCategorySuccess) {
      setTimeout(() => {
        reset()
        if (openCategoryDialog) {
          toast.success(t('category.CategoryAddSuccessfully'))
          handleClose()
        } else {
          navigatetoCategory()
        }
      }, 1000)
    }

    if (isCreateCategoryError) {
      let toastTitle = categoryError
        ? categoryError?.error
        : t('category.errorCreatingCategory')
      toast.error(toastTitle)
    }
    // eslint-disable-next-line
  }, [isCreateCategorySuccess, isEditCategorySuccess, isCreateCategoryError])

  useEffect(() => {
    if (isCategoryDetailSuccess) {
      reset({
        ...formFieldValueMap
      })
    }
    // eslint-disable-next-line
  }, [isCategoryDetailSuccess])

  useEffect(() => {
    if (isEdit && id) {
      fetchCategoryRecord(id)
    } else {
      reset(formFieldValueMap)
    }
    // eslint-disable-next-line
  }, [isEdit, id])

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className="p-error">{t(errors[name].message)}</small>
      )
    )
  }
  const handleClose = () => {
    setAddCategoryDaialogue(false)
  }

  const getTenantLabel = (id) => {
    if (!tenantList || !Array.isArray(tenantList) || tenantList.length === 0) {
      return ''
    }
    const tenant = tenantList.find((tenant) => tenant.id === id)
    return tenant ? tenant.companyName : ''
  }

  const onSubmit = (data) => {
    const isSuperAdmin = userRole === USER_ROLE.SUPER_ADMIN
    const tenantId = isSuperAdmin
      ? (data?.tenant ?? '')
      : (user?.tenant?.id ?? '')
    const payload = {
      tenant: { id: tenantId },
      name: data.name
      // ...(isEdit ? {} : { active: true })
    }

    if (isEdit) {
      payload.id = data.id
      payload.active = data.active
    }
    if (isEdit) {
      updateCategoryRecord(payload)
    } else {
      createCategoryRecord(payload)
    }
  }

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      {
        label: t('category.Category'),
        command: () => history(ROUTE_PATH.CATEGORY.HOME)
      },
      {
        label: isEdit
          ? t('category.UpdateCategory')
          : t('category.AddCategory'),
        command: () =>
          history(
            isEdit
              ? `${ROUTE_PATH.CATEGORY.EDIT.replace(':id', id)}`
              : ROUTE_PATH.CATEGORY.ADD
          )
      }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }

    return (
      <div className="flex justify-content-between align-items-center  border-1 border-200  border-round  bg-white ">
        <BreadCrumb model={breadCrumbItems} home={home} className="border-0" />
      </div>
    )
  }
  return (
    <div>
      {openCategoryDialog ? (
        <>
          <div className="flex bg-white flex-column align-items-center justify-content-center border-1 border-200 border-round mt-3 p-2 md:p-0">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" w-full md:px-4 mt-2"
            >
              {userRole === USER_ROLE.SUPER_ADMIN && (
                <div className="card">
                  <div className="grid">
                    <div className=" col-12 md:col-12">
                      <MzAutoComplete
                        control={control}
                        name={FORM_FIELDS_NAME.TENANT.name}
                        label={FORM_FIELDS_NAME.TENANT.label}
                        optionLabel={'companyName'}
                        optionValue={'id'}
                        placeholder={FORM_FIELDS_NAME.TENANT.placeholder}
                        isError={!!errors[FORM_FIELDS_NAME.TENANT.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.TENANT.name
                        )}
                        rules={FORM_FIELDS_NAME.TENANT.rules}
                        suggestions={tenantList}
                        dropdown
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="card mt-3">
                <div className="grid">
                  <div className="col-12 md:col-12">
                    <MzInput
                      control={control}
                      name={FORM_FIELDS_NAME.NAME.name}
                      label={FORM_FIELDS_NAME.NAME.label}
                      placeholder={FORM_FIELDS_NAME.NAME.placeholder}
                      type={FORM_FIELDS_NAME.NAME.type}
                      isError={errors[FORM_FIELDS_NAME.NAME.name]}
                      errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.NAME.name)}
                      rules={FORM_FIELDS_NAME.NAME.rules}
                      wrapperClass={'p-float-label'}
                    />
                  </div>
                </div>
              </div>

              <div className="grid justify-content-center mt-3 mb-5">
                <div className="col-12 md:col-6">
                  <Button
                    label={t('category.Cancel')}
                    className="w-full"
                    severity="secondary"
                    onClick={(e) => {
                      e.preventDefault()
                      handleClose()
                    }}
                    disabled={isLoading || isPageLevelError}
                  />
                </div>
                <div className="col-12 md:col-6">
                  <Button
                    type="submit"
                    label={t('category.Submit')}
                    className="w-full"
                    disabled={isLoading || isPageLevelError}
                  />
                </div>
              </div>
            </form>
          </div>
        </>
      ) : (
        <>
          {renderPageHeaderSection()}
          <div className="flex bg-white flex-column align-items-center justify-content-center border-1 border-200 border-round mt-3 p-2 md:p-0">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" w-full md:px-4 mt-2"
            >
              {userRole === USER_ROLE.SUPER_ADMIN && (
                <div className="card">
                  <Fieldset legend={t('category.CategoryDetails')}>
                    <div className="grid">
                      <div className=" col-12 md:col-6">
                        <MzAutoComplete
                          control={control}
                          name={FORM_FIELDS_NAME.TENANT.name}
                          label={FORM_FIELDS_NAME.TENANT.label}
                          optionLabel={'companyName'}
                          optionValue={'id'}
                          placeholder={FORM_FIELDS_NAME.TENANT.placeholder}
                          isError={!!errors[FORM_FIELDS_NAME.TENANT.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.TENANT.name
                          )}
                          rules={FORM_FIELDS_NAME.TENANT.rules}
                          suggestions={tenantList}
                          dropdown
                          value={getTenantLabel(
                            getValues(FORM_FIELDS_NAME.TENANT.name)
                          )}
                          disabled={isEdit}
                        />
                      </div>
                    </div>
                  </Fieldset>
                </div>
              )}

              <div className="card mt-3">
                <Fieldset legend={t('category.CategoryDetails')}>
                  <div className="grid">
                    <div className="col-12 md:col-6">
                      <MzInput
                        control={control}
                        name={FORM_FIELDS_NAME.NAME.name}
                        label={FORM_FIELDS_NAME.NAME.label}
                        placeholder={FORM_FIELDS_NAME.NAME.placeholder}
                        type={FORM_FIELDS_NAME.NAME.type}
                        isError={errors[FORM_FIELDS_NAME.NAME.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.NAME.name
                        )}
                        rules={FORM_FIELDS_NAME.NAME.rules}
                        wrapperClass={'p-float-label'}
                      />
                    </div>
                  </div>
                </Fieldset>
              </div>

              <div className="grid justify-content-center mt-3 mb-5">
                <div className="col-12 md:col-4">
                  <Button
                    label={t('category.Cancel')}
                    className="w-full"
                    severity="secondary"
                    onClick={() => navigatetoCategory()}
                    disabled={isLoading || isPageLevelError}
                  />
                </div>
                <div className="col-12 md:col-4">
                  <Button
                    type="submit"
                    label={t('category.Submit')}
                    className="w-full"
                    disabled={isLoading || isPageLevelError}
                  />
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  )
}
export default AddCategoryComponent
