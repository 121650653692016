import React, { useEffect, useState } from 'react'
import AmenitiesComponent from '../../components/amenities'
import {
  deleteAmenities,
  fetchAmenitiesList,
  initAmenities
} from '../../redux/action/amenities'
import { connect } from 'react-redux'
import { USER_ROLE } from '../../constant/actionTypes/role'
import { ProgressBar } from 'primereact/progressbar'
import { fetchTenantList, initTenant } from '../../redux/action/tenant'
import amenitiesTableData from './data.json'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import { useTranslation } from 'react-i18next'
const AmenitiesScreen = (props) => {
  const [viewAmenities, setViewAmenities] = useState(true)
  const [isDeleteAmenitiesDialogVisible, setIsDeleteDialogVisible] =
    useState(false)
  const [selectedAmenities, setSelectedAmenities] = useState(null)
  // const [isDeleteProductsDialogVisible, setIsDeleteProductsDialogVisible] =
  //   useState(false)
  // const [selectedProducts, setSelectedProducts] = useState(null)

  const {
    fetchAmenitiesList,
    fetchProductsList,
    fetchTenantList,
    initAmenities,
    amenitiesList,
    isLoading,
    isPageLevelError,
    userRole,
    tenantList,
    user,
    deleteAmenities,
    isDeleteAmenitiesSuccess,
    isDeleteAmenitiesError
  } = props
  const { t } = useTranslation()
  useEffect(() => {
    initAmenities()
    initTenant()

    const payload = {
      pageNumber: 0,
      pageSize: 10
    }

    if (userRole === USER_ROLE.SUPER_ADMIN) {
      fetchTenantList()
      fetchAmenitiesList(payload)
      // fetchAmenitiesList()
    } else {
      const modifiedPayload = {
        ...payload,
        tenantId: user?.tenant?.id || ''
      }
      fetchAmenitiesList(modifiedPayload)
    }
  }, [
    userRole,
    fetchAmenitiesList,
    fetchProductsList,
    fetchTenantList,
    user,
    initAmenities,isDeleteAmenitiesSuccess
  ])

// useEffect(() => {
//     if (userRole===USER_ROLE.ADMIN) {
//       setTimeout(() => {
//         fetchAmenitiesList({tenantId:user?.tenant?.id ?? ''})
//       }, 500) 
//     }
//     if(userRole===USER_ROLE.SUPER_ADMIN)
//     {
//       setTimeout(() => {
//         fetchAmenitiesList()
//       }, 500) 
//     }
//   }, [
//     ,
//     fetchAmenitiesList,
//     user
//   ])
   
  const history = useNavigate()

  const handleOnReadRecord = () => {}

  const handleOnDeleteRecord = (data) => {
    setSelectedAmenities(data)
    setIsDeleteDialogVisible(true)
  }

  const handleOnEditRecord = (data) => {
    history(`${ROUTE_PATH.AMENITIES.EDIT.replace(':id', data?.id)}`)
  }

  const handleOnCreatedRecord = () => {
    history(ROUTE_PATH.AMENITIES.ADD)
  }

  const confirmDelete = () => {
    if (selectedAmenities) {
      deleteAmenities(selectedAmenities.id)
      setIsDeleteDialogVisible(false)
      setSelectedAmenities(null)
    }
  }

  const cancelDelete = () => {
    setIsDeleteDialogVisible(false)
    setSelectedAmenities(null)
  }
  const filteredTableData = {
    ...amenitiesTableData,
    tableData: {
      ...amenitiesTableData.tableData,
      columns: Array.isArray(amenitiesTableData?.tableData?.columns)
        ? amenitiesTableData.tableData.columns.filter((column) => {
            if (
              column.dataKey === 'tenant.companyName' &&
              userRole === USER_ROLE.ADMIN
            ) {
              return false
            }
            return true
          })
        : []
    }
  }

  const amenitiesProps = {
    amenitiesList,
    tenantList,
    fetchAmenitiesList,
    fetchProductsList,
    isPageLevelError,
    isLoading,
    amenitiesTableData: filteredTableData,
    userRole,
    viewAmenities,
    handleOnReadRecord,
    handleOnDeleteRecord,
    handleOnEditRecord,
    handleOnCreatedRecord,
    setViewAmenities,
    user
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }

  const getToastProps = () => {
    if (isDeleteAmenitiesSuccess) {
      const toastTitle = t('Amenities.serviceStatusChangeSuccessfully')
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isDeleteAmenitiesError) {
      let toastTitle = t('Amenities.errorWhileChangingServiceStatus')
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <AmenitiesComponent amenitiesProps={amenitiesProps} />

      <Dialog
        header={`${t('Amenities.deleteDailogHeader.Confirm')} ${
          selectedAmenities?.active
            ? t('Amenities.deleteDailogHeader.Deactivate')
            : t('Amenities.deleteDailogHeader.Activate')
        }`}
        visible={isDeleteAmenitiesDialogVisible}
        onHide={cancelDelete}
        footer={
          <div>
            <Button
              severity={selectedAmenities?.active ? 'primary' : ''}
              onClick={confirmDelete}
            >
              {t('Amenities.yes')}
            </Button>
            <Button severity="secondary" onClick={cancelDelete}>
              {t('Amenities.no')}
            </Button>
          </div>
        }
      >
        <div className="text-warp">
          {t('employee.deleteConfirmMessage')}
          {selectedAmenities?.active
            ? t('employee.deleteDailogHeader.Deactivate')
            : t('employee.deleteDailogHeader.Activate')}{' '}
          {t('Amenities.thisService')}
          <span className="text-primary">{selectedAmenities?.name}</span>
        </div>
      </Dialog>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initAmenities: () => dispatch(initAmenities()),
    initTenant: () => dispatch(initTenant()),
    fetchTenantList: () => dispatch(fetchTenantList()),
    fetchAmenitiesList: (payload) => dispatch(fetchAmenitiesList(payload)),
    deleteAmenities: (payload) => dispatch(deleteAmenities(payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isPageLevelError: state.amenitiesReducer.isPageLevelError,
    isLoading: state.amenitiesReducer.isLoading,
    error: state.amenitiesReducer.error,
    amenitiesList: state.amenitiesReducer.amenitiesList,
    userRole: state.loginReducer.userRole,
    tenantList: state.tenantReducer.tenantList,
    user: state.userProfileReducer.user,
    isDeleteAmenitiesSuccess: state.amenitiesReducer.isDeleteAmenitiesSuccess,
    isDeleteAmenitiesError: state.amenitiesReducer.isDeleteAmenitiesError
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmenitiesScreen)
