import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { FORM_FIELDS_NAME } from './constant'
import MzInput from '../../../common/MzForm/MzInput'
import { Button } from 'primereact/button'
import { BreadCrumb } from 'primereact/breadcrumb'
import { ROUTE_PATH } from '../../../constant/urlConstant'
import { useNavigate, useParams } from 'react-router-dom'
import MzPhoneInput from '../../../common/MzForm/MzPhoneInput'
import { Fieldset } from 'primereact/fieldset'
import MzAutoComplete from '../../../common/MzForm/MzAutoComplete'
import MzTextarea from '../../../common/MzForm/MzTextArea'
import { USER_ROLE } from '../../../constant/actionTypes/role'
import { useTranslation } from 'react-i18next'

const AddContactComponent = (props) => {
  const {
    createContactRecord,
    formFieldValueMap,
    isLoading,
    isCreateContactSuccess,
    isEditContactSuccess,
    isContactDetailSuccess,
    isPageLevelError,
    navigatetoContact,
    fetchContactRecord,
    updateContactRecord,
    user,
    isEdit,
    userRole,
    tenantList,
    createContactRecordBysuperAdmin,
    updateContactRecordBysuperAdmin
  } = props.addContactProps

  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    getValues
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap
    }, [formFieldValueMap]),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const history = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    if (isEdit && id) {
      fetchContactRecord(id)
    }
    // eslint-disable-next-line
  }, [isEdit, id])

  useEffect(() => {
    if (isCreateContactSuccess || isEditContactSuccess) {
      setTimeout(() => {
        reset()
        navigatetoContact()
      }, 1000)
    }
    // eslint-disable-next-line
  }, [isCreateContactSuccess, isEditContactSuccess])

  useEffect(() => {
    if (isContactDetailSuccess) {
      reset({
        ...formFieldValueMap
      })
    }
    // eslint-disable-next-line
  }, [isContactDetailSuccess])

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className="p-error">{t(errors[name].message)}</small>
      )
    )
  }
  const onSubmit = (data) => {
    const isSuperAdmin = userRole === USER_ROLE.SUPER_ADMIN
    const payload = {
      user: { id: user?.id },
      name: data.name,
      email: data.email,
      phone: data.phone,
      address: data.address,
      ...(isEdit && { id: data.id, active: data.active, user : data.userId }),
      ...(isSuperAdmin && { tenantId: data.tenant, user : data.userId })
    }

    const handleSubmit = isEdit
      ? isSuperAdmin
        ? updateContactRecordBysuperAdmin
        : updateContactRecord
      : isSuperAdmin
        ? createContactRecordBysuperAdmin
        : createContactRecord

    handleSubmit(payload)
  }

  // const onSubmit = (data) => {
  //   const isSuperAdmin = userRole === USER_ROLE.SUPER_ADMIN;
  //   const payload = {
  //     user: { id: user?.id },
  //     name: data.name,
  //     email: data.email,
  //     phone: data.phone,
  //     address: data.address,
  //     ...(isEdit ? { id: data.id } : {}),
  //     ...(isSuperAdmin ? { tenantId: data.tenant } : {})
  //   };

  //   if (isEdit) {
  //     if (isSuperAdmin) {
  //       updateContactRecordBysuperAdmin(payload)
  //     }
  //     else {
  //       updateContactRecord(payload)
  //     }

  //   } else {
  //     if (isSuperAdmin) {
  //       createContactRecordBysuperAdmin(payload)
  //     }
  //     createContactRecord(payload)
  //   }
  // };

  const getTenatntLabel = (tenantId) => {
    const TenantLabel = tenantList?.find((tenant) => tenant?.id === tenantId)
    return TenantLabel ? TenantLabel?.companyName : ''
  }

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      {
        label: t('contact.contact'),
        command: () => history(ROUTE_PATH.CONTACT.HOME)
      },
      {
        label: isEdit ? t('contact.updateContact') : t('contact.addContact'),
        command: () =>
          history(
            isEdit
              ? `${ROUTE_PATH.CONTACT.EDIT.replace(':id', id)}`
              : ROUTE_PATH.CONTACT.ADD
          )
      }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }

    return (
      <div className="flex justify-content-between align-items-center  border-1 border-200  border-round  bg-white ">
        <BreadCrumb model={breadCrumbItems} home={home} className="border-0" />
      </div>
    )
  }
  return (
    <div className="">
      {renderPageHeaderSection()}
      <div className="flex bg-white flex-column align-items-center justify-content-center border-1 border-200 border-round mt-3 p-2 md:p-0">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" w-full md:px-4 mt-2"
        >
          {userRole === USER_ROLE.SUPER_ADMIN && (
            <div className="card">
              <Fieldset legend={t('tenant.title3')}>
                <div className="grid ">
                  <div className=" col-12 md:col-6">
                    <MzAutoComplete
                      control={control}
                      name={FORM_FIELDS_NAME.TENANT.name}
                      label={FORM_FIELDS_NAME.TENANT.label}
                      optionLabel={'companyName'}
                      optionValue={'id'}
                      placeholder={FORM_FIELDS_NAME.TENANT.placeholder}
                      isError={!!errors[FORM_FIELDS_NAME.TENANT.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.TENANT.name
                      )}
                      rules={FORM_FIELDS_NAME.TENANT.rules}
                      suggestions={tenantList ?? []}
                      value={getTenatntLabel(
                        getValues(FORM_FIELDS_NAME.TENANT.name)
                      )}
                      disabled={isEdit}
                      dropdown
                    />
                  </div>
                </div>
              </Fieldset>
            </div>
          )}
          <div className="card mt-3">
            <Fieldset legend={t('contact.title')}>
              <div className="grid">
                <div className=" col-12 md:col-6">
                  <MzInput
                    control={control}
                    name={FORM_FIELDS_NAME.NAME.name}
                    label={FORM_FIELDS_NAME.NAME.label}
                    placeholder={FORM_FIELDS_NAME.NAME.placeholder}
                    type={FORM_FIELDS_NAME.NAME.type}
                    isError={errors[FORM_FIELDS_NAME.NAME.name]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.NAME.name)}
                    rules={FORM_FIELDS_NAME.NAME.rules}
                    wrapperClass={'p-float-label'}
                  />
                </div>
                <div className=" col-12  md:col-6">
                  <MzPhoneInput
                    control={control}
                    name={FORM_FIELDS_NAME.PHONE_NUMBER.name}
                    label={FORM_FIELDS_NAME.PHONE_NUMBER.label}
                    placeholder={FORM_FIELDS_NAME.PHONE_NUMBER.placeholder}
                    rules={FORM_FIELDS_NAME.PHONE_NUMBER.rules}
                    isError={errors[FORM_FIELDS_NAME.PHONE_NUMBER.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.PHONE_NUMBER.name
                    )}
                    country="in"
                  />
                </div>
                <div className=" col-12 md:col-6">
                  <MzInput
                    control={control}
                    name={FORM_FIELDS_NAME.EMAIL.name}
                    label={FORM_FIELDS_NAME.EMAIL.label}
                    placeholder={FORM_FIELDS_NAME.EMAIL.placeholder}
                    type={FORM_FIELDS_NAME.EMAIL.type}
                    isError={errors[FORM_FIELDS_NAME.EMAIL.name]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.EMAIL.name)}
                    rules={FORM_FIELDS_NAME.EMAIL.rules}
                    wrapperClass={'p-float-label'}
                  />
                </div>

                <div className="col-12 md:col-6">
                  <MzTextarea
                    control={control}
                    name={FORM_FIELDS_NAME.ADDRESS.name}
                    label={FORM_FIELDS_NAME.ADDRESS.label}
                    placeholder={FORM_FIELDS_NAME.ADDRESS.placeholder}
                    value={watch(FORM_FIELDS_NAME.ADDRESS.name)}
                    isError={errors[FORM_FIELDS_NAME.ADDRESS.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.ADDRESS.name
                    )}
                    wrapperClass={'p-float-label'}
                    rows={3}
                  />
                </div>
              </div>
            </Fieldset>
          </div>
          <div className="grid justify-content-center mt-3 mb-5">
            <div className="col-12 md:col-4">
              <Button
                label={t('contact.cancel')}
                className="w-full"
                severity="secondary"
                onClick={() => navigatetoContact()}
                disabled={isLoading || isPageLevelError}
              />
            </div>
            <div className="col-12 md:col-4">
              <Button
                type="submit"
                label={t('contact.submit')}
                className="w-full"
                disabled={isLoading || isPageLevelError}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddContactComponent
