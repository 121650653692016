import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FORM_FIELDS_NAME } from './constant'
import MzInput from '../../../common/MzForm/MzInput'
import { Button } from 'primereact/button'
import { BreadCrumb } from 'primereact/breadcrumb'
import { ROUTE_PATH } from '../../../constant/urlConstant'
import { useNavigate, useParams } from 'react-router-dom'
import MzFileInput from '../../../common/MzForm/MzFileInput'
import { USER_ROLE } from '../../../constant/actionTypes/role'
import { Fieldset } from 'primereact/fieldset'
import MzTextarea from '../../../common/MzForm/MzTextArea'
import { Dialog } from 'primereact/dialog'
import AddCategoryComponent from '../../category/addCategory'
import MzAutoComplete from '../../../common/MzForm/MzAutoComplete'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { useDispatch } from 'react-redux'
import MzDialog from '../../../common/MzDialog'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const AddProductsComponent = (props) => {
  const dispatch = useDispatch()
  const {
    createProductsRecord,
    formFieldValueMap,
    isLoading,
    isCreateProductsSuccess,
    isProductsDetailSuccess,
    isPageLevelError,
    fetchProductsRecord,
    updateProductsRecord,
    isEditProductsSuccess,
    user,
    isEdit,
    tenantList,
    userRole,
    categoryError,
    fetchCategoryList,
    categorysList,
    deleteImage,
    createCategoryRecord,
    isCreateCategoryError,
    isCreateCategorySuccess,
    navigatetoCategory,
    initCategory,
  } = props.addAmenitiesProps
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedImageId, setSelectedImageId] = useState(null)
  const [addCategoryDialogue, setAddCategoryDaialogue] = useState(false)
  const [openCategoryDialog] = useState(true)
  const [selectedTenantId, setSelectedTenantId] = useState(null)
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
    getValues,
    clearErrors
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap
    }, [formFieldValueMap]),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const history = useNavigate()
  const { id } = useParams()
  const [filePreview, setFilePreview] = useState({
    icon: null,
    image: null,
    video: null,
    brochure: null
  })
  const [fileErrors, setFileErrors] = useState({
    icon: '',
    image: '',
    video: '',
    brochure: ''
  })
  const [imageError, setImageError] = useState(false)
  const [videoError, setVidoError] = useState(false)
  const [pdfError, setPdfError] = useState(false)
  const [iconError, setIconError] = useState(false)

  const handleFileChange = (file, fileType) => {
    setFileErrors((prev) => ({ ...prev, [fileType]: null }))

    if (file && file.length > 0) {
      const selectedFile = file[0]
      const validTypes = ['application/pdf']

      if (!validTypes.includes(selectedFile.type)) {
        setFileErrors((prev) => ({
          ...prev,
          [fileType]: 'Invalid file type. Please upload a .pdf file.'
        }))
        setFilePreview((prev) => ({ ...prev, [fileType]: null }))
        setPdfError(true)
        return
      }
      const fileUrl = URL.createObjectURL(selectedFile)
      setFilePreview((prev) => ({ ...prev, [fileType]: fileUrl }))
      setFileErrors((prev) => ({ ...prev, [fileType]: null }))
      setPdfError(false)
    } else {
      setFilePreview((prev) => ({ ...prev, [fileType]: null }))
      setPdfError(false)
    }
  }

  const handleVideoChange = (file, fileType) => {
    setFileErrors((prev) => ({ ...prev, [fileType]: null }))
    if (file && file.length > 0) {
      const selectedFile = file[0]
      const validTypes = [
        'video/mp4',
        'video/webm',
        'video/quicktime',
        'video/x-msvideo',
        'video/x-ms-wmv',
        'video/x-flv',
        'video/mpeg',
        'video/ogg',
        'application/x-mpegURL',
        'video/3gpp',
        'video/3gpp2'
      ]

      if (!validTypes.includes(selectedFile.type)) {
        setFileErrors((prev) => ({
          ...prev,
          [fileType]: 'Invalid file type. Please upload a video file.'
        }))
        setFilePreview((prev) => ({ ...prev, [fileType]: null }))
        setVidoError(true)
        return
      }
      const fileUrl = URL.createObjectURL(selectedFile)
      setFilePreview((prev) => ({ ...prev, [fileType]: fileUrl }))
      setFileErrors((prev) => ({ ...prev, [fileType]: null }))
      setVidoError(false)
    } else {
      setFilePreview((prev) => ({ ...prev, [fileType]: null }))
      setVidoError(false)
    }
  }

  const handleImageChange = (file, fileType) => {
    setFileErrors((prev) => ({ ...prev, [fileType]: null }))

    if (file && file.length > 0) {
      const selectedFile = file[0]
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png']

      if (!validTypes.includes(selectedFile.type)) {
        setFileErrors((prev) => ({
          ...prev,
          [fileType]: 'Invalid file type. Please upload a JPG or PNG file.'
        }))
        setFilePreview((prev) => ({ ...prev, [fileType]: null }))
        setImageError(true)
        return
      }
      const fileUrl = URL.createObjectURL(selectedFile)
      setFilePreview((prev) => ({ ...prev, [fileType]: fileUrl }))
      setFileErrors((prev) => ({ ...prev, [fileType]: null }))
      setImageError(false)
    } else {
      setFilePreview((prev) => ({ ...prev, [fileType]: null }))
      setImageError(false)
    }
  }

  const handleIconChange = (file, fileType) => {
    setFileErrors((prev) => ({ ...prev, [fileType]: null }))

    if (file && file.length > 0) {
      const selectedFile = file[0]
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png']

      if (!validTypes.includes(selectedFile.type)) {
        setFileErrors((prev) => ({
          ...prev,
          [fileType]: 'Invalid file type. Please upload a JPG or PNG file.'
        }))
        setFilePreview((prev) => ({ ...prev, [fileType]: null }))
        setIconError(true)
        return
      }
      const fileUrl = URL.createObjectURL(selectedFile)
      setFilePreview((prev) => ({ ...prev, [fileType]: fileUrl }))
      setFileErrors((prev) => ({ ...prev, [fileType]: null }))
      setIconError(false)
    } else {
      setFilePreview((prev) => ({ ...prev, [fileType]: null }))
      setIconError(false)
    }
  }

  useEffect(() => {
    if (isEdit && id) {
      fetchProductsRecord(id)
    } else {
      reset()
    }
    // eslint-disable-next-line
  }, [isEdit, id])

  useEffect(() => {
    if (isCreateProductsSuccess || isEditProductsSuccess) {
      setTimeout(() => {
        reset()
        history(ROUTE_PATH.PRODUCTS.HOME)
      }, 1000)
    }
    // eslint-disable-next-line
  }, [isCreateProductsSuccess, isEditProductsSuccess])

  const navigateProducts = () => {
    history(ROUTE_PATH.PRODUCTS.HOME)
  }


  useEffect(() => {
    const tenant =
      watch(FORM_FIELDS_NAME.TENANT.name) ?? formFieldValueMap?.tenant
    setSelectedTenantId(tenant)
    if (userRole === USER_ROLE.SUPER_ADMIN && tenant) {
      const payload = {
        pageNumber: 0,
        pageSize: 100,
        tenantId: tenant ?? ''
      }
      fetchCategoryList(payload)
    } else if (userRole === USER_ROLE.ADMIN) {
      const payload = {
        pageNumber: 0,
        pageSize: 100,
        tenantId: user?.tenant?.id ?? ''
      }
      fetchCategoryList(payload)
    } else {
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    watch(FORM_FIELDS_NAME.TENANT.name),
    fetchCategoryList,
    isCreateCategorySuccess,
    isCreateCategoryError
  ])
  useEffect(() => {
    if (isProductsDetailSuccess) {
      reset({
        ...formFieldValueMap
      })
    }
    // eslint-disable-next-line
  }, [isProductsDetailSuccess])

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className="p-error">{t(errors[name].message)}</small>
      )
    )
  }

  const cancelDelete = () => {
    setOpenDialog(false)
    setSelectedImageId(null)
  }
  const handleDelete = (event, imageId) => {
    event.preventDefault()
    event.stopPropagation()
    setSelectedImageId(imageId)
    setOpenDialog(true)
  }

  const confirmDelete = async () => {
    try {
      await dispatch(deleteImage(selectedImageId))
      toast.success(t('product.imgerror3'))
    } catch (error) {
      console.error('Error during deletion:', error)
    } finally {
      setOpenDialog(false)
      setSelectedImageId(null)
    }
  }

  const getTenantLabel = (id) => {
    const tenant = tenantList?.find((tenant) => tenant.id === id)
    return tenant ? tenant?.companyName : ''
  }

  const getCategorylabel = (id) => {
    const category = categorysList?.content?.find(
      (categoryItem) => categoryItem.id === id
    )
    return category ? category?.name : ''
  }

  const activeCategorySuggestion =
    categorysList?.content?.filter((category) => category.active) ?? []

  useEffect(() => {
    if (!openDialog && isEdit) {
      fetchProductsRecord(id)
    }
    // eslint-disable-next-line
  }, [openDialog, id, fetchProductsRecord])

  const onSubmit = (data) => {
    console.log("data",data);
    if (imageError || videoError || pdfError || iconError) {
      return
    } else {
      const formData = new FormData()

      const files = {
        images: data[FORM_FIELDS_NAME.IMAGE.name],
        video: data[FORM_FIELDS_NAME.VIDEO.name],
        icon: data[FORM_FIELDS_NAME.ICON.name],
        brochure: data[FORM_FIELDS_NAME.BROCHURE.name]
      }
      console.log("filesfiles",files);
      Object.keys(files).forEach((key) => {
        const fileData = files[key]
        if (fileData && Array.isArray(fileData)) {
          fileData.forEach((file) => {
            if (file instanceof File) {
              formData.append(key, file)
            }
          })
        } else if (fileData && fileData instanceof File) {
          formData.append(key, fileData)
        }
      })

      let payload = {
        name: data[FORM_FIELDS_NAME.NAME.name],
        description: data[FORM_FIELDS_NAME.DESCRIPTION.name]
      }

      if (userRole !== USER_ROLE.SUPER_ADMIN) {
        payload.tenant = { id: user?.tenant?.id ?? '' }
      }
      if (userRole === USER_ROLE.SUPER_ADMIN) {
        payload.tenant = {
          id: data.tenant
        }
      }
      if (!isEdit) {
        payload.category = {
          id: data[FORM_FIELDS_NAME.CATEGORY.name]
        }
      }
      if (isEdit) {
        payload.id = id
        payload.active = data.active
      }

      const blob = new Blob([JSON.stringify(payload)], {
        type: 'application/json'
      })
      formData.append('data', blob)

      if (isEdit) {
        updateProductsRecord(formData, id)
      } else {
        createProductsRecord(formData)
      }
    }
  }

  const cancelCategory = () => {
    setAddCategoryDaialogue(false)
  }

  const handleCategoryChange = () => {
    setAddCategoryDaialogue(true)
    if (isCreateCategorySuccess) {
      initCategory()
    }
  }

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      {
        label: t('product.Products'),
        command: () => history(ROUTE_PATH.PRODUCTS.HOME)
      },
      {
        label: isEdit ? t('product.Update Products') : t('product.Add Product'),
        command: () =>
          history(
            isEdit
              ? `${ROUTE_PATH.PRODUCTS.EDIT.replace(':id', id)}`
              : ROUTE_PATH.PRODUCTS.ADD
          )
      }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }
    return (
      <div className="flex justify-content-between align-align-items-center  border-1 border-200  border-round  bg-white ">
        <BreadCrumb model={breadCrumbItems} home={home} className="border-0" />
      </div>
    )
  }

  const addCategoryProps = {
    createCategoryRecord,
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    categoryError,
    user,
    isEdit,
    userRole,
    isCreateCategoryError,
    isCreateCategorySuccess,
    fetchCategoryList,
    tenantList,
    navigatetoCategory,
    openCategoryDialog,
    setAddCategoryDaialogue,
    selectedTenantId
  }

  return (
    <div className="">
      {renderPageHeaderSection()}

      <div className="flex w-full bg-white flex-column align-align-items-center justify-content-center border-1 border-200 border-round mt-3 p-2 md:p-0  ">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" w-full md:px-4 mt-2"
        >
          {userRole === USER_ROLE.SUPER_ADMIN && (
            <div className="card">
              <Fieldset legend={t('product.CompanyDetails')}>
                <div className="grid">
                  <div className=" col-12 md:col-6">
                    <MzAutoComplete
                      control={control}
                      name={FORM_FIELDS_NAME.TENANT.name}
                      label={FORM_FIELDS_NAME.TENANT.label}
                      optionLabel={'companyName'}
                      optionValue={'id'}
                      placeholder={FORM_FIELDS_NAME.TENANT.placeholder}
                      isError={!!errors[FORM_FIELDS_NAME.TENANT.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.TENANT.name
                      )}
                      rules={FORM_FIELDS_NAME.TENANT.rules}
                      suggestions={tenantList}
                      value={getTenantLabel(
                        getValues(FORM_FIELDS_NAME.TENANT.name)
                      )}
                      dropdown
                      disabled={isEdit}
                    />
                  </div>
                </div>
              </Fieldset>
            </div>
          )}
          <div className="card mt-3">
            <Fieldset legend={t('product.Product Details')}>
              <div className="grid">
                <div className=" col-12 md:col-6">
                  <MzInput
                    control={control}
                    name={FORM_FIELDS_NAME.NAME.name}
                    label={FORM_FIELDS_NAME.NAME.label}
                    placeholder={FORM_FIELDS_NAME.NAME.placeholder}
                    type={FORM_FIELDS_NAME.NAME.type}
                    isError={errors[FORM_FIELDS_NAME.NAME.name]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.NAME.name)}
                    rules={FORM_FIELDS_NAME.NAME.rules}
                    wrapperClass={'p-float-label'}
                  />
                </div>
                <div className=" col-12 md:col-6">
                  <MzTextarea
                    control={control}
                    name={FORM_FIELDS_NAME.DESCRIPTION.name}
                    label={FORM_FIELDS_NAME.DESCRIPTION.label}
                    placeholder={FORM_FIELDS_NAME.DESCRIPTION.placeholder}
                    value={watch(FORM_FIELDS_NAME.DESCRIPTION.name)}
                    isError={errors[FORM_FIELDS_NAME.DESCRIPTION.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.DESCRIPTION.name
                    )}
                    rules={FORM_FIELDS_NAME.DESCRIPTION.rules}
                    wrapperClass={'p-float-label'}
                    rows={3}
                  />
                </div>
                <div className="col-12 md:col-6">
                  <MzAutoComplete
                    control={control}
                    name={FORM_FIELDS_NAME.CATEGORY.name}
                    label={FORM_FIELDS_NAME.CATEGORY.label}
                    optionLabel={'name'}
                    optionValue={'id'}
                    placeholder={FORM_FIELDS_NAME.CATEGORY.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.CATEGORY.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.CATEGORY.name
                    )}
                    rules={FORM_FIELDS_NAME.CATEGORY.rules}
                    wrapperClass={'p-float-label'}
                    suggestions={activeCategorySuggestion ?? []}
                    dropdown
                    value={getCategorylabel(
                      getValues(FORM_FIELDS_NAME.CATEGORY.name)
                    )}
                  />
                  {!isEdit && (
                    <i
                      className="pi pi-plus"
                      onClick={handleCategoryChange}
                      style={{
                        position: 'relative',
                        fontSize: '13px',
                        bottom: '33%',
                        left: '94%',
                        cursor: 'pointer'
                      }}
                    ></i>
                  )}
                </div>
                <div className="col-12 md:col-6">
                  {!isEdit ? (
                    <div className="flex gap-3 align-items-center w-full">
                      {filePreview.icon && (
                        <img
                          src={filePreview.icon}
                          alt="profile"
                          className="h-4rem w-4rem border-circle"
                        />
                      )}
                      <div className="w-full">
                        <MzFileInput
                          name={FORM_FIELDS_NAME.ICON.name}
                          label={FORM_FIELDS_NAME.ICON.label}
                          placeholder={FORM_FIELDS_NAME.ICON.placeholder}
                          rules={FORM_FIELDS_NAME.ICON.rules}
                          control={control}
                          isError={
                            !!errors[FORM_FIELDS_NAME.ICON.name] ||
                            !!fileErrors.icon
                          }
                          errorMsg={
                            getFormErrorMessage(FORM_FIELDS_NAME.ICON.name) ||
                            fileErrors.icon
                          }
                          wrapperClass="p-float-label"
                          onChange={(file) => {
                            handleIconChange(file, 'icon')
                            setValue(FORM_FIELDS_NAME.ICON.name, file)
                          }}
                          accept=".jpeg, .jpg, .png"
                        />
                        {!iconError && (
                          <small className="text-gray-500">
                            {t('product.uploadImageOnly')}
                          </small>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex gap-3 align-items-center w-full">
                      {(filePreview.icon ||
                        watch(FORM_FIELDS_NAME.ICON.name)) && (
                        <img
                          src={
                            filePreview.icon ??
                            watch(FORM_FIELDS_NAME.ICON.name)
                          }
                          alt="profile"
                          className="h-4rem w-4rem border-circle"
                        />
                      )}
                      <div className="w-full">
                        <MzFileInput
                          name={FORM_FIELDS_NAME.ICON.name}
                          label={FORM_FIELDS_NAME.ICON.label}
                          placeholder={FORM_FIELDS_NAME.ICON.placeholder}
                          control={control}
                          isError={
                            !!errors[FORM_FIELDS_NAME.ICON.name] ||
                            !!fileErrors.icon
                          }
                          errorMsg={
                            getFormErrorMessage(FORM_FIELDS_NAME.ICON.name) ||
                            fileErrors.icon
                          }
                          wrapperClass="p-float-label"
                          onChange={(file) => {
                            handleIconChange(file, 'icon')
                            setValue(FORM_FIELDS_NAME.ICON.name, file)
                          }}
                          accept=".jpeg, .jpg, .png"
                        />
                        {!iconError && (
                          <small className="text-gray-500">
                            {t('product.messagedeleteimg')}
                          </small>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-12 md:col-6">
                  {!isEdit ? (
                    <div className="flex align-items-center gap-3 w-full">
                      {filePreview.brochure && (
                        <Button
                          icon="pi pi-eye"
                          className="p-button-rounded p-button-text p-button-help"
                          aria-label="Favorite"
                          disabled={!watch(FORM_FIELDS_NAME.BROCHURE.name)}
                          onClick={(event) => {
                            event.preventDefault()
                            if (
                              filePreview.brochure &&
                              filePreview.brochure.startsWith('blob:')
                            ) {
                              window.open(filePreview.brochure, '_blank')
                            }
                          }}
                        />
                      )}
                      <div className="w-full">
                        <MzFileInput
                          name={FORM_FIELDS_NAME.BROCHURE.name}
                          label={FORM_FIELDS_NAME.BROCHURE.label}
                          placeholder={FORM_FIELDS_NAME.BROCHURE.placeholder}
                          rules={FORM_FIELDS_NAME.BROCHURE.rules}
                          control={control}
                          isError={
                            !!errors[FORM_FIELDS_NAME.BROCHURE.name] ||
                            !!fileErrors.brochure
                          }
                          errorMsg={
                            getFormErrorMessage(
                              FORM_FIELDS_NAME.BROCHURE.name
                            ) || fileErrors.brochure
                          }
                          wrapperClass="p-float-label"
                          onChange={(file) => {
                            handleFileChange(file, 'brochure')
                            setValue(FORM_FIELDS_NAME.BROCHURE.name, file)
                          }}
                          accept="application/pdf"
                        />
                        {!pdfError && (
                          <small className="text-gray-500">
                            {t('OrganizationComp.uploadPdfOnly')}
                          </small>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex align-items-center gap-3 w-full">
                      {filePreview.brochure ||
                      watch(FORM_FIELDS_NAME.BROCHURE.name) ? (
                        <Button
                          icon="pi pi-eye"
                          className="p-button-rounded p-button-text p-button-help"
                          aria-label="Favorite"
                          disabled={!watch(FORM_FIELDS_NAME.BROCHURE.name)}
                          onClick={(event) => {
                            event.preventDefault()
                            if (
                              filePreview.brochure &&
                              filePreview.brochure.startsWith('blob:')
                            ) {
                              window.open(filePreview.brochure, '_blank')
                            } else {
                              window.open(
                                watch(FORM_FIELDS_NAME.BROCHURE.name),
                                '_blank'
                              )
                            }
                          }}
                        />
                      ) : null}
                      <div className="w-full">
                        <MzFileInput
                          name={FORM_FIELDS_NAME.BROCHURE.name}
                          label={FORM_FIELDS_NAME.BROCHURE.label}
                          placeholder={FORM_FIELDS_NAME.BROCHURE.placeholder}
                          control={control}
                          isError={
                            !!errors[FORM_FIELDS_NAME.BROCHURE.name] ||
                            !!fileErrors.brochure
                          }
                          errorMsg={
                            getFormErrorMessage(
                              FORM_FIELDS_NAME.BROCHURE.name
                            ) || fileErrors.brochure
                          }
                          wrapperClass="p-float-label"
                          onChange={(file) => {
                            handleFileChange(file, 'brochure')
                            setValue(FORM_FIELDS_NAME.BROCHURE.name, file)
                          }}
                          accept="application/pdf"
                        />
                        {!pdfError && (
                          <small className="text-gray-500">
                            {t('OrganizationComp.uploadPdfOnly')}
                          </small>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {/* Video */}
                <div className="col-12 md:col-6">
                  {!isEdit ? (
                    <div className="flex align-items-center gap-3 w-full">
                      {filePreview.video && (
                        <video
                          controls
                          className="h-4rem w-4rem"
                          src={filePreview.video}
                          type="video/mp4"
                          Your
                          browser
                          does
                          not
                          support
                          the
                          video
                        ></video>
                      )}
                      <div className="w-full">
                        <MzFileInput
                          name={FORM_FIELDS_NAME.VIDEO.name}
                          label={FORM_FIELDS_NAME.VIDEO.label}
                          placeholder={FORM_FIELDS_NAME.VIDEO.placeholder}
                          rules={FORM_FIELDS_NAME.VIDEO.rules}
                          control={control}
                          isError={
                            !!errors[FORM_FIELDS_NAME.VIDEO.name] ||
                            !!fileErrors.video
                          }
                          errorMsg={
                            getFormErrorMessage(FORM_FIELDS_NAME.VIDEO.name) ||
                            fileErrors.video
                          }
                          wrapperClass="p-float-label"
                          onChange={(file) => {
                            handleVideoChange(file, 'video')
                            setValue(FORM_FIELDS_NAME.VIDEO.name, file)
                          }}
                          accept="video/*"
                        />
                        {!videoError && (
                          <small className="text-gray-500">
                            {t('OrganizationComp.uploadVideoOnly')}
                          </small>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex align-items-center gap-3 w-full">
                      {filePreview.video || formFieldValueMap?.video ? (
                        <video
                          controls
                          className="h-4rem w-4rem"
                          src={filePreview.video ?? formFieldValueMap?.video}
                          type="video/mp4"
                          Your
                          browser
                          does
                          not
                          support
                          the
                          video
                        ></video>
                      ) : null}
                      <div className="w-full">
                        <MzFileInput
                          name={FORM_FIELDS_NAME.VIDEO.name}
                          label={FORM_FIELDS_NAME.VIDEO.label}
                          placeholder={FORM_FIELDS_NAME.VIDEO.placeholder}
                          control={control}
                          isError={
                            !!errors[FORM_FIELDS_NAME.VIDEO.name] ||
                            !!fileErrors.video
                          }
                          errorMsg={
                            getFormErrorMessage(FORM_FIELDS_NAME.VIDEO.name) ||
                            fileErrors.video
                          }
                          wrapperClass="p-float-label"
                          onChange={(file) => {
                            handleVideoChange(file, 'video')
                            setValue(FORM_FIELDS_NAME.VIDEO.name, file)
                          }}
                          accept="video/*"
                        />
                        {!videoError && (
                          <small className="text-gray-500">
                            {t('OrganizationComp.uploadVideoOnly')}
                          </small>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-12 lg:col-6">
                  {!isEdit ? (
                    <div className="flex align-items-center gap-3 w-full">
                      {filePreview?.image && (
                        <img
                          src={filePreview?.image}
                          alt={`profile`}
                          className="h-4rem w-4rem border-circle sm:h-3rem sm:w-3rem"
                        />
                      )}

                      <div className="w-full">
                        <MzFileInput
                          name={FORM_FIELDS_NAME.IMAGE.name}
                          label={FORM_FIELDS_NAME.IMAGE.label}
                          placeholder={FORM_FIELDS_NAME.IMAGE.placeholder}
                          rules={FORM_FIELDS_NAME.IMAGE.rules}
                          control={control}
                          isError={
                            !!errors[FORM_FIELDS_NAME.IMAGE.name] ||
                            !!fileErrors.image
                          }
                          errorMsg={
                            getFormErrorMessage(FORM_FIELDS_NAME.IMAGE.name) ||
                            fileErrors.image
                          }
                          wrapperClass="p-float-label"
                          onChange={(file) => {
                            handleImageChange(file, 'image')
                            setValue(FORM_FIELDS_NAME.IMAGE.name, file)
                            clearErrors(FORM_FIELDS_NAME.IMAGE.name);
                          }}
                          multiple={true}
                          accept=".jpeg, .jpg, .png"
                        />
                        {!imageError && (
                          <small className="text-gray-500">
                            {t('OrganizationComp.uploadImageOnly')}
                          </small>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-wrap gap-3 w-full pt-4">
                      {formFieldValueMap &&
                      formFieldValueMap.images &&
                      formFieldValueMap.images.length > 0 &&
                      FORM_FIELDS_NAME.IMAGE.name
                        ? formFieldValueMap.images.map((image, index) => (
                            <div key={index} className="relative">
                              <img
                                src={image.url}
                                alt={`profile-${index}`}
                                className="h-4rem w-4rem border-circle sm:h-3rem sm:w-3rem"
                              />
                              <button
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleDelete(e, image.id)
                                }}
                                className="absolute top-0 right-0 bg-red-600 border-circle border-red-600 shadow-none text-white font-bold font-sm cursor-pointer"
                              >
                                X
                              </button>
                              <Dialog
                                header={t('product.Confirmation')}
                                visible={openDialog}
                                onHide={cancelDelete}
                                footer={
                                  <div>
                                    <Button
                                      label={t('product.yes')}
                                      onClick={confirmDelete}
                                      className="p-button-primary"
                                    />
                                    <Button
                                      label={t('product.no')}
                                      onClick={cancelDelete}
                                      className="p-button-secondary"
                                    />
                                  </div>
                                }
                              >
                                <div
                                  className="text-wrap"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px'
                                  }}
                                >
                                  <i
                                    className="pi pi-exclamation-triangle"
                                    style={{
                                      fontSize: '2em',
                                      color: 'orange',
                                      lineHeight: '1'
                                    }}
                                  ></i>
                                  <p style={{ margin: 0, lineHeight: '1' }}>
                                    {t('product.deletemessage')}
                                  </p>
                                </div>
                              </Dialog>
                            </div>
                          ))
                        : FORM_FIELDS_NAME.IMAGE.name}
                      <div className="w-full">
                        <MzFileInput
                          name={FORM_FIELDS_NAME.IMAGE.name}
                          label={FORM_FIELDS_NAME.IMAGE.label}
                          placeholder={FORM_FIELDS_NAME.IMAGE.placeholder}
                          control={control}
                          isError={
                            !!errors[FORM_FIELDS_NAME.IMAGE.name] ||
                            !!fileErrors.image
                          }
                          errorMsg={
                            getFormErrorMessage(FORM_FIELDS_NAME.IMAGE.name) ||
                            fileErrors.image
                          }
                          wrapperClass="p-float-label"
                          onChange={(file) => {
                            handleImageChange(file, 'images')
                            setValue(FORM_FIELDS_NAME.IMAGE.name, file)
                          }}
                          multiple={true}
                          accept=".jpeg, .jpg, .png"
                        />
                        {!imageError && (
                          <small className="text-gray-500">
                            {t('OrganizationComp.uploadImageOnly')}
                          </small>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Fieldset>
          </div>
          <div className="grid justify-content-center mt-3 mb-5">
            <div className="col-12 md:col-4">
              <Button
                label={t('product.Cancel')}
                className="w-full"
                severity="secondary"
                onClick={() => navigateProducts()}
                disabled={isLoading || isPageLevelError}
              />
            </div>
            <div className="col-12 md:col-4">
              <Button
                type="submit"
                label={t('product.Submit')}
                className="w-full"
                disabled={isLoading || isPageLevelError}
              />
            </div>
            <ConfirmDialog />
          </div>
        </form>
      </div>

      <MzDialog
        visible={addCategoryDialogue}
        onHide={cancelCategory}
        maximizable={false}
        height="230px"
        children={
          <>
            <AddCategoryComponent addCategoryProps={addCategoryProps} />
          </>
        }
      ></MzDialog>
    </div>
  )
}

export default AddProductsComponent
