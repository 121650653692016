import axios from 'axios'
import { API_PATH } from '../../constant/urlConstant'
import { handleAPIError } from '../common/errorHandler'
import { baseUrl } from '../PostAPI'
import { getDecoratedUrl } from '../common/urlService'

export const fetchOrganizationList = async () => {
  const url = `${baseUrl}${API_PATH.ORGANIZATION.FETCH}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.data.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}

export const fetchPagableOrganizationList = async (payload) => {
  const { pageSize, pageNumber } = payload ?? {}

  const url = `${baseUrl}${API_PATH.ORGANIZATION.FETCHPAGE}`

  let finalUrl = getDecoratedUrl({
    url,
    payload: { pageSize, pageNumber }
  })

  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(finalUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.data.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }

    return result?.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}

export const fetchOrganizationRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.ORGANIZATION.FETCH}/${payload}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}

export const createOrganizationRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.ORGANIZATION.ADD}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    if (result?.data?.error || result.status !== 201) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error.response.data.detail)
  }
}

export const updateOrganizationRecord = async (payload, id) => {
  const url = `${baseUrl}${API_PATH.ORGANIZATION.EDIT}/${id}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.put(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error.response.data.detail)
  }
}

export const deleteOrganization = async (organizationId) => {
  const url = `${baseUrl}${API_PATH.ORGANIZATION.DELETE}/${organizationId}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 204) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error.response.data.detail)
  }
}
