import {
  INIT_ENQUIRY,
  FETCH_ENQUIRY_LIST,
  FETCH_ENQUIRY_RECORD,
  CREATE_ENQUIRY_RECORD,
  DELETE_ENQUIRY,
  UPDATE_ENQUIRY_RECORD,
  GET_ENQUIRY_COUNT
} from '../../../constant/actionTypes/enquiry'
const formFieldValueMap = {
  name: '',
  phone: '',
  email: '',
  address: '',
  user: {
    id: ''
  }
}

const initialState = {
  enquiryList: null,
  enquiryCount: 0,
  selectedEnquiry: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateEnquirySuccess: false,
  isEditEnquirySuccess: false,
  isEnquiryDetailSuccess: false,
  isDeleteEnquirySuccess: false,
  isDeleteEnquiryError: false,
  isEnquiryDetailError: false,
  isCreateEnquiryError: false,
  isEditEnquiryError: false
}

const enquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_ENQUIRY:
      return {
        ...state,
        enquiryList: null,
        formFieldValueMap: null,
        selectedEnquiry: null,
        isLoading: false,
        error: null
      }
    case FETCH_ENQUIRY_LIST.START:
    case FETCH_ENQUIRY_RECORD.START:
    case CREATE_ENQUIRY_RECORD.START:
    case DELETE_ENQUIRY.START:
    case UPDATE_ENQUIRY_RECORD.START:
    case GET_ENQUIRY_COUNT.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreateEnquirySuccess: false,
        isEditEnquirySuccess: false,
        isEnquiryDetailSuccess: false,
        isDeleteEnquirySuccess: false,
        isDeleteEnquiryError: false,
        isEnquiryDetailError: false,
        isCreateEnquiryError: false,
        isEditEnquiryError: false
      }
    case FETCH_ENQUIRY_LIST.SUCCESS:
      return {
        ...state,
        enquiryList: {
          ...action.payload,
          content: action.payload.content.map((enquiry) => ({
            ...enquiry,
            fullName: `${enquiry.contacts.user.firstName} ${enquiry.contacts.user.lastName}`,
            emailVerified: enquiry.contacts.emailVerified
          }))
        },
        isLoading: false,
        error: null
      }
    case FETCH_ENQUIRY_RECORD.SUCCESS:
      return {
        ...state,
        selectedEnquiry: action.payload,
        formFieldValueMap: {
          tenant: action?.payload?.tenant?.id ?? '',
          id: action?.payload?.id ?? '',
          name: action?.payload?.name ?? ''
        },
        isLoading: false,
        error: null,
        isEnquiryDetailSuccess: true
      }
    case CREATE_ENQUIRY_RECORD.SUCCESS:
      return {
        ...state,
        ...state.enquiryList,
        content: [...state.enquiryList.content, action.payload],
        isLoading: false,
        error: null,
        isCreateEnquirySuccess: true
      }
    case UPDATE_ENQUIRY_RECORD.SUCCESS:
      return {
        ...state,
        enquiryList: {
          ...state.enquiryList,
          content: state.enquiryList.content.map((enquiry) =>
            enquiry.id === action.payload.id ? action.payload : enquiry
          )
        },
        isLoading: false,
        error: null,
        isEditEnquirySuccess: true
      }
    case DELETE_ENQUIRY.SUCCESS:
      return {
        ...state,
        ...state.enquiryList,
        content: state.enquiryList.content.filter(
          (enquiry) => enquiry.id !== action.payload.enquiryId
        ),
        isLoading: false,
        error: null,
        isDeleteEnquirySuccess: true
      }
    case GET_ENQUIRY_COUNT.SUCCESS:
      return {
        ...state,
        enquiryCount: action.payload,
        isLoading: false,
        error: null
      }
    case FETCH_ENQUIRY_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case FETCH_ENQUIRY_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isEnquiryDetailError: true
      }
    case GET_ENQUIRY_COUNT.ERROR:
      return {
        ...state,
        enquiryCount: 0,
        isLoading: false,
        error: null
      }
    case UPDATE_ENQUIRY_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isEditEnquiryError: true
      }
    case DELETE_ENQUIRY.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDeleteEnquiryError: true
      }
    case CREATE_ENQUIRY_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isCreateEnquiryError: true
      }
    default:
      return state
  }
}

export default enquiryReducer
