export const INIT_DEPARTMENT = 'INIT_DEPARTMENT'

export const FETCH_DEPARTMENT_LIST = {
  START: 'FETCH_DEPARTMENT_START',
  SUCCESS: 'FETCH_DEPARTMENT_SUCCESS',
  ERROR: 'FETCH_DEPARTMENT_ERROR'
}

export const FETCH_DEPARTMENT_RECORD = {
  START: 'FETCH_DEPARTMENT_RECORD_START',
  SUCCESS: 'FETCH_DEPARTMENT_RECORD_SUCCESS',
  ERROR: 'FETCH_DEPARTMENT_RECORD_ERROR'
}

export const CREATE_DEPARTMENT_RECORD = {
  START: 'CREATE_DEPARTMENT_RECORD_START',
  SUCCESS: 'CREATE_DEPARTMENT_RECORD_SUCCESS',
  ERROR: 'CREATE_DEPARTMENT_RECORD_ERROR'
}
export const UPDATE_DEPARTMENT_RECORD = {
  START: 'UPDATE_DEPARTMENT_RECORD_START',
  SUCCESS: 'UPDATE_DEPARTMENT_RECORD_SUCCESS',
  ERROR: 'UPDATE_DEPARTMENT_RECORD_ERROR'
}

export const DELETE_DEPARTMENT = {
  START: 'DELETE_DEPARTMENT_START',
  SUCCESS: 'DELETE_DEPARTMENT_SUCCESS',
  ERROR: 'DELETE_DEPARTMENT_ERROR'
}
