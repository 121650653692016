import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import DashboardComponent from '../../components/Dashboard'
import {
  getAmenitiesCount,
  getAmenitiesIntrestCount,
  getAmenitiesEnqueryCount
} from '../../redux/action/amenities'
import { getContactCount } from '../../redux/action/contact'
import { getEmployeeCount } from '../../redux/action/employee'
import { USER_ROLE } from '../../constant/actionTypes/role'
import {
  getProductsCount,
  getProductsEnqueryCount,
  getProductsIntrestCount
} from '../../redux/action/products'

const DashboardScreen = (props) => {
  const {
    getAmenitiesCount,
    getContactCount,
    getEmployeeCount,
    getProductsCount,
    amenitiesCount,
    contactCount,
    employeeCount,
    productsCount,
    user,
    userRole,
    getAmenitiesIntrestCount,
    getAmenitiesEnqueryCount,
    amenitiesIntrestCount,
    amenitiesEnqueryCount,
    getProductsEnqueryCount,
    getProductsIntrestCount,
    productsEnqueryCount,
    productsIntrestCount
  } = props

  const fetchDashboardCounts = (role, tenantId) => {
    getAmenitiesCount(role, tenantId)
    getContactCount(role, tenantId)
    getEmployeeCount(role, tenantId)
    getProductsCount(role, tenantId)
    getAmenitiesIntrestCount(role, tenantId)
    getAmenitiesEnqueryCount(role, tenantId)
    getProductsEnqueryCount(role, tenantId)
    getProductsIntrestCount(role, tenantId)
  }

  useEffect(() => {
    const tenantId = user?.tenant?.id ?? ''
    const userId = user?.id ?? ''

    if (userRole === USER_ROLE.ADMIN) {
      fetchDashboardCounts(userRole, tenantId)
    } else if (
      userRole === USER_ROLE.SUPER_ADMIN ||
      userRole === USER_ROLE.USER
    ) {
      getAmenitiesCount(userRole, tenantId)
      getContactCount(userRole, userId)
      getEmployeeCount(userRole, tenantId)
      getProductsCount(userRole, tenantId)
      getAmenitiesIntrestCount(userRole, tenantId)
      getAmenitiesEnqueryCount(userRole, tenantId)
      getProductsEnqueryCount(userRole, tenantId)
      getProductsIntrestCount(userRole, tenantId)
    }
    // eslint-disable-next-line
  }, [userRole, user?.tenant?.id])

  const dashbaordProps = {
    getAmenitiesCount,
    getContactCount,
    getEmployeeCount,
    amenitiesCount,
    contactCount,
    employeeCount,
    user,
    userRole,
    getProductsCount,
    productsCount,
    amenitiesIntrestCount,
    amenitiesEnqueryCount,
    productsIntrestCount,
    productsEnqueryCount
  }

  return (
    <>
      <DashboardComponent dashbaordProps={dashbaordProps} />
    </>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    getAmenitiesCount: (role, payload) =>
      dispatch(getAmenitiesCount(role, payload)),
    getContactCount: (role, payload) =>
      dispatch(getContactCount(role, payload)),
    getEmployeeCount: (role, payload) =>
      dispatch(getEmployeeCount(role, payload)),
    getProductsCount: (role, payload) =>
      dispatch(getProductsCount(role, payload)),
    getAmenitiesEnqueryCount: (role, payload) =>
      dispatch(getAmenitiesEnqueryCount(role, payload)),
    getAmenitiesIntrestCount: (role, payload) =>
      dispatch(getAmenitiesIntrestCount(role, payload)),
    getProductsEnqueryCount: (role, payload) =>
      dispatch(getProductsEnqueryCount(role, payload)),
    getProductsIntrestCount: (role, payload) =>
      dispatch(getProductsIntrestCount(role, payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    employeeCount: state.employeeReducer.employeeCount,
    amenitiesCount: state.amenitiesReducer.amenitiesCount,
    contactCount: state.contactReducer.contactCount,
    userRole: state.loginReducer.userRole,
    user: state.userProfileReducer.user,
    productsCount: state.productsReducer.productsCount,
    amenitiesIntrestCount: state.amenitiesReducer.amenitiesIntrestCount,
    amenitiesEnqueryCount: state.amenitiesReducer.amenitiesEnqueryCount,
    productsEnqueryCount: state.productsReducer.productsEnqueryCount,
    productsIntrestCount: state.productsReducer.productsIntrestCount
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen)
