import React from 'react'
import { connect } from 'react-redux'
import AddTenantComponent from '../../../components/Tenant/addTenant'
import { MzToast, TOAST_SEVERITY } from '../../../common/MzToast'
import { ProgressBar } from 'primereact/progressbar'
import { useTranslation } from 'react-i18next'
import {
  createTenantRecord,
  fetchTenantRecord,
  updateTenantRecord,
  deleteTenant
} from '../../../redux/action/tenant'
const AddTenantScreen = (props) => {
  const {
    isCreateTenantSuccess,
    isCreateTenantError,
    isTenantDetailSuccess,
    isTenantDetailError,
    isEditTenantSuccess,
    isEditTenantError,
    isEdit,
    error,
    isLoading,
    createTenantRecord,
    formFieldValueMap,
    isPageLevelError,
    fetchTenantRecord,
    updateTenantRecord
  } = props

  const { t } = useTranslation()

  const getToastProps = () => {
    if (isCreateTenantSuccess || isEditTenantSuccess) {
      const toastTitle = isEdit
        ? t('tenant.toastUpdateSuccess')
        : t('tenant.toastCreateSuccess')
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isCreateTenantError || isTenantDetailError || isEditTenantError) {
      let toastTitle = error ? error : t('tenant.toastCreateError')
      if (isEditTenantError) {
        toastTitle = error ? error : t('tenant.toastUpdateError')
      } else if (isTenantDetailError) {
        toastTitle = t('tenant.toastError')
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }

  const addTenantProps = {
    createTenantRecord,
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    isEdit,
    isCreateTenantSuccess,
    deleteTenant,
    isEditTenantSuccess,
    isTenantDetailSuccess,
    fetchTenantRecord,
    updateTenantRecord
  }
  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <AddTenantComponent addTenantProps={addTenantProps} />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    createTenantRecord: (payload) => dispatch(createTenantRecord(payload)),
    fetchTenantRecord: (payload) => dispatch(fetchTenantRecord(payload)),
    updateTenantRecord: (payload, id) =>
      dispatch(updateTenantRecord(payload, id))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    formFieldValueMap: selectFormFieldValueMap(state.tenantReducer),
    isPageLevelError: state.tenantReducer.isPageLevelError,
    isLoading: state.tenantReducer.isLoading,
    error: state.tenantReducer.error,
    isTenantDetailSuccess: state.tenantReducer.isTenantDetailSuccess,
    isCreateTenantSuccess: state.tenantReducer.isCreateTenantSuccess,
    isCreateTenantError: state.tenantReducer.isCreateTenantError,
    isEditTenantSuccess: state.tenantReducer.isEditTenantSuccess,
    isEditTenantError: state.tenantReducer.isEditTenantError,
    isTenantDetailError: state.tenantReducer.isTenantDetailError
  }
}

const selectFormFieldValueMap = (tenantReducer) => {
  return tenantReducer.formFieldValueMap
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTenantScreen)
