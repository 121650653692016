import React, { useEffect, useState } from 'react'
import EmployeeComponent from '../../components/employee'
import {
  deleteEmployee,
  fetchEmployeeList,
  initEmployee
} from '../../redux/action/employee'
import { connect } from 'react-redux'
import { USER_ROLE } from '../../constant/actionTypes/role'
import { ProgressBar } from 'primereact/progressbar'
import { fetchTenantList, initTenant } from '../../redux/action/tenant'
import employeeTableData from './data.json'
import { INIT_TENANT } from '../../constant/actionTypes/tenant'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import MzDialog from '../../common/MzDialog'
import CardComponent from '../../components/card'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import { useTranslation } from 'react-i18next'

const EmployeeScreen = (props) => {
  const {
    fetchEmployeeList,
    fetchTenantList,
    initEmployee,
    employeesList,
    isLoading,
    isPageLevelError,
    userRole,
    tenantList,
    user,
    deleteEmployee,
    error,
    isDeleteEmployeeSuccess,
    isDeleteEmployeeError
  } = props

  useEffect(() => {
    initEmployee()
    initTenant()
    const payload = {
      pageNumber: 0,
      pageSize: 10
    }
    if (userRole === USER_ROLE.SUPER_ADMIN) {
      fetchTenantList()
      fetchEmployeeList(payload)
    } else {
      const modifiedPayload = {
        ...payload,
        tenantId: user?.tenant?.id || ''
      }
      fetchEmployeeList(modifiedPayload)
    }
    // eslint-disable-next-line
  }, [
    isDeleteEmployeeSuccess,
    fetchEmployeeList,
    fetchTenantList,
    initEmployee
  ])
  const history = useNavigate()
  const { t } = useTranslation()
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)
  const [isReadDailogVisible, setIsReadDialogVisible] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [visible, setVisible] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const handleOnReadRecord = (data) => {
    setSelectedEmployee(data)
    setIsReadDialogVisible(true)
  }

  const handleOnDeleteRecord = (data) => {
    setSelectedEmployee(data)
    setIsDeleteDialogVisible(true)
  }
  const handleOnEditRecord = (data) => {
    history(`${ROUTE_PATH.EMPOLYEE.EDIT.replace(':id', data?.id)}`)
  }

  const handleOnCreatedRecord = () => {
    history(ROUTE_PATH.EMPOLYEE.ADD)
  }

  const confirmDelete = () => {
    if (selectedEmployee) {
      deleteEmployee(selectedEmployee.id)
      setIsDeleteDialogVisible(false)
      setSelectedEmployee(null)
    }
  }

  const cancelDelete = () => {
    setIsDeleteDialogVisible(false)
    setSelectedEmployee(null)
  }

  const cancelRead = () => {
    setIsReadDialogVisible(false)
    setSelectedEmployee(null)
  }

  const employeeProps = {
    employeesList,
    tenantList,
    fetchEmployeeList,
    isPageLevelError,
    isLoading,
    employeeTableData,
    userRole,
    user,
    handleOnReadRecord,
    handleOnDeleteRecord,
    handleOnEditRecord,
    handleOnCreatedRecord
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }

  const getToastProps = () => {
    if (isDeleteEmployeeSuccess) {
      const toastTitle = t('employee.toastDeleteSuccess')
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isDeleteEmployeeError) {
      let toastTitle = error ? error?.error : t('employee.toastDeleteError')
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  const handleNavigation = (url) => {
    window.open(url, '_blank')
  }
  let otherLinks = {}
  try {
    const otherLinksString = selectedEmployee?.tenant?.otherLinks || '{}'
    otherLinks = JSON.parse(otherLinksString)
  } catch (error) {
    console.error('Error parsing otherLinks:', error)
  }
 
  const showDialog = (data = false) => {
    setSelectedImage(data)
    setVisible(true)
  } 
  const hideDialog = () => {
    setVisible(false)
    setSelectedImage(null)
  }

  const headerTemplate = () => {
    return (
      <>
        {selectedEmployee && (
          <div className="flex justify-content-center align-items-center w-full">
            <div style={{ minWidth: '360px', maxWidth: '360px' }}>
              <div className="w-full  flex align-items-center  ">
                {selectedEmployee.tenant?.companyLogo ? (
                  <img
                    src={selectedEmployee.tenant?.companyLogo}
                    className="h-2rem w-2rem border-circle cursor-pointer"
                    alt="Logo"
                    onClick={() =>
                      showDialog(selectedEmployee.tenant?.companyLogo || null)
                    }
                  />
                ) : null}
                <div className="text-sm ml-2">
                  <span>{selectedEmployee?.tenant?.companyName ?? ''}</span>
                </div>
              </div>

              <div className="flex ml-2 mt-3 w-full  gap-3">
                <i
                  className="pi pi-facebook"
                  style={{
                    cursor: !otherLinks.facebook ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.facebook && handleNavigation(otherLinks.facebook)
                  }
                />
                <i
                  className="pi pi-instagram"
                  style={{
                    cursor: !otherLinks.instagram ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.instagram &&
                    handleNavigation(otherLinks.instagram)
                  }
                />
                <i
                  className="pi pi-twitter"
                  style={{
                    cursor: !otherLinks.twitter ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.twitter && handleNavigation(otherLinks.twitter)
                  }
                />

                <i
                  className="pi pi-linkedin "
                  style={{
                    cursor: !otherLinks.linkedin ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.linkedin && handleNavigation(otherLinks.linkedin)
                  }
                />
                <i
                  className="pi pi-globe"
                  style={{
                    cursor: !otherLinks.website ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.website && handleNavigation(otherLinks.website)
                  }
                />
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <EmployeeComponent employeeProps={employeeProps} />
      <Dialog
        header={`${t('employee.deleteDailogHeader.Confirm')} ${
          selectedEmployee?.active
            ? t('employee.deleteDailogHeader.Deactivate')
            : t('employee.deleteDailogHeader.Activate')
        }`}
        visible={isDeleteDialogVisible}
        onHide={cancelDelete}
        footer={
          <div>
            <Button
              severity={selectedEmployee?.active ? 'primary' : ''}
              onClick={confirmDelete}
            >
              {t('employee.yes')}
            </Button>
            <Button severity="secondary" onClick={cancelDelete}>
              {t('employee.no')}
            </Button>
          </div>
        }
      >
        <div className="text-warp">
          {t('employee.deleteConfirmMessage')}
          {selectedEmployee?.active
            ? t('employee.deleteDailogHeader.Deactivate')
            : t('employee.deleteDailogHeader.Activate')}{' '}
          {t('employee.deleteConfirmMessage1')}
          <span className="text-primary">{selectedEmployee?.username}</span>
        </div>
      </Dialog>
      <MzDialog
        header={() => headerTemplate()}
        visible={isReadDailogVisible}
        onHide={cancelRead}
        children={
          <>
            <CardComponent selectedEmployee={selectedEmployee} />
          </>
        }
      ></MzDialog> 
       <Dialog visible={visible} onHide={hideDialog} style={{ maxWidth: '300px' }}>
        <div className="text-center w-full">
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full cursor-pointer"
            />
          )}
        </div>
      </Dialog>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initEmployee: () => dispatch(initEmployee()),
    initTenant: () => dispatch(INIT_TENANT()),
    fetchTenantList: () => dispatch(fetchTenantList()),
    fetchEmployeeList: (payload) => dispatch(fetchEmployeeList(payload)),
    deleteEmployee: (payload) => dispatch(deleteEmployee(payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isPageLevelError: state.employeeReducer.isPageLevelError,
    isLoading: state.employeeReducer.isLoading,
    error: state.employeeReducer.error,
    employeesList: state.employeeReducer.employeesList,
    userRole: state.loginReducer.userRole,
    tenantList: state.tenantReducer.tenantList,
    user: state.userProfileReducer.user,
    isDeleteEmployeeSuccess: state.employeeReducer.isDeleteEmployeeSuccess,
    isDeleteEmployeeError: state.employeeReducer.isDeleteEmployeeError
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeScreen)
