import React from 'react'
import './App.css'
import { BrowserRouter } from 'react-router-dom'

import Routing from './routing'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const App = () => {
  return (
    <BrowserRouter>
      <Routing />
      <ToastContainer />
    </BrowserRouter>
  )
}

export default App
