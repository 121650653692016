export const INIT_ORGANIZATION = 'INIT_ORGANIZATION'

export const FETCH_ORGANIZATION_LIST = {
  START: 'FETCH_ORGANIZATION_START',
  SUCCESS: 'FETCH_ORGANIZATION_SUCCESS',
  ERROR: 'FETCH_ORGANIZATION_ERROR'
}

export const FETCH_PAGABLE_ORGANIZATION_LIST = {
  START: 'FETCH_PAGABLE_ORGANIZATION_START',
  SUCCESS: 'FETCH_PAGABLE_ORGANIZATION_SUCCESS',
  ERROR: 'FETCH_PAGABLE_ORGANIZATION_ERROR'
}


export const FETCH_ORGANIZATION_RECORD = {
  START: 'FETCH_ORGANIZATION_RECORD_START',
  SUCCESS: 'FETCH_ORGANIZATION_RECORD_SUCCESS',
  ERROR: 'FETCH_ORGANIZATION_RECORD_ERROR'
}

export const CREATE_ORGANIZATION_RECORD = {
  START: 'CREATE_ORGANIZATION_RECORD_START',
  SUCCESS: 'CREATE_ORGANIZATION_RECORD_SUCCESS',
  ERROR: 'CREATE_ORGANIZATION_RECORD_ERROR'
}
export const UPDATE_ORGANIZATION_RECORD = {
  START: 'UPDATE_ORGANIZATION_RECORD_START',
  SUCCESS: 'UPDATE_ORGANIZATION_RECORD_SUCCESS',
  ERROR: 'UPDATE_ORGANIZATION_RECORD_ERROR'
}

export const DELETE_ORGANIZATION = {
  START: 'DELETE_ORGANIZATION_START',
  SUCCESS: 'DELETE_ORGANIZATION_SUCCESS',
  ERROR: 'DELETE_ORGANIZATION_ERROR'
}
