import React from 'react'
import { Controller } from 'react-hook-form'
import { classNames } from 'primereact/utils'
import { Message } from 'primereact/message'
import { useTranslation } from "react-i18next";

const MzFileInput = ({
  control,
  name,
  disabled,
  rules,
  labelClassName,
  label,
  onChange,
  errorMsg,
  isError,
  wrapperClass,
  placeholder,
  multiple,
  accept
}) => {
  const getLabelClassName = () => {
    return classNames({
      'p-error': isError,
      labelClassName
    })
  }

  const { t } = useTranslation();

  const handleFileChange = (e) => {
    const files = e.target.files
    if (files.length) {
      onChange && onChange(Array.from(files))
    } else {
      onChange && onChange(null)
    }
  }

  return (
    <div className="field" style={{ textAlign: 'left' }}>
      <label htmlFor={name} className={getLabelClassName()}>
        {t(label)}
        {rules?.required ? <span className="p-error">*</span> : null}
      </label>
      <span className={wrapperClass}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field, fieldState }) => (
            <input
              type="file"
              id={field.name}
              name={field.name}
              title={placeholder}
              placeholder={t(placeholder)}
              onChange={handleFileChange}
              disabled={disabled}
              multiple={multiple}
              accept={accept}
              className={classNames('p-inputtext w-full', {
                'p-invalid': fieldState.invalid
              })}
              style={{
                textAlign: 'center',
                height: '46px',
                width: '100%'
              }}
            />
          )}
        />
      </span>
      {errorMsg && (
        <Message
          className="mt-1 flex"
          style={{
            borderWidth: '0 0 0 2px'
          }}
          severity="error"
          content={errorMsg}
        />
      )}
    </div>
  )
}

export default MzFileInput
