import {
  INIT_CATEGORY,
  FETCH_CATEGORY_LIST,
  FETCH_CATEGORY_RECORD,
  CREATE_CATEGORY_RECORD,
  DELETE_CATEGORY,
  UPDATE_CATEGORY_RECORD
} from '../../../constant/actionTypes/category'
import { categoryService } from '../../../services'

export const initCategory = () => ({
  type: INIT_CATEGORY
})

export const fetchCategoryListStart = () => ({
  type: FETCH_CATEGORY_LIST.START
})

export const fetchCategoryListSuccess = (categorys) => ({
  type: FETCH_CATEGORY_LIST.SUCCESS,
  payload: categorys
})

export const fetchCategoryListError = (error) => ({
  type: FETCH_CATEGORY_LIST.ERROR,
  payload: { error }
})

export const fetchCategoryList = (payload) => {
  return (dispatch) => {
    dispatch(fetchCategoryListStart(payload))
    categoryService.fetchCategoryList(payload).then((categoryData) => {
      if (!categoryData.isError) {
        dispatch(fetchCategoryListSuccess(categoryData))
      } else {
        dispatch(fetchCategoryListError(categoryData))
      }
    })
  }
}

export const fetchCategoryRecordStart = () => ({
  type: FETCH_CATEGORY_RECORD.START
})

export const fetchCategoryRecordSuccess = (category) => ({
  type: FETCH_CATEGORY_RECORD.SUCCESS,
  payload: category
})

export const fetchCategoryRecordError = (error) => ({
  type: FETCH_CATEGORY_RECORD.ERROR,
  payload: { error }
})

export const fetchCategoryRecord = (payload) => {
  return (dispatch) => {
    dispatch(fetchCategoryRecordStart(payload))
    categoryService.fetchCategoryRecord(payload).then((categoryData) => {
      if (!categoryData.isError) {
        dispatch(fetchCategoryRecordSuccess(categoryData))
      } else {
        dispatch(fetchCategoryRecordError(categoryData))
      }
    })
  }
}

export const createCategoryRecordStart = () => ({
  type: CREATE_CATEGORY_RECORD.START
})

export const createCategoryRecordSuccess = (newCategory) => ({
  type: CREATE_CATEGORY_RECORD.SUCCESS,
  payload: newCategory
})

export const createCategoryRecordError = (error) => ({
  type: CREATE_CATEGORY_RECORD.ERROR,
  payload: { error }
})

export const createCategoryRecord = (payload) => {
  return (dispatch) => {
    dispatch(createCategoryRecordStart(payload))
    categoryService.createCategoryRecord(payload).then((categoryData) => {
      if (!categoryData.isError) {
        dispatch(createCategoryRecordSuccess(categoryData))
      } else {
        dispatch(createCategoryRecordError(categoryData))
      }
    })
  }
}

export const updateCategoryRecordStart = () => ({
  type: UPDATE_CATEGORY_RECORD.START
})

export const updateCategoryRecordSuccess = (newCategory) => ({
  type: UPDATE_CATEGORY_RECORD.SUCCESS,
  payload: newCategory
})

export const updateCategoryRecordError = (error) => ({
  type: UPDATE_CATEGORY_RECORD.ERROR,
  payload: { error }
})

export const updateCategoryRecord = (payload) => {
  return (dispatch) => {
    dispatch(updateCategoryRecordStart(payload))
    categoryService.updateCategoryRecord(payload).then((categoryData) => {
      if (!categoryData.isError) {
        dispatch(updateCategoryRecordSuccess(categoryData))
      } else {
        dispatch(updateCategoryRecordError(categoryData))
      }
    })
  }
}

export const deleteCategoryStart = () => ({
  type: DELETE_CATEGORY.START
})

export const deleteCategorySuccess = (categoryId) => ({
  type: DELETE_CATEGORY.SUCCESS,
  payload: { categoryId }
})

export const deleteCategoryError = (error) => ({
  type: DELETE_CATEGORY.ERROR,
  payload: { error }
})

export const deleteCategory = (categoryId) => {
  return (dispatch) => {
    dispatch(deleteCategoryStart())
    categoryService.deleteCategory(categoryId).then((categoryData) => {
      if (!categoryData.isError) {
        dispatch(deleteCategorySuccess(categoryData))
      } else {
        dispatch(deleteCategoryError(categoryData))
      }
    })
  }
}
