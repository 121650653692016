import { INIT_REGISTER, REGISTER } from '../../../constant/actionTypes/auth'

const formFieldValueMap = {
  // username: "",
  // password: "",
  // rememberMe: false,
  // companyName: "",
  // phoneNumber: "",
  // email: "",
  // baseUrl: "",
  // contactPerson: "",
  // footerColor: "#000000",
  // h1Font: "",
  // h2Font: "",
  // h3Font: "",
  // headerColor: "#000000",
  // iconColor: "#000000",
  // otherLinks: "",
  // plan: "",
  // planCost: 0,
  // resellerId: "",
  // companyLogo: "",
  // bandColor: "#000000",
  // bgColor: "#FFFFFF",
  // useremail: "",
  // socialLinks: "",
  // profile: "",
  // additionalInfo: [
  //   { day: "Monday", isOpened: false, openTime: "", closeTime: "" },
  //   { day: "Tuesday", isOpened: false, openTime: "", closeTime: "" },
  //   { day: "Wednesday", isOpened: false, openTime: "", closeTime: "" },
  //   { day: "Thursday", isOpened: false, openTime: "", closeTime: "" },
  //   { day: "Friday", isOpened: false, openTime: "", closeTime: "" },
  //   { day: "Saturday", isOpened: false, openTime: "", closeTime: "" },
  //   { day: "Sunday", isOpened: false, openTime: "", closeTime: "" },
  // ],
}

const initialState = {
  formFieldValueMap,
  error: '',
  isLoading: false,
  isPageLevelError: false,
  isLoadingPage: false,
  isRegisterSuccess: false,
  isRegisterError: false
}

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_REGISTER:
      return {
        ...state,
        error: null,
        isRegisterSuccess: false,
        isRegisterError: false
      }
    case REGISTER.START:
      return {
        ...state,
        isLoading: true,
        isRegisterSuccess: false,
        isRegisterError: false
      }
    case REGISTER.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isRegisterSuccess: true,
        isRegisterError: false
      }
    case REGISTER.ERROR:
      return {
        ...state,
        isLoading: false,
        isRegisterError: true,
        error: action.payload.error
      }

    default: {
      return state
    }
  }
}

export default registerReducer
