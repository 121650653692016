import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ROUTE_PATH } from '../constant/urlConstant'
import LoginScreen from '../containers/loginScreen'
import RegisterScreen from '../containers/registerScreen'
import LayoutScreen from '../containers/layout'
import EmployeeScreen from '../containers/employeeScreen'
import AddEmployeeScreen from '../containers/employeeScreen/addEmployeeScreen'
import DashboardScreen from '../containers/dashboardScreen'
import TenantScreen from '../containers/TenantScreen'
import AddTenantScreen from '../containers/TenantScreen/addTenantScreen'
import DepartmentScreen from '../containers/departmentScreen'
import AddDepartmentScreen from '../containers/departmentScreen/addDepartmentScreen'
import ContactScreen from '../containers/contactScreen'
import AddContactScreen from '../containers/contactScreen/addContactScreen'
import OrganizationScreen from '../containers/orgnizationScreen'
import EditOrgnizationScreen from '../containers/orgnizationScreen/editOrgnization'
import AmenitiesScreen from '../containers/amenitiesScreen'
import AddAmenitiesScreen from '../containers/amenitiesScreen/addAmenitiesScreen'
import AddProdctsScreen from '../containers/productsScreen/addProdctsScreen'
import CategoryScreen from '../containers/categoryScreen'
import AddCategoryScreen from '../containers/categoryScreen/addCategoryScreen'
import CardScreen from '../containers/cardScreen'
import { USER_ROLE } from '../constant/actionTypes/role'
import EnquiryScreen from '../containers/enquiryScreen'
import ProductsScreen from '../containers/productsScreen'

const Routing = () => {
  const isLoggedIn = useSelector((state) => state.loginReducer?.isLoggedIn)
  const userRole = useSelector((state) => state.loginReducer?.userRole)

  const commonRoutes = (
    <>
      <Route path={ROUTE_PATH.DASHBOARD.HOME} element={<DashboardScreen />} />
      <Route path={ROUTE_PATH.CONTACT.HOME} element={<ContactScreen />} />
      <Route path={ROUTE_PATH.CONTACT.ADD} element={<AddContactScreen />} />
      <Route
        path={ROUTE_PATH.CONTACT.EDIT}
        element={<AddContactScreen isEdit />}
      />
      <Route path={ROUTE_PATH.ENQUIRY.HOME} element={<EnquiryScreen />} />
      <Route
        path={ROUTE_PATH.ORGANIZATION.HOME}
        element={<OrganizationScreen />}
      />
      <Route
        path={ROUTE_PATH.ORGANIZATION.EDIT}
        element={<EditOrgnizationScreen isEdit />}
      />
      <Route path={ROUTE_PATH.BUSSINESS_CARD.HOME} element={<CardScreen />} />
    </>
  )

  const userRoutes = (
    <Routes>
      <Route path={ROUTE_PATH.DASHBOARD.HOME} element={<LayoutScreen />}>
        {commonRoutes}
        <Route path="*" element={<Navigate to={ROUTE_PATH.DASHBOARD.HOME} />} />
      </Route>
    </Routes>
  )

  const adminRoutes = (
    <Routes>
      <Route path={ROUTE_PATH.DASHBOARD.HOME} element={<LayoutScreen />}>
        <Route path={ROUTE_PATH.EMPOLYEE.HOME} element={<EmployeeScreen />} />
        <Route path={ROUTE_PATH.EMPOLYEE.ADD} element={<AddEmployeeScreen />} />
        <Route
          path={ROUTE_PATH.EMPOLYEE.EDIT}
          element={<AddEmployeeScreen isEdit />}
        />
        <Route
          path={ROUTE_PATH.DEPARTMENT.HOME}
          element={<DepartmentScreen />}
        />
        <Route
          path={ROUTE_PATH.DEPARTMENT.ADD}
          element={<AddDepartmentScreen />}
        />
        <Route
          path={ROUTE_PATH.DEPARTMENT.EDIT}
          element={<AddDepartmentScreen isEdit />}
        />
        <Route path={ROUTE_PATH.AMENITIES.HOME} element={<AmenitiesScreen />} />
        <Route
          path={ROUTE_PATH.AMENITIES.ADD}
          element={<AddAmenitiesScreen />}
        />
        <Route
          path={ROUTE_PATH.AMENITIES.EDIT}
          element={<AddAmenitiesScreen isEdit={true} />}
        />
        <Route path={ROUTE_PATH.PRODUCTS.HOME} element={<ProductsScreen />} />
        <Route path={ROUTE_PATH.PRODUCTS.ADD} element={<AddProdctsScreen />} />
        <Route
          path={ROUTE_PATH.PRODUCTS.EDIT}
          element={<AddProdctsScreen isEdit={true} />}
        />
        <Route path={ROUTE_PATH.CATEGORY.HOME} element={<CategoryScreen />} />
        <Route path={ROUTE_PATH.CATEGORY.ADD} element={<AddCategoryScreen />} />
        <Route
          path={ROUTE_PATH.CATEGORY.EDIT}
          element={<AddCategoryScreen isEdit />}
        />
        {commonRoutes}
        <Route path="*" element={<Navigate to={ROUTE_PATH.DASHBOARD.HOME} />} />
      </Route>
    </Routes>
  )

  const superAdminRoutes = (
    <Routes>
      <Route path={ROUTE_PATH.DASHBOARD.HOME} element={<LayoutScreen />}>
        <Route path={ROUTE_PATH.COMPANY.HOME} element={<TenantScreen />} />
        <Route path={ROUTE_PATH.COMPANY.ADD} element={<AddTenantScreen />} />
        <Route
          path={ROUTE_PATH.COMPANY.EDIT}
          element={<AddTenantScreen isEdit />}
        />
        <Route path={ROUTE_PATH.EMPOLYEE.HOME} element={<EmployeeScreen />} />
        <Route path={ROUTE_PATH.EMPOLYEE.ADD} element={<AddEmployeeScreen />} />
        <Route
          path={ROUTE_PATH.EMPOLYEE.EDIT}
          element={<AddEmployeeScreen isEdit />}
        />
        <Route
          path={ROUTE_PATH.DEPARTMENT.HOME}
          element={<DepartmentScreen />}
        />
        <Route
          path={ROUTE_PATH.DEPARTMENT.ADD}
          element={<AddDepartmentScreen />}
        />
        <Route
          path={ROUTE_PATH.DEPARTMENT.EDIT}
          element={<AddDepartmentScreen isEdit />}
        />
        <Route path={ROUTE_PATH.AMENITIES.HOME} element={<AmenitiesScreen />} />
        <Route
          path={ROUTE_PATH.AMENITIES.ADD}
          element={<AddAmenitiesScreen />}
        />
        <Route
          path={ROUTE_PATH.AMENITIES.EDIT}
          element={<AddAmenitiesScreen isEdit={true} />}
        />
        <Route path={ROUTE_PATH.PRODUCTS.HOME} element={<ProductsScreen />} />
        <Route path={ROUTE_PATH.PRODUCTS.ADD} element={<AddProdctsScreen />} />
        <Route
          path={ROUTE_PATH.PRODUCTS.EDIT}
          element={<AddProdctsScreen isEdit={true} />}
        />
        <Route path={ROUTE_PATH.CATEGORY.HOME} element={<CategoryScreen />} />
        <Route path={ROUTE_PATH.CATEGORY.ADD} element={<AddCategoryScreen />} />
        <Route
          path={ROUTE_PATH.CATEGORY.EDIT}
          element={<AddCategoryScreen isEdit />}
        />
        {commonRoutes}
        <Route path="*" element={<Navigate to={ROUTE_PATH.DASHBOARD.HOME} />} />
      </Route>
    </Routes>
  )

  if (!isLoggedIn) {
    return (
      <Routes>
        <Route path={ROUTE_PATH.BASE.HOME} element={<LoginScreen />} />
        <Route path={ROUTE_PATH.BASE.REGISTER} element={<RegisterScreen />} />
        <Route path={ROUTE_PATH.BUSSINESS_CARD.HOME} element={<CardScreen />} />
        <Route path="*" element={<Navigate to={ROUTE_PATH.BASE.HOME} />} />
      </Routes>
    )
  }

  switch (userRole) {
    case USER_ROLE.USER:
      return userRoutes
    case USER_ROLE.ADMIN:
      return adminRoutes
    case USER_ROLE.SUPER_ADMIN:
      return superAdminRoutes
    default:
      return (
        <Routes>
          <Route path={ROUTE_PATH.BASE.HOME} element={<LoginScreen />} />
          <Route path={ROUTE_PATH.BASE.REGISTER} element={<RegisterScreen />} />
          <Route
            path={ROUTE_PATH.BUSSINESS_CARD.HOME}
            element={<CardScreen />}
          />
          <Route path="*" element={<Navigate to={ROUTE_PATH.BASE.HOME} />} />
        </Routes>
      )
  }
}

export default Routing
