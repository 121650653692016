import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import AddEmployeeComponent from '../../../components/employee/addEmployee'
import { ProgressBar } from 'primereact/progressbar'
import {
  createEmployeeRecord,
  fetchEmployeeRecord,
  initEmployee,
  updateEmployeeRecord
} from '../../../redux/action/employee'
import { USER_ROLE } from '../../../constant/actionTypes/role'
import {
  createDepartmentRecord,
  fetchDepartmentList,
  initDepartment
} from '../../../redux/action/department'
import { fetchUserProfile } from '../../../redux/action/userProfile'
import { fetchTenantList, initTenant } from '../../../redux/action/tenant'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../../constant/urlConstant'
import { MzToast, TOAST_SEVERITY } from '../../../common/MzToast'
import { useTranslation } from 'react-i18next'

const AddEmployeeScreen = (props) => {
  const {
    isCreateEmployeeSuccess,
    isCreateEmployeeError,
    isEmployeeDetailSuccess,
    isEmployeeDetailError,
    isEditEmployeeSuccess,
    isEditEmployeeError,
    initEmployee,
    isEdit,
    isLoading,
    createEmployeeRecord,
    formFieldValueMap,
    isPageLevelError,
    user,
    userRole,
    tenantList,
    departmentsList,
    initDepartment,
    fetchTenantList,
    fetchDepartmentList,
    createDepartmentRecord,
    isCreateDepartmentSuccess,
    isCreateDepartmentError,
    departmentError,
    createDepartmentRecordError,
    fetchEmployeeRecord,
    updateEmployeeRecord,
    error,
    fetchUserProfile
  } = props
  useEffect(() => {
    initEmployee()
    initTenant()
    if (userRole === USER_ROLE.SUPER_ADMIN) {
      fetchTenantList()
    } else {
      const payload = {
        pageNumber: 0,
        pageSize: 100,
        tenantId: user?.tenant?.id ?? ''
      }
      fetchDepartmentList(payload)
    }
  }, [
    userRole,
    fetchTenantList,
    user?.tenant?.id,
    fetchDepartmentList,
    initEmployee
  ])

  const history = useNavigate()
  const { t } = useTranslation()

  const getToastProps = () => {
    if (isCreateEmployeeSuccess || isEditEmployeeSuccess) {
      let toastTitle = isEdit
        ? t('employee.toastUpdateSuccess')
        : t('employee.toastCreateSuccess')
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isCreateEmployeeError || isEmployeeDetailError || isEditEmployeeError) {
      let toastTitle = error ? error?.error : t('employee.toastCreateError')
      if (isEditEmployeeError) {
        toastTitle = error ? error?.error : t('employee.toastUpdateError')
      } else if (isEmployeeDetailError) {
        toastTitle =
          'Error while performing operation. Please refresh your browser'
      }
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }
  const navigatetoEmployee = () => {
    history(ROUTE_PATH.EMPOLYEE.HOME)
  }
  const addEmployeeProps = {
    fetchUserProfile,
    createEmployeeRecord,
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    user,
    isEdit,
    userRole,
    error,
    isCreateEmployeeSuccess,
    isEditEmployeeSuccess,
    isEmployeeDetailSuccess,
    isEmployeeDetailError,
    fetchDepartmentList,
    departmentsList,
    tenantList,
    fetchEmployeeRecord,
    updateEmployeeRecord,
    navigatetoEmployee,
    createDepartmentRecord,
    isCreateDepartmentSuccess,
    isCreateDepartmentError,
    departmentError,
    createDepartmentRecordError,
    initDepartment
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <AddEmployeeComponent addEmployeeProps={addEmployeeProps} />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initEmployee: () => dispatch(initEmployee()),
    initDepartment: () => dispatch(initDepartment()),
    initTenant: () => dispatch(initTenant()),
    createEmployeeRecord: (payload) => dispatch(createEmployeeRecord(payload)),
    fetchTenantList: (payload) => dispatch(fetchTenantList(payload)),
    fetchDepartmentList: (payload) => dispatch(fetchDepartmentList(payload)),
    fetchEmployeeRecord: (payload) => dispatch(fetchEmployeeRecord(payload)),
    updateEmployeeRecord: (payload, id) =>
      dispatch(updateEmployeeRecord(payload, id)),
    createDepartmentRecord: (payload) =>
      dispatch(createDepartmentRecord(payload)),
    fetchUserProfile: () => dispatch(fetchUserProfile())
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    formFieldValueMap: selectFormFieldValueMap(state.employeeReducer),
    isPageLevelError: state.employeeReducer.isPageLevelError,
    isLoading: state.employeeReducer.isLoading,
    error: state.employeeReducer.error,
    isCreateEmployeeSuccess: state.employeeReducer.isCreateEmployeeSuccess,
    isCreateEmployeeError: state.employeeReducer.isCreateEmployeeError,
    isEditEmployeeSuccess: state.employeeReducer.isEditEmployeeSuccess,
    isEditEmployeeError: state.employeeReducer.isEditEmployeeError,
    isEmployeeDetailError: state.employeeReducer.isEmployeeDetailError,
    isEmployeeDetailSuccess: state.employeeReducer.isEmployeeDetailSuccess,
    user: state.userProfileReducer.user,
    userRole: state.loginReducer.userRole,
    createDepartmentRecord: state.departmentReducer.createDepartmentRecord,
    departmentsList: state.departmentReducer.departmentsList,
    isCreateDepartmentSuccess:
      state.departmentReducer.isCreateDepartmentSuccess,
    isCreateDepartmentError: state.departmentReducer.isCreateDepartmentError,
    createDepartmentRecordError:
      state.departmentReducer.createDepartmentRecordError,
    departmentError: state.departmentReducer.error,
    tenantList: state.tenantReducer.tenantList
  }
}

const selectFormFieldValueMap = (employeeReducer) => {
  return employeeReducer.formFieldValueMap
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEmployeeScreen)
