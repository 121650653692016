import {
  INIT_CONTACT,
  FETCH_CONTACT_LIST,
  FETCH_CONTACT_RECORD,
  CREATE_CONTACT_RECORD,
  DELETE_CONTACT,
  UPDATE_CONTACT_RECORD,
  GET_CONTACT_COUNT,
  CREATE_EXCHANGEINFO_RECORD
} from '../../../constant/actionTypes/contact'
import { contactService } from '../../../services'

export const initContact = () => ({
  type: INIT_CONTACT
})

export const fetchContactListStart = (payload) => ({
  type: FETCH_CONTACT_LIST.START,
  payload
})

export const fetchContactListSuccess = (contacts) => ({
  type: FETCH_CONTACT_LIST.SUCCESS,
  payload: contacts
})

export const fetchContactListError = (error) => ({
  type: FETCH_CONTACT_LIST.ERROR,
  payload: { error }
})

export const fetchContactList = (payload) => {
  return (dispatch) => {
    dispatch(fetchContactListStart(payload))
    contactService.fetchContactList(payload).then((contactData) => {
      if (!contactData.isError) {
        dispatch(fetchContactListSuccess(contactData))
      } else {
        dispatch(fetchContactListError(contactData))
      }
    })
  }
}

export const fetchContactListByUserId = (payload) => {
  return (dispatch) => {
    dispatch(fetchContactListStart(payload))
    contactService.fetchContactListByUserId(payload).then((contactData) => {
      if (!contactData.isError) {
        dispatch(fetchContactListSuccess(contactData))
      } else {
        dispatch(fetchContactListError(contactData))
      }
    })
  }
}

export const fetchContactRecordStart = () => ({
  type: FETCH_CONTACT_RECORD.START
})

export const fetchContactRecordSuccess = (contact) => ({
  type: FETCH_CONTACT_RECORD.SUCCESS,
  payload: contact
})

export const fetchContactRecordError = (error) => ({
  type: FETCH_CONTACT_RECORD.ERROR,
  payload: { error }
})

export const fetchContactRecord = (payload) => {
  return (dispatch) => {
    dispatch(fetchContactRecordStart(payload))
    contactService.fetchContactRecord(payload).then((contactData) => {
      if (!contactData.isError) {
        dispatch(fetchContactRecordSuccess(contactData))
      } else {
        dispatch(fetchContactRecordError(contactData))
      }
    })
  }
}

export const createContactRecordStart = () => ({
  type: CREATE_CONTACT_RECORD.START
})

export const createContactRecordSuccess = (newContact) => ({
  type: CREATE_CONTACT_RECORD.SUCCESS,
  payload: newContact
})

export const createContactRecordError = (error) => ({
  type: CREATE_CONTACT_RECORD.ERROR,
  payload: { error }
})

export const createContactRecord = (payload) => {
  return (dispatch) => {
    dispatch(createContactRecordStart(payload))
    contactService.createContactRecord(payload).then((contactData) => {
      if (!contactData.isError) {
        dispatch(createContactRecordSuccess(contactData))
      } else {
        dispatch(createContactRecordError(contactData))
      }
    })
  }
}

export const createExchangeInfoRecordStart = () => ({
  type: CREATE_EXCHANGEINFO_RECORD.START
})

export const createExchangeInfoRecordSuccess = (newExchangeInfo) => ({
  type: CREATE_EXCHANGEINFO_RECORD.SUCCESS,
  payload: newExchangeInfo
})

export const createExchangeInfoRecordError = (error) => ({
  type: CREATE_EXCHANGEINFO_RECORD.ERROR,
  payload: { error }
})

export const createExchangeInfoRecord = (payload) => {
  return (dispatch) => {
    dispatch(createExchangeInfoRecordStart(payload))
    contactService.createExchangeInfoRecord(payload).then((contactData) => {
      if (!contactData.isError) {
        dispatch(createExchangeInfoRecordSuccess(contactData))
      } else {
        dispatch(createExchangeInfoRecordError(contactData))
      }
    })
  }
}

export const updateContactRecordStart = () => ({
  type: UPDATE_CONTACT_RECORD.START
})

export const updateContactRecordSuccess = (newContact) => ({
  type: UPDATE_CONTACT_RECORD.SUCCESS,
  payload: newContact
})

export const updateContactRecordError = (error) => ({
  type: UPDATE_CONTACT_RECORD.ERROR,
  payload: { error }
})

export const updateContactRecord = (payload) => {
  return (dispatch) => {
    dispatch(updateContactRecordStart(payload))
    contactService.updateContactRecord(payload).then((contactData) => {
      if (!contactData.isError) {
        dispatch(updateContactRecordSuccess(contactData))
      } else {
        dispatch(updateContactRecordError(contactData))
      }
    })
  }
}

export const deleteContactStart = () => ({
  type: DELETE_CONTACT.START
})

export const deleteContactSuccess = (contactId) => ({
  type: DELETE_CONTACT.SUCCESS,
  payload: { contactId }
})

export const deleteContactError = (error) => ({
  type: DELETE_CONTACT.ERROR,
  payload: { error }
})

export const deleteContact = (contactId) => {
  return (dispatch) => {
    dispatch(deleteContactStart())
    contactService.deleteContact(contactId).then((contactData) => {
      if (!contactData.isError) {
        dispatch(deleteContactSuccess(contactData))
      } else {
        dispatch(deleteContactError(contactData))
      }
    })
  }
}

const getContactCountStart = (role, payload) => {
  return {
    type: GET_CONTACT_COUNT.START,
    role,
    payload
  }
}

const getContactCountSuccess = (payload) => {
  return {
    type: GET_CONTACT_COUNT.SUCCESS,
    payload
  }
}

const getContactCountError = (payload) => {
  return {
    type: GET_CONTACT_COUNT.ERROR,
    payload
  }
}

export const getContactCount = (role, payload) => {
  return (dispatch) => {
    dispatch(getContactCountStart(role, payload))
    contactService.getContactCount(role, payload).then((contactData) => {
      if (!contactData.isError) {
        dispatch(getContactCountSuccess(contactData))
      } else {
        dispatch(getContactCountError(contactData))
      }
    })
  }
}

export const updateContactRecordBysuperAdmin = (payload) => {
  return (dispatch) => {
    dispatch(updateContactRecordStart(payload))
    contactService.updateContactRecordBysuperAdmin(payload).then((contactData) => {
      if (!contactData.isError) {
        dispatch(updateContactRecordSuccess(contactData))
      } else {
        dispatch(updateContactRecordError(contactData))
      }
    })
  }
}

export const createContactRecordBysuperAdmin = (payload) => {
  return (dispatch) => {
    dispatch(createContactRecordStart(payload))
    contactService.createContactRecordBysuperAdmin(payload).then((contactData) => {
      if (!contactData.isError) {
        dispatch(createContactRecordSuccess(contactData))
      } else {
        dispatch(createContactRecordError(contactData))
      }
    })
  }
}