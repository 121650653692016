import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FORM_FIELDS_NAME } from './constant'
import MzInput from '../../../common/MzForm/MzInput'
import { Button } from 'primereact/button'
import { BreadCrumb } from 'primereact/breadcrumb'
import { ROUTE_PATH } from '../../../constant/urlConstant'
import { useNavigate, useParams } from 'react-router-dom'
import { USER_ROLE } from '../../../constant/actionTypes/role'
import MzFileInput from '../../../common/MzForm/MzFileInput'
import MzAutoComplete from '../../../common/MzForm/MzAutoComplete'
import MzPhoneInput from '../../../common/MzForm/MzPhoneInput'
import { Fieldset } from 'primereact/fieldset'
import CardPreviewComponent from '../../cardPreview'
import AddDepartmentComponent from '../../department/addDepartment'
import MzDialog from '../../../common/MzDialog'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const AddEmployeeComponent = (props) => {
  const {
    createEmployeeRecord,
    formFieldValueMap,
    isLoading,
    isCreateEmployeeSuccess,
    isEditEmployeeSuccess,
    isEmployeeDetailSuccess,
    isPageLevelError,
    user,
    isEdit,
    userRole,
    departmentsList,
    tenantList,
    fetchDepartmentList,
    fetchEmployeeRecord,
    navigatetoEmployee,
    updateEmployeeRecord,
    createDepartmentRecord,
    isDepartmentDetailSuccess,
    isCreateDepartmentSuccess,
    departmentError,
    isCreateDepartmentError,
    initDepartment,
    fetchUserProfile
  } = props.addEmployeeProps

  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
    getValues
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap
    }, [formFieldValueMap]),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const history = useNavigate()
  const { id } = useParams()
  const [previewButton, setPreviewButton] = useState(false)
  const [previewData, setPreviewData] = useState(null)
  const [openAddDepartmentDialog] = useState(true)
  const [filePreview, setFilePreview] = useState({
    profileIcon: null
  })
  const [fileErrors, setFileErrors] = useState({
    profileIcon: ''
  })
  const [imageError, setImageError] = useState(false)
  const [addDepartmentDialogue, setAddDepartmentDialogue] = useState(false)
  const [selectedTenantId, setSelectedTenantId] = useState(null)

  const handleFileChange = (file, fileType) => {
    if (file && file.length > 0) {
      const selectedFile = file[0]
      const fileUrl = URL.createObjectURL(selectedFile)
      setFilePreview((prev) => ({
        ...prev,
        [fileType]: fileUrl
      }))
    } else {
      console.error('Invalid file:', file)
    }
  }

  const handleImageChange = (file, fileType) => {
    setFileErrors((prev) => ({ ...prev, [fileType]: null }))

    if (file && file.length > 0) {
      const selectedFile = file[0]
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png']

      if (!validTypes.includes(selectedFile.type)) {
        setFileErrors((prev) => ({
          ...prev,
          [fileType]: 'Invalid file type. Please upload a JPG or PNG file.'
        }))
        setFilePreview((prev) => ({ ...prev, [fileType]: null }))
        setImageError(true)
        return
      }

      const fileUrl = URL.createObjectURL(selectedFile)
      setFilePreview((prev) => ({ ...prev, [fileType]: fileUrl }))
      setFileErrors((prev) => ({ ...prev, [fileType]: null }))
      setImageError(false)
    } else {
      setFilePreview((prev) => ({ ...prev, [fileType]: null }))
      setImageError(false)
    }
  }
  const formValues = useWatch({ control })
  useEffect(() => {
    setPreviewData(formValues)
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME)])

  useEffect(() => {
    if (isCreateEmployeeSuccess || isEditEmployeeSuccess) {
      setTimeout(() => {
        reset()
        navigatetoEmployee()
      }, 1000)
      fetchUserProfile()
    }
    // eslint-disable-next-line
  }, [isCreateEmployeeSuccess, isEditEmployeeSuccess])

  useEffect(() => {
    if (isEmployeeDetailSuccess) {
      reset({
        ...formFieldValueMap
      })
    }
    // eslint-disable-next-line
  }, [isEmployeeDetailSuccess])

  useEffect(() => {
    const tenant =
      watch(FORM_FIELDS_NAME.TENANT.name) ?? formFieldValueMap?.tenant
    setSelectedTenantId(tenant)
    if (userRole === USER_ROLE.SUPER_ADMIN && tenant) {
      const payload = {
        pageNumber: 0,
        pageSize: 100,
        tenantId: tenant ?? ''
      }
      fetchDepartmentList(payload)
    } else if (userRole === USER_ROLE.ADMIN) {
      const payload = {
        pageNumber: 0,
        pageSize: 100,
        tenantId: user?.tenant?.id ?? ''
      }
      fetchDepartmentList(payload)
    } else {
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    watch(FORM_FIELDS_NAME.TENANT.name),
    fetchDepartmentList,
    isCreateDepartmentSuccess,
    isCreateDepartmentError
  ])

  useEffect(() => {
    if (isEdit && id) {
      fetchEmployeeRecord(id)
    } else {
      reset()
    }
    // eslint-disable-next-line
  }, [isEdit, id])

  const [, setGeneratedPassword] = useState('')

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className="p-error">{t(errors[name].message)}</small>
      )
    )
  }

  const onSubmit = (data) => {
    if (imageError) {
      return
    } else {
      const file = watch(FORM_FIELDS_NAME.FILE.name)
      const formData = new FormData()
      let payload = {
        tenant: {
          id:
            userRole === USER_ROLE.SUPER_ADMIN
              ? data.tenant
              : (user?.tenant?.id ?? '')
        },
        username: data?.username ?? '',
        email: data?.email ?? '',
        authorities: [{ name: data?.authorities ?? '' }],
        firstName: data?.firstName ?? '',
        lastName: data?.lastName ?? '',
        mobileNumber: data?.mobileNumber ?? ''
      }
      if (isEdit) {
        payload.id = data.id
        payload.active = data.active
      }
      if (data.department) {
        payload.department = { id: data?.department ?? null }
      }
      if (!isEdit) {
        payload.password = data?.password ?? ''
      }

      const blob = new Blob([JSON.stringify(payload)], {
        type: 'application/json'
      })
      formData.append('data', blob)

      if (file && typeof file === 'object') {
        Object.keys(file).forEach((key) => {
          const fileData = file[key]
          if (fileData && Array.isArray(fileData)) {
            fileData.forEach((file) => {
              if (file instanceof File) {
                formData.append('file', file)
              }
            })
          } else if (fileData && fileData instanceof File) {
            formData.append('file', fileData)
          }
        })
      }

      if (isEdit) {
        updateEmployeeRecord(formData, data.id)
      } else {
        createEmployeeRecord(formData)
      }
    }
  }

  const getTenatntLabel = (id) => {
    const TenantLabel = tenantList?.find((tenant) => tenant?.id === id)
    return TenantLabel ? TenantLabel?.companyName : ''
  }
  const getDepartmentLabel = (id) => {
    const department = departmentsList?.content?.find((dept) => dept.id === id)
    return department ? department?.name : ''
  }
  const getAuthoritiesLabel = (value) => {
    const selectedOption = FORM_FIELDS_NAME?.AUTHORITIES?.options.find(
      (option) => option?.value === value
    )
    return selectedOption ? selectedOption?.name : ''
  }

  const handlePreviewToggle = () => {
    const formValues = watch()
    setPreviewData(formValues)
    setPreviewButton((prevState) => !prevState)
  }

  const generateRandomPassword = () => {
    const length = 8
    const charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let result = ''
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length)
      result += charset[randomIndex]
    }
    return result
  }

  const handleGeneratePassword = (event) => {
    event.preventDefault()
    const password = generateRandomPassword()
    setGeneratedPassword(password)
    setValue(FORM_FIELDS_NAME.PASSWORD.name, password)
  }
  const handleAddDepartment = () => {
    setAddDepartmentDialogue(true)
    if (isCreateDepartmentSuccess) {
      initDepartment()
    }
  }

  let tenantId
  if (userRole === USER_ROLE.ADMIN) {
    tenantId = user.tenant.id ?? ''
  }
  if (userRole === USER_ROLE.SUPER_ADMIN) {
    tenantId = getValues(FORM_FIELDS_NAME?.TENANT?.name)
  }

  const cancelAddDepartmentDialog = () => {
    setAddDepartmentDialogue(false)
  }

  const addDepartmentProps = {
    createDepartmentRecord,
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    user,
    isEdit,
    userRole,
    isCreateDepartmentSuccess,
    isCreateDepartmentError,
    departmentError,
    isDepartmentDetailSuccess,
    fetchDepartmentList,
    tenantList,
    setAddDepartmentDialogue,
    openAddDepartmentDialog,
    selectedTenantId
  }

  useEffect(() => {
    const emailValue = watch(FORM_FIELDS_NAME.EMAIL.name)
    if (emailValue) {
      setValue(FORM_FIELDS_NAME.USERNAME.name, emailValue)
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.EMAIL.name), setValue])

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      {
        label: t('employee.employeeItem'),
        command: () => history(ROUTE_PATH.EMPOLYEE.HOME)
      },
      {
        label: isEdit
          ? t('employee.updateEmployee')
          : t('employee.addEmployee'),
        command: () =>
          history(
            isEdit
              ? `${ROUTE_PATH.EMPOLYEE.EDIT.replace(':id', id)}`
              : ROUTE_PATH.EMPOLYEE.ADD
          )
      }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }

    return (
      <div className="flex justify-content-between align-items-center  border-1 border-200  border-round  bg-white ">
        <BreadCrumb
          model={breadCrumbItems}
          home={home}
          className=" border-0 "
        />
      </div>
    )
  }
  const activeDepartments =
    departmentsList?.content?.filter((department) => department.active) ?? []

  return (
    <div className="">
      {renderPageHeaderSection()}
      <div className="flex w-full md:flex-column bg-white align-items-center justify-content-center border-1 border-200 border-round mt-3 p-2 md:p-0  ">
        <div className="grid grid-nogutter	">
          <div
            className={
              previewButton ? 'xl:col-7 lg:col-6 sm:col-12 2xl:col-6' : 'col-12'
            }
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" w-full md:px-4 mt-2"
            >
              {!isEdit && (
                <div className="flex justify-content-end pr-5">
                  <Button
                    type="button"
                    label={
                      previewButton
                        ? t('employee.closePreview')
                        : t('employee.previewCard')
                    }
                    className="hidden md:block"
                    onClick={handlePreviewToggle}
                  />
                </div>
              )}
              {userRole === USER_ROLE.SUPER_ADMIN && (
                <div className="card">
                  <Fieldset legend={t('employee.title1')}>
                    <div className="grid ">
                      <div className=" col-12 md:col-6">
                        <MzAutoComplete
                          control={control}
                          name={FORM_FIELDS_NAME.TENANT.name}
                          label={FORM_FIELDS_NAME.TENANT.label}
                          optionLabel={'companyName'}
                          optionValue={'id'}
                          placeholder={FORM_FIELDS_NAME.TENANT.placeholder}
                          isError={!!errors[FORM_FIELDS_NAME.TENANT.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.TENANT.name
                          )}
                          rules={FORM_FIELDS_NAME.TENANT.rules}
                          suggestions={tenantList}
                          value={getTenatntLabel(
                            getValues(FORM_FIELDS_NAME.TENANT.name)
                          )}
                          disabled={isEdit}
                          dropdown
                        />
                      </div>
                    </div>
                  </Fieldset>
                </div>
              )}
              <div className="card mt-3">
                <Fieldset legend={t('employee.title2')}>
                  <div className="grid">
                    <div className="col-12 md:col-6">
                      <MzInput
                        control={control}
                        name={FORM_FIELDS_NAME.FIRST_NAME.name}
                        label={FORM_FIELDS_NAME.FIRST_NAME.label}
                        placeholder={FORM_FIELDS_NAME.FIRST_NAME.placeholder}
                        type={FORM_FIELDS_NAME.FIRST_NAME.type}
                        isError={errors[FORM_FIELDS_NAME.FIRST_NAME.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.FIRST_NAME.name
                        )}
                        rules={FORM_FIELDS_NAME.FIRST_NAME.rules}
                        wrapperClass={'p-float-label'}
                      />
                    </div>
                    <div className="col-12 md:col-6">
                      <MzInput
                        control={control}
                        name={FORM_FIELDS_NAME.LAST_NAME.name}
                        label={FORM_FIELDS_NAME.LAST_NAME.label}
                        placeholder={FORM_FIELDS_NAME.LAST_NAME.placeholder}
                        type={FORM_FIELDS_NAME.LAST_NAME.type}
                        isError={errors[FORM_FIELDS_NAME.LAST_NAME.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.LAST_NAME.name
                        )}
                        rules={FORM_FIELDS_NAME.LAST_NAME.rules}
                        wrapperClass={'p-float-label'}
                      />
                    </div>
                    <div className="col-12 md:col-6">
                      <MzPhoneInput
                        control={control}
                        name={FORM_FIELDS_NAME.PHONE_NUMBER.name}
                        label={FORM_FIELDS_NAME.PHONE_NUMBER.label}
                        placeholder={FORM_FIELDS_NAME.PHONE_NUMBER.placeholder}
                        rules={FORM_FIELDS_NAME.PHONE_NUMBER.rules}
                        isError={errors[FORM_FIELDS_NAME.PHONE_NUMBER.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.PHONE_NUMBER.name
                        )}
                        country="in"
                      />
                    </div>
                    <div className="col-12 md:col-6">
                      <MzInput
                        control={control}
                        name={FORM_FIELDS_NAME.EMAIL.name}
                        label={FORM_FIELDS_NAME.EMAIL.label}
                        placeholder={FORM_FIELDS_NAME.EMAIL.placeholder}
                        type={FORM_FIELDS_NAME.EMAIL.type}
                        isError={errors[FORM_FIELDS_NAME.EMAIL.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.EMAIL.name
                        )}
                        rules={FORM_FIELDS_NAME.EMAIL.rules}
                        wrapperClass={'p-float-label'}
                      />
                    </div>
                    {!isEdit && (
                      <div className="flex justify-space-between align-items-center gap-3 col-12 md:col-6">
                        {filePreview.profileIcon && (
                          <img
                            src={filePreview.profileIcon}
                            alt="profile"
                            className="h-4rem w-4rem border-circle"
                          />
                        )}

                        <div className="w-full">
                          <MzFileInput
                            name={FORM_FIELDS_NAME.FILE.name}
                            label={FORM_FIELDS_NAME.FILE.label}
                            placeholder={FORM_FIELDS_NAME.FILE.placeholder}
                            rules={
                              isEdit && formFieldValueMap?.profilePicture
                                ? undefined
                                : FORM_FIELDS_NAME.FILE.rules
                            }
                            control={control}
                            isError={
                              !!errors[FORM_FIELDS_NAME.FILE.name] ||
                              !!fileErrors.profileIcon
                            }
                            errorMsg={
                              getFormErrorMessage(FORM_FIELDS_NAME.FILE.name) ||
                              fileErrors.profileIcon
                            }
                            wrapperClass="p-float-label"
                            onChange={(file) => {
                              handleImageChange(file, 'profileIcon')
                              setValue(FORM_FIELDS_NAME.FILE.name, file)
                            }}
                            accept=".jpeg, .jpg, .png"
                          />
                          {!imageError && (
                            <small className="text-gray-500">
                              {t('OrganizationComp.uploadImageOnly')}
                            </small>
                          )}
                        </div>
                      </div>
                    )}
                    {isEdit && (
                      <div className="flex justify-space-between align-items-center gap-3 col-12 md:col-6">
                        <div>
                          <img
                            src={
                              filePreview.profileIcon ??
                              formFieldValueMap?.profilePicture
                            }
                            alt={t('employee.alt')}
                            className="h-4rem w-4rem border-circle"
                          />
                        </div>
                        <div className="w-full">
                          <MzFileInput
                            name={FORM_FIELDS_NAME.FILE.name}
                            label={FORM_FIELDS_NAME.FILE.label}
                            placeholder={FORM_FIELDS_NAME.FILE.placeholder}
                            rules={
                              isEdit && formFieldValueMap?.profilePicture
                                ? undefined
                                : FORM_FIELDS_NAME.FILE.rules
                            }
                            control={control}
                            isError={!!errors[FORM_FIELDS_NAME.FILE.name]}
                            errorMsg={getFormErrorMessage(
                              FORM_FIELDS_NAME.FILE.name
                            )}
                            wrapperClass="p-float-label"
                            onChange={(file) => {
                              handleFileChange(file, 'profileIcon')
                              setValue(FORM_FIELDS_NAME.FILE.name, file)
                            }}
                            accept=".jpeg, .jpg, .png"
                          />
                          <small className="text-gray-500">
                            {t('employee.profileHint')}
                          </small>
                        </div>
                      </div>
                    )}
                  </div>
                </Fieldset>
              </div>
              <div className="card mt-3">
                <Fieldset legend={t('employee.title3')}>
                  <div className="grid">
                    <div className="col-12 md:col-6">
                      <MzInput
                        control={control}
                        name={FORM_FIELDS_NAME.USERNAME.name}
                        label={FORM_FIELDS_NAME.USERNAME.label}
                        placeholder={FORM_FIELDS_NAME.USERNAME.placeholder}
                        type={FORM_FIELDS_NAME.USERNAME.type}
                        isError={errors[FORM_FIELDS_NAME.USERNAME.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.USERNAME.name
                        )}
                        rules={FORM_FIELDS_NAME.USERNAME.rules}
                        wrapperClass={'p-float-label'}
                        disabled={true}
                      />
                    </div>
                    {!isEdit && (
                      <div className="  col-12  md:col-6">
                        <MzInput
                          control={control}
                          name={FORM_FIELDS_NAME.PASSWORD.name}
                          label={FORM_FIELDS_NAME.PASSWORD.label}
                          placeholder={t(FORM_FIELDS_NAME.PASSWORD.placeholder)}
                          type={FORM_FIELDS_NAME.PASSWORD.type}
                          isError={errors[FORM_FIELDS_NAME.PASSWORD.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.PASSWORD.name
                          )}
                          rules={FORM_FIELDS_NAME.PASSWORD.rules}
                          wrapperClass={'p-float-label'}
                          disabled={isEdit}
                        />
                        <Button
                          label={t('employee.GeneratePassword')}
                          className="p-button-rounded p-button-outlined p-button-text"
                          onClick={handleGeneratePassword}
                        />
                      </div>
                    )}
                    <div className="col-12 md:col-6">
                      <MzAutoComplete
                        control={control}
                        name={FORM_FIELDS_NAME.DEPARTMENT.name}
                        label={FORM_FIELDS_NAME.DEPARTMENT.label}
                        optionLabel={'name'}
                        optionValue={'id'}
                        placeholder={FORM_FIELDS_NAME.DEPARTMENT.placeholder}
                        isError={!!errors[FORM_FIELDS_NAME.DEPARTMENT.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.DEPARTMENT.name
                        )}
                        rules={FORM_FIELDS_NAME.DEPARTMENT.rules}
                        wrapperClass={'p-float-label'}
                        suggestions={activeDepartments ?? []}
                        dropdown
                        value={getDepartmentLabel(
                          getValues(FORM_FIELDS_NAME.DEPARTMENT.name)
                        )}
                      />
                      {!isEdit ? (
                        <i
                          className="pi pi-plus"
                          onClick={handleAddDepartment}
                          style={{
                            position: 'relative',
                            fontSize: '13px',
                            bottom: '33%',
                            left: '94%',
                            cursor: 'pointer'
                          }}
                        ></i>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className=" col-12 md:col-6">
                      <MzAutoComplete
                        control={control}
                        name={FORM_FIELDS_NAME.AUTHORITIES.name}
                        label={FORM_FIELDS_NAME.AUTHORITIES.label}
                        optionLabel={'name'}
                        optionValue={'value'}
                        placeholder={FORM_FIELDS_NAME.AUTHORITIES.placeholder}
                        isError={!!errors[FORM_FIELDS_NAME.AUTHORITIES.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.AUTHORITIES.name
                        )}
                        rules={FORM_FIELDS_NAME.AUTHORITIES.rules}
                        suggestions={FORM_FIELDS_NAME.AUTHORITIES.options}
                        value={getAuthoritiesLabel(
                          getValues(FORM_FIELDS_NAME.AUTHORITIES.name)
                        )}
                        dropdown
                      />
                    </div>
                  </div>
                </Fieldset>
              </div>
              <div className="grid justify-content-center mt-3 mb-5">
                <div className=" col-12 md:col-4">
                  <Button
                    label={t('employee.Cancel')}
                    className="w-full"
                    severity="secondary"
                    onClick={() => navigatetoEmployee()}
                    disabled={isLoading || isPageLevelError}
                  ></Button>
                </div>
                <div className=" col-12 md:col-4">
                  <Button
                    type="submit"
                    label={t('employee.Submit')}
                    className="w-full  sm:block"
                    disabled={isLoading || isPageLevelError}
                  ></Button>
                </div>
              </div>
            </form>
          </div>
          {!isEdit && previewButton && (
            <div className="xl:col-5 2xl:col-4 hidden lg:col-6 md:block flex align-items-center  justify-content-center">
              <CardPreviewComponent
                data={previewData}
                filePreviewUrl={filePreview.profileIcon}
                tenantId={tenantId}
              />
            </div>
          )}
        </div>
      </div>
      <MzDialog
        visible={addDepartmentDialogue}
        onHide={cancelAddDepartmentDialog}
        maximizable={false}
        height="230px"
        children={
          <AddDepartmentComponent addDepartmentProps={addDepartmentProps} />
        }
      />
    </div>
  )
}

export default AddEmployeeComponent
