import {
  INIT_EMPLOYEE,
  FETCH_EMPLOYEE_LIST,
  FETCH_EMPLOYEE_RECORD,
  CREATE_EMPLOYEE_RECORD,
  DELETE_EMPLOYEE,
  UPDATE_EMPLOYEE_RECORD,
  GET_EMPLOYEE_COUNT
} from '../../../constant/actionTypes/employee'
import { employeeService } from '../../../services'

export const initEmployee = () => ({
  type: INIT_EMPLOYEE
})

export const fetchEmployeeListStart = (payload) => ({
  type: FETCH_EMPLOYEE_LIST.START,
  payload
})

export const fetchEmployeeListSuccess = (employees) => ({
  type: FETCH_EMPLOYEE_LIST.SUCCESS,
  payload: employees
})

export const fetchEmployeeListError = (error) => ({
  type: FETCH_EMPLOYEE_LIST.ERROR,
  payload: { error }
})

export const fetchEmployeeList = (payload) => {
  return (dispatch) => {
    dispatch(fetchEmployeeListStart(payload))
    employeeService.fetchEmployeeList(payload).then((employeeData) => {
      if (!employeeData.isError) {
        dispatch(fetchEmployeeListSuccess(employeeData))
      } else {
        dispatch(fetchEmployeeListError(employeeData))
      }
    })
  }
}

export const fetchEmployeeRecordStart = () => ({
  type: FETCH_EMPLOYEE_RECORD.START
})

export const fetchEmployeeRecordSuccess = (employee) => ({
  type: FETCH_EMPLOYEE_RECORD.SUCCESS,
  payload: employee
})

export const fetchEmployeeRecordError = (error) => ({
  type: FETCH_EMPLOYEE_RECORD.ERROR,
  payload: { error }
})

export const fetchEmployeeRecord = (payload) => {
  return (dispatch) => {
    dispatch(fetchEmployeeRecordStart(payload))
    employeeService.fetchEmployeeRecord(payload).then((employeeData) => {
      if (!employeeData.isError) {
        dispatch(fetchEmployeeRecordSuccess(employeeData))
      } else {
        dispatch(fetchEmployeeRecordError(employeeData))
      }
    })
  }
}

export const createEmployeeRecordStart = () => ({
  type: CREATE_EMPLOYEE_RECORD.START
})

export const createEmployeeRecordSuccess = (newEmployee) => ({
  type: CREATE_EMPLOYEE_RECORD.SUCCESS,
  payload: newEmployee
})

export const createEmployeeRecordError = (error) => ({
  type: CREATE_EMPLOYEE_RECORD.ERROR,
  payload: { error }
})

export const createEmployeeRecord = (payload) => {
  return (dispatch) => {
    dispatch(createEmployeeRecordStart(payload))
    employeeService.createEmployeeRecord(payload).then((employeeData) => {
      if (!employeeData.isError) {
        dispatch(createEmployeeRecordSuccess(employeeData))
      } else {
        dispatch(createEmployeeRecordError(employeeData))
      }
    })
  }
}

export const updateEmployeeRecordStart = (payload, id) => ({
  type: UPDATE_EMPLOYEE_RECORD.START,
  payload,
  id
})

export const updateEmployeeRecordSuccess = (newEmployee) => ({
  type: UPDATE_EMPLOYEE_RECORD.SUCCESS,
  payload: newEmployee
})

export const updateEmployeeRecordError = (error) => ({
  type: UPDATE_EMPLOYEE_RECORD.ERROR,
  payload: { error }
})

export const updateEmployeeRecord = (payload, id) => {
  return (dispatch) => {
    dispatch(updateEmployeeRecordStart(payload, id))
    employeeService.updateEmployeeRecord(payload, id).then((employeeData) => {
      if (!employeeData.isError) {
        dispatch(updateEmployeeRecordSuccess(employeeData))
      } else {
        dispatch(updateEmployeeRecordError(employeeData))
      }
    })
  }
}

export const deleteEmployeeStart = () => ({
  type: DELETE_EMPLOYEE.START
})

export const deleteEmployeeSuccess = (employeeId) => ({
  type: DELETE_EMPLOYEE.SUCCESS,
  payload: { employeeId }
})

export const deleteEmployeeError = (error) => ({
  type: DELETE_EMPLOYEE.ERROR,
  payload: { error }
})

export const deleteEmployee = (employeeId) => {
  return (dispatch) => {
    dispatch(deleteEmployeeStart())
    employeeService.deleteEmployee(employeeId).then((employeeData) => {
      if (!employeeData.isError) {
        dispatch(deleteEmployeeSuccess(employeeData))
      } else {
        dispatch(deleteEmployeeError(employeeData))
      }
    })
  }
}

export const getEmployeeCountStart = (role, payload) => {
  return {
    type: GET_EMPLOYEE_COUNT.START,
    role,
    payload
  }
}
export const getEmployeeCountSuccess = (employeeData) => {
  return {
    type: GET_EMPLOYEE_COUNT.SUCCESS,
    payload: employeeData
  }
}
export const getEmployeeCountError = (error) => {
  return {
    type: GET_EMPLOYEE_COUNT.ERROR,
    payload: error
  }
}

export const getEmployeeCount = (role, payload) => {
  return (dispatch) => {
    dispatch(getEmployeeCountStart(role, payload))
    employeeService.getEmployeeCount(role, payload).then((employeeData) => {
      if (!employeeData.isError) {
        dispatch(getEmployeeCountSuccess(employeeData))
      } else {
        dispatch(getEmployeeCountError(employeeData))
      }
    })
  }
}
