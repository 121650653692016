import React, { useEffect, useState } from 'react'
import CategoryComponent from '../../components/category'
import {
  deleteCategory,
  fetchCategoryList,
  initCategory
} from '../../redux/action/category'
import { connect } from 'react-redux'
import { USER_ROLE } from '../../constant/actionTypes/role'
import { ProgressBar } from 'primereact/progressbar'
import categoryTableData from './data.json'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { fetchTenantList } from '../../redux/action/tenant'
import { useTranslation } from 'react-i18next'
const CategoryScreen = (props) => {
  const {
    fetchCategoryList,
    initCategory,
    categorysList,
    isLoading,
    isPageLevelError,
    userRole,
    user,
    tenantList,
    error,
    deleteCategory,
    isDeleteCategorySuccess,
    isDeleteCategoryError
  } = props
  const { t } = useTranslation()
  useEffect(() => {
    initCategory()
    const payload = {
      pageNumber: 0,
      pageSize: 10
    }
    if (userRole === USER_ROLE.SUPER_ADMIN) {
      fetchCategoryList(payload)
      fetchTenantList()
    } else {
      const modifiedPayload = {
        ...payload,
        tenantId: user?.tenant?.id || ''
      }
      fetchCategoryList(modifiedPayload)
    }
  }, [
    isDeleteCategorySuccess,
    initCategory,
    fetchCategoryList,
    user?.tenant?.id,
    userRole
  ])

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)

  const history = useNavigate()
  const handleOnReadRecord = () => {}

  const handleOnDeleteRecord = (data) => {
    setSelectedCategory(data)
    setIsDeleteDialogVisible(true)
  }
  const handleOnEditRecord = (data) => {
    history(`${ROUTE_PATH.CATEGORY.EDIT.replace(':id', data?.id)}`)
  }

  const handleOnCreatedRecord = () => {
    history(ROUTE_PATH.CATEGORY.ADD)
  }

  const confirmDelete = () => {
    if (selectedCategory) {
      deleteCategory(selectedCategory.id)
      setIsDeleteDialogVisible(false)
      setSelectedCategory(null)
    }
  }

  const cancelDelete = () => {
    setIsDeleteDialogVisible(false)
    setSelectedCategory(null)
  }

  const filteredTableData = {
    ...categoryTableData,
    tableData: {
      ...categoryTableData.tableData,
      columns: Array.isArray(categoryTableData?.tableData?.columns)
        ? categoryTableData.tableData.columns.filter((column) => {
            if (
              column.dataKey === 'tenant.companyName' &&
              userRole === USER_ROLE.ADMIN
            ) {
              return false
            }
            return true
          })
        : []
    }
  }

  const categoryProps = {
    categorysList,
    fetchCategoryList,
    isPageLevelError,
    isLoading,
    categoryTableData: filteredTableData,
    userRole,
    user,
    tenantList,
    fetchTenantList,
    handleOnReadRecord,
    handleOnDeleteRecord,
    handleOnEditRecord,
    handleOnCreatedRecord
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }
  const getToastProps = () => {
    if (isDeleteCategorySuccess) {
      const toastTitle = t('category.categoryStatusChangeSuccess')
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isDeleteCategoryError) {
      let toastTitle = error ? error : t('category.errorChangingCategoryStatus')
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <CategoryComponent categoryProps={categoryProps} />
      <Dialog
        header={`${t('employee.deleteDailogHeader.Confirm')} ${
          selectedCategory?.active
            ? t('employee.deleteDailogHeader.Deactivate')
            : t('employee.deleteDailogHeader.Activate')
        }`}
        visible={isDeleteDialogVisible}
        onHide={cancelDelete}
        footer={
          <div>
            <Button
              severity={selectedCategory?.active ? 'primary' : ''}
              onClick={confirmDelete}
            >
              {t('category.yes')}
            </Button>
            <Button severity="secondary" onClick={cancelDelete}>
              {t('category.no')}
            </Button>
          </div>
        }
      >
        <div className="text-warp">
          {t('employee.deleteConfirmMessage')}
          {selectedCategory?.active
            ? t('employee.deleteDailogHeader.Deactivate')
            : t('employee.deleteDailogHeader.Activate')}{' '}
          {t('category.thisCategory')}
          <span className="text-primary">{selectedCategory?.name}</span>
        </div>
      </Dialog>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initCategory: () => dispatch(initCategory()),
    fetchCategoryList: (payload) => dispatch(fetchCategoryList(payload)),
    deleteCategory: (payload) => dispatch(deleteCategory(payload)),
    fetchTenantList: () => dispatch(fetchTenantList())
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isPageLevelError: state.categoryReducer.isPageLevelError,
    isLoading: state.categoryReducer.isLoading,
    error: state.categoryReducer.error,
    categorysList: state.categoryReducer.categorysList,
    userRole: state.loginReducer.userRole,
    tenantList: state.tenantReducer.tenantList,
    user: state.userProfileReducer.user,
    isDeleteCategorySuccess: state.categoryReducer.isDeleteCategorySuccess,
    isDeleteCategoryError: state.categoryReducer.isDeleteCategoryError
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryScreen)
