import { Dialog } from 'primereact/dialog'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
// import { useTranslation } from "react-i18next";

const MzDialog = (props) => {
  const {
    children,
    header,
    width,
    height,
    onHide,
    visible,
    maximizable,
    isLoading,
    footer
  } = props

  // const { t } = useTranslation()

  const splitLength = (length) => {
    const index = length.split('').findIndex((char) => isNaN(char))
    if (index === -1) return [length, 'px']
    return [length.substring(0, index), length.substring(index)]
  }

  const calculateHeightAndWidth = (dialogHeight, dialogWidth) => {
    const [heightNumber, heightUnit] = splitLength(dialogHeight)
    const [widthNumber, widthUnit] = splitLength(dialogWidth)
    return {
      dialogChildHeight: (heightNumber - 40).toLocaleString() + heightUnit,
      dialogChildWidth: (widthNumber - 10).toLocaleString() + widthUnit
    }
  }

  const { dialogChildHeight, dialogChildWidth } = calculateHeightAndWidth(
    height,
    width
  )

  const isLoggedIn = useSelector((state) => state.loginReducer?.isLoggedIn)

  const defaultFooter = (
    <div className="text-xs text-700 text-center pt-2">
      {!isLoggedIn && (
        <div className="w-full text-900 p-2 ">
          Get your card? &nbsp;
          <NavLink to="/signup" style={{ textDecoration: 'none' }}>
            Here
          </NavLink>
        </div>
      )}
      <div className="mt-2">&#169; MultiGenesys Software Pvt. Ltd. 2024</div>
    </div>
  )

  return (
    <Dialog
      onHide={onHide}
      visible={visible}
      header={header}
      contentStyle={{ height }}
      maximizable={maximizable}
      footer={footer || defaultFooter}
    >
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            height: dialogChildHeight,
            width: dialogChildWidth,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        ></div>
      ) : (
        <div>{children}</div>
      )}
    </Dialog>
  )
}

MzDialog.defaultProps = {
  height: '600px',
  width: '50vw',
  maximizable: true,
  visible: true,
  isLoading: false
}

export default MzDialog
