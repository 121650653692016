import {
  INIT_TENANT,
  FETCH_TENANT_LIST,
  FETCH_TENANT_RECORD,
  CREATE_TENANT_RECORD,
  DELETE_TENANT,
  UPDATE_TENANT_RECORD,
  FETCH_PAGABLE_TENANT_LIST
} from '../../../constant/actionTypes/tenant'

const formFieldValueMap = {
}

const initialState = {
  tenantList: [],
  pagableTenantList:null,
  selectedTenant: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateTenantSuccess: false,
  isEditTenantSuccess: false,
  isTenantDetailSuccess: false,
  isDeleteTenantSuccess: false,
  isDeleteTenantError: false,
  isTenantDetailError: false,
  isCreateTenantError: false,
  isEditTenantError: false
}

const tenantReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_TENANT:
      return {
        ...state,
        tenantList: [],
        pagableTenantList:null,
        formFieldValueMap: null,
        selectedTenant: null,
        isLoading: false,
        isCreateTenantSuccess: false,
        isEditTenantSuccess: false,
        isTenantDetailSuccess: false,
        isDeleteTenantSuccess: false,
        isDeleteTenantError: false,
        isTenantDetailError: false,
        isCreateTenantError: false,
        isEditTenantError: false,
        error: null
      }
    case FETCH_TENANT_LIST.START:
    case FETCH_PAGABLE_TENANT_LIST.START:
    case FETCH_TENANT_RECORD.START:
    case CREATE_TENANT_RECORD.START:
    case DELETE_TENANT.START:
    case UPDATE_TENANT_RECORD.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreateTenantSuccess: false,
        isEditTenantSuccess: false,
        isTenantDetailSuccess: false,
        isDeleteTenantSuccess: false,
        isDeleteTenantError: false,
        isTenantDetailError: false,
        isCreateTenantError: false,
        isEditTenantError: false
      }
    case FETCH_TENANT_LIST.SUCCESS:
      return {
        ...state,
        tenantList: action.payload,
        isLoading: false,
        error: null
      }
      case FETCH_PAGABLE_TENANT_LIST.SUCCESS:
      return {
        ...state,
        pagableTenantList: action.payload,
        isLoading: false,
        error: null
      }
    case FETCH_TENANT_RECORD.SUCCESS:
      let otherLinks = action.payload.otherLinks ?? ''
      let linkobj = {}

      if (otherLinks) {
        try {
          linkobj = JSON.parse(otherLinks)
        } catch (error) {
          console.log('Error parsing otherLinks:', error)
        }
      }
      return {
        ...state,
        formFieldValueMap: {
          id: action.payload.id ?? '',
          companyName: action.payload.companyName ?? '',
          email: action.payload.tenantConfigDTO?.email ?? '',
          phoneNumber: action.payload.phoneNumber ?? '',
          baseUrl: action.payload.tenantConfigDTO?.baseUrl ?? '',
          contactPerson: action.payload.contactPerson ?? '',
          h1Font: action.payload.h1Font ?? '',
          h2Font: action.payload.h2Font ?? '',
          h3Font: action.payload.h3Font ?? '',
          headerColor: action.payload.headerColor ?? '',
          footerColor: action.payload.footerColor ?? '',
          bgColor: action.payload.bgColor ?? '',
          iconColor: action.payload.iconColor ?? '',
          bandColor: action.payload.bandColor ?? '',
          facebookLink: linkobj.facebook ?? '',
          instagramLink: linkobj.instagram ?? '',
          twitterLink: linkobj.twitter ?? '',
          linkedinLink: linkobj.linkedin ?? '',
          websiteLink: linkobj.website ?? '',
          companyLogo: action.payload.companyLogo ?? '',
          companyIntro: action.payload.companyIntro ?? '',
          noOfServices: action.payload.noOfServices ?? '',
          noOfProduct: action.payload.noOfProduct ?? '',
          noOfEmp: action.payload.noOfEmp ?? '',
          brochure:action?.payload?.brochure??''
        },
        selectedTenant: {
          id: action.payload.id ?? '',
          companyName: action.payload.companyName ?? '',
          email: action.payload.tenantConfigDTO?.email ?? '',
          phoneNumber: action.payload.phoneNumber ?? '',
          baseUrl: action.payload.tenantConfigDTO?.baseUrl ?? '',
          contactPerson: action.payload.contactPerson ?? '',
          h1Font: action.payload.h1Font ?? '',
          h2Font: action.payload.h2Font ?? '',
          h3Font: action.payload.h3Font ?? '',
          headerColor: action.payload.headerColor ?? '',
          footerColor: action.payload.footerColor ?? '',
          bgColor: action.payload.bgColor ?? '',
          iconColor: action.payload.iconColor ?? '',
          bandColor: action.payload.bandColor ?? '',
          facebookLink: linkobj.facebook ?? '',
          instagramLink: linkobj.instagram ?? '',
          twitterLink: linkobj.twitter ?? '',
          linkedinLink: linkobj.linkedin ?? '',
          websiteLink: linkobj.website ?? '',
          companyLogo: action.payload.companyLogo ?? '',
          companyIntro: action.payload.companyIntro ?? '',
          noOfServices: action.payload.noOfServices ?? '',
          noOfProduct: action.payload.noOfProduct ?? '',
          noOfEmp: action.payload.noOfEmp ?? ''
        },
        isLoading: false,
        error: null,
        isTenantDetailSuccess: true
      }
    case CREATE_TENANT_RECORD.SUCCESS:
      return {
        ...state,
        tenantList: [...state.tenantList, action.payload],
        isLoading: false,
        error: null,
        isCreateTenantSuccess: true
      }
    case UPDATE_TENANT_RECORD.SUCCESS:
      return {
        ...state,
        tenantList: state.tenantList.map((tenant) =>
          tenant.id === action.payload.id ? action.payload : tenant
        ),
        isLoading: false,
        error: null,
        isEditTenantSuccess: true
      }
    case DELETE_TENANT.SUCCESS:
      return {
        ...state,
        tenantList: state.tenantList.filter(
          (tenant) => tenant.id !== action.payload.tenantId
        ),
        isLoading: false,
        error: null,
        isDeleteTenantSuccess: true
      }
    case FETCH_TENANT_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
      case FETCH_PAGABLE_TENANT_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case FETCH_TENANT_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isTenantDetailError: true
      }
    case CREATE_TENANT_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        isCreateTenantError: true
      }
    case UPDATE_TENANT_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        isEditTenantError: true
      }

    case DELETE_TENANT.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDeleteTenantError: true
      }
    default:
      return state
  }
}

export default tenantReducer
