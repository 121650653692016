import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { FORM_FIELDS_NAME } from './constant'
import MzInput from '../../../common/MzForm/MzInput'
import { Button } from 'primereact/button'
import { BreadCrumb } from 'primereact/breadcrumb'
import { ROUTE_PATH } from '../../../constant/urlConstant'
import { useNavigate, useParams } from 'react-router-dom'
import { USER_ROLE } from '../../../constant/actionTypes/role'
import { Fieldset } from 'primereact/fieldset'
import MzAutoComplete from '../../../common/MzForm/MzAutoComplete'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const AddDepartmentComponent = (props) => {
  const {
    createDepartmentRecord,
    formFieldValueMap,
    isLoading,
    isCreateDepartmentSuccess,
    isCreateDepartmentError,
    isEditDepartmentSuccess,
    isEditDepartmentError,
    departmentError,
    isDepartmentDetailSuccess,
    isPageLevelError,
    user,
    isEdit,
    userRole,
    tenantList,
    fetchDepartmentList,
    fetchDepartmentRecord,
    navigatetoDepartment,
    updateDepartmentRecord,
    setAddDepartmentDialogue,
    openAddDepartmentDialog,
    selectedTenantId
  } = props.addDepartmentProps

  const {
    control,
    formState: { errors },
    watch,
    getValues,
    handleSubmit,
    reset,
    setValue
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap
    }, [formFieldValueMap]),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const history = useNavigate()
  const { id } = useParams()
  const { t } = useTranslation()

  const handleCancelDialog = () => {
    setAddDepartmentDialogue(false)
  }
  useEffect(() => {
    if (isCreateDepartmentSuccess || isEditDepartmentSuccess) {
      setTimeout(() => {
        reset()
        if (openAddDepartmentDialog) {
          toast.success(t('department.DepartmentAddSuccessfully'))
          handleCancelDialog()
        } else {
          navigatetoDepartment()
        }
      }, 1000)
    }
    if (isCreateDepartmentError || isEditDepartmentError) {
      let toastTitle = departmentError
        ? departmentError?.error
        : t('department.DepartmentAddError')
      toast.error(toastTitle)
    }
    // eslint-disable-next-line
  }, [isCreateDepartmentSuccess, isCreateDepartmentError, isEditDepartmentSuccess, isEditDepartmentError ])

  useEffect(() => {
    if (isDepartmentDetailSuccess) {
      reset({
        ...formFieldValueMap
      })
    }
    // eslint-disable-next-line
  }, [isDepartmentDetailSuccess])

  const tenant = watch(FORM_FIELDS_NAME.TENANT.name)

  useEffect(() => {
    if (userRole === USER_ROLE.SUPER_ADMIN && tenant) {
      fetchDepartmentList(tenant)
    }
    // eslint-disable-next-line
  }, [tenant])

  useEffect(() => {
    if (isEdit && id) {
      fetchDepartmentRecord(id)
    } else {
      reset(formFieldValueMap)
    }
    // eslint-disable-next-line
  }, [isEdit, id])

  useEffect(() => {
    if (selectedTenantId) {
      setValue(FORM_FIELDS_NAME.TENANT.name, selectedTenantId)
    }
  }, [selectedTenantId, setValue])

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className="p-error">{t(errors[name].message)}</small>
      )
    )
  }
  const getTenatntLabel = (id) => {
    const TenantLabel = tenantList?.find((tenant) => tenant.id === id)
    return TenantLabel ? TenantLabel?.companyName : ''
  }

  const onSubmit = (data) => {
    let payload
    if (isEdit) {
      if (userRole === USER_ROLE.SUPER_ADMIN) {
        payload = {
          id: data.id,
          tenant: { id: data.tenant },
          name: data.name,
          active: data.active
        }
        updateDepartmentRecord(payload)
      } else {
        payload = {
          id: data.id,
          tenant: { id: user?.tenant?.id ?? '' },
          name: data.name,
          active: data.active
        }
        updateDepartmentRecord(payload)
      }
    } else {
      if (userRole === USER_ROLE.SUPER_ADMIN) {
        payload = {
          tenant: { id: data.tenant },
          name: data.name
        }
        createDepartmentRecord(payload)
      } else {
        payload = {
          tenant: { id: user?.tenant?.id ?? '' },
          name: data.name
        }
        createDepartmentRecord(payload)
      }
    }
  }
  const getTenantLabel = (id) => {
    const tenant = tenantList?.find((tenant) => tenant.id === id)
    return tenant ? tenant?.companyName : ''
  }

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      {
        label: t('department.Department'),
        command: () => history(ROUTE_PATH.DEPARTMENT.HOME)
      },
      {
        label: isEdit
          ? t('department.UpdateDepartment')
          : t('department.AddDepartment'),
        command: () =>
          history(
            isEdit
              ? `${ROUTE_PATH.DEPARTMENT.EDIT.replace(':id', id)}`
              : ROUTE_PATH.DEPARTMENT.ADD
          )
      }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }

    return (
      <div className="flex justify-content-between align-items-center  border-1 border-200  border-round  bg-white ">
        <BreadCrumb model={breadCrumbItems} home={home} className="border-0" />
      </div>
    )
  }
  return (
    <div>
      {openAddDepartmentDialog ? (
        <div>
          <div className="flex bg-white flex-column align-items-center justify-content-center border-1 border-200 border-round mt-3 p-2 md:p-0">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" w-full md:px-4 mt-2"
            >
              {userRole === USER_ROLE.SUPER_ADMIN && !isEdit && (
                <div className="card">
                  <div className="grid">
                    <div className=" col-12 md:col-12">
                      <MzAutoComplete
                        control={control}
                        name={FORM_FIELDS_NAME.TENANT.name}
                        label={FORM_FIELDS_NAME.TENANT.label}
                        optionLabel={'companyName'}
                        optionValue={'id'}
                        placeholder={FORM_FIELDS_NAME.TENANT.placeholder}
                        isError={!!errors[FORM_FIELDS_NAME.TENANT.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.TENANT.name
                        )}
                        rules={FORM_FIELDS_NAME.TENANT.rules}
                        suggestions={tenantList}
                        value={getTenantLabel(selectedTenantId) ?? ''}
                        dropdown
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="card mt-3">
                <div className="grid">
                  <div className="col-12 md:col-12">
                    <MzInput
                      control={control}
                      name={FORM_FIELDS_NAME.NAME.name}
                      label={FORM_FIELDS_NAME.NAME.label}
                      placeholder={FORM_FIELDS_NAME.NAME.placeholder}
                      type={FORM_FIELDS_NAME.NAME.type}
                      isError={errors[FORM_FIELDS_NAME.NAME.name]}
                      errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.NAME.name)}
                      rules={FORM_FIELDS_NAME.NAME.rules}
                      wrapperClass={'p-float-label'}
                    />
                  </div>
                </div>
              </div>

              <div className="grid justify-content-center mt-3 mb-5">
                <div className="col-12 md:col-6">
                  <Button
                    label={t('department.Cancel')}
                    className="w-full"
                    severity="secondary"
                    onClick={(e) => {
                      e.preventDefault()
                      handleCancelDialog()
                    }}
                    disabled={isLoading || isPageLevelError}
                  />
                </div>
                <div className="col-12 md:col-6">
                  <Button
                    type="submit"
                    label={t('department.Submit')}
                    className="w-full"
                    disabled={isLoading || isPageLevelError}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div>
          {renderPageHeaderSection()}

          <div className="flex bg-white flex-column align-items-center justify-content-center border-1 border-200 border-round mt-3 p-2 md:p-0">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" w-full md:px-4 mt-2"
            >
              {userRole === USER_ROLE.SUPER_ADMIN && (
                <div className="card">
                  <Fieldset legend={t('department.title1')}>
                    <div className="grid">
                      <div className=" col-12 md:col-6">
                        <MzAutoComplete
                          control={control}
                          name={FORM_FIELDS_NAME.TENANT.name}
                          label={FORM_FIELDS_NAME.TENANT.label}
                          optionLabel={'companyName'}
                          optionValue={'id'}
                          placeholder={FORM_FIELDS_NAME.TENANT.placeholder}
                          isError={!!errors[FORM_FIELDS_NAME.TENANT.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.TENANT.name
                          )}
                          rules={FORM_FIELDS_NAME.TENANT.rules}
                          suggestions={tenantList}
                          value={getTenatntLabel(
                            getValues(FORM_FIELDS_NAME.TENANT.name)
                          )}
                          dropdown
                          disabled={isEdit}
                        />
                      </div>
                    </div>
                  </Fieldset>
                </div>
              )}

              <div className="card mt-3">
                <Fieldset legend={t('department.DepartmentDetails')}>
                  <div className="grid">
                    <div className="col-12 md:col-6">
                      <MzInput
                        control={control}
                        name={FORM_FIELDS_NAME.NAME.name}
                        label={FORM_FIELDS_NAME.NAME.label}
                        placeholder={FORM_FIELDS_NAME.NAME.placeholder}
                        type={FORM_FIELDS_NAME.NAME.type}
                        isError={errors[FORM_FIELDS_NAME.NAME.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.NAME.name
                        )}
                        rules={FORM_FIELDS_NAME.NAME.rules}
                        wrapperClass={'p-float-label'}
                      />
                    </div>
                  </div>
                </Fieldset>
              </div>

              <div className="grid justify-content-center mt-3 mb-5">
                <div className="col-12 md:col-4">
                  <Button
                    label={t('department.Cancel')}
                    className="w-full"
                    severity="secondary"
                    onClick={() => navigatetoDepartment()}
                    disabled={isLoading || isPageLevelError}
                  />
                </div>
                <div className="col-12 md:col-4">
                  <Button
                    type="submit"
                    label={t('department.Submit')}
                    className="w-full"
                    disabled={isLoading || isPageLevelError}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default AddDepartmentComponent
