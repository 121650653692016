import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import AddProductsComponent from '../../../components/product/addProducts'
import { MzToast, TOAST_SEVERITY } from '../../../common/MzToast'
import { ProgressBar } from 'primereact/progressbar'
import { useTranslation } from 'react-i18next'
import {
  createProductsRecord,
  updateProductsRecord,
  fetchProductsRecord,
  deleteImage,
  deleteProduct
} from '../../../redux/action/products'
import {
  fetchCategoryList,
  createCategoryRecord,
  fetchCategoryRecord,
  updateCategoryRecord,
  initCategory
} from '../../../redux/action/category'
import { ROUTE_PATH } from '../../../constant/urlConstant'
import { useNavigate } from 'react-router-dom'
import { fetchTenantList } from '../../../redux/action/tenant'
import { USER_ROLE } from '../../../constant/actionTypes/role'

const AddProductsScreen = (props) => {
  const {
    isCreateProductsSuccess,
    isCreateProductsError,
    isEditProductsSuccess,
    isEditProductsError,
    isEdit,
    error,
    isLoading,
    createProductsRecord,
    formFieldValueMap,
    isPageLevelError,
    user,
    isProductsDetailSuccess,
    updateProductsRecord,
    userRole,
    fetchTenantList,
    categoryError,
    tenantList,
    fetchCategoryList,
    fetchProductsRecord,
    isDeleteImageError,
    isDeleteImageSuccess,
    categorysList,
    deleteImage,
    createCategoryRecord,
    isCreateCategorySuccess,
    isEditCategorySuccess,
    isCategoryDetailSuccess,
    fetchCategoryRecord,
    updateCategoryRecord,
    isCreateCategoryError,
    navigatetoCategory,
    initCategory
  } = props

  const history = useNavigate()
  const { t } = useTranslation()
  useEffect(() => {
    if (userRole === USER_ROLE.SUPER_ADMIN) {
      fetchTenantList()
    } else {
      const payload = {
        pageNumber: 0,
        pageSize: 100,
        tenantId: user?.tenant?.id ?? ''
      }
      fetchCategoryList(payload)
    }
  }, [userRole, fetchTenantList, fetchCategoryList, user?.tenant?.id])

  const getToastProps = () => {
    if (isCreateProductsSuccess || isEditProductsSuccess) {
      const toastTitle = isEdit
        ? t('product.productsUpdated')
        : t('product.productsCreated')
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }
    if (isCreateProductsError || isEditProductsError) {
      let toastTitle = error ? error?.error : t('product.toastCreateError')
      if (isEditProductsError) {
        toastTitle = error ? error?.error : t('product.toastUpdateError')
      }
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isDeleteImageError) {      
      let toastTitle =error ? error?.error : t('product.imgerror2')
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
    else if(isDeleteImageSuccess)
    {
       let toastTitle=error ? error?.error : t('product.imgerror3')
       return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }

  const navigatetoAmenities = () => {
    history(ROUTE_PATH.PRODUCTS.HOME)
  }

  const addAmenitiesProductProps = {
    navigatetoAmenities,
    fetchCategoryList,
    createProductsRecord,
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    isCreateProductsSuccess,
    isCreateProductsError,
    isEditProductsSuccess,
    isEditProductsError,
    user,
    error,
    categoryError,
    userRole,
    isEdit,
    fetchProductsRecord,
    updateProductsRecord,
    tenantList,
    categorysList,
    isProductsDetailSuccess,
    isDeleteImageError,
    isDeleteImageSuccess,
    isCreateCategoryError,
    deleteProduct,
    deleteImage,
    createCategoryRecord,
    isCreateCategorySuccess,
    isEditCategorySuccess,
    isCategoryDetailSuccess,
    fetchCategoryRecord,
    updateCategoryRecord,
    navigatetoCategory,
    initCategory
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <AddProductsComponent addAmenitiesProps={addAmenitiesProductProps} />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    createProductsRecord: (payload) => dispatch(createProductsRecord(payload)),
    updateProductsRecord: (payload, id) =>
      dispatch(updateProductsRecord(payload, id)),
    fetchTenantList: () => dispatch(fetchTenantList()),
    initCategory: () => dispatch(initCategory()),
    fetchCategoryList: (payload) => dispatch(fetchCategoryList(payload)),
    fetchProductsRecord: (payload) => dispatch(fetchProductsRecord(payload)),
    deleteImage: (payload) => dispatch(deleteImage(payload)),
    createCategoryRecord: (payload) => dispatch(createCategoryRecord(payload)),
    fetchCategoryRecord: (payload) => dispatch(fetchCategoryRecord(payload)),
    updateCategoryRecord: (payload) => dispatch(updateCategoryRecord(payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    formFieldValueMap: selectFormFieldValueMap(state.productsReducer),
    isPageLevelError: state.productsReducer.isPageLevelError,
    isLoading: state.productsReducer.isLoading,
    error: state.productsReducer.error,
    isCreateProductsSuccess: state.productsReducer.isCreateProductsSuccess,
    isCreateProductsError: state.productsReducer.isCreateProductsError,
    isEditProductsSuccess: state.productsReducer.isEditProductsSuccess,
    isEditProductsError: state.productsReducer.isEditProductsError,
    user: state.userProfileReducer.user,
    userRole: state.loginReducer.userRole,
    tenantList: state.tenantReducer.tenantList,
    categorysList: state.categoryReducer.categorysList,
    isProductsDetailSuccess: state.productsReducer.isProductsDetailSuccess,
    isDeleteImageSuccess: state.productsReducer.isDeleteImageSuccess,
    isDeleteImageError: state.productsReducer.isDeleteImageError,
    isCreateCategorySuccess: state.categoryReducer.isCreateCategorySuccess,
    categoryError: state.categoryReducer.error,
    isCreateCategoryError: state.categoryReducer.isCreateCategoryError
  }
}

const selectFormFieldValueMap = (productsReducer) => {
  return productsReducer?.formFieldValueMap || {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProductsScreen)
