import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'primereact/button'
import 'react-phone-input-2/lib/style.css'
import { USER, ICON_LOGO } from '../../assets/images'
import { Carousel } from 'primereact/carousel'
import { fetchAmenitiesList } from '../../redux/action/amenities'
import { fetchProductsList } from '../../redux/action/products'
import { fetchDepartmentRecord } from '../../redux/action/department'
import '../card/index.css'
import { useTranslation } from 'react-i18next'

const CardPreviewComponent = ({
  selectedEmployee,
  serviceId,
  tenantId,
  filePreviewUrl,
  data
}) => {
  const [, setServiceVisible] = useState(false)
  const [, setSelectService] = useState([])
  const [, setSelectProduct] = useState([])

  // eslint-disable-next-line
  const dispatch = useDispatch()
  const employee = selectedEmployee
  const previewData = data
  const { t } = useTranslation()

  const payload = useMemo(() => ({ tenantId }), [tenantId])

  useEffect(() => {
    if (tenantId) {
      dispatch(fetchAmenitiesList(payload))
      dispatch(fetchProductsList(payload))
    }
  }, [dispatch, payload, tenantId])

  const aminitList = useSelector(
    (state) => state.amenitiesReducer?.amenitiesList?.content ?? []
  )

  const productsList = useSelector(
    (state) => state.productsReducer?.productList.content ?? []
  )
  useEffect(() => {
    if (previewData?.department) {
      dispatch(fetchDepartmentRecord(previewData.department))
    }
  }, [dispatch, previewData?.department])

  const selectedDepartment = useSelector(
    (state) => state.departmentReducer?.selectedDepartment
  )

  const ServicesProductList = [...aminitList, ...productsList]

  useEffect(() => {
    if (!serviceId) {
      setServiceVisible(false)
      return
    }

    if (aminitList && Array.isArray(aminitList)) {
      const idNumber = Number(serviceId)
      const data = aminitList.find((item) => item?.id === idNumber)
      if (data) {
        setSelectService(data)
        setServiceVisible(true)
        setSelectProduct(data)
      }
    } else {
      setSelectService(data)
    }
  }, [serviceId, aminitList, data])

  const itemTemplate = (item) => {
    return (
      <div
        className="flex flex-column justify-content-center align-items-center border-round w-full"
        style={{ height: '250px', width: '360px' }}
      >
        <div className="flex justify-content-evenly align-items-center border-circle  text-xs">
          <div className="flex justify-cotent-center col-2">
            <img
              src={item.icon ?? ICON_LOGO}
              className="w-2rem border-circle"
              alt="Logo"
            />
          </div>
          <div className="font-bold text-sm mr-2 text-900 col-10 flex justify-content-center">
            <span>{item.name ?? ''}</span>
          </div>
        </div>
        {item.video && (
          <video controls className="w-full mb-2" autoPlay loop muted>
            <source src={item.video} type="video/mp4" />
            {t('card.videoSupport')}
          </video>
        )}
        {item.description && (
          <div className="text-900 text-wrap max-h-4rem text-sm flex">
            {item.description.length > 15 ? (
              <>
                <div className="text-center">
                  {`${item.description.substring(0, 50)}...`}
                  <Button
                    disabled
                    label={t('card.ShowMore')}
                    text
                    className="text-indigo-500 text-xs h-1rem py-2 px-2 mt-2 underline outline-none border-none "
                  ></Button>
                </div>
              </>
            ) : (
              <p className="text-center">{item.description}</p>
            )}
          </div>
        )}
      </div>
    )
  }
  return (
    <div className="flex justify-content-center w-full">
      <div style={{ maxWidth: '360px', minWidth: '350px' }}>
        <div className="card px-1 border-round-xl ">
          <div className="card-header">
            <div className="mt-2 border-round-lg h-10rem  relative card-bg ">
              <div className="flex justify-content-start">
                <div className="flex col-3">
                  <img
                    src={filePreviewUrl ?? USER}
                    className="w-4rem h-4rem cursor-pointer border-circle"
                    alt="Profile"
                  />
                </div>
                <div className="col-4">
                  <div className="flex py-2 w-4rem">
                    <span className="font-bold text-base ">
                      {employee?.firstName ?? previewData?.firstName ?? ''}
                    </span>
                    <span className="font-bold ml-2 text-base ">
                      {employee?.lastName ?? previewData?.lastName ?? ''}
                    </span>
                  </div>
                  <div className="w-16rem  mb-1 font-medium">
                    <i className="pi pi-briefcase  text-white text-xs"></i>
                    <span className="ml-2 text-xs text-white card-font">
                      {employee?.department?.name ??
                        selectedDepartment?.name ??
                        ''}
                    </span>
                  </div>
                  <div className="flex  align-items-center font-medium mb-1 cursor-pointer">
                    <i className="pi   pi-phone text-white text-xs"></i>
                    <span className="ml-2  text-xs text-white card-font">
                      {employee?.mobileNumber ??
                        previewData?.mobileNumber ??
                        ''}
                    </span>
                  </div>
                  <div className="flex  align-items-center w-16rem cursor-pointer">
                    <i className="pi pi-envelope  text-white text-xs"></i>
                    <span className="ml-2 text-xs text-white card-font">
                      {employee?.email ?? previewData?.email ?? ''}
                    </span>
                  </div>
                </div>
                <div className="col-5 text-right">
                  <Button
                    disabled
                    icon="pi pi-share-alt  text-sm"
                    aria-label="share"
                  />
                </div>
              </div>
              <div className="flex p-2 w-full bg-white border-200 border-1  gap-2 absolute  border-round-bottom-xl mg-card-footer">
                <Button
                  disabled
                  label={t('Download Contact')}
                  className="w-full  border-none"
                />
                <Button
                  disabled
                  label={t('Exchange Info')}
                  className="w-full border-none"
                />
              </div>
            </div>
          </div>
          <div className="mt-5">
            {employee?.tenant?.companyIntro && (
              <div className="text-center">
                <h4>About Us</h4>
                <video controls className="w-full p-1" autoPlay loop muted>
                  <source
                    src={employee?.tenant?.companyIntro ?? ''}
                    type="video/mp4"
                  />
                  {t('card.videoSupport')}
                </video>
              </div>
            )}
          </div>
          {productsList && productsList.some((product) => product.active) && (
            <div className="mt-3 text-center">
              <h4>{t('card.Product')}</h4>
              <div className="grid ">
                {productsList
                  .filter((product) => product.active)
                  .map((product, index) => (
                    <div key={index} className="col-6">
                      <div className="surface  h-auto p-1   w-full shadow-2 border-round">
                        <div className="flex flex-column  align-items-center justify-content-between">
                          {product?.icon ? (
                            <img
                              src={product.icon}
                              alt={'Icon'}
                              className="h-5rem w-7rem border-round bg-cover  mt-2 mb-2"
                            />
                          ) : (
                            <img
                              src={ICON_LOGO}
                              alt={'Icon'}
                              className="h-5rem w-7rem border-round bg-cover  mt-2 mb-2"
                            />
                          )}

                          <div className="text-xs h-auto mb-2 whitespace-nowrap">
                            {product.name}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}

          {aminitList && aminitList.some((service) => service.active) && (
            <div className="mt-3 text-center">
              <h4>{t('card.Services')}</h4>
              <div className="grid">
                {aminitList
                  .filter((service) => service.active)
                  .map((service, index) => (
                    <div key={index} className="col-6">
                      <div className="surface  h-auto p-1  w-full shadow-2 border-50 border-round ">
                        <div className="flex flex-column  align-items-center">
                          {service?.icon ? (
                            <img
                              src={service.icon}
                              alt={service.name}
                              className="h-5rem w-7rem bg-cover border-round bg-cover  mt-2 mb-2"
                            />
                          ) : (
                            <img
                              src={ICON_LOGO}
                              alt={'Icon'}
                              className="h-5rem w-7rem border-round bg-cover  mt-2 mb-2"
                            />
                          )}
                          <div className="text-xs h-auto mb-2 whitespace-nowrap">
                            {service.name}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>

        {ServicesProductList &&
          ServicesProductList.some((items) => items.active) && (
            <div className="mt-3 border-2 border-200 border-round-xl p-3">
              <Carousel
                value={ServicesProductList.filter((items) => items.active)}
                numVisible={1}
                numScroll={1}
                itemTemplate={itemTemplate}
                showIndicators={false}
              />
            </div>
          )}
      </div>
    </div>
  )
}

export default CardPreviewComponent
