export const base64UrlDecode = (str) => {
  try {
    return decodeURIComponent(
      atob(str.replace(/-/g, '+').replace(/_/g, '/'))
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
  } catch (e) {
    console.error('Failed to decode base64 URL:', e)
    return null
  }
}

export const decodeToken = (token) => {
  if (!token) {
    return null
  }

  const parts = token.split('.')
  if (parts.length !== 3) {
    console.error('Token format is invalid')
    return null
  }

  const payload = parts[1]
  const decodedPayload = base64UrlDecode(payload)

  if (!decodedPayload) {
    console.error('Failed to decode token payload')
    return null
  }

  try {
    return JSON.parse(decodedPayload)
  } catch (e) {
    console.error('Failed to parse token payload:', e)
    return null
  }
}
