import {
  INIT_DEPARTMENT,
  FETCH_DEPARTMENT_LIST,
  FETCH_DEPARTMENT_RECORD,
  CREATE_DEPARTMENT_RECORD,
  DELETE_DEPARTMENT,
  UPDATE_DEPARTMENT_RECORD
} from '../../../constant/actionTypes/department'

const formFieldValueMap = {
  tenant: '',
  name: ''
}

const initialState = {
  departmentsList: null,
  selectedDepartment: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateDepartmentSuccess: false,
  isEditDepartmentSuccess: false,
  isDepartmentDetailSuccess: false,
  isDeleteDepartmentSuccess: false,
  isDeleteDepartmentError: false,
  isDepartmentDetailError: false,
  isCreateDepartmentError: false,
  isEditDepartmentError: false
}

const departmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_DEPARTMENT:
      return {
        ...state,
        departmentsList: null,
        formFieldValueMap: null,
        selectedDepartment: null,
        isLoading: false,
        isCreateDepartmentSuccess: false,
        isEditDepartmentSuccess: false,
        isDepartmentDetailSuccess: false,
        isDeleteDepartmentSuccess: false,
        isDeleteDepartmentError: false,
        isDepartmentDetailError: false,
        isCreateDepartmentError: false,
        isEditDepartmentError: false,
        error: null
      }
    case FETCH_DEPARTMENT_LIST.START:
    case FETCH_DEPARTMENT_RECORD.START:
    case CREATE_DEPARTMENT_RECORD.START:
    case DELETE_DEPARTMENT.START:
    case UPDATE_DEPARTMENT_RECORD.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreateDepartmentSuccess: false,
        isEditDepartmentSuccess: false,
        isDepartmentDetailSuccess: false,
        isDeleteDepartmentSuccess: false,
        isDeleteDepartmentError: false,
        isDepartmentDetailError: false,
        isCreateDepartmentError: false
      }
    case FETCH_DEPARTMENT_LIST.SUCCESS:
      return {
        ...state,
        departmentsList: action?.payload,
        isLoading: false,
        error: null
      }
    case FETCH_DEPARTMENT_RECORD.SUCCESS:
      return {
        ...state,
        selectedDepartment: action?.payload,
        formFieldValueMap: {
          tenant: action?.payload?.tenant?.id ?? '',
          id: action?.payload?.id ?? '',
          name: action?.payload?.name ?? '',
          active: action?.payload?.active
        },
        isLoading: false,
        error: null,
        isDepartmentDetailSuccess: true
      }
    case CREATE_DEPARTMENT_RECORD.SUCCESS:
      return {
        ...state,
        departmentsList: {
          ...state.departmentsList,
          content: state?.departmentsList?.content
            ? [...state.departmentsList.content, action.payload]
            : [action.payload]
        },
        isLoading: false,
        error: null,
        isCreateDepartmentSuccess: true
      }

    case UPDATE_DEPARTMENT_RECORD.SUCCESS:
      return {
        ...state,
        departmentsList: {
          ...state.departmentsList,
          content: state?.departmentsList?.content?.map((department) =>
            department.id === action?.payload?.id ? action?.payload : department
          )
        },
        isLoading: false,
        error: null,
        isEditDepartmentSuccess: true
      }
    case DELETE_DEPARTMENT.SUCCESS:
      return {
        ...state,
        ...state?.departmentsList,
        content: state?.departmentsList?.content?.filter(
          (department) => department?.id !== action?.payload?.departmentId
        ),
        isLoading: false,
        error: null,
        isDeleteDepartmentSuccess: true
      }
    case FETCH_DEPARTMENT_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error
      }
    case FETCH_DEPARTMENT_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error,
        isDepartmentDetailError: true
      }
    case CREATE_DEPARTMENT_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error,
        isCreateDepartmentError: true
      }
    case UPDATE_DEPARTMENT_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        isEditDepartmentError: true
      }

    case DELETE_DEPARTMENT.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error,
        isDeleteDepartmentError: true
      }
    // case CREATE_DEPARTMENT_RECORD.ERROR:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: action.payload.error,
    //     isCreateDepartmentError: true,
    //   };
    default:
      return state
  }
}

export default departmentReducer
