export const FORM_FIELDS_NAME = {
  TENANT: {
    name: 'tenant',
    label: 'card.tenant.label',
    type: 'dropdown',
    rules: {
      required: 'card.error.tenant.required'
    },
    options: [
      { name: 'Select tenant', value: '' },
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'card.tenant.placeholder'
  },

  NAME: {
    name: 'name',
    label: 'card.form.name.label',
    type: 'text',
    placeholder: 'card.form.name.placeholder',
    rules: {
      required: 'card.error.name.required',
      maxLength: {
        value: 50,
        message: 'card.error.name.maxLength'
      }
    }
  },

  PHONE_NUMBER: {
    name: 'phone',
    label: 'card.form.phoneNumber.label',
    type: 'tel',
    placeholder: 'card.form.phoneNumber.placeholder',
    rules: {
      required: 'card.error.phoneNumber.required',
      minLength: {
        value: 8,
        message: 'card.error.phoneNumber.minLength'
      },
      maxLength: {
        value: 14,
        message: 'card.error.phoneNumber.maxLength'
      }
    }
  },

  EMAIL: {
    name: 'email',
    label: 'card.form.email.label',
    type: 'email',
    placeholder: 'card.form.email.placeholder',
    rules: {
      required: 'card.error.email.required',
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        message: 'card.error.email.invalid'
      }
    }
  },

  CONFIRM_EMAIL: {
    name: 'confirmemail',
    label: 'card.form.confirmemail.label',
    type: 'email',
    placeholder: 'card.form.confirmemail.placeholder',
    rules: {
      required: 'card.error.confirmemail.required',
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        message: 'card.error.confirmemail.invalid'
      }
    }
  },

  ADDRESS: {
    name: 'address',
    label: 'card.form.address.label',
    type: 'text',
    placeholder: 'card.form.address.placeholder',
    rules: {
      required: 'card.error.address.required',
      maxLength: {
        value: 50,
        message: 'card.error.address.maxLength'
      }
    }
  },

  ADDITIONALINFO: {
    name: 'additionalInfo',
    label: 'card.form.additionalInfo.label',
    type: 'text',
    placeholder: 'card.form.additionalInfo.placeholder',
    rules: {
      required: 'card.error.additionalInfo.required',
      maxLength: {
        value: 50,
        message: 'card.error.additionalInfo.maxLength'
      }
    }
  },

  NOTES: {
    name: 'notes',
    label: 'card.form.notes.label',
    type: 'text',
    placeholder: 'card.form.notes.placeholder',
    rules: {
      required: 'card.error.notes.required',
      maxLength: {
        value: 50,
        message: 'card.error.notes.maxLength'
      }
    }
  },

  QUANTITY: {
    name: 'quantity',
    label: 'card.form.quantity.label',
    type: 'number',
    placeholder: 'card.form.quantity.placeholder',
    rules: {
      required: 'card.error.quantity.required',
      min: { value: 0, message: 'card.error.quantity.min' },
      max: { value: 50, message: 'card.error.quantity.max' }
    }
  }
};
