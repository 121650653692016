export const FORM_FIELDS_NAME = {
  FACEBOOK_LINK: {
    name: 'facebookLink',
    label: 'Login.form.facebookLink.label',
    type: 'text',
    placeholder: 'Login.form.facebookLink.placeholder',
    rules: {
      maxLength: {
        value: 100,
        message: 'Login.error.facebookLink.maxLength'
      }
    },
    icon: 'pi pi-facebook'
  },
  INSTAGRAM_LINK: {
    name: 'instagramLink',
    label: 'Login.form.instagramLink.label',
    type: 'text',
    placeholder: 'Login.form.instagramLink.placeholder',
    rules: {
      maxLength: {
        value: 100,
        message: 'Login.error.instagramLink.maxLength'
      }
    },
    icon: 'pi pi-instagram'
  },
  TWITTER_LINK: {
    name: 'twitterLink',
    label: 'Login.form.twitterLink.label',
    type: 'text',
    placeholder: 'Login.form.twitterLink.placeholder',
    rules: {
      maxLength: {
        value: 100,
        message: 'Login.error.twitterLink.maxLength'
      }
    },
    icon: 'pi pi-twitter'
  },
  LINKEDIN_LINK: {
    name: 'linkedinLink',
    label: 'Login.form.linkedinLink.label',
    type: 'text',
    placeholder: 'Login.form.linkedinLink.placeholder',
    rules: {
      maxLength: {
        value: 100,
        message: 'Login.error.linkedinLink.maxLength'
      }
    },
    icon: 'pi pi-linkedin'
  },
  WEBSITE_LINK: {
    name: 'websiteLink',
    label: 'Login.form.websiteLink.label',
    type: 'text',
    placeholder: 'Login.form.websiteLink.placeholder',
    rules: {
      maxLength: {
        value: 100,
        message: 'Login.error.websiteLink.maxLength'
      }
    },
    icon: 'pi pi-globe'
  },

  FIRST_NAME: {
    name: 'firstName',
    label: 'Login.form.firstName.label',
    type: 'text',
    placeholder: 'Login.form.firstName.placeholder',
    rules: {
      required: 'Login.error.firstName.required',
      minLength: {
        value: 3,
        message: 'Login.error.firstName.minLength'
      },
      maxLength: {
        value: 20,
        message: 'Login.error.firstName.maxLength'
      }
    }
  },
  LAST_NAME: {
    name: 'lastName',
    label: 'Login.form.lastName.label',
    type: 'text',
    placeholder: 'Login.form.lastName.placeholder',
    rules: {
      required: 'Login.error.lastName.required',
      minLength: {
        value: 3,
        message: 'Login.error.lastName.minLength'
      },
      maxLength: {
        value: 20,
        message: 'Login.error.lastName.maxLength'
      }
    }
  },
  MOBILE_NUMBER: {
    name: 'mobileNumber',
    label: 'Login.form.mobileNumber.label',
    type: 'tel',
    placeholder: 'Login.form.mobileNumber.placeholder',
    rules: {
      required: 'Login.error.mobileNumber.required',
      minLength: {
        value: 8,
        message: 'Login.error.mobileNumber.minLength'
      },
      maxLength: {
        value: 14,
        message: 'Login.error.mobileNumber.maxLength'
      }
    }
  },

  COMPANY_NAME: {
    name: 'companyName',
    label: 'Login.form.companyName.label',
    type: 'text',
    placeholder: 'Login.form.companyName.placeholder',
    rules: {
      required: 'Login.error.companyName.required',
      maxLength: {
        value: 50,
        message: 'Login.error.companyName.maxLength'
      }
    }
  },
  PHONE_NUMBER: {
    name: 'phoneNumber',
    label: 'Login.form.phoneNumber.label',
    type: 'tel',
    placeholder: 'Login.form.phoneNumber.placeholder',
    rules: {
      required: 'Login.error.phoneNumber.required',
      minLength: {
        value: 8,
        message: 'Login.error.phoneNumber.minLength'
      },
      maxLength: {
        value: 14,
        message: 'Login.error.phoneNumber.maxLength'
      }
    }
  },
  EMAIL: {
    name: 'email',
    label: 'Login.form.email.label',
    type: 'email',
    placeholder: 'Login.form.email.placeholder',
    rules: {
      required: 'Login.error.email.required',
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: 'Login.error.email.invalid'
      }
    }
  },
  BASE_URL: {
    name: 'baseUrl',
    label: 'Login.form.companyUrl.label',
    type: 'url',
    placeholder: 'Login.form.companyUrl.placeholder',
    rules: {
      maxLength: {
        value: 60,
        message: 'Login.error.companyUrl.maxLength'
      }
    }
  },
  CONTACT_PERSON: {
    name: 'contactPerson',
    label: 'Login.form.contactPerson.label',
    type: 'text',
    placeholder: 'Login.form.contactPerson.placeholder',
    rules: {
      required: 'Login.error.contactPerson.required',
      minLength: {
        value: 4,
        message: 'Login.error.contactPerson.minLength'
      },
      maxLength: {
        value: 50,
        message: 'Login.error.contactPerson.maxLength'
      }
    }
  },
  OTHER_LINKS: {
    name: 'otherLinks',
    label: 'Other Links',
    type: 'text',
    placeholder: 'Enter other links'
  },
  PLAN: {
    name: 'plan',
    label: 'Plan',
    type: 'text',
    placeholder: 'Enter plan'
  },
  PLAN_COST: {
    name: 'planCost',
    label: 'Plan Cost',
    type: 'number',
    placeholder: 'Enter plan cost'
  },
  RESELLER_ID: {
    name: 'resellerId',
    label: 'Reseller ID',
    type: 'text',
    placeholder: 'Enter reseller ID'
  },
  COMPANY_LOGO: {
    name: 'companyLogo',
    label: 'Company Logo',
    type: 'file',
    placeholder: 'Upload company logo',
    rules: {
      required: 'Company logo is required'
    }
  },

  NO_OF_EMPLOYEE: {
    name: 'noOfEmp',
    label: 'Login.form.noOfEmployee.label', // Reference for translation key
    type: 'dropdown',
    options: [
      { name: '0-9', value: '0-9' },
      { name: '10-20', value: '10-20' },
      { name: '20-50', value: '20-50' },
      { name: '50-100', value: '50-100' },
      { name: '100-300', value: '100-300' },
      { name: '300-500', value: '300-500' },
      { name: '500-1000+', value: '500-1000+' }
    ],
    placeholder: 'Login.form.noOfEmployee.placeholder'
  },

  NO_OF_SERVICES: {
    name: 'noOfServices',
    label: 'Login.form.noOfServices.label', // Reference for translation key
    type: 'dropdown',
    options: [
      { name: '0-9', value: '0-9' },
      { name: '10-20', value: '10-20' },
      { name: '20-50', value: '20-50' },
      { name: '50-100', value: '50-100' },
      { name: '100-300', value: '100-300' },
      { name: '300-500', value: '300-500' },
      { name: '500-1000+', value: '500-1000+' }
    ],
    placeholder: 'Login.form.noOfServices.placeholder'
  },

  NO_OF_PRODUCTS: {
    name: 'noOfProduct',
    label: 'Login.form.noOfProducts.label',
    type: 'dropdown',
    options: [
      { name: '0-9', value: '0-9' },
      { name: '10-20', value: '10-20' },
      { name: '20-50', value: '20-50' },
      { name: '50-100', value: '50-100' },
      { name: '100-300', value: '100-300' },
      { name: '300-500', value: '300-500' },
      { name: '500-1000+', value: '500-1000+' }
    ],
    placeholder: 'Login.form.noOfProducts.placeholder'
  },

  USER_PROFILE: {
    name: 'profile',
    label: 'User Profile',
    type: 'file',
    placeholder: 'Upload User Profile',
    rules: {
      required: 'User Profile is required'
    }
  },
  USERNAME: {
    name: 'username',
    label: 'Login.form.userName.label',
    type: 'text',
    placeholder: 'Login.form.userName.placeholder',
    rules: {
      required: 'Login.error.userName.required',
      minLength: {
        value: 3,
        message: 'Login.error.userName.minLength'
      },
      maxLength: {
        value: 50,
        message: 'Login.error.userName.maxLength'
      }
    }
  },
  USER_EMAIL: {
    name: 'userEmail',
    label: 'Login.form.email.label',
    type: 'email',
    placeholder: 'Login.form.password.placeholder',
    rules: {
      required: 'Login.error.email.required',
      pattern: {
        value: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
        message: 'Login.error.email.invalid'
      }
    }
  },
  PASSWORD: {
    name: 'password',
    label: 'Login.form.password.label',
    type: 'password',
    placeholder: 'Enter your password',
    rules: {
      required: 'Login.error.password.required',
      minLength: {
        value: 4,
        message: 'Login.error.password.minLength'
      },
      maxLength: {
        value: 40,
        message: 'Login.error.password.maxLength'
      }
    }
  },
  CONFIRM_PASSWORD: {
    name: 'confirmPassword',
    label: 'Login.form.confirmPassword.label',
    type: 'password',
    placeholder: 'Enter your confirm password',
    rules: {
      required: 'Password is confirm required',
      minLength: {
        value: 4,
        message: ' ConFirm password must be at least 4 characters'
      },
      maxLength: {
        value: 40,
        message: 'ConFirm password cannot exceed 40 characters'
      }
    }
  },
  SOCIAL_LINKS: {
    name: 'socialLinks',
    label: 'Login.form.socialLinks.label',
    type: 'text',
    placeholder: 'Login.form.socialLinks.placeholder'
  },

  FOOTER_COLOR: {
    name: 'footerColor',
    label: 'Footer Color',
    type: 'dropdown',
    options: [
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'Select footer color'
  },
  H1_FONT: {
    name: 'h1Font',
    label: 'H1 Font',
    type: 'dropdown',
    options: [
      { name: 'Arial', value: 'Arial, sans-serif' },
      { name: 'Times New Roman', value: 'Times New Roman, serif' },
      { name: 'Verdana', value: 'Verdana, sans-serif' }
    ],
    placeholder: 'Select H1 font'
  },
  H2_FONT: {
    name: 'h2Font',
    label: 'H2 Font',
    type: 'dropdown',
    options: [
      { name: 'Arial', value: 'Arial, sans-serif' },
      { name: 'Times New Roman', value: 'Times New Roman, serif' },
      { name: 'Verdana', value: 'Verdana, sans-serif' }
    ],
    placeholder: 'Select H2 font'
  },
  H3_FONT: {
    name: 'h3Font',
    label: 'H3 Font',
    type: 'dropdown',
    options: [
      { name: 'Arial', value: 'Arial, sans-serif' },
      { name: 'Times New Roman', value: 'Times New Roman, serif' },
      { name: 'Verdana', value: 'Verdana, sans-serif' }
    ],
    placeholder: 'Select H3 font'
  },
  HEADER_COLOR: {
    name: 'headerColor',
    label: 'Header Color',
    type: 'dropdown',
    options: [
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'Select header color'
  },
  ICON_COLOR: {
    name: 'iconColor',
    label: 'Icon Color',
    type: 'dropdown',
    options: [
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'Select icon color'
  },
  BAND_COLOR: {
    name: 'bandColor',
    label: 'Band Color',
    type: 'dropdown',
    options: [
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'Select band color'
  },
  BG_COLOR: {
    name: 'bgColor',
    label: 'Background Color',
    type: 'dropdown',
    options: [
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'Select background color'
  }
}

export const FORM_FIELDS_DROP_DOWN = [
  {
    name: 'footerColor',
    label: 'Footer Color',
    type: 'dropdown',
    options: [
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'Select footer color'
  },
  {
    name: 'h1Font',
    label: 'H1 Font',
    type: 'dropdown',
    options: [
      { name: 'Arial', value: 'Arial, sans-serif' },
      { name: 'Times New Roman', value: 'Times New Roman, serif' },
      { name: 'Verdana', value: 'Verdana, sans-serif' }
    ],
    placeholder: 'Select H1 font'
  },
  {
    name: 'h2Font',
    label: 'H2 Font',
    type: 'dropdown',
    options: [
      { name: 'Arial', value: 'Arial, sans-serif' },
      { name: 'Times New Roman', value: 'Times New Roman, serif' },
      { name: 'Verdana', value: 'Verdana, sans-serif' }
    ],
    placeholder: 'Select H2 font'
  },
  {
    name: 'h3Font',
    label: 'H3 Font',
    type: 'dropdown',
    options: [
      { name: 'Arial', value: 'Arial, sans-serif' },
      { name: 'Times New Roman', value: 'Times New Roman, serif' },
      { name: 'Verdana', value: 'Verdana, sans-serif' }
    ],
    placeholder: 'Select H3 font'
  },
  {
    name: 'headerColor',
    label: 'Header Color',
    type: 'dropdown',
    options: [
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'Select header color'
  },
  {
    name: 'iconColor',
    label: 'Icon Color',
    type: 'dropdown',
    options: [
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'Select icon color'
  },
  {
    name: 'bandColor',
    label: 'Band Color',
    type: 'dropdown',
    options: [
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'Select band color'
  },
  {
    name: 'bgColor',
    label: 'Background Color',
    type: 'dropdown',
    options: [
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'Select background color'
  }
]
