export const ROUTE_PATH = {
  BASE: {
    HOME: '/',
    REGISTER: '/signup'
  },
  DASHBOARD: {
    HOME: '/'
  },
  COMPANY: {
    HOME: '/company',
    ADD: '/company/add',
    EDIT: '/company/edit/:id'
  },
  EMPOLYEE: {
    HOME: '/employee',
    ADD: '/employee/add',
    EDIT: '/employee/edit/:id'
  },
  CONTACT: {
    HOME: '/contact',
    ADD: '/contact/add',
    EDIT: '/contact/edit/:id'
  },
  ENQUIRY: {
    HOME: '/enquiry',
    ADD: '/enquiry/add',
    EDIT: '/enquiry/edit/:id'
  },
  DEPARTMENT: {
    HOME: '/department',
    ADD: '/department/add',
    EDIT: '/department/edit/:id'
  },
  ORGANIZATION: {
    HOME: '/organization',
    ADD: '/organization/add',
    EDIT: '/organization/edit/:id'
  },
  AMENITIES: {
    HOME: '/services',
    ADD: '/services/add',
    EDIT: '/services/edit/:id'
  },
  PRODUCTS: {
    HOME: '/products',
    ADD: '/products/addProduct',
    EDIT: '/products/editProduct/:id'
  },
  SETING: {
    HOME: '/setting'
  },
  BUSSINESS_CARD: {
    HOME: '/user-digital-card/:id'
  },
  CATEGORY: {
    HOME: '/category',
    ADD: '/category/add',
    EDIT: '/category/edit/:id'
  }
}

export const API_PATH = {
  AUTH: {
    LOGIN: '/authenticate',
    REGISTER: '/tenant-user'
  },

  USER_PROFILE: {
    FETCH: '/users'
  },
  EMPOLYEE: {
    GETALL:"/getallusers",
    FETCH: '/users',
    ADD: '/users',
    EDIT: '/users',
    DELETE: '/user-deactivate',
    COUNT: '/users/count'
  },
  TENANT: {
    FETCH: '/tenants',
    FETCHPAGE: '/tenants/page',
    ADD: '/tenants',
    EDIT: '/tenants',
    DELETE: '/tenant'
  },
  ORGANIZATION:{
    FETCH: '/organization', 
    FETCHPAGE: '/organization/page',
    EDIT: '/organization',
  },
  CONTACT: {
    FETCH: '/contacts',
    FETCH_BY_TENANT: '/contacts/tenant',
    ADD: '/contacts',
    EDIT: '/contacts',
    DELETE: '/contacts',
    COUNT: '/contacts/count',
    SAVE:"/contacts/superadmin/save",
    UPDATE:"/contacts/superadmin/update",
    EXCHANGEINFO: '/contacts-exchange-info'
  },
  ENQUIRY: {
    FETCH : '/interest',
    FETCH_BY_TENANT: '/interest-tenants'
  },
  AMENITIES: {
    FETCH: '/services',
    FETCH_BY_TENANT: '/services-tenant',
    ADD: '/services',
    EDIT: '/services',
    DELETE: '/services',
    COUNT: '/services/count',
    ENQUERYCOUNT:'/services/enquiry/count',
    INTRESTCOUNT:'/services/like/count'
  },
  DEPARTMENT: {
    FETCH: '/department',
    FETCH_BY_TENANT: '/department-tenant',
    ADD: '/department',
    EDIT: '/department',
    DELETE: '/department'
  },
  INTEREST: {
    FETCH: '/interest',
    ADD: '/interest',
    COUNT: '/interest-contacts'
  },

  OTP_VERIFY: {
    VERIFY: '/account/otp/verify'
  },

  RESEND_OTP: {
    RESEND: '/account/otp/resend'
  },

  ANALYTICS: {
    FETCH: '/analytics',
    FETCH_BY_TENANT: '/analytics-tenant',
    FETCH_BY_USER: '/analytics-user',
    FETCH_BY_USER_AND_TENANT: '/analytics-user-tenant'
  },
  PRODUCTS: {
    FETCH: '/products',
    ADD: '/products',
    EDIT: '/products',
    DELETE: '/products',
    DELETEIMAGEE: '/products-images',
    FETCH_BY_TENANT: '/products-tenant',
    ADD_INQUIRY: '/interest/product-inquiry',
    COUNT: '/products/count',
    ENQUERYCOUNT:'/products/enquiry/count',
    INTRESTCOUNT:'/products/like/count'
  },
  CATEGORY: {
    FETCH: '/categories',
    FETCH_BY_TENANT: '/categories-tenant',
    ADD: '/categories',
    EDIT: '/categories',
    DELETE: '/categories'
  }
}
