import {
  INIT_USER_PROFILE,
  FETCH_USER_PROFILE_RECORD
} from '../../../constant/actionTypes/userProfile'
import { userProfileService } from '../../../services'

export const initialUserProfileScreen = () => {
  return {
    type: INIT_USER_PROFILE
  }
}

export const userProfileFetchStart = (payload) => {
  return {
    type: FETCH_USER_PROFILE_RECORD.START,
    payload
  }
}

export const userProfileFetchSuccess = (payload) => {
  return {
    type: FETCH_USER_PROFILE_RECORD.SUCCESS,
    payload
  }
}

export const userProfileFetchError = (payload) => {
  return {
    type: FETCH_USER_PROFILE_RECORD.ERROR,
    payload
  }
}

export const fetchUserProfile = () => {
  return (dispatch) => {
    dispatch(userProfileFetchStart())
    userProfileService.fetchUserProfile().then((userProfileData) => {
      if (!userProfileData.isError) {
        dispatch(userProfileFetchSuccess(userProfileData))
        sessionStorage.setItem('userProfile', JSON.stringify(userProfileData))
      } else {
        dispatch(userProfileFetchError(userProfileData))
      }
    })
  }
}
