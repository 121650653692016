import axios from 'axios'
import { API_PATH } from '../../constant/urlConstant'
import { handleAPIError } from '../common/errorHandler'
import { baseUrl } from '../PostAPI'
import { getDecoratedUrl } from '../common/urlService'
import { USER_ROLE } from '../../constant/actionTypes/role'

export const fetchAmenitiesList = async (payload = {}) => {
  let url
  const { tenantId, pageSize, pageNumber } = payload ?? {}
  if (!tenantId) {
    url = `${baseUrl}${API_PATH.AMENITIES.FETCH}`
  } else {
    url = `${baseUrl}${API_PATH.AMENITIES.FETCH_BY_TENANT}/${tenantId}`
  }

  let finalUrl = getDecoratedUrl({
    url,
    payload: { pageSize, pageNumber },
    shouldSort: false,
    shouldFilter: false
  })

  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(finalUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const fetchAmenitiesListByUserId = async (payload) => {
  const url = `${baseUrl}${API_PATH.AMENITIES.FETCH}?userId.equals=${payload}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const fetchAmenitiesRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.AMENITIES.FETCH}/${payload}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const createAmenitiesRecord = async (payload) => {
  const token = sessionStorage.getItem('token')
  const url = `${baseUrl}${API_PATH.AMENITIES.ADD}`
  try {
    const result = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    if (result?.data?.error || result.status !== 201) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const updateAmenitiesRecord = async (payload, id) => {
  const url = `${baseUrl}${API_PATH.AMENITIES.EDIT}/${id}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.patch(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const deleteAmenities = async (amenitiesId) => {
  const url = `${baseUrl}${API_PATH.AMENITIES.DELETE}/${amenitiesId}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 204) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const getAmenitiesCount = async (role, payload) => {
  let url = ''
  if (role === USER_ROLE.SUPER_ADMIN) {
    url = `${baseUrl}${API_PATH.AMENITIES.COUNT}`
  } else if (role === USER_ROLE.ADMIN || role === USER_ROLE.USER) {
    url = `${baseUrl}${API_PATH.AMENITIES.COUNT}/${payload}`
  }

  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.status !== 200) {
      return handleAPIError(result?.data?.detail ?? '')
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const getAmenitiesEnqueryCount = async (role, payload) => {
  let url = ''
  if (role === USER_ROLE.SUPER_ADMIN) {
    url = `${baseUrl}${API_PATH.AMENITIES.ENQUERYCOUNT}`
  } else if (role === USER_ROLE.ADMIN || role === USER_ROLE.USER) {
    url = `${baseUrl}${API_PATH.AMENITIES.ENQUERYCOUNT}?tenantId=${payload}`
  }

  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.status !== 200) {
      return handleAPIError(result?.data?.detail ?? '')
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const getAmenitiesIntrestCount = async (role, payload) => {
  let url = ''
  if (role === USER_ROLE.SUPER_ADMIN) {
    url = `${baseUrl}${API_PATH.AMENITIES.INTRESTCOUNT}`
  } else if (role === USER_ROLE.ADMIN || role === USER_ROLE.USER) {
    url = `${baseUrl}${API_PATH.AMENITIES.INTRESTCOUNT}?tenantId=${payload}`
  }

  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.status !== 200) {
      return handleAPIError(result?.data?.detail ?? '')
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}
