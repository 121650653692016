import axios from 'axios'
import { API_PATH } from '../../constant/urlConstant'
import { handleAPIError } from '../common/errorHandler'
import { baseUrl } from '../PostAPI'
import { USER_ROLE } from '../../constant/actionTypes/role'
import { getDecoratedUrl } from '../common/urlService'

export const fetchContactList = async (payload) => {
  const { tenantId, pageSize, pageNumber } = payload ?? {}
  let url
  if (!tenantId) {
    url = `${baseUrl}${API_PATH.CONTACT.FETCH}`
  } else {
    url = `${baseUrl}${API_PATH.CONTACT.FETCH_BY_TENANT}/${tenantId}`
  }
  const finalUrl = getDecoratedUrl({
    url,
    payload: { pageSize, pageNumber },
    shouldSort: false,
    shouldFilter: false
  })

  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(finalUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}

export const fetchContactListByUserId = async (payload) => {
  const { userId, pageSize, pageNumber } = payload ?? {}
  let url
  url = `${baseUrl}${API_PATH.CONTACT.FETCH}`
  const finalUrl = getDecoratedUrl({
    url,
    payload: { pageSize, pageNumber },
    shouldSort: false,
    shouldFilter: false
  })
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(`${finalUrl}&userId.equals=${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}

export const fetchContactRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.CONTACT.FETCH}/${payload}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}

export const createContactRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.CONTACT.ADD}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.post(url, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 201) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const createExchangeInfoRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.CONTACT.EXCHANGEINFO}?eventType=CARD_EXCHANGE`
  try {
    const result = await axios.post(url, payload)
    if (result?.data?.error || result.status !== 201) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const updateContactRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.CONTACT.EDIT}/${payload.id}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.patch(url, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const deleteContact = async (contactId) => {
  const url = `${baseUrl}${API_PATH.CONTACT.DELETE}/${contactId}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 204) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const getContactCount = async (role, payload) => {
  let url = ''
  if (USER_ROLE.ADMIN === role) {
    url = `${baseUrl}${API_PATH.CONTACT.FETCH}/tenants/${payload}`
  } else if (role === USER_ROLE.SUPER_ADMIN) {
    url = `${baseUrl}${API_PATH.CONTACT.COUNT}`
  } else if (role === USER_ROLE.USER) {
    url = `${baseUrl}${API_PATH.CONTACT.COUNT}?userId.equals=${payload}`
  }

  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.status !== 200) {
      return handleAPIError(result?.data?.detail ?? '')
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const updateContactRecordBysuperAdmin = async (payload) => {
  const url = `${baseUrl}${API_PATH.CONTACT.UPDATE}/${payload.id}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.put(url, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const createContactRecordBysuperAdmin = async (payload) => {
  const url = `${baseUrl}${API_PATH.CONTACT.SAVE}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.post(url, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}
