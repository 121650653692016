import {
  INIT_ORGANIZATION,
  FETCH_ORGANIZATION_LIST,
  FETCH_ORGANIZATION_RECORD,
  CREATE_ORGANIZATION_RECORD,
  DELETE_ORGANIZATION,
  UPDATE_ORGANIZATION_RECORD,
  FETCH_PAGABLE_ORGANIZATION_LIST
} from '../../../constant/actionTypes/organization'

const formFieldValueMap = {

}

const initialState = {
  organizationList: [],
  pagableOrganizationList:null,
  selectedOrganization: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateOrganizationSuccess: false,
  isEditOrganizationSuccess: false,
  isOrganizationDetailSuccess: false,
  isDeleteOrganizationSuccess: false,
  isDeleteOrganizationError: false,
  isOrganizationDetailError: false,
  isCreateOrganizationError: false,
  isEditOrganizationError: false
}

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_ORGANIZATION:
      return {
        ...state,
        organizationList: [],
        pagableOrganizationList:null,
        formFieldValueMap: null,
        selectedOrganization: null,
        isLoading: false,
        isCreateOrganizationSuccess: false,
        isEditOrganizationSuccess: false,
        isOrganizationDetailSuccess: false,
        isDeleteOrganizationSuccess: false,
        isDeleteOrganizationError: false,
        isOrganizationDetailError: false,
        isCreateOrganizationError: false,
        isEditOrganizationError: false,
        error: null
      }
    case FETCH_ORGANIZATION_LIST.START:
    case FETCH_PAGABLE_ORGANIZATION_LIST.START:
    case FETCH_ORGANIZATION_RECORD.START:
    case CREATE_ORGANIZATION_RECORD.START:
    case DELETE_ORGANIZATION.START:
    case UPDATE_ORGANIZATION_RECORD.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreateOrganizationSuccess: false,
        isEditOrganizationSuccess: false,
        isOrganizationDetailSuccess: false,
        isDeleteOrganizationSuccess: false,
        isDeleteOrganizationError: false,
        isOrganizationDetailError: false,
        isCreateOrganizationError: false,
        isEditOrganizationError: false
      }
    case FETCH_ORGANIZATION_LIST.SUCCESS:
      return {
        ...state,
        organizationList: action.payload,
        isLoading: false,
        error: null
      }
      case FETCH_PAGABLE_ORGANIZATION_LIST.SUCCESS:
      return {
        ...state,
        pagableOrganizationList: action.payload,
        isLoading: false,
        error: null
      }
    case FETCH_ORGANIZATION_RECORD.SUCCESS:
      let otherLinks = action.payload.otherLinks ?? ''
      let linkobj = {}

      if (otherLinks) {
        try {
          linkobj = JSON.parse(otherLinks)
        } catch (error) {
          console.log('Error parsing otherLinks:', error)
        }
      }
      return {
        ...state,
        formFieldValueMap: {
          id: action.payload.id ?? '',
          companyName: action.payload.companyName ?? '',
          email: action.payload.tenantConfigDTO?.email ?? '',
          phoneNumber: action.payload.phoneNumber ?? '',
          baseUrl: action.payload.tenantConfigDTO?.baseUrl ?? '',
          contactPerson: action.payload.contactPerson ?? '',
          h1Font: action.payload.h1Font ?? '',
          h2Font: action.payload.h2Font ?? '',
          h3Font: action.payload.h3Font ?? '',
          headerColor: action.payload.headerColor ?? '',
          footerColor: action.payload.footerColor ?? '',
          bgColor: action.payload.bgColor ?? '',
          iconColor: action.payload.iconColor ?? '',
          bandColor: action.payload.bandColor ?? '',
          facebookLink: linkobj.facebook ?? '',
          instagramLink: linkobj.instagram ?? '',
          twitterLink: linkobj.twitter ?? '',
          linkedinLink: linkobj.linkedin ?? '',
          websiteLink: linkobj.website ?? '',
          companyBroucher:action?.payload?.brochure?? '',
          companyIntro: action.payload?.companyIntro ?? '',
          companyLogo: action.payload.companyLogo ?? '',
          noOfServices: action.payload.noOfServices ?? '',
          noOfProduct: action.payload.noOfProduct ?? '',
          noOfEmp: action.payload.noOfEmp ?? '',
        },
        selectedOrganization: {
          id: action.payload.id ?? '',
          companyName: action.payload.companyName ?? '',
          email: action.payload.tenantConfigDTO?.email ?? '',
          phoneNumber: action.payload.phoneNumber ?? '',
          baseUrl: action.payload.tenantConfigDTO?.baseUrl ?? '',
          contactPerson: action.payload.contactPerson ?? '',
          h1Font: action.payload.h1Font ?? '',
          h2Font: action.payload.h2Font ?? '',
          h3Font: action.payload.h3Font ?? '',
          headerColor: action.payload.headerColor ?? '',
          footerColor: action.payload.footerColor ?? '',
          bgColor: action.payload.bgColor ?? '',
          iconColor: action.payload.iconColor ?? '',
          bandColor: action.payload.bandColor ?? '',
          facebookLink: linkobj.facebook ?? '',
          instagramLink: linkobj.instagram ?? '',
          twitterLink: linkobj.twitter ?? '',
          linkedinLink: linkobj.linkedin ?? '',
          websiteLink: linkobj.website ?? '',
          companyBroucher:action?.payload?.companyBroucher?? '',
          companyLogo: action.payload.companyLogo ?? '',
          companyIntro: action.payload.companyIntro ?? '',
          noOfServices: action.payload.noOfServices ?? '',
          noOfProduct: action.payload.noOfProduct ?? '',
          noOfEmp: action.payload.noOfEmp ?? ''
        },
        isLoading: false,
        error: null,
        isOrganizationDetailSuccess: true
      }
    case CREATE_ORGANIZATION_RECORD.SUCCESS:
      return {
        ...state,
        organizationList: [...state.organizationList, action.payload],
        isLoading: false,
        error: null,
        isCreateOrganizationSuccess: true
      }
    case UPDATE_ORGANIZATION_RECORD.SUCCESS:
      return {
        ...state,
        organizationList: state.organizationList.map((organization) =>
          organization.id === action.payload.id ? action.payload : organization
        ),
        isLoading: false,
        error: null,
        isEditOrganizationSuccess: true
      }
    case DELETE_ORGANIZATION.SUCCESS:
      return {
        ...state,
        organizationList: state.organizationList.filter(
          (organization) => organization.id !== action.payload.organizationId
        ),
        isLoading: false,
        error: null,
        isDeleteOrganizationSuccess: true
      }
    case FETCH_ORGANIZATION_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
      case FETCH_PAGABLE_ORGANIZATION_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case FETCH_ORGANIZATION_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isOrganizationDetailError: true
      }
    case CREATE_ORGANIZATION_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        isCreateOrganizationError: true
      }
    case UPDATE_ORGANIZATION_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        isEditOrganizationError: true
      }

    case DELETE_ORGANIZATION.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDeleteOrganizationError: true
      }
    default:
      return state
  }
}

export default organizationReducer
