export const INIT_ENQUIRY = 'INIT_ENQUIRY'

export const FETCH_ENQUIRY_LIST = {
  START: 'FETCH_ENQUIRY_START',
  SUCCESS: 'FETCH_ENQUIRY_SUCCESS',
  ERROR: 'FETCH_ENQUIRY_ERROR'
}

export const FETCH_ENQUIRY_RECORD = {
  START: 'FETCH_ENQUIRY_RECORD_START',
  SUCCESS: 'FETCH_ENQUIRY_RECORD_SUCCESS',
  ERROR: 'FETCH_ENQUIRY_RECORD_ERROR'
}

export const CREATE_ENQUIRY_RECORD = {
  START: 'CREATE_ENQUIRY_RECORD_START',
  SUCCESS: 'CREATE_ENQUIRY_RECORD_SUCCESS',
  ERROR: 'CREATE_ENQUIRY_RECORD_ERROR'
}

export const UPDATE_ENQUIRY_RECORD = {
  START: 'UPDATE_ENQUIRY_RECORD_START',
  SUCCESS: 'UPDATE_ENQUIRY_RECORD_SUCCESS',
  ERROR: 'UPDATE_ENQUIRY_RECORD_ERROR'
}

export const DELETE_ENQUIRY = {
  START: 'DELETE_ENQUIRY_START',
  SUCCESS: 'DELETE_ENQUIRY_SUCCESS',
  ERROR: 'DELETE_ENQUIRY_ERROR'
}

export const GET_ENQUIRY_COUNT = {
  START: 'GET_ENQUIRY_COUNT_START',
  SUCCESS: 'GET_ENQUIRY_COUNT_SUCCESS',
  ERROR: 'GET_ENQUIRY_COUNT_ERROR'
}
