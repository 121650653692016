export const FORM_FIELDS_NAME = {
  TENANT: {
    name: 'tenant',
    label: 'employee.form.tenant.label',
    type: 'dropdown',
    rules: {
      required: 'employee.error.tenant.required'
    },
    options: [],
    placeholder: 'employee.form.tenant.placeholder'
  },

  DEPARTMENT: {
    name: 'department',
    label: 'employee.form.department.label',
    type: 'dropdown',
    options: [],
    placeholder: 'employee.form.department.placeholder'
  },
  AUTHORITIES: {
    name: 'authorities',
    label: 'employee.form.authorities.label',
    type: 'dropdown',
    rules: {
      required: 'employee.error.authorities.required'
    },
    options: [
      { name: 'Admin', value: 'ROLE_ADMIN' },
      { name: 'Employee', value: 'ROLE_USER' }
    ],
    placeholder: 'employee.form.authorities.placeholder'
  },
  FIRST_NAME: {
    name: 'firstName',
    label: 'employee.form.firstName.label',
    type: 'text',
    placeholder: 'employee.form.firstName.placeholder',
    rules: {
      required: 'employee.error.firstName.required',
      minLength: {
        value: 3,
        message: 'employee.error.firstName.minLength'
      },
      maxLength: {
        value: 50,
        message: 'employee.error.firstName.maxLength'
      }
    }
  },
  LAST_NAME: {
    name: 'lastName',
    label: 'employee.form.lastName.label',
    type: 'text',
    placeholder: 'employee.form.lastName.placeholder',
    rules: {
      required: 'employee.error.lastName.required',
      minLength: {
        value: 3,
        message: 'employee.error.lastName.minLength'
      },
      maxLength: {
        value: 50,
        message: 'employee.error.lastName.maxLength'
      }
    }
  },
  PHONE_NUMBER: {
    name: 'mobileNumber',
    label: 'employee.form.mobileNumber.label',
    type: 'tel',
    placeholder: 'employee.form.mobileNumber.placeholder',
    rules: {
      required: 'employee.error.mobileNumber.required',
      minLength: {
        value: 8,
        message: 'employee.error.mobileNumber.minLength'
      },
      maxLength: {
        value: 14,
        message: 'employee.error.mobileNumber.maxLength'
      }
    }
  },
  USERNAME: {
    name: 'username',
    label: 'employee.form.username.label',
    type: 'text',
    placeholder: 'employee.form.username.placeholder',
    rules: {
      required: 'employee.error.username.required',
      minLength: {
        value: 3,
        message: 'employee.error.username.minLength'
      },
      maxLength: {
        value: 50,
        message: 'employee.error.username.maxLength'
      }
    }
  },
  EMAIL: {
    name: 'email',
    label: 'employee.form.email.label',
    type: 'email',
    placeholder: 'employee.form.email.placeholder',
    rules: {
      required: 'employee.error.email.required',
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        message: 'employee.error.email.pattern'
      }
    }
  },
  PASSWORD: {
    name: 'password',
    label: 'employee.form.password.label',
    type: 'password',
    placeholder: 'employee.form.password.placeholder',
    rules: {
      required: 'employee.error.password.required',
      minLength: {
        value: 4,
        message: 'employee.error.password.minLength'
      },
      maxLength: {
        value: 40,
        message: 'employee.error.password.maxLength'
      }
    }
  },

  FILE: {
    name: 'file',
    label: 'employee.form.file.label',
    type: 'file',
    placeholder: 'employee.form.file.placeholder',
    rules: {
      required: 'employee.error.file.required'
    }
  }
}
