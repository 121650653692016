import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FORM_FIELDS_NAME } from './constant'
import MzInput from '../../../common/MzForm/MzInput'
import { Button } from 'primereact/button'
import { BreadCrumb } from 'primereact/breadcrumb'
import { ROUTE_PATH } from '../../../constant/urlConstant'
import { useNavigate, useParams } from 'react-router-dom'
import MzPhoneInput from '../../../common/MzForm/MzPhoneInput'
import { Fieldset } from 'primereact/fieldset'
import MzFileInput from '../../../common/MzForm/MzFileInput'
import MzInputGroup from '../../../common/MzForm/MzInputGroup'
import MzAutoComplete from '../../../common/MzForm/MzAutoComplete'
import { useTranslation } from 'react-i18next'

const EditOrgnizationComponent = (props) => {
  const {
    updateOrganizationRecord,
    formFieldValueMap,
    isLoading,
    isCreateOrganizationSuccess,
    isEditOrganizationSuccess,
    isOrganizationDetailSuccess,
    isPageLevelError,
    isEdit,
    handlefetchOrganizationRecord
  } = props.addOrganizationProps

  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
    reset,
    getValues
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap
    }, [formFieldValueMap]),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const history = useNavigate()
  const { id } = useParams()
  const [filePreview, setFilePreview] = useState({
    icon: null,
    image: null,
    video: null,
    brochure: null
  })

  useEffect(() => {
    const baseUrlValue = watch(FORM_FIELDS_NAME.BASE_URL.name)
    if (baseUrlValue) {
      setValue(FORM_FIELDS_NAME.WEBSITE_LINK.name, baseUrlValue)
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.BASE_URL.name), watch, setValue])

  const [fileErrors, setFileErrors] = useState({
    icon: '',
    image: '',
    video: '',
    brochure: ''
  })
  const [imageError, setImageError] = useState(false)
  const [videoError, setVidoError] = useState(false)
  const [pdfError, setPdfError] = useState(false)

  const handleFileChange = (file, fileType) => {
    setFileErrors((prev) => ({ ...prev, [fileType]: null }))

    if (file && file.length > 0) {
      const selectedFile = file[0]
      const validTypes = ['application/pdf']

      if (!validTypes.includes(selectedFile.type)) {
        setFileErrors((prev) => ({
          ...prev,
          [fileType]: 'Invalid file type. Please upload a .pdf file.'
        }))
        setFilePreview((prev) => ({ ...prev, [fileType]: null }))
        setPdfError(true)
        return
      }
      const fileUrl = URL.createObjectURL(selectedFile)
      setFilePreview((prev) => ({ ...prev, [fileType]: fileUrl }))
      setFileErrors((prev) => ({ ...prev, [fileType]: null }))
      setPdfError(false)
    } else {
      setFilePreview((prev) => ({ ...prev, [fileType]: null }))
      setPdfError(false)
    }
  }
  const handleVideoChange = (file, fileType) => {
    setFileErrors((prev) => ({ ...prev, [fileType]: null }))
    if (file && file.length > 0) {
      const selectedFile = file[0]
      const validTypes = [
        'video/mp4',
        'video/webm',
        'video/quicktime',
        'video/x-msvideo',
        'video/x-ms-wmv',
        'video/x-flv',
        'video/mpeg',
        'video/ogg',
        'application/x-mpegURL',
        'video/3gpp',
        'video/3gpp2'
      ]

      if (!validTypes.includes(selectedFile.type)) {
        setFileErrors((prev) => ({
          ...prev,
          [fileType]: 'Invalid file type. Please upload a video file.'
        }))
        setFilePreview((prev) => ({ ...prev, [fileType]: null }))
        setVidoError(true)
        return
      }
      const fileUrl = URL.createObjectURL(selectedFile)
      setFilePreview((prev) => ({ ...prev, [fileType]: fileUrl }))
      setFileErrors((prev) => ({ ...prev, [fileType]: null }))
      setVidoError(false)
    } else {
      setFilePreview((prev) => ({ ...prev, [fileType]: null }))
      setVidoError(false)
    }
  }

  const handleImageChange = (file, fileType) => {
    setFileErrors((prev) => ({ ...prev, [fileType]: null }))

    if (file && file.length > 0) {
      const selectedFile = file[0]
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png']

      if (!validTypes.includes(selectedFile.type)) {
        setFileErrors((prev) => ({
          ...prev,
          [fileType]: 'Invalid file type. Please upload a JPG or PNG file.'
        }))
        setFilePreview((prev) => ({ ...prev, [fileType]: null }))
        setImageError(true)
        return
      }
      const fileUrl = URL.createObjectURL(selectedFile)
      setFilePreview((prev) => ({ ...prev, [fileType]: fileUrl }))
      setFileErrors((prev) => ({ ...prev, [fileType]: null }))
      setImageError(false)
    } else {
      setFilePreview((prev) => ({ ...prev, [fileType]: null }))
      setImageError(false)
    }
  }

  const navigatetoCompany = useCallback(() => {
    history(ROUTE_PATH.ORGANIZATION.HOME)
  }, [history])

  useEffect(() => {
    if (isCreateOrganizationSuccess ?? isEditOrganizationSuccess) {
      setTimeout(() => {
        reset()
        navigatetoCompany()
      }, 4000)
    }
  }, [
    isCreateOrganizationSuccess,
    isEditOrganizationSuccess,
    reset,
    navigatetoCompany
  ])

  useEffect(() => {
    if (isOrganizationDetailSuccess) {
      reset({
        ...formFieldValueMap
      })
    }
  }, [isOrganizationDetailSuccess, formFieldValueMap, reset])

  useEffect(() => {
    if (isEdit && id) {
      handlefetchOrganizationRecord(id)
      reset({
        ...formFieldValueMap
      })
    } else {
      reset()
    }
    // eslint-disable-next-line
  }, [isEdit, id])

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className="p-error">{t(errors[name].message)}</small>
      )
    )
  }

  const onSubmit = (data) => {
    if (imageError || videoError || pdfError) {
      return
    } else {
      const formData = new FormData()
      const files = {
        companyIntro: data[FORM_FIELDS_NAME.COMPANY_INTRO.name],
        file: data[FORM_FIELDS_NAME.COMPANY_LOGO.name],
        brochure: data[FORM_FIELDS_NAME.COMPANY_BROUCHER.name]
      }
      if (files && typeof files === 'object') {
        Object.keys(files).forEach((key) => {
          const fileData = files[key]
          if (fileData && Array.isArray(fileData)) {
            fileData.forEach((files) => {
              if (files instanceof File) {
                formData.append(key, files)
              }
            })
          } else if (fileData && fileData instanceof File) {
            formData.append('file', fileData)
          }
        })
      }
      const otherLinks = {
        facebook: data?.facebookLink,
        instagram: data?.instagramLink,
        twitter: data?.twitterLink,
        linkedin: data?.linkedinLink,
        website: data?.websiteLink
      }

      Object.keys(otherLinks).forEach((key) => {
        if (!otherLinks[key]) {
          delete otherLinks[key]
        }
      })
      const otherLinksString = JSON.stringify(otherLinks)
      let links = []
      links.push(otherLinksString)
      let payload = {
        email: data.email,
        companyName: data.companyName,
        phoneNumber: data.phoneNumber,
        baseUrl: data.baseUrl,
        contactPerson: data.contactPerson,
        h1Font: data.h1Font,
        h2Font: data.h2Font,
        h3Font: data.h3Font,
        headerColor: data.headerColor,
        footerColor: data.footerColor,
        bgColor: data.bgColor,
        iconColor: data.iconColor,
        bandColor: data.bandColor,
        otherLinks: links,
        noOfEmp: data.noOfEmp,
        noOfServices: data.noOfServices,
        noOfProduct: data.noOfProduct
      }
      if (isEdit) {
        payload.id = id
      }

      const blob = new Blob([JSON.stringify(payload)], {
        type: 'application/json'
      })
      formData.append('data', blob)
      updateOrganizationRecord(formData, id)
      ;(() => {
        setTimeout(() => {
          navigatetoCompany()
        }, 5000)
      })()
    }
  }

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      {
        label: t('Organization'),
        command: () => history(ROUTE_PATH.ORGANIZATION.HOME)
      },
      {
        label: isEdit
          ? t('OrganizationComp.Update Organization')
          : t('OrganizationComp.Add company'),
        command: () =>
          history(`${ROUTE_PATH.ORGANIZATION.EDIT.replace(':id', id)}`)
      }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }

    return (
      <div className="flex justify-content-between align-items-center  border-1 border-200  border-round  bg-white ">
        <BreadCrumb
          model={breadCrumbItems}
          home={home}
          className=" border-0 "
        />
      </div>
    )
  }
  return (
    <div>
      {renderPageHeaderSection()}
      <div className="flex w-full bg-white flex-column align-items-center justify-content-center border-1 border-200 border-round mt-3 p-2 md:p-0  ">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-2 w-full px-4 md:mt-5"
        >
          <div className="card">
            <Fieldset legend={t('OrganizationComp.CompanayBasicDetails')}>
              <div className="grid">
                <div className="col-12 md:col-6">
                  <MzInput
                    control={control}
                    name={FORM_FIELDS_NAME.COMPANY_NAME.name}
                    label={FORM_FIELDS_NAME.COMPANY_NAME.label}
                    placeholder={FORM_FIELDS_NAME.COMPANY_NAME.placeholder}
                    type={FORM_FIELDS_NAME.COMPANY_NAME.type}
                    isError={errors[FORM_FIELDS_NAME.COMPANY_NAME.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.COMPANY_NAME.name
                    )}
                    rules={FORM_FIELDS_NAME.COMPANY_NAME.rules}
                    wrapperClass={'p-float-label'}
                  />
                </div>
                <div className="col-12 md:col-6">
                  <MzInput
                    control={control}
                    name={FORM_FIELDS_NAME.BASE_URL.name}
                    label={FORM_FIELDS_NAME.BASE_URL.label}
                    placeholder={FORM_FIELDS_NAME.BASE_URL.placeholder}
                    type={FORM_FIELDS_NAME.BASE_URL.type}
                    isError={errors[FORM_FIELDS_NAME.BASE_URL.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.BASE_URL.name
                    )}
                    wrapperClass={'p-float-label'}
                  />
                </div>
                <div className="col-12 md:col-6">
                  <MzPhoneInput
                    control={control}
                    name={FORM_FIELDS_NAME.PHONE_NUMBER.name}
                    label={FORM_FIELDS_NAME.PHONE_NUMBER.label}
                    placeholder={FORM_FIELDS_NAME.PHONE_NUMBER.placeholder}
                    rules={FORM_FIELDS_NAME.PHONE_NUMBER.rules}
                    isError={errors[FORM_FIELDS_NAME.PHONE_NUMBER.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.PHONE_NUMBER.name
                    )}
                    country="in"
                  />
                </div>
                <div className="  col-12 md:col-6">
                  <MzInput
                    control={control}
                    name={FORM_FIELDS_NAME.EMAIL.name}
                    label={FORM_FIELDS_NAME.EMAIL.label}
                    placeholder={FORM_FIELDS_NAME.EMAIL.placeholder}
                    type={FORM_FIELDS_NAME.EMAIL.type}
                    isError={errors[FORM_FIELDS_NAME.EMAIL.name]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.EMAIL.name)}
                    rules={FORM_FIELDS_NAME.EMAIL.rules}
                    wrapperClass={'p-float-label'}
                  />
                </div>
                <div className="col-12 md:col-6">
                  <MzInput
                    control={control}
                    name={FORM_FIELDS_NAME.CONTACT_PERSON.name}
                    label={FORM_FIELDS_NAME.CONTACT_PERSON.label}
                    placeholder={FORM_FIELDS_NAME.CONTACT_PERSON.placeholder}
                    type={FORM_FIELDS_NAME.CONTACT_PERSON.type}
                    isError={errors[FORM_FIELDS_NAME.CONTACT_PERSON.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.CONTACT_PERSON.name
                    )}
                    rules={FORM_FIELDS_NAME.CONTACT_PERSON.rules}
                    wrapperClass={'p-float-label'}
                  />
                </div>

                <div className="col-12 md:col-6">
                  <MzAutoComplete
                    control={control}
                    name={FORM_FIELDS_NAME.NO_OF_SERVICES.name}
                    label={FORM_FIELDS_NAME.NO_OF_SERVICES.label}
                    optionLabel={'name'}
                    optionValue={'value'}
                    placeholder={FORM_FIELDS_NAME.NO_OF_SERVICES.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.NO_OF_SERVICES.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.NO_OF_SERVICES.name
                    )}
                    rules={FORM_FIELDS_NAME.NO_OF_SERVICES.rules}
                    suggestions={FORM_FIELDS_NAME.NO_OF_SERVICES.options}
                    value={getValues(FORM_FIELDS_NAME.NO_OF_SERVICES.name)}
                    dropdown
                  />
                </div>
                <div className="col-12 md:col-6">
                  <MzAutoComplete
                    control={control}
                    name={FORM_FIELDS_NAME.NO_OF_EMPLOYEE.name}
                    label={FORM_FIELDS_NAME.NO_OF_EMPLOYEE.label}
                    optionLabel={'name'}
                    optionValue={'value'}
                    placeholder={FORM_FIELDS_NAME.NO_OF_EMPLOYEE.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.NO_OF_EMPLOYEE.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.NO_OF_EMPLOYEE.name
                    )}
                    rules={FORM_FIELDS_NAME.NO_OF_EMPLOYEE.rules}
                    suggestions={FORM_FIELDS_NAME.NO_OF_EMPLOYEE.options}
                    value={getValues(FORM_FIELDS_NAME.NO_OF_EMPLOYEE.name)}
                    dropdown
                  />
                </div>
                <div className="col-12 md:col-6">
                  <MzAutoComplete
                    control={control}
                    name={FORM_FIELDS_NAME.NO_OF_PRODUCTS.name}
                    label={FORM_FIELDS_NAME.NO_OF_PRODUCTS.label}
                    optionLabel={'name'}
                    optionValue={'value'}
                    placeholder={FORM_FIELDS_NAME.NO_OF_PRODUCTS.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.NO_OF_PRODUCTS.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.NO_OF_PRODUCTS.name
                    )}
                    rules={FORM_FIELDS_NAME.NO_OF_PRODUCTS.rules}
                    suggestions={FORM_FIELDS_NAME.NO_OF_PRODUCTS.options}
                    value={getValues(FORM_FIELDS_NAME.NO_OF_PRODUCTS.name)}
                    dropdown
                  />
                </div>

                {isEdit && (
                  <div className="col-12 md:col-6">
                    <div className="flex justify-space-between align-items-center gap-3 w-full ">
                      {filePreview.icon ||
                      watch(FORM_FIELDS_NAME.COMPANY_LOGO.name) ? (
                        <img
                          src={
                            filePreview.icon ??
                            watch(FORM_FIELDS_NAME.COMPANY_LOGO.name)
                          }
                          alt="profile"
                          className="h-4rem w-6rem md:w-4rem border-circle"
                        />
                      ) : null}
                      <div className="w-full">
                        <MzFileInput
                          name={FORM_FIELDS_NAME.COMPANY_LOGO.name}
                          label={FORM_FIELDS_NAME.COMPANY_LOGO.label}
                          placeholder={
                            FORM_FIELDS_NAME.COMPANY_LOGO.placeholder
                          }
                          control={control}
                          isError={
                            !!errors[FORM_FIELDS_NAME.COMPANY_LOGO.name] ||
                            !!fileErrors.icon
                          }
                          errorMsg={
                            getFormErrorMessage(
                              FORM_FIELDS_NAME.COMPANY_LOGO.name
                            ) || fileErrors.icon
                          }
                          wrapperClass="p-float-label"
                          onChange={(file) => {
                            handleImageChange(file, 'icon')
                            setValue(FORM_FIELDS_NAME.COMPANY_LOGO.name, file)
                          }}
                          accept=".jpeg, .jpg, .png"
                        />
                        {!imageError && (
                          <small className="text-gray-500">
                            {t('OrganizationComp.uploadImageOnly')}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {isEdit && (
                  <div className="col-12 md:col-6">
                    <div className="flex justify-content-between align-items-center gap-3 w-full">
                      {watch(FORM_FIELDS_NAME.COMPANY_INTRO.name) ||
                      filePreview.video ? (
                        <video
                          controls
                          className="h-4rem w-4rem"
                          src={
                            filePreview?.video ??
                            watch(FORM_FIELDS_NAME.COMPANY_INTRO.name) ??
                            formFieldValueMap?.companyIntro
                          }
                          type="video/mp4"
                        >
                          {t('Your browser does not support the video tag.')}
                        </video>
                      ) : null}

                      <div className="w-full">
                        <MzFileInput
                          name={FORM_FIELDS_NAME.COMPANY_INTRO.name}
                          label={FORM_FIELDS_NAME.COMPANY_INTRO.label}
                          placeholder={
                            FORM_FIELDS_NAME.COMPANY_INTRO.placeholder
                          }
                          control={control}
                          isError={!!fileErrors.video}
                          errorMsg={fileErrors.video}
                          wrapperClass="p-float-label"
                          onChange={(file) => {
                            handleVideoChange(file, 'video')
                            setValue(FORM_FIELDS_NAME.COMPANY_INTRO.name, file)
                          }}
                          accept="video/*"
                        />
                        {!videoError && (
                          <small className="text-gray-500">
                            {t('OrganizationComp.uploadVideoOnly')}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {isEdit && (
                  <div className="col-12 md:col-6">
                    <div className="flex justify-space-between align-items-center gap-3 w-full ">
                      {(filePreview.brochure ??
                      watch(FORM_FIELDS_NAME.COMPANY_BROUCHER.name)) ? (
                        <Button
                          icon="pi pi-eye"
                          className="h-3rem w-5rem md:w-4rem p-button-rounded p-button-text p-button-help"
                          aria-label="Favorite"
                          disabled={
                            !watch(FORM_FIELDS_NAME.COMPANY_BROUCHER.name)
                          }
                          onClick={(event) => {
                            event.preventDefault()
                            if (
                              filePreview.brochure &&
                              filePreview.brochure.startsWith('blob:')
                            ) {
                              window.open(filePreview.brochure, '_blank')
                            } else {
                              window.open(
                                watch(FORM_FIELDS_NAME.COMPANY_BROUCHER.name) ??
                                  formFieldValueMap?.companyBroucher,
                                '_blank'
                              )
                            }
                          }}
                        />
                      ) : null}
                      <div className="w-full">
                        <MzFileInput
                          name={FORM_FIELDS_NAME.COMPANY_BROUCHER.name}
                          label={FORM_FIELDS_NAME.COMPANY_BROUCHER.label}
                          placeholder={
                            FORM_FIELDS_NAME.COMPANY_BROUCHER.placeholder
                          }
                          control={control}
                          isError={
                            !!errors[FORM_FIELDS_NAME.COMPANY_BROUCHER.name] ||
                            !!fileErrors.brochure
                          }
                          errorMsg={
                            getFormErrorMessage(
                              FORM_FIELDS_NAME.COMPANY_BROUCHER.name
                            ) || fileErrors.brochure
                          }
                          wrapperClass="p-float-label"
                          onChange={(file) => {
                            handleFileChange(file, 'brochure')
                            setValue(
                              FORM_FIELDS_NAME.COMPANY_BROUCHER.name,
                              file
                            )
                          }}
                          accept="application/pdf"
                        />
                        {!pdfError && (
                          <small className="text-gray-500">
                            {t('OrganizationComp.uploadPdfOnly')}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Fieldset>
          </div>

          <div className="card mt-3">
            <Fieldset legend="Social Links">
              <div className="grid">
                <div className="  col-12 md:col-6">
                  <MzInputGroup
                    control={control}
                    name={FORM_FIELDS_NAME.FACEBOOK_LINK.name}
                    label={FORM_FIELDS_NAME.FACEBOOK_LINK.label}
                    placeholder={FORM_FIELDS_NAME.FACEBOOK_LINK.placeholder}
                    type={FORM_FIELDS_NAME.FACEBOOK_LINK.type}
                    isError={errors[FORM_FIELDS_NAME.FACEBOOK_LINK.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.FACEBOOK_LINK.name
                    )}
                    addonIcon={FORM_FIELDS_NAME.FACEBOOK_LINK.icon}
                  />
                </div>

                <div className="  col-12  md:col-6">
                  <MzInputGroup
                    control={control}
                    name={FORM_FIELDS_NAME.INSTAGRAM_LINK.name}
                    label={FORM_FIELDS_NAME.INSTAGRAM_LINK.label}
                    placeholder={FORM_FIELDS_NAME.INSTAGRAM_LINK.placeholder}
                    type={FORM_FIELDS_NAME.INSTAGRAM_LINK.type}
                    isError={errors[FORM_FIELDS_NAME.INSTAGRAM_LINK.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.INSTAGRAM_LINK.name
                    )}
                    addonIcon={FORM_FIELDS_NAME.INSTAGRAM_LINK.icon}
                  />
                </div>

                <div className="col-12 md:col-6">
                  <MzInputGroup
                    control={control}
                    name={FORM_FIELDS_NAME.TWITTER_LINK.name}
                    label={FORM_FIELDS_NAME.TWITTER_LINK.label}
                    placeholder={FORM_FIELDS_NAME.TWITTER_LINK.placeholder}
                    type={FORM_FIELDS_NAME.TWITTER_LINK.type}
                    isError={errors[FORM_FIELDS_NAME.TWITTER_LINK.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.TWITTER_LINK.name
                    )}
                    addonIcon={FORM_FIELDS_NAME.TWITTER_LINK.icon}
                  />
                </div>
                <div className=" col-12 md:col-6">
                  <MzInputGroup
                    control={control}
                    name={FORM_FIELDS_NAME.LINKEDIN_LINK.name}
                    label={FORM_FIELDS_NAME.LINKEDIN_LINK.label}
                    placeholder={FORM_FIELDS_NAME.LINKEDIN_LINK.placeholder}
                    type={FORM_FIELDS_NAME.LINKEDIN_LINK.type}
                    isError={errors[FORM_FIELDS_NAME.LINKEDIN_LINK.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.LINKEDIN_LINK.name
                    )}
                    addonIcon={FORM_FIELDS_NAME.LINKEDIN_LINK.icon}
                  />
                </div>
                <div className=" col-12 md:col-6">
                  <MzInputGroup
                    control={control}
                    name={FORM_FIELDS_NAME.WEBSITE_LINK.name}
                    label={FORM_FIELDS_NAME.WEBSITE_LINK.label}
                    placeholder={FORM_FIELDS_NAME.WEBSITE_LINK.placeholder}
                    type={FORM_FIELDS_NAME.WEBSITE_LINK.type}
                    isError={errors[FORM_FIELDS_NAME.WEBSITE_LINK.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.WEBSITE_LINK.name
                    )}
                    addonIcon={FORM_FIELDS_NAME.WEBSITE_LINK.icon}
                  />
                </div>
              </div>
            </Fieldset>
          </div>
          <div className="grid justify-content-center mt-3 mb-5">
            <div className=" col-12 md:col-4">
              <Button
                label="Cancel"
                className="w-full"
                severity="secondary"
                onClick={() => navigatetoCompany()}
                disabled={isLoading || isPageLevelError}
              ></Button>
            </div>
            <div className=" col-12 md:col-4">
              <Button
                type="submit"
                label="Submit"
                className="w-full"
                disabled={isLoading || isPageLevelError}
              ></Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditOrgnizationComponent
