import React, { useState } from 'react'
import MzTable from '../../common/MzTable'
import ErrorPage from '../../common/Error'
import { BreadCrumb } from 'primereact/breadcrumb'
import { Button } from 'primereact/button'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { useNavigate } from 'react-router-dom'
import { USER_ROLE } from '../../constant/actionTypes/role'
import { useTranslation } from 'react-i18next'

const TenantComponent = (props) => {
  const {
    fetchPagableTenantList,
    pagableTenantList,
    isPageLevelError,
    tenantTableData,
    handleOnReadRecord,
    handleOnDeleteRecord,
    handleOnEditRecord,
    isLoading,
    handleOnCreatedRecord,
    userRole
  } = props.tenantProps

  const [paginationInfo, setPaginationInfo] = useState({
    pageSize: 10,
    pageNumber: 0,
    totalRecords: 0,
    totalPages: 0
  })

  const loadLazyData = (event) => {
    const { pageNumber, pageSize } = event

    setPaginationInfo({
      pageNumber,
      pageSize
    })

    if (userRole === USER_ROLE.SUPER_ADMIN) {
      fetchPagableTenantList({
        pageNumber,
        pageSize: pageSize
      })
    }
  }

  const { t } = useTranslation()

  const shouldRenderFullPageError = () => isPageLevelError
  const shouldRenderUserTransactionList = () =>
    tenantTableData?.tableData?.columns?.length > 0
  const shouldRenderNotFoundView = () =>
    !shouldRenderFullPageError && !shouldRenderUserTransactionList
  const history = useNavigate()
  const filters = {
    global: { value: null },
    userName: { filterKey: 'username', value: null },
    email: { filterKey: 'email', value: null }
  }

  const tableProps = {
    value: pagableTenantList?.content ?? [],
    loading: isLoading,
    columns: tenantTableData?.tableData?.columns,
    paginationInfo: {
      ...paginationInfo,
      totalRecords: pagableTenantList?.totalElements ?? 0
    },
    screenPermission: tenantTableData.tableData.screenPermission,
    loadLazyData,
    emptyMessage: t('tenant.noRecord'),
    filters,
    sortField: null,
    showMoreAction: false,
    onReadRecord: handleOnReadRecord,
    onEditRecord: handleOnEditRecord,
    onDeleteRecord: handleOnDeleteRecord,
    paginator: true,
    scrollable: true,
    responsiveLayout: 'scroll'
  }

  const handleToCreateTenant = () => {
    handleOnCreatedRecord()
  }

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      {
        label: t('tenant.company'),
        command: () => history(ROUTE_PATH.COMPANY.HOME)
      }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }

    return (
      <div className="flex justify-content-between align-items-center  border-1 border-200 border-round  bg-white">
        <BreadCrumb model={breadCrumbItems} home={home} className="border-0 " />
        <div className="mr-2 flex justify-content-between h-full align-items-center gap-3">
          <div>
            <Button
              onClick={() => handleToCreateTenant()}
              className="py-2"
              disabled={false}
            >
              {t('tenant.addCompany')}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className=" ">
        {renderPageHeaderSection()}
        {shouldRenderFullPageError() && (
          <div>
            <ErrorPage />
          </div>
        )}
        {shouldRenderUserTransactionList() && (
          <div className=" border-1 border-200 border-round mt-3 p-2 bg-white">
            <MzTable {...tableProps} />
          </div>
        )}
        {shouldRenderNotFoundView() && <div>{t('tenant.noRecord')}</div>}
      </div>
    </div>
  )
}

export default TenantComponent
