import React from 'react'
import { Button } from 'primereact/button'
import { ScrollPanel } from 'primereact/scrollpanel'
import { USER_ROLE } from '../../constant/actionTypes/role'
import './index.css'
import { useTranslation } from 'react-i18next'
import icon from '../../assets/images/Sales-Ladder-Logo.png'

const OrganizationComponent = (props) => {
  const {
    selectedOrganization,
    handleOnEditRecord,
    user,
    userRole,
    amenitiesList,
    productsList
  } = props.orgnizationProps

  const { t} = useTranslation();

   
  const openLinkInNewTab = (url) => {
    const validUrl =
      url?.startsWith('http://') || url?.startsWith('https://')
        ? url
        : `https://${url}`
    window.open(validUrl, '_blank', 'noopener,noreferrer')
  }

  return (
    <>
      <div className=" relative border-1 border-200 border-round p-2 bg-white font-poppins">
        {userRole === USER_ROLE.ADMIN && (
          <div className="p-2 absolute top-0 right-0 ">
            {' '}
            <Button
              icon="pi pi-pencil"
              onClick={() => handleOnEditRecord(user?.tenant)}
              rounded
              text
              raised
              aria-label="Favorite"
              className="h-2rem md:h-3rem w-2rem md:w-3rem"
            />
          </div>
        )}
        <div className="md:flex card-header  w-full justify-content-between align-items-center  ">
          <div className="flex ">
            {selectedOrganization && selectedOrganization.companyLogo ? (
              <div className="mt-3">
                <img
                  src={selectedOrganization?.companyLogo}
                  alt={selectedOrganization?.companyName}
                  className="h-4rem w-4rem md:h-6rem md:w-6rem bg-white border-circle"
                />
              </div>
            ) : (
              <div className="mt-3">
                <img
                  src={icon}
                  alt={icon}
                  className="h-4rem w-4rem md:h-6rem md:w-6rem bg-white border-circle"
                />
              </div>
            )}

            <div className="p-2 ml-3 md:p-0">
              <div className="text-900 font-medium text-2xl mb-2">
                {selectedOrganization?.companyName}
              </div>
              {selectedOrganization?.contactPerson && (
                <div className="text-700 my-1 text-sm  ">
                  <i className="pi pi-user mr-2" />
                  {selectedOrganization?.contactPerson}
                </div>
              )}
              {selectedOrganization?.email && (
                <div className="text-700 my-1 text-sm">
                  <i className="pi pi-envelope mr-2" />
                  {selectedOrganization?.email}
                </div>
              )}
              {selectedOrganization?.phoneNumber && (
                <div className="text-700 mb-1 text-sm">
                  <i className="pi pi-phone mr-2" />
                  {selectedOrganization?.phoneNumber}
                </div>
              )}
            </div>
          </div>

          <div className="flex-1">
            <div className="flex justify-content-center mb-4">
              <Button
                icon="pi pi-facebook"
                disabled={!selectedOrganization?.facebookLink}
                className="p-button-rounded p-button-outlined h-2rem md:h-3rem w-2rem md:w-3rem bg-white"
                onClick={() =>
                  openLinkInNewTab(selectedOrganization?.facebookLink)
                }
              />
              <Button
                icon="pi pi-instagram"
                disabled={!selectedOrganization?.instagramLink}
                className="p-button-rounded p-button-outlined h-2rem md:h-3rem w-2rem md:w-3rem bg-white ml-2"
                onClick={() =>
                  openLinkInNewTab(selectedOrganization?.instagramLink)
                }
              />
              <Button
                icon="pi pi-twitter"
                disabled={!selectedOrganization?.twitterLink}
                className="p-button-rounded p-button-outlined h-2rem md:h-3rem w-2rem md:w-3rem bg-white ml-2"
                onClick={() =>
                  openLinkInNewTab(selectedOrganization?.twitterLink)
                }
              />
              <Button
                icon="pi pi-linkedin"
                disabled={!selectedOrganization?.linkedinLink}
                className="p-button-rounded p-button-outlined h-2rem md:h-3rem w-2rem md:w-3rem bg-white ml-2"
                onClick={() =>
                  openLinkInNewTab(selectedOrganization?.linkedinLink)
                }
              />
              <Button
                icon="pi pi-external-link"
                disabled={!selectedOrganization?.websiteLink}
                className="p-button-rounded p-button-outlined h-2rem md:h-3rem w-2rem md:w-3rem bg-white ml-2"
                onClick={() =>
                  openLinkInNewTab(selectedOrganization?.websiteLink)
                }
              />
            </div>
          </div>
        </div>

        <hr />

        {selectedOrganization?.companyIntro && (
          <div className="surface-0 text-center mt-3">
            <video controls autoPlay className=" video-container  w-22rem md:w-auto  p-2 md:h-20rem mb-2">
              <source
                src={selectedOrganization?.companyIntro}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      
        <div className="surface-0 text-center mt-3">
          {amenitiesList.length > 0 && (
            <>
              <h1>{t('Services')}</h1>
              <div className="grid-containerr p-3 md:p-2">
                {amenitiesList && amenitiesList.filter(item=>item.active).map((feature, index) => (
                    <div
                      key={index}
                      className="card-comp shadow-2 border-round-xl"
                    >
                      <span className=" inline-block border-round-xl mb-1">
                        {feature?.icon ? (
                          <img
                            src={feature.icon}
                            alt=""
                            className="h-8rem w-9rem object-cover border-round-xl"
                          />
                        ) : (
                          <img
                            src={icon}
                            alt=""
                            className="h-8rem w-9rem object-cover border-round-xl"
                          />
                        )}
                      </span>

                          <div className="text-lg mb-1 font-medium text-center">
                            {feature?.name}
                          </div>

                          {feature?.description && (
                            <ScrollPanel className="scroll-panell">
                              <p className="text-700 line-height-2 text-sm border-round-xl">
                                {feature.description}
                              </p>
                            </ScrollPanel>
                          )}
                        </div>
                      ))}
                </div>
              </>
            )}

          {productsList.length > 0 && productsList.some(item => item.active) &&(
            <>
              <h1>{t('Products')}</h1>
              <div className="grid-containerr p-3 md:p-2">
                {productsList &&
                  productsList.filter(item=>item.active).map((feature, index) => (
                    <div
                      key={index}
                      className="card-comp shadow-2 border-round-xl"
                    >
                      <span className="inline-block border-round-xl mb-1">
                        {feature?.icon ? (
                          <img
                            src={feature.icon}
                            alt=""
                            className="h-8rem w-9rem object-cover border-round-xl"
                          />
                        ) : (
                          <img
                            src={icon}
                            alt=""
                            className="h-8rem w-9rem object-cover border-round-xl"
                          />
                        )}
                      </span>

                          <div className="text-lg mb-1 font-medium text-center">
                            {feature?.name}
                          </div>

                          {feature?.description && (
                            <ScrollPanel className="scroll-panell">
                              <p className="text-700 line-height-2 text-sm">
                                {feature.description}
                              </p>
                            </ScrollPanel>
                          )}
                        </div>
                      ))}
                </div>
              </>
            )}
        </div>
      </div>
    </>
  )
}

export default OrganizationComponent
