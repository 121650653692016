import {
  INIT_AMENITIES,
  FETCH_AMENITIES_LIST,
  FETCH_AMENITIES_RECORD,
  CREATE_AMENITIES_RECORD,
  DELETE_AMENITIES,
  UPDATE_AMENITIES_RECORD,
  GET_COUNT_AMENITIES,
  GET_AMENITIES_ENQUERY_COUNT,
  GET_AMENITIES_INTREST_COUNT
} from '../../../constant/actionTypes/amenities'
import { amenitiesService } from '../../../services'

export const initAmenities = () => ({
  type: INIT_AMENITIES
})

export const fetchAmenitiesListStart = (payload) => ({
  type: FETCH_AMENITIES_LIST.START,
  payload
})

export const fetchAmenitiesListSuccess = (amenities) => ({
  type: FETCH_AMENITIES_LIST.SUCCESS,
  payload: amenities
})

export const fetchAmenitiesListError = (error) => ({
  type: FETCH_AMENITIES_LIST.ERROR,
  payload: { error }
})

export const fetchAmenitiesList = (payload) => {
  return (dispatch) => {
    dispatch(fetchAmenitiesListStart(payload))
    amenitiesService.fetchAmenitiesList(payload).then((amenitiesData) => {
      if (!amenitiesData.isError) {
        dispatch(fetchAmenitiesListSuccess(amenitiesData))
      } else {
        dispatch(fetchAmenitiesListError(amenitiesData))
      }
    })
  }
}

export const fetchAmenitiesListByUserId = (payload) => {
  return (dispatch) => {
    dispatch(fetchAmenitiesListStart(payload))
    amenitiesService
      .fetchAmenitiesListByUserId(payload)
      .then((amenitiesData) => {
        if (!amenitiesData.isError) {
          dispatch(fetchAmenitiesListSuccess(amenitiesData))
        } else {
          dispatch(fetchAmenitiesListError(amenitiesData))
        }
      })
  }
}

export const fetchAmenitiesRecordStart = () => ({
  type: FETCH_AMENITIES_RECORD.START
})

export const fetchAmenitiesRecordSuccess = (amenities) => ({
  type: FETCH_AMENITIES_RECORD.SUCCESS,
  payload: amenities
})

export const fetchAmenitiesRecordError = (error) => ({
  type: FETCH_AMENITIES_RECORD.ERROR,
  payload: { error }
})

export const fetchAmenitiesRecord = (payload) => {
  return (dispatch) => {
    dispatch(fetchAmenitiesRecordStart(payload))
    amenitiesService.fetchAmenitiesRecord(payload).then((amenitiesData) => {
      if (!amenitiesData.isError) {
        dispatch(fetchAmenitiesRecordSuccess(amenitiesData))
      } else {
        dispatch(fetchAmenitiesRecordError(amenitiesData))
      }
    })
  }
}

export const createAmenitiesRecordStart = () => ({
  type: CREATE_AMENITIES_RECORD.START
})

export const createAmenitiesRecordSuccess = (newAmenities) => ({
  type: CREATE_AMENITIES_RECORD.SUCCESS,
  payload: newAmenities
})

export const createAmenitiesRecordError = (error) => ({
  type: CREATE_AMENITIES_RECORD.ERROR,
  payload: { error }
})

export const createAmenitiesRecord = (payload) => {
  return (dispatch) => {
    dispatch(createAmenitiesRecordStart(payload))
    amenitiesService.createAmenitiesRecord(payload).then((amenitiesData) => {
      if (!amenitiesData.isError) {
        dispatch(createAmenitiesRecordSuccess(amenitiesData))
      } else {
        dispatch(createAmenitiesRecordError(amenitiesData))
      }
    })
  }
}

export const updateAmenitiesRecordStart = (payload, id) => ({
  type: UPDATE_AMENITIES_RECORD.START,
  payload: payload,
  id: id
})

export const updateAmenitiesRecordSuccess = (newAmenities) => ({
  type: UPDATE_AMENITIES_RECORD.SUCCESS,
  payload: newAmenities
})

export const updateAmenitiesRecordError = (error) => ({
  type: UPDATE_AMENITIES_RECORD.ERROR,
  payload: { error }
})

export const updateAmenitiesRecord = (payload, id) => {
  return (dispatch) => {
    dispatch(updateAmenitiesRecordStart(payload, id))
    amenitiesService
      .updateAmenitiesRecord(payload, id)
      .then((amenitiesData) => {
        if (!amenitiesData.isError) {
          dispatch(updateAmenitiesRecordSuccess(amenitiesData))
        } else {
          dispatch(updateAmenitiesRecordError(amenitiesData))
        }
      })
  }
}

export const deleteAmenitiesStart = () => ({
  type: DELETE_AMENITIES.START
})

export const deleteAmenitiesSuccess = (amenitiesId) => ({
  type: DELETE_AMENITIES.SUCCESS,
  payload: { amenitiesId }
})

export const deleteAmenitiesError = (error) => ({
  type: DELETE_AMENITIES.ERROR,
  payload: { error }
})

export const deleteAmenities = (amenitiesId) => {
  return (dispatch) => {
    dispatch(deleteAmenitiesStart())
    amenitiesService.deleteAmenities(amenitiesId).then((amenitiesData) => {
      if (!amenitiesData.isError) {
        dispatch(deleteAmenitiesSuccess(amenitiesData))
      } else {
        dispatch(deleteAmenitiesError(amenitiesData))
      }
    })
  }
}

export const getAmenitiesCountStart = (payload) => {
  return {
    type: GET_COUNT_AMENITIES.START,
    payload
  }
}
export const getAmenitiesCountSuccess = (payload) => {
  return {
    type: GET_COUNT_AMENITIES.SUCCESS,
    payload
  }
}
export const getAmenitiesCountError = (payload) => {
  return {
    type: GET_COUNT_AMENITIES.ERROR,
    payload
  }
}

export const getAmenitiesCount = (role, payload) => {
  return (dispatch) => {
    dispatch(getAmenitiesCountStart(payload))
    amenitiesService.getAmenitiesCount(role, payload).then((amenitiesData) => {
      if (!amenitiesData.isError) {
        dispatch(getAmenitiesCountSuccess(amenitiesData))
      } else {
        dispatch(getAmenitiesCountError(amenitiesData))
      }
    })
  }
}

export const getAmenitiesEnqueryCountStart = (payload) => {
  return {
    type: GET_AMENITIES_ENQUERY_COUNT.START,
    payload
  }
}
export const getAmenitiesEnqueryCountSuccess = (payload) => {
  return {
    type: GET_AMENITIES_ENQUERY_COUNT.SUCCESS,
    payload
  }
}
export const getAmenitiesEnqueryCountError = (payload) => {
  return {
    type: GET_AMENITIES_ENQUERY_COUNT.ERROR,
    payload
  }
}

export const getAmenitiesEnqueryCount = (role, payload) => {
  return (dispatch) => {
    dispatch(getAmenitiesEnqueryCountStart(payload))
    amenitiesService
      .getAmenitiesEnqueryCount(role, payload)
      .then((amenitiesData) => {
        if (!amenitiesData.isError) {
          dispatch(getAmenitiesEnqueryCountSuccess(amenitiesData))
        } else {
          dispatch(getAmenitiesEnqueryCountError(amenitiesData))
        }
      })
  }
}

export const getAmenitiesIntrestCountStart = (payload) => {
  return {
    type: GET_AMENITIES_INTREST_COUNT.START,
    payload
  }
}
export const getAmenitiesIntrestCountSuccess = (payload) => {
  return {
    type: GET_AMENITIES_INTREST_COUNT.SUCCESS,
    payload
  }
}
export const getAmenitiesIntrestCountError = (payload) => {
  return {
    type: GET_AMENITIES_INTREST_COUNT.ERROR,
    payload
  }
}

export const getAmenitiesIntrestCount = (role, payload) => {
  return (dispatch) => {
    dispatch(getAmenitiesIntrestCountStart(payload))
    amenitiesService
      .getAmenitiesIntrestCount(role, payload)
      .then((amenitiesData) => {
        if (!amenitiesData.isError) {
          dispatch(getAmenitiesIntrestCountSuccess(amenitiesData))
        } else {
          dispatch(getAmenitiesIntrestCountError(amenitiesData))
        }
      })
  }
}
