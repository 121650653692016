export const FORM_FIELDS_NAME = {
  TENANT: {
    name: 'tenant',
    label: 'product.form.Tenant.label',
    type: 'dropdown',
    rules: {
      required: 'product.error.Tenant.required'
    },
    options: [],
    placeholder: 'product.form.Tenant.placeholder'
  },
  NAME: {
    name: 'name',
    label: 'category.form.name.label',
    type: 'text',
    placeholder: 'category.form.name.placeholder',
    rules: {
      required: 'category.error.name.required',
      minLength: {
        value: 2,
        message: 'category.error.name.minLength'
      },
      maxLength: {
        value: 50,
        message: 'category.error.name.maxLength'
      }
    }
  }
}
