import {
  INIT_ORGANIZATION,
  FETCH_ORGANIZATION_LIST,
  FETCH_ORGANIZATION_RECORD,
  CREATE_ORGANIZATION_RECORD,
  DELETE_ORGANIZATION,
  UPDATE_ORGANIZATION_RECORD,
  FETCH_PAGABLE_ORGANIZATION_LIST
} from '../../../constant/actionTypes/organization'
import { organizationService } from '../../../services'

export const initOrganization = () => ({
  type: INIT_ORGANIZATION
})

export const fetchOrganizationListStart = (payload) => ({
  type: FETCH_ORGANIZATION_LIST.START,
  payload
})

export const fetchOrganizationListSuccess = (organization) => ({
  type: FETCH_ORGANIZATION_LIST.SUCCESS,
  payload: organization
})

export const fetchOrganizationListError = (error) => ({
  type: FETCH_ORGANIZATION_LIST.ERROR,
  payload: { error }
})

export const fetchOrganizationList = (payload) => {
  return (dispatch) => {
    dispatch(fetchOrganizationListStart(payload))
    organizationService.fetchOrganizationList(payload).then((organizationData) => {
      if (!organizationData.isError) {
        dispatch(fetchOrganizationListSuccess(organizationData))
      } else {
        dispatch(fetchOrganizationListError(organizationData))
      }
    })
  }
}


export const fetchPagableOrganizationListStart = (payload) => ({
  type: FETCH_PAGABLE_ORGANIZATION_LIST.START,
  payload
})

export const fetchPagableOrganizationListSuccess = (payload) => ({
  type: FETCH_PAGABLE_ORGANIZATION_LIST.SUCCESS,
  payload: payload
})

export const fetchPagableOrganizationListError = (error) => ({
  type: FETCH_PAGABLE_ORGANIZATION_LIST.ERROR,
  payload: { error }
})

export const fetchPagableOrganizationList = (payload) => { 
  return (dispatch) => {
    dispatch(fetchPagableOrganizationListStart(payload));
    organizationService.fetchPagableOrganizationList(payload).then((organizationData) => {
      if (!organizationData.isError) {
        dispatch(fetchPagableOrganizationListSuccess(organizationData));
      } else {
        dispatch(fetchPagableOrganizationListError(organizationData));
      }
    });
  }
}

export const fetchOrganizationRecordStart = () => ({
  type: FETCH_ORGANIZATION_RECORD.START
})

export const fetchOrganizationRecordSuccess = (organization) => ({
  type: FETCH_ORGANIZATION_RECORD.SUCCESS,
  payload: organization
})

export const fetchOrganizationRecordError = (error) => ({
  type: FETCH_ORGANIZATION_RECORD.ERROR,
  payload: { error }
})

export const fetchOrganizationRecord = (payload) => {
  return (dispatch) => {
    dispatch(fetchOrganizationRecordStart(payload))
    organizationService.fetchOrganizationRecord(payload).then((organizationData) => {
      if (!organizationData.isError) {
        dispatch(fetchOrganizationRecordSuccess(organizationData))
      } else {
        dispatch(fetchOrganizationRecordError(organizationData))
      }
    })
  }
}

export const createOrganizationRecordStart = () => ({
  type: CREATE_ORGANIZATION_RECORD.START
})

export const createOrganizationRecordSuccess = (newOrganization) => ({
  type: CREATE_ORGANIZATION_RECORD.SUCCESS,
  payload: newOrganization
})

export const createOrganizationRecordError = (error) => ({
  type: CREATE_ORGANIZATION_RECORD.ERROR,
  payload: { error }
})

export const createOrganizationRecord = (payload) => {
  return (dispatch) => {
    dispatch(createOrganizationRecordStart(payload))
    organizationService.createOrganizationRecord(payload).then((organizationData) => {
      if (!organizationData.isError) {
        dispatch(createOrganizationRecordSuccess(organizationData))
      } else {
        dispatch(createOrganizationRecordError(organizationData))
      }
    })
  }
}

export const updateOrganizationRecordStart = (payload, id) => ({
  type: UPDATE_ORGANIZATION_RECORD.START,
  payload,
  id
})

export const updateOrganizationRecordSuccess = (newOrganization) => ({
  type: UPDATE_ORGANIZATION_RECORD.SUCCESS,
  payload: newOrganization
})

export const updateOrganizationRecordError = (error) => ({
  type: UPDATE_ORGANIZATION_RECORD.ERROR,
  payload: { error }
})

export const updateOrganizationRecord = (payload, id) => {
  return (dispatch) => {
    dispatch(updateOrganizationRecordStart(payload, id))
    organizationService.updateOrganizationRecord(payload, id).then((organizationData) => {
      if (!organizationData.isError) {
        dispatch(updateOrganizationRecordSuccess(organizationData))
      } else {
        dispatch(updateOrganizationRecordError(organizationData))
      }
    })
  }
} 

export const deleteOrganizationStart = () => ({
  type: DELETE_ORGANIZATION.START
})

export const deleteOrganizationSuccess = (organizationId) => ({
  type: DELETE_ORGANIZATION.SUCCESS,
  payload: { organizationId }
})

export const deleteOrganizationError = (error) => ({
  type: DELETE_ORGANIZATION.ERROR,
  payload: { error }
})

export const deleteOrganization = (organizationId) => {
  return (dispatch) => {
    dispatch(deleteOrganizationStart())
    organizationService.deleteOrganization(organizationId).then((organizationData) => {
      if (!organizationData.isError) {
        dispatch(deleteOrganizationSuccess(organizationData))
      } else {
        dispatch(deleteOrganizationError(organizationData))
      }
    })
  }
}
