export const FORM_FIELDS_NAME = {
  FACEBOOK_LINK: {
    name: 'facebookLink',
    label: 'tenant.form.facebookLink.label',
    type: 'text',
    placeholder: 'tenant.form.facebookLink.placeholder',
    rules: {
      maxLength: {
        value: 100,
        message: 'tenant.form.facebookLink.error'
      }
    },
    icon: 'pi pi-facebook'
  },
  INSTAGRAM_LINK: {
    name: 'instagramLink',
    label: 'tenant.form.instagramLink.label',
    type: 'text',
    placeholder: 'tenant.form.instagramLink.placeholder',
    rules: {
      maxLength: {
        value: 100,
        message: 'tenant.error.instagramLink.maxLength'
      }
    },
    icon: 'pi pi-instagram'
  },
  TWITTER_LINK: {
    name: 'twitterLink',
    label: 'tenant.form.twitterLink.label',
    type: 'text',
    placeholder: 'tenant.form.twitterLink.placeholder',
    rules: {
      maxLength: {
        value: 100,
        message: 'tenant.error.twitterLink.maxLength',
      }
    },
    icon: 'pi pi-twitter'
  },
  LINKEDIN_LINK: {
    name: 'linkedinLink',
    label: 'tenant.form.linkedinLink.label', 
    type: 'text',
    placeholder: 'tenant.form.linkedinLink.placeholder', 
    rules: {
      maxLength: {
        value: 100,
        message:'tenant.error.linkedinLink.maxLength',
      }
    },
    icon: 'pi pi-linkedin'
  },
  WEBSITE_LINK: {
    name: 'websiteLink',
    label: 'tenant.form.websiteLink.label', 
    type: 'text',
    placeholder: 'tenant.form.websiteLink.label', 
    rules: {
      maxLength: {
        value: 100,
        message:'tenant.error.websiteLink.maxLength', 
      }
    },
    icon: 'pi pi-globe'
  },

  COMPANY_NAME: {
    name: 'companyName',
    label: 'tenant.form.companyName.label', 
    type: 'text',
    placeholder: 'tenant.form.companyName.placeholder', 
    rules: {
      required: 'tenant.error.companyName.required', 
      maxLength: {
        value: 50,
        message: 'tenant.error.companyName.maxLength', 
      },
    }
  },
  PHONE_NUMBER: {
    name: 'phoneNumber',
    label: 'tenant.form.phoneNumber.label', 
    type: 'tel',
    placeholder: 'tenant.form.phoneNumber.placeholder', 
    rules: {
      required: 'tenant.error.phoneNumber.required', 
      minLength: {
        value: 8,
        message: 'tenant.error.phoneNumber.minLength', 
      },
      maxLength: {
        value: 14,
        message: 'tenant.error.phoneNumber.maxLength', 
      },
    }
  },
  EMAIL: {
    name: 'email',
    label: 'tenant.form.email.label', 
    type: 'email',
    placeholder: 'tenant.form.email.placeholder', 
    rules: {
      required: 'tenant.error.email.required', 
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: 'tenant.error.email.invalid', 
      },
    }
  },
  BASE_URL: {
    name: 'baseUrl',
    label: 'tenant.form.baseUrl.label', 
    type: 'url',
    placeholder: 'tenant.form.baseUrl.placeholder', 
    rules: {
      maxLength: {
        value: 60,
        message: 'tenant.error.baseUrl.maxLength', 
      },
    }
  },
  NO_OF_SERVICES: {
    name: 'noOfServices',
    label: 'tenant.form.noOfServices.label', 
    type: 'dropdown',
    options: [
      { name: '0-9', value: '0-9' },
      { name: '10-20', value: '10-20' },
      { name: '20-50', value: '20-50' },
      { name: '50-100', value: '50-100' },
      { name: '100-300', value: '100-300' },
      { name: '300-500', value: '300-500' },
      { name: '500-1000+', value: '500-1000+' }
    ],
    placeholder: 'tenant.form.noOfServices.placeholder', 
  }, 
  NO_OF_PRODUCTS: {
    name: 'noOfProduct',
    label: 'tenant.form.noOfProducts.label', 
    type: 'dropdown',
    options: [
      { name: '0-9', value: '0-9' },
      { name: '10-20', value: '10-20' },
      { name: '20-50', value: '20-50' },
      { name: '50-100', value: '50-100' },
      { name: '100-300', value: '100-300' },
      { name: '300-500', value: '300-500' },
      { name: '500-1000+', value: '500-1000+' }
    ],
    placeholder: 'tenant.form.noOfProducts.placeholder', 
  }, 
  NO_OF_EMPLOYEE: {
    name: 'noOfEmp',
    label: 'tenant.form.noOfEmployee.label', 
    type: 'dropdown',
    options: [
      { name: '0-9', value: '0-9' },
      { name: '10-20', value: '10-20' },
      { name: '20-50', value: '20-50' },
      { name: '50-100', value: '50-100' },
      { name: '100-300', value: '100-300' },
      { name: '300-500', value: '300-500' },
      { name: '500-1000+', value: '500-1000+' }
    ],
    placeholder: 'tenant.form.noOfEmployee.placeholder', 
  },
  RESELLER_ID: {
    name: 'resellerId',
    label: 'tenant.form.resellerId.label', 
    type: 'text',
    placeholder: 'tenant.form.resellerId.placeholder', 
  },
  COMPANY_LOGO: {
    name: 'companyLogo',
    label: 'tenant.form.companyLogo.label', 
    type: 'file',
    placeholder: 'tenant.form.companyLogo.placeholder', 
    rules: {
      required: 'tenant.error.companyLogo.required', 
    }
  },
  COMPANY_INTRO: {
    name: 'companyIntro',
    label: 'tenant.form.companyIntro.label', 
    type: 'file',
    placeholder: 'tenant.form.companyIntro.placeholder', 
    rules: {
      // required: 'tenant.error.companyIntro.required', 
    }
  },
  COMPANY_BROUCHER: {
    name: 'companyBroucher',
    label: 'tenant.form.companyBroucher.label', 
    type: 'file',
    placeholder: 'tenant.form.companyBroucher.placeholder', 
    rules: {
      // required: 'tenant.error.companyBroucher.required', 
    }
  },
  CONTACT_PERSON: {
    name: 'contactPerson',
    label: 'tenant.form.contactPerson.label', 
    type: 'text',
    placeholder: 'tenant.form.contactPerson.placeholder', 
    rules: {
      required: 'tenant.error.contactPerson.required', 
      minLength: {
        value: 4,
        message: 'tenant.error.contactPerson.minLength', 
      },
      maxLength: {
        value: 50,
        message: 'tenant.error.contactPerson.maxLength', 
      },
    }
  },
  OTHER_LINKS: {
    name: 'otherLinks',
    label: 'tenant.form.otherLinks.label', 
    type: 'text',
    placeholder: 'tenant.form.otherLinks.placeholder', 
  },
  PLAN: {
    name: 'plan',
    label: 'tenant.form.plan.label', 
    type: 'text',
    placeholder: 'tenant.form.plan.placeholder', 
  },
  PLAN_COST: {
    name: 'planCost',
    label: 'tenant.form.planCost.label', 
    type: 'number',
    placeholder: 'tenant.form.planCost.placeholder', 
  },
  FOOTER_COLOR: {
    name: 'footerColor',
    label: 'Footer Color',
    type: 'dropdown',
    options: [
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'Select footer color'
  },
  H1_FONT: {
    name: 'h1Font',
    label: 'H1 Font',
    type: 'dropdown',
    options: [
      { name: 'Arial', value: 'Arial, sans-serif' },
      { name: 'Times New Roman', value: 'Times New Roman, serif' },
      { name: 'Verdana', value: 'Verdana, sans-serif' }
    ],
    placeholder: 'Select H1 font'
  },
  H2_FONT: {
    name: 'h2Font',
    label: 'H2 Font',
    type: 'dropdown',
    options: [
      { name: 'Arial', value: 'Arial, sans-serif' },
      { name: 'Times New Roman', value: 'Times New Roman, serif' },
      { name: 'Verdana', value: 'Verdana, sans-serif' }
    ],
    placeholder: 'Select H2 font'
  },
  H3_FONT: {
    name: 'h3Font',
    label: 'H3 Font',
    type: 'dropdown',
    options: [
      { name: 'Arial', value: 'Arial, sans-serif' },
      { name: 'Times New Roman', value: 'Times New Roman, serif' },
      { name: 'Verdana', value: 'Verdana, sans-serif' }
    ],
    placeholder: 'Select H3 font'
  },
  HEADER_COLOR: {
    name: 'headerColor',
    label: 'Header Color',
    type: 'dropdown',
    options: [
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'Select header color'
  },
  ICON_COLOR: {
    name: 'iconColor',
    label: 'Icon Color',
    type: 'dropdown',
    options: [
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'Select icon color'
  },
  BAND_COLOR: {
    name: 'bandColor',
    label: 'Band Color',
    type: 'dropdown',
    options: [
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'Select band color'
  },
  BG_COLOR: {
    name: 'bgColor',
    label: 'Background Color',
    type: 'dropdown',
    options: [
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'Select background color'
  }
}
