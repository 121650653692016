import { combineReducers } from 'redux'
import userProfileReducer from './userProfile'
import loginReducer from './auth/login'
import registerReducer from './auth/register'
import departmentReducer from './department'
import employeeReducer from './employee'
import tenantReducer from './tenant'
import contactReducer from './contact'
import amenitiesReducer from './amenities'
import productsReducer from './products'
import toastReducer from './toast'
import enquiryReducer from './enquiry'
import categoryReducer from './category'
import translatorReducer from './translator'
import organizationReducer from './organization'
const rootReducers = combineReducers({
  userProfileReducer,
  loginReducer,
  registerReducer,
  departmentReducer,
  employeeReducer,
  tenantReducer,
  contactReducer,
  amenitiesReducer,
  productsReducer,
  toastReducer,
  enquiryReducer,
  categoryReducer,
  translatorReducer,
  organizationReducer,
})
export default rootReducers
