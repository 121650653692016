import React, { useEffect } from 'react'
import { ProgressBar } from 'primereact/progressbar'
import LoginComponent from '../../components/login'
import { initLoginScreen, login } from '../../redux/action/auth/login'
import { connect, useDispatch } from 'react-redux'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import { toastFailed, toastSuccess } from '../../redux/action/toast'

const LoginScreen = (props) => {
  const {
    initLoginScreen,
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    isLoginSuccess,
    isLoginError,
    error,
    login
  } = props

  useEffect(() => {
    initLoginScreen()
    // eslint-disable-next-line
  }, [])

  const loginProps = {
    formFieldValueMap,
    isPageLevelError,
    isLoginSuccess,
    isLoading,
    login
  }
  const dispatch = useDispatch()

  const getToastProps = () => {
    if (isLoginSuccess) {
      const toastTitle = 'Login Successfully'
      dispatch(toastSuccess(toastTitle))
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }
    if (isLoginError) {
      const toastTitle = error ? error : 'Error while login'
      toastFailed(toastTitle)
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }

  return (
    <div>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <LoginComponent loginProps={loginProps} />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initLoginScreen: () => dispatch(initLoginScreen()),
    login: (loginData) => dispatch(login(loginData))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    formFieldValueMap: selectFormFieldValueMap(state.loginReducer),
    isPageLevelError: state.loginReducer.isPageLevelError,
    isLoading: state.loginReducer.isLoading,
    isLoginSuccess: state.loginReducer.isLoginSuccess,
    isLoginError: state.loginReducer.isLoginError,
    error: state.loginReducer.error
  }
}

const selectFormFieldValueMap = (loginReducer) => {
  return loginReducer.formFieldValueMap
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
