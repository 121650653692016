import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import MzInput from '../../common/MzForm/MzInput'
import { FORM_FIELDS_NAME } from './constant'
import { Button } from 'primereact/button'
import { NavLink, useNavigate } from 'react-router-dom'
import MzPhoneInput from '../../common/MzForm/MzPhoneInput'
import MzFileInput from '../../common/MzForm/MzFileInput'
import MzAutoComplete from '../../common/MzForm/MzAutoComplete'
import MzInputGroup from '../../common/MzForm/MzInputGroup'
import { Card } from 'primereact/card'
import { SALESLADDERLOGO } from '../../assets/images'
import './index.css'
import { useTranslation } from 'react-i18next'

const RegisterComponent = (props) => {
  const { t } = useTranslation()
  const {
    formFieldValueMap,
    register,
    isLoading,
    isPageLevelError,
    isRegisterSuccess
  } = props.registerProps

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    trigger,
    watch,
    setValue
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap
    }, [formFieldValueMap]),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const [fileErrors, setFileErrors] = useState({
    icon: ''
  })

  const [filePreview, setFilePreview] = useState({
    profileIcon: null
  })
  const [step, setStep] = useState(0)
  const [, setImageError] = useState(false)

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className="p-error">{t(errors[name].message)}</small>
      )
    )
  }
  const history = useNavigate()
  useEffect(() => {
    if (isRegisterSuccess) {
      setTimeout(() => {
        reset()
        history('/')
      }, 1000)
    }
    // eslint-disable-next-line
  }, [isRegisterSuccess])

  const onSubmit = (data) => {
    const formData = new FormData()
    let image = data.companyLogo
    if (image) {
      formData.append('logo', image)
    }

    // if (image1) {
    //   formData.append("profile", image1);
    // }
    let image1 = data.profile

    Object.keys(image1).forEach((key) => {
      const fileData = image1[key]
      if (fileData && Array.isArray(fileData)) {
        fileData.forEach((file) => {
          if (file instanceof File) {
            formData.append('profile', fileData)
          }
        })
      } else if (fileData && fileData instanceof File) {
        formData.append('profile', fileData)
      }
    })

    const otherLinks = {
      facebook: data?.facebookLink,
      instagram: data?.instagramLink,
      twitter: data?.twitterLink,
      linkedin: data?.linkedinLink,
      website: data?.websiteLink
    }

    Object.keys(otherLinks).forEach((key) => {
      if (!otherLinks[key]) {
        delete otherLinks[key]
      }
    })

    const otherLinksString = JSON.stringify(otherLinks)
    let links = []
    links.push(otherLinksString)
    let payload = {
      username: data.username,
      email: data.email,
      userEmail: data.userEmail,
      password: data.password,
      companyName: data.companyName,
      phoneNumber: data.phoneNumber,
      firstName: data.firstName,
      lastName: data.lastName,
      mobileNumber: data.mobileNumber,
      baseUrl: data.baseUrl,
      contactPerson: data.contactPerson,
      h1Font: data.h1Font,
      h2Font: data.h2Font,
      h3Font: data.h3Font,
      headerColor: data.headerColor,
      footerColor: data.footerColor,
      bgColor: data.bgColor,
      iconColor: data.iconColor,
      bandColor: data.bandColor,
      otherLinks: links,
      noOfEmp: data.noOfEmp,
      noOfServices: data.noOfServices,
      noOfProduct: data.noOfProduct
    }

    const blob = new Blob([JSON.stringify(payload)], {
      type: 'application/json'
    })
    formData.append('data', blob)

    register(formData)
  }

  const handleNextStep = async () => {
    const isStepValid = await trigger()
    if (isStepValid) {
      setStep((prevStep) => prevStep + 1)
    }
  }

  
  const handleFileChange = (file, fileType) => {
      
    
    setFileErrors((prev) => ({ ...prev, [fileType]: null }))
    if (file && file.length > 0) {
      const selectedFile = file[0]
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png']
      if (!validTypes.includes(selectedFile.type)) {
        setFileErrors((prev) => ({
          ...prev,
          [fileType]: 'Invalid file type. Please upload a JPG or PNG file.'
        }))
        setFilePreview((prev) => ({ ...prev, [fileType]: null }))
        setImageError(true)
        return
      }
      const fileUrl = URL.createObjectURL(selectedFile)
      setFilePreview((prev) => ({ ...prev, [fileType]: fileUrl }))
      setFileErrors((prev) => ({ ...prev, [fileType]: null }))
      setImageError(false)
    } else {
      setFilePreview((prev) => ({ ...prev, [fileType]: null }))
      setImageError(false)
    }
  }

  const handlePrevStep = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 0))
  }

  useEffect(() => {
    const emailValue = watch(FORM_FIELDS_NAME.EMAIL.name)
    if (emailValue) {
      setValue(FORM_FIELDS_NAME.USER_EMAIL.name, emailValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(FORM_FIELDS_NAME.EMAIL.name), watch, setValue])

  useEffect(() => {
    const emailValue = watch(FORM_FIELDS_NAME.USER_EMAIL.name)
    if (emailValue) {
      setValue(FORM_FIELDS_NAME.USERNAME.name, emailValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(FORM_FIELDS_NAME.USER_EMAIL.name), watch, setValue])

  useEffect(() => {
    const phoneNumberValue = watch(FORM_FIELDS_NAME.PHONE_NUMBER.name)
    if (phoneNumberValue) {
      setValue(FORM_FIELDS_NAME.MOBILE_NUMBER.name, phoneNumberValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(FORM_FIELDS_NAME.PHONE_NUMBER.name), watch, setValue])

  useEffect(() => {
    const baseUrlValue = watch(FORM_FIELDS_NAME.BASE_URL.name)
    if (baseUrlValue) {
      setValue(FORM_FIELDS_NAME.WEBSITE_LINK.name, baseUrlValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(FORM_FIELDS_NAME.BASE_URL.name), watch, setValue])

  const features = (items) =>
    items.map((item, index) => (
      <li
        key={index}
        className="flex  align-items-start justify-content-start py-1"
      >
        <span className="mr-1">✅</span>
        <span className="text-sm ">{item}</span>
      </li>
    ))

  const password = watch(FORM_FIELDS_NAME.PASSWORD.name)
  const validateConfirmPassword = (value) =>
    value === password || 'Password do not match'
  return (
    <div className="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-scroll ">
      <div className="flex flex-column align-items-center justify-content-center">
        <div
          style={{
            borderRadius: '56px',
            padding: '0.3rem'
          }}
          className="login-backgoud"
        >
          <div
            className="w-full text-center surface-card py-5 md:py-7 px-5 md:px-8 flex flex-column align-items-center"
            style={{ borderRadius: '53px' }}
          >
            <div className="flex justify-content-center align-items-center h-3rem w-3rem  ">
              <img
                src={SALESLADDERLOGO}
                className="h-3rem w-3rem"
                alt="Sales Ladder"
              />
            </div>
            <h1 className="text-900 font-bold text-3xl md:text-5xl mb-2">
              {t('Sign Up')}
            </h1>
            <div className="text-600 mb-5">
              {t('Create a new account with Sales Ladder.')}
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-column text-center border-round-sm">
                {step === 0 && (
                  <>
                    <div className="md:flex gap-3">
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzInput
                          control={control}
                          name={FORM_FIELDS_NAME.COMPANY_NAME.name}
                          label={FORM_FIELDS_NAME.COMPANY_NAME.label}
                          placeholder={
                            FORM_FIELDS_NAME.COMPANY_NAME.placeholder
                          }
                          type={FORM_FIELDS_NAME.COMPANY_NAME.type}
                          isError={errors[FORM_FIELDS_NAME.COMPANY_NAME.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.COMPANY_NAME.name
                          )}
                          rules={FORM_FIELDS_NAME.COMPANY_NAME.rules}
                          wrapperClass={'p-float-label'}
                        />
                      </div>
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzInput
                          control={control}
                          name={FORM_FIELDS_NAME.BASE_URL.name}
                          label={FORM_FIELDS_NAME.BASE_URL.label}
                          placeholder={FORM_FIELDS_NAME.BASE_URL.placeholder}
                          type={FORM_FIELDS_NAME.BASE_URL.type}
                          isError={errors[FORM_FIELDS_NAME.BASE_URL.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.BASE_URL.name
                          )}
                          rules={FORM_FIELDS_NAME.BASE_URL.rules}
                          wrapperClass={'p-float-label'}
                        />
                      </div>
                    </div>
                    <div className="md:flex gap-3">
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzPhoneInput
                          control={control}
                          name={FORM_FIELDS_NAME.PHONE_NUMBER.name}
                          label={FORM_FIELDS_NAME.PHONE_NUMBER.label}
                          placeholder={
                            FORM_FIELDS_NAME.PHONE_NUMBER.placeholder
                          }
                          rules={FORM_FIELDS_NAME.PHONE_NUMBER.rules}
                          isError={errors[FORM_FIELDS_NAME.PHONE_NUMBER.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.PHONE_NUMBER.name
                          )}
                          country="in"
                        />
                      </div>
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzInput
                          control={control}
                          name={FORM_FIELDS_NAME.EMAIL.name}
                          label={FORM_FIELDS_NAME.EMAIL.label}
                          placeholder={FORM_FIELDS_NAME.EMAIL.placeholder}
                          type={FORM_FIELDS_NAME.EMAIL.type}
                          isError={errors[FORM_FIELDS_NAME.EMAIL.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.EMAIL.name
                          )}
                          rules={FORM_FIELDS_NAME.EMAIL.rules}
                          wrapperClass={'p-float-label'}
                        />
                      </div>
                    </div>
                    <div className="md:flex gap-3">
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzInput
                          control={control}
                          name={FORM_FIELDS_NAME.CONTACT_PERSON.name}
                          label={FORM_FIELDS_NAME.CONTACT_PERSON.label}
                          placeholder={
                            FORM_FIELDS_NAME.CONTACT_PERSON.placeholder
                          }
                          type={FORM_FIELDS_NAME.CONTACT_PERSON.type}
                          isError={errors[FORM_FIELDS_NAME.CONTACT_PERSON.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.CONTACT_PERSON.name
                          )}
                          rules={FORM_FIELDS_NAME.CONTACT_PERSON.rules}
                          wrapperClass={'p-float-label'}
                        />
                      </div>
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzAutoComplete
                          control={control}
                          name={FORM_FIELDS_NAME.NO_OF_EMPLOYEE.name}
                          label={FORM_FIELDS_NAME.NO_OF_EMPLOYEE.label}
                          optionLabel={'name'}
                          optionValue={'value'}
                          placeholder={
                            FORM_FIELDS_NAME.NO_OF_EMPLOYEE.placeholder
                          }
                          isError={
                            !!errors[FORM_FIELDS_NAME.NO_OF_EMPLOYEE.name]
                          }
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.NO_OF_EMPLOYEE.name
                          )}
                          rules={FORM_FIELDS_NAME.NO_OF_EMPLOYEE.rules}
                          suggestions={FORM_FIELDS_NAME.NO_OF_EMPLOYEE.options}
                          dropdown
                          value={watch(FORM_FIELDS_NAME.NO_OF_EMPLOYEE.name)}
                        />
                      </div>
                    </div>
                    <div className="md:flex gap-3">
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzAutoComplete
                          control={control}
                          name={FORM_FIELDS_NAME.NO_OF_SERVICES.name}
                          label={FORM_FIELDS_NAME.NO_OF_SERVICES.label}
                          optionLabel={'name'}
                          optionValue={'value'}
                          placeholder={
                            FORM_FIELDS_NAME.NO_OF_SERVICES.placeholder
                          }
                          isError={
                            !!errors[FORM_FIELDS_NAME.NO_OF_SERVICES.name]
                          }
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.NO_OF_SERVICES.name
                          )}
                          rules={FORM_FIELDS_NAME.NO_OF_SERVICES.rules}
                          suggestions={FORM_FIELDS_NAME.NO_OF_SERVICES.options}
                          dropdown
                          value={watch(FORM_FIELDS_NAME.NO_OF_SERVICES.name)}
                        />
                      </div>
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzAutoComplete
                          control={control}
                          name={FORM_FIELDS_NAME.NO_OF_PRODUCTS.name}
                          label={FORM_FIELDS_NAME.NO_OF_PRODUCTS.label}
                          optionLabel={'name'}
                          optionValue={'value'}
                          placeholder={
                            FORM_FIELDS_NAME.NO_OF_PRODUCTS.placeholder
                          }
                          isError={
                            !!errors[FORM_FIELDS_NAME.NO_OF_PRODUCTS.name]
                          }
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.NO_OF_PRODUCTS.name
                          )}
                          rules={FORM_FIELDS_NAME.NO_OF_PRODUCTS.rules}
                          suggestions={FORM_FIELDS_NAME.NO_OF_PRODUCTS.options}
                          dropdown
                          value={watch(FORM_FIELDS_NAME.NO_OF_PRODUCTS.name)}
                        />
                      </div>
                    </div>

                    <div className="flex justify-content-end gap-3">
                      <div className="mb-3">
                        <Button
                          label={t('Next')}
                          onClick={handleNextStep}
                          className="w-full"
                          disabled={isLoading || isPageLevelError}
                        />
                      </div>
                    </div>
                  </>
                )}

                {step === 9 && (
                  <>
                    <div className="md:flex gap-3">
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzAutoComplete
                          control={control}
                          name={FORM_FIELDS_NAME.HEADER_COLOR.name}
                          label={FORM_FIELDS_NAME.HEADER_COLOR.label}
                          optionLabel={'name'}
                          optionValue={'value'}
                          placeholder={
                            FORM_FIELDS_NAME.HEADER_COLOR.placeholder
                          }
                          isError={!!errors[FORM_FIELDS_NAME.HEADER_COLOR.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.HEADER_COLOR.name
                          )}
                          rules={FORM_FIELDS_NAME.HEADER_COLOR.rules}
                          suggestions={FORM_FIELDS_NAME.HEADER_COLOR.options}
                          dropdown
                        />
                      </div>

                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzAutoComplete
                          control={control}
                          name={FORM_FIELDS_NAME.FOOTER_COLOR.name}
                          label={FORM_FIELDS_NAME.FOOTER_COLOR.label}
                          optionLabel={'name'}
                          optionValue={'value'}
                          placeholder={
                            FORM_FIELDS_NAME.FOOTER_COLOR.placeholder
                          }
                          isError={!!errors[FORM_FIELDS_NAME.FOOTER_COLOR.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.FOOTER_COLOR.name
                          )}
                          rules={FORM_FIELDS_NAME.FOOTER_COLOR.rules}
                          suggestions={FORM_FIELDS_NAME.FOOTER_COLOR.options}
                          dropdown
                        />
                      </div>
                    </div>
                    <div className="md:flex gap-3">
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzAutoComplete
                          control={control}
                          name={FORM_FIELDS_NAME.BG_COLOR.name}
                          label={FORM_FIELDS_NAME.BG_COLOR.label}
                          optionLabel={'name'}
                          optionValue={'value'}
                          placeholder={FORM_FIELDS_NAME.BG_COLOR.placeholder}
                          isError={!!errors[FORM_FIELDS_NAME.BG_COLOR.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.BG_COLOR.name
                          )}
                          rules={FORM_FIELDS_NAME.BG_COLOR.rules}
                          suggestions={FORM_FIELDS_NAME.BG_COLOR.options}
                          dropdown
                        />
                      </div>
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzAutoComplete
                          control={control}
                          name={FORM_FIELDS_NAME.BAND_COLOR.name}
                          label={FORM_FIELDS_NAME.BAND_COLOR.label}
                          optionLabel={'name'}
                          optionValue={'value'}
                          placeholder={FORM_FIELDS_NAME.BAND_COLOR.placeholder}
                          isError={!!errors[FORM_FIELDS_NAME.BAND_COLOR.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.BAND_COLOR.name
                          )}
                          rules={FORM_FIELDS_NAME.BAND_COLOR.rules}
                          suggestions={FORM_FIELDS_NAME.BAND_COLOR.options}
                          dropdown
                        />
                      </div>
                    </div>
                    <div className="md:flex gap-3">
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzAutoComplete
                          control={control}
                          name={FORM_FIELDS_NAME.ICON_COLOR.name}
                          label={FORM_FIELDS_NAME.ICON_COLOR.label}
                          optionLabel={'name'}
                          optionValue={'value'}
                          placeholder={FORM_FIELDS_NAME.ICON_COLOR.placeholder}
                          isError={!!errors[FORM_FIELDS_NAME.ICON_COLOR.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.ICON_COLOR.name
                          )}
                          rules={FORM_FIELDS_NAME.ICON_COLOR.rules}
                          suggestions={FORM_FIELDS_NAME.ICON_COLOR.options}
                          dropdown
                        />
                      </div>
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzAutoComplete
                          control={control}
                          name={FORM_FIELDS_NAME.H1_FONT.name}
                          label={FORM_FIELDS_NAME.H1_FONT.label}
                          optionLabel={'name'}
                          optionValue={'value'}
                          placeholder={FORM_FIELDS_NAME.H1_FONT.placeholder}
                          isError={!!errors[FORM_FIELDS_NAME.H1_FONT.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.H1_FONT.name
                          )}
                          rules={FORM_FIELDS_NAME.H1_FONT.rules}
                          suggestions={FORM_FIELDS_NAME.H1_FONT.options}
                          dropdown
                        />
                      </div>
                    </div>
                    <div className="md:flex gap-3">
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzAutoComplete
                          control={control}
                          name={FORM_FIELDS_NAME.H2_FONT.name}
                          label={FORM_FIELDS_NAME.H2_FONT.label}
                          optionLabel={'name'}
                          optionValue={'value'}
                          placeholder={FORM_FIELDS_NAME.H2_FONT.placeholder}
                          isError={!!errors[FORM_FIELDS_NAME.H2_FONT.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.H2_FONT.name
                          )}
                          rules={FORM_FIELDS_NAME.H2_FONT.rules}
                          suggestions={FORM_FIELDS_NAME.H2_FONT.options}
                          dropdown
                        />
                      </div>
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzAutoComplete
                          control={control}
                          name={FORM_FIELDS_NAME.H3_FONT.name}
                          label={FORM_FIELDS_NAME.H3_FONT.label}
                          optionLabel={'name'}
                          optionValue={'value'}
                          placeholder={FORM_FIELDS_NAME.H3_FONT.placeholder}
                          isError={!!errors[FORM_FIELDS_NAME.H3_FONT.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.H3_FONT.name
                          )}
                          rules={FORM_FIELDS_NAME.H3_FONT.rules}
                          suggestions={FORM_FIELDS_NAME.H3_FONT.options}
                          dropdown
                        />
                      </div>
                    </div>

                    <div className="flex justify-content-end gap-3">
                      <div className="mb-3">
                        <Button
                          label={t('Back')}
                          onClick={handlePrevStep}
                          className="w-full"
                          disabled={isLoading || isPageLevelError}
                        />
                      </div>
                      <div className="mb-3">
                        <Button
                          label={t('Next')}
                          onClick={handleNextStep}
                          className="w-full"
                          disabled={isLoading || isPageLevelError}
                        />
                      </div>
                    </div>
                  </>
                )}
                {step === 2 && (
                  <>
                    <div className="md:flex gap-3">
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzInputGroup
                          control={control}
                          name={FORM_FIELDS_NAME.FACEBOOK_LINK.name}
                          label={FORM_FIELDS_NAME.FACEBOOK_LINK.label}
                          placeholder={
                            FORM_FIELDS_NAME.FACEBOOK_LINK.placeholder
                          }
                          type={FORM_FIELDS_NAME.FACEBOOK_LINK.type}
                          isError={errors[FORM_FIELDS_NAME.FACEBOOK_LINK.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.FACEBOOK_LINK.name
                          )}
                          addonIcon={FORM_FIELDS_NAME.FACEBOOK_LINK.icon}
                        />
                      </div>
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzInputGroup
                          control={control}
                          name={FORM_FIELDS_NAME.INSTAGRAM_LINK.name}
                          label={FORM_FIELDS_NAME.INSTAGRAM_LINK.label}
                          placeholder={
                            FORM_FIELDS_NAME.INSTAGRAM_LINK.placeholder
                          }
                          type={FORM_FIELDS_NAME.INSTAGRAM_LINK.type}
                          isError={errors[FORM_FIELDS_NAME.INSTAGRAM_LINK.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.INSTAGRAM_LINK.name
                          )}
                          addonIcon={FORM_FIELDS_NAME.INSTAGRAM_LINK.icon}
                        />
                      </div>
                    </div>
                    <div className="md:flex gap-3">
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzInputGroup
                          control={control}
                          name={FORM_FIELDS_NAME.TWITTER_LINK.name}
                          label={FORM_FIELDS_NAME.TWITTER_LINK.label}
                          placeholder={
                            FORM_FIELDS_NAME.TWITTER_LINK.placeholder
                          }
                          type={FORM_FIELDS_NAME.TWITTER_LINK.type}
                          isError={errors[FORM_FIELDS_NAME.TWITTER_LINK.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.TWITTER_LINK.name
                          )}
                          addonIcon={FORM_FIELDS_NAME.TWITTER_LINK.icon}
                        />
                      </div>
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzInputGroup
                          control={control}
                          name={FORM_FIELDS_NAME.LINKEDIN_LINK.name}
                          label={FORM_FIELDS_NAME.LINKEDIN_LINK.label}
                          placeholder={
                            FORM_FIELDS_NAME.LINKEDIN_LINK.placeholder
                          }
                          type={FORM_FIELDS_NAME.LINKEDIN_LINK.type}
                          isError={errors[FORM_FIELDS_NAME.LINKEDIN_LINK.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.LINKEDIN_LINK.name
                          )}
                          addonIcon={FORM_FIELDS_NAME.LINKEDIN_LINK.icon}
                        />
                      </div>
                    </div>
                    <div className="md:flex gap-3">
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzInputGroup
                          control={control}
                          name={FORM_FIELDS_NAME.WEBSITE_LINK.name}
                          label={FORM_FIELDS_NAME.WEBSITE_LINK.label}
                          placeholder={
                            FORM_FIELDS_NAME.WEBSITE_LINK.placeholder
                          }
                          type={FORM_FIELDS_NAME.WEBSITE_LINK.type}
                          isError={errors[FORM_FIELDS_NAME.WEBSITE_LINK.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.WEBSITE_LINK.name
                          )}
                          addonIcon={FORM_FIELDS_NAME.WEBSITE_LINK.icon}
                        />
                      </div>
                    </div>

                    <div className="flex justify-content-end gap-3">
                      <div className="mb-3">
                        <Button
                          label={t('Back')}
                          onClick={handlePrevStep}
                          className="w-full"
                          disabled={isLoading || isPageLevelError}
                        />
                      </div>
                      <div className="mb-3">
                        <Button
                          label={t('Submit')}
                          type="submit"
                          className="w-full"
                          disabled={isLoading || isPageLevelError}
                        />
                      </div>
                    </div>
                  </>
                )}

                {step === 1 && (
                  <>
                    <div className="md:flex gap-3">
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzInput
                          control={control}
                          name={FORM_FIELDS_NAME.FIRST_NAME.name}
                          label={FORM_FIELDS_NAME.FIRST_NAME.label}
                          placeholder={FORM_FIELDS_NAME.FIRST_NAME.placeholder}
                          type={FORM_FIELDS_NAME.FIRST_NAME.type}
                          isError={errors[FORM_FIELDS_NAME.FIRST_NAME.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.FIRST_NAME.name
                          )}
                          rules={FORM_FIELDS_NAME.FIRST_NAME.rules}
                          wrapperClass={'p-float-label'}
                        />
                      </div>
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzInput
                          control={control}
                          name={FORM_FIELDS_NAME.LAST_NAME.name}
                          label={FORM_FIELDS_NAME.LAST_NAME.label}
                          placeholder={FORM_FIELDS_NAME.LAST_NAME.placeholder}
                          type={FORM_FIELDS_NAME.LAST_NAME.type}
                          isError={errors[FORM_FIELDS_NAME.LAST_NAME.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.LAST_NAME.name
                          )}
                          rules={FORM_FIELDS_NAME.LAST_NAME.rules}
                          wrapperClass={'p-float-label'}
                        />
                      </div>
                    </div>
                    <div className="md:flex gap-3">
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzPhoneInput
                          control={control}
                          name={FORM_FIELDS_NAME.MOBILE_NUMBER.name}
                          label={FORM_FIELDS_NAME.MOBILE_NUMBER.label}
                          placeholder={
                            FORM_FIELDS_NAME.MOBILE_NUMBER.placeholder
                          }
                          rules={FORM_FIELDS_NAME.MOBILE_NUMBER.rules}
                          isError={errors[FORM_FIELDS_NAME.MOBILE_NUMBER.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.MOBILE_NUMBER.name
                          )}
                          country="in"
                        />
                      </div>
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzInput
                          control={control}
                          name={FORM_FIELDS_NAME.USER_EMAIL.name}
                          label={FORM_FIELDS_NAME.USER_EMAIL.label}
                          placeholder={FORM_FIELDS_NAME.USER_EMAIL.placeholder}
                          type={FORM_FIELDS_NAME.USER_EMAIL.type}
                          isError={errors[FORM_FIELDS_NAME.USER_EMAIL.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.USER_EMAIL.name
                          )}
                          rules={FORM_FIELDS_NAME.USER_EMAIL.rules}
                          wrapperClass={'p-float-label'}
                        />
                      </div>
                    </div>
                    <div className="md:flex gap-3">
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzInput
                          control={control}
                          name={FORM_FIELDS_NAME.USERNAME.name}
                          label={FORM_FIELDS_NAME.USERNAME.label}
                          placeholder={FORM_FIELDS_NAME.USERNAME.placeholder}
                          type={FORM_FIELDS_NAME.USERNAME.type}
                          isError={errors[FORM_FIELDS_NAME.USERNAME.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.USERNAME.name
                          )}
                          rules={FORM_FIELDS_NAME.USERNAME.rules}
                          wrapperClass={'p-float-label'}
                          disabled={true}
                        />
                      </div>
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzInput
                          control={control}
                          name={FORM_FIELDS_NAME.PASSWORD.name}
                          label={FORM_FIELDS_NAME.PASSWORD.label}
                          placeholder={FORM_FIELDS_NAME.PASSWORD.placeholder}
                          type={FORM_FIELDS_NAME.PASSWORD.type}
                          isError={errors[FORM_FIELDS_NAME.PASSWORD.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.PASSWORD.name
                          )}
                          rules={FORM_FIELDS_NAME.PASSWORD.rules}
                          wrapperClass={'p-float-label'}
                        />
                      </div>
                    </div>
                    <div className="md:flex gap-3">
                      <div className="mb-3 w-17rem md:w-20rem">
                        <MzInput
                          control={control}
                          name={FORM_FIELDS_NAME.CONFIRM_PASSWORD.name}
                          label={FORM_FIELDS_NAME.CONFIRM_PASSWORD.label}
                          placeholder={
                            FORM_FIELDS_NAME.CONFIRM_PASSWORD.placeholder
                          }
                          type={FORM_FIELDS_NAME.CONFIRM_PASSWORD.type}
                          isError={
                            errors[FORM_FIELDS_NAME.CONFIRM_PASSWORD.name]
                          }
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.CONFIRM_PASSWORD.name
                          )}
                          rules={{
                            ...FORM_FIELDS_NAME.CONFIRM_PASSWORD.rules,
                            validate: validateConfirmPassword
                          }}
                          wrapperClass={'p-float-label'}
                        />
                      </div>
                      <div className="mb-3 w-17rem md:w-20rem">
                        <div className="flex justify-space-between align-items-center gap-3 w-full ">
                          {filePreview.profileIcon && (
                            <img
                              src={filePreview.profileIcon}
                              alt="profile"
                              className="h-4rem w-4rem border-circle"
                            />
                          )}
                          <div className="w-full">
                            <MzFileInput
                              name={FORM_FIELDS_NAME.USER_PROFILE.name}
                              label={FORM_FIELDS_NAME.USER_PROFILE.label}
                              placeholder={
                                FORM_FIELDS_NAME.USER_PROFILE.placeholder
                              }
                              rules={FORM_FIELDS_NAME.USER_PROFILE.rules}
                              control={control}
                              isError={
                                !!errors[FORM_FIELDS_NAME.USER_PROFILE.name] ||
                                !!fileErrors.profileIcon
                              }
                              errorMsg={
                                getFormErrorMessage(
                                  FORM_FIELDS_NAME.USER_PROFILE.name
                                ) || fileErrors.profileIcon
                              }
                              wrapperClass="p-float-label"
                              onChange={(file) => {
                                handleFileChange(file, 'profileIcon')
                                setValue(
                                  FORM_FIELDS_NAME.USER_PROFILE.name,
                                  file,
                                  {
                                    shouldValidate: true
                                  }
                                )
                                trigger(FORM_FIELDS_NAME.USER_PROFILE.name)
                              }}
                              accept=".jpeg, .jpg, .png"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-content-end gap-3">
                      <div className="mb-3">
                        <Button
                          label={t('Back')}
                          onClick={handlePrevStep}
                          className="w-full"
                          disabled={isLoading || isPageLevelError}
                        />
                      </div>
                      <div className="mb-3">
                        <Button
                          label={t('Next')}
                          onClick={handleNextStep}
                          className="w-full"
                          disabled={
                            isLoading ||
                            isPageLevelError ||
                            !!fileErrors.profileIcon
                          }
                        />
                      </div>
                    </div>
                  </>
                )}

                {step === 10 && (
                  <>
                    <div className="grid">
                      <div className="col-12 md:col-4 py-2">
                        <Card
                          title="Basic Plan"
                          className="basic-plan h-full w-full text-white rounded-2xl shadow-lg pt-4 flex flex-column justify-between transition-transform duration-300 hover:scale-200 hover:z-10"
                        >
                          <p className="text-xl font-bold mb-4">
                            $9.99 / month
                          </p>
                          <ul className="flex-1 w-full  list-none">
                            {features([
                              'Access to all content',
                              'HD Streaming',
                              'Two simultaneous'
                            ])}
                          </ul>
                          <Button
                            label="Subscribe"
                            onClick={handleNextStep}
                            className=" w-full p-button-rounded p-button-raised mt-3"
                          />
                        </Card>
                      </div>

                      <div className="col-12 md:col-4 py-2">
                        <Card
                          title="Standard Plan"
                          className="standrad-plan h-full bg-blue-500 text-white rounded-2xl shadow-lg py-4 flex flex-column justify-between transition-transform duration-300 hover:scale-105"
                        >
                          <p className="text-xl font-bold mb-4">
                            $14.99 / month
                          </p>
                          <ul className="flex-1 space-y-2 list-none">
                            {features([
                              'Access to all content',
                              'HD Streaming',
                              'Two simultaneous'
                            ])}
                          </ul>
                          <Button
                            label="Subscribe"
                            onClick={handleNextStep}
                            className=" w-full p-button-rounded p-button-raised mt-3"
                          />
                        </Card>
                      </div>

                      <div className="col-12 md:col-4 py-2">
                        <Card
                          title="Premium Plan"
                          className="premium-plan h-full bg-pink-500 text-white rounded-2xl shadow-lg pt-4 flex flex-column justify-between transition-transform duration-300 "
                        >
                          <p className="text-xl font-bold mb-4">
                            $19.99 / month
                          </p>
                          <ul className="flex-1 space-y-2 list-none">
                            {features([
                              'Access to all content',
                              '4K Streaming',
                              'Exclusive content'
                            ])}
                          </ul>
                          <Button
                            label="Subscribe"
                            onClick={handleNextStep}
                            className=" w-full p-button-rounded p-button-raised mt-3"
                          />
                        </Card>
                      </div>
                    </div>
                    <div className="flex justify-content-end mt-4">
                      <Button
                        label="Back"
                        onClick={handlePrevStep}
                        className="p-button-rounded p-button-secondary"
                        disabled={isLoading || isPageLevelError}
                      />
                    </div>
                  </>
                )}
              </div>
            </form>
            <div className="text-sm  justify-content-between align-items-center">
              {t('Already have an account?')} &nbsp;
              <NavLink to="/" style={{ textDecoration: 'none' }}>
                {t('Sign In')}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterComponent
