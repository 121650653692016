export const INIT_AMENITIES = 'INIT_AMENITIES'

export const FETCH_AMENITIES_LIST = {
  START: 'FETCH_AMENITIES_START',
  SUCCESS: 'FETCH_AMENITIES_SUCCESS',
  ERROR: 'FETCH_AMENITIES_ERROR'
}

export const FETCH_AMENITIES_RECORD = {
  START: 'FETCH_AMENITIES_RECORD_START',
  SUCCESS: 'FETCH_AMENITIES_RECORD_SUCCESS',
  ERROR: 'FETCH_AMENITIES_RECORD_ERROR'
}

export const CREATE_AMENITIES_RECORD = {
  START: 'CREATE_AMENITIES_RECORD_START',
  SUCCESS: 'CREATE_AMENITIES_RECORD_SUCCESS',
  ERROR: 'CREATE_AMENITIES_RECORD_ERROR'
}

export const UPDATE_AMENITIES_RECORD = {
  START: 'UPDATE_AMENITIES_RECORD_START',
  SUCCESS: 'UPDATE_AMENITIES_RECORD_SUCCESS',
  ERROR: 'UPDATE_AMENITIES_RECORD_ERROR'
}

export const DELETE_AMENITIES = {
  START: 'DELETE_AMENITIES_START',
  SUCCESS: 'DELETE_AMENITIES_SUCCESS',
  ERROR: 'DELETE_AMENITIES_ERROR'
}

export const GET_COUNT_AMENITIES = {
  START: 'GET_COUNT_AMENITIES_START',
  SUCCESS: 'GET_COUNT_AMENITIES_SUCCESS',
  ERROR: 'GET_COUNT_AMENITIES_ERROR'
}

export const GET_AMENITIES_INTREST_COUNT = {
  START: 'GET_AMENITIES_INTREST_COUNT_START',
  SUCCESS: 'GET_AMENITIES_INTREST_COUNT_SUCCESS',
  ERROR: 'GET_AMENITIES_INTREST_COUNT_ERROR'
}

export const GET_AMENITIES_ENQUERY_COUNT = {
  START: 'GET_AMENITIES_ENQUERY_COUNT_START',
  SUCCESS: 'GET_AMENITIES_ENQUERY_COUNT_SUCCESS',
  ERROR: 'GET_COUNT_AMENITIES_ERROR'
}