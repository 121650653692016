export const INIT_CONTACT = 'INIT_CONTACT'

export const FETCH_CONTACT_LIST = {
  START: 'FETCH_CONTACT_START',
  SUCCESS: 'FETCH_CONTACT_SUCCESS',
  ERROR: 'FETCH_CONTACT_ERROR'
}

export const FETCH_CONTACT_RECORD = {
  START: 'FETCH_CONTACT_RECORD_START',
  SUCCESS: 'FETCH_CONTACT_RECORD_SUCCESS',
  ERROR: 'FETCH_CONTACT_RECORD_ERROR'
}

export const CREATE_CONTACT_RECORD = {
  START: 'CREATE_CONTACT_RECORD_START',
  SUCCESS: 'CREATE_CONTACT_RECORD_SUCCESS',
  ERROR: 'CREATE_CONTACT_RECORD_ERROR'
}

export const UPDATE_CONTACT_RECORD = {
  START: 'UPDATE_CONTACT_RECORD_START',
  SUCCESS: 'UPDATE_CONTACT_RECORD_SUCCESS',
  ERROR: 'UPDATE_CONTACT_RECORD_ERROR'
}

export const DELETE_CONTACT = {
  START: 'DELETE_CONTACT_START',
  SUCCESS: 'DELETE_CONTACT_SUCCESS',
  ERROR: 'DELETE_CONTACT_ERROR'
}

export const GET_CONTACT_COUNT = {
  START: 'GET_CONTACT_COUNT_START',
  SUCCESS: 'GET_CONTACT_COUNT_SUCCESS',
  ERROR: 'GET_CONTACT_COUNT_ERROR'
}
export const CREATE_EXCHANGEINFO_RECORD = {
  START: 'GET_EXCHANGEINFO_START',
  SUCCESS: 'GET_EXCHANGEINFO_SUCCESS',
  ERROR: 'GET_EXCHANGEINFO_ERROR'
}
