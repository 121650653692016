import axios from 'axios'
import { API_PATH } from '../../constant/urlConstant'
import { handleAPIError } from '../common/errorHandler'
import { baseUrl } from '../PostAPI'
import { USER_ROLE } from '../../constant/actionTypes/role'
import { getDecoratedUrl } from '../common/urlService'
export const fetchEnquiryList = async (payload) => {
  const { tenantId, pageSize, pageNumber } = payload ?? {}
  let url

  if (!tenantId) {
    url = `${baseUrl}${API_PATH.ENQUIRY.FETCH}`
  } else {
    url = `${baseUrl}${API_PATH.ENQUIRY.FETCH_BY_TENANT}/${tenantId}`
  }

  const finalUrl = getDecoratedUrl({
    url,
    payload: { pageSize, pageNumber },
    shouldSort: false,
    shouldFilter: false
  })

  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(finalUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const fetchEnquiryListByUserId = async (payload) => {
  const url = `${baseUrl}${API_PATH.ENQUIRY.FETCH}?userId.equals=${payload}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const fetchEnquiryRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.ENQUIRY.FETCH}/${payload}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const createEnquiryRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.ENQUIRY.ADD}`
  try {
    const result = await axios.post(url, payload)
    if (result?.data?.error || result.status !== 201) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const updateEnquiryRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.ENQUIRY.EDIT}/${payload.id}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.patch(url, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const deleteEnquiry = async (contactId) => {
  const url = `${baseUrl}${API_PATH.ENQUIRY.DELETE}/${contactId}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 204) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const getEnquiryCount = async (role, payload) => {
  let url = ''
  if (USER_ROLE.ADMIN === role) {
    url = `${baseUrl}${API_PATH.ENQUIRY.FETCH}/tenants/${payload}`
  } else {
    url = `${baseUrl}${API_PATH.ENQUIRY.COUNT}?userId.equals=${payload}`
  }

  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.status !== 200) {
      return handleAPIError(result?.data?.detail ?? '')
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}
