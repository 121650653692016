export const INIT_TENANT = 'INIT_TENANT'

export const FETCH_TENANT_LIST = {
  START: 'FETCH_TENANT_START',
  SUCCESS: 'FETCH_TENANT_SUCCESS',
  ERROR: 'FETCH_TENANT_ERROR'
}

export const FETCH_PAGABLE_TENANT_LIST = {
  START: 'FETCH_PAGABLE_TENANT_START',
  SUCCESS: 'FETCH_PAGABLE_TENANT_SUCCESS',
  ERROR: 'FETCH_PAGABLE_TENANT_ERROR'
}


export const FETCH_TENANT_RECORD = {
  START: 'FETCH_TENANT_RECORD_START',
  SUCCESS: 'FETCH_TENANT_RECORD_SUCCESS',
  ERROR: 'FETCH_TENANT_RECORD_ERROR'
}

export const CREATE_TENANT_RECORD = {
  START: 'CREATE_TENANT_RECORD_START',
  SUCCESS: 'CREATE_TENANT_RECORD_SUCCESS',
  ERROR: 'CREATE_TENANT_RECORD_ERROR'
}
export const UPDATE_TENANT_RECORD = {
  START: 'UPDATE_TENANT_RECORD_START',
  SUCCESS: 'UPDATE_TENANT_RECORD_SUCCESS',
  ERROR: 'UPDATE_TENANT_RECORD_ERROR'
}

export const DELETE_TENANT = {
  START: 'DELETE_TENANT_START',
  SUCCESS: 'DELETE_TENANT_SUCCESS',
  ERROR: 'DELETE_TENANT_ERROR'
}
