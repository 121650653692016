import {
  INIT_TENANT,
  FETCH_TENANT_LIST,
  FETCH_TENANT_RECORD,
  CREATE_TENANT_RECORD,
  DELETE_TENANT,
  UPDATE_TENANT_RECORD,
  FETCH_PAGABLE_TENANT_LIST
} from '../../../constant/actionTypes/tenant'
import { tenantService } from '../../../services'

export const initTenant = () => ({
  type: INIT_TENANT
})

export const fetchTenantListStart = (payload) => ({
  type: FETCH_TENANT_LIST.START,
  payload
})

export const fetchTenantListSuccess = (tenants) => ({
  type: FETCH_TENANT_LIST.SUCCESS,
  payload: tenants
})

export const fetchTenantListError = (error) => ({
  type: FETCH_TENANT_LIST.ERROR,
  payload: { error }
})

export const fetchTenantList = (payload) => {
  return (dispatch) => {
    dispatch(fetchTenantListStart(payload))
    tenantService.fetchTenantList(payload).then((tenantData) => {
      if (!tenantData.isError) {
        dispatch(fetchTenantListSuccess(tenantData))
      } else {
        dispatch(fetchTenantListError(tenantData))
      }
    })
  }
}


export const fetchPagableTenantListStart = (payload) => ({
  type: FETCH_PAGABLE_TENANT_LIST.START,
  payload
})

export const fetchPagableTenantListSuccess = (payload) => ({
  type: FETCH_PAGABLE_TENANT_LIST.SUCCESS,
  payload: payload
})

export const fetchPagableTenantListError = (error) => ({
  type: FETCH_PAGABLE_TENANT_LIST.ERROR,
  payload: { error }
})

export const fetchPagableTenantList = (payload) => { 
  return (dispatch) => {
    dispatch(fetchPagableTenantListStart(payload));
    tenantService.fetchPagableTenantList(payload).then((tenantData) => {
      if (!tenantData.isError) {
        dispatch(fetchPagableTenantListSuccess(tenantData));
      } else {
        dispatch(fetchPagableTenantListError(tenantData));
      }
    });
  }
}

export const fetchTenantRecordStart = () => ({
  type: FETCH_TENANT_RECORD.START
})

export const fetchTenantRecordSuccess = (tenant) => ({
  type: FETCH_TENANT_RECORD.SUCCESS,
  payload: tenant
})

export const fetchTenantRecordError = (error) => ({
  type: FETCH_TENANT_RECORD.ERROR,
  payload: { error }
})

export const fetchTenantRecord = (payload) => {
  return (dispatch) => {
    dispatch(fetchTenantRecordStart(payload))
    tenantService.fetchTenantRecord(payload).then((tenantData) => {
      if (!tenantData.isError) {
        dispatch(fetchTenantRecordSuccess(tenantData))
      } else {
        dispatch(fetchTenantRecordError(tenantData))
      }
    })
  }
}

export const createTenantRecordStart = () => ({
  type: CREATE_TENANT_RECORD.START
})

export const createTenantRecordSuccess = (newTenant) => ({
  type: CREATE_TENANT_RECORD.SUCCESS,
  payload: newTenant
})

export const createTenantRecordError = (error) => ({
  type: CREATE_TENANT_RECORD.ERROR,
  payload: { error }
})

export const createTenantRecord = (payload) => {
  return (dispatch) => {
    dispatch(createTenantRecordStart(payload))
    tenantService.createTenantRecord(payload).then((tenantData) => {
      if (!tenantData.isError) {
        dispatch(createTenantRecordSuccess(tenantData))
      } else {
        dispatch(createTenantRecordError(tenantData))
      }
    })
  }
}

export const updateTenantRecordStart = (payload, id) => ({
  type: UPDATE_TENANT_RECORD.START,
  payload,
  id
})

export const updateTenantRecordSuccess = (newTenant) => ({
  type: UPDATE_TENANT_RECORD.SUCCESS,
  payload: newTenant
})

export const updateTenantRecordError = (error) => ({
  type: UPDATE_TENANT_RECORD.ERROR,
  payload: { error }
})

export const updateTenantRecord = (payload, id) => {
  return (dispatch) => {
    dispatch(updateTenantRecordStart(payload, id))
    tenantService.updateTenantRecord(payload, id).then((tenantData) => {
      if (!tenantData.isError) {
        dispatch(updateTenantRecordSuccess(tenantData))
      } else {
        dispatch(updateTenantRecordError(tenantData))
      }
    })
  }
}

export const deleteTenantStart = () => ({
  type: DELETE_TENANT.START
})

export const deleteTenantSuccess = (tenantId) => ({
  type: DELETE_TENANT.SUCCESS,
  payload: { tenantId }
})

export const deleteTenantError = (error) => ({
  type: DELETE_TENANT.ERROR,
  payload: { error }
})

export const deleteTenant = (tenantId) => {
  return (dispatch) => {
    dispatch(deleteTenantStart())
    tenantService.deleteTenant(tenantId).then((tenantData) => {
      if (!tenantData.isError) {
        dispatch(deleteTenantSuccess(tenantData))
      } else {
        dispatch(deleteTenantError(tenantData))
      }
    })
  }
}
