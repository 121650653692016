export const FORM_FIELDS_NAME = {
  TENANT: {
    name: 'tenant',
    label: 'department.form.tenant.label',
    type: 'dropdown',
    rules: {
      required: 'department.error.tenant.required'
    },
    options: [],
    placeholder: 'department.form.tenant.placeholder'
  },

  NAME: {
    name: 'name',
    label: 'department.form.name.label',
    type: 'text',
    placeholder: 'department.form.name.placeholder',
    rules: {
      required: 'department.error.name.required',
      minLength: {
        value: 2,
        message: 'department.error.name.minLength'
      },
      maxLength: {
        value: 50,
        message: 'department.error.name.maxLength'
      }
    }
  }
}
