import {
  INIT_CATEGORY,
  FETCH_CATEGORY_LIST,
  FETCH_CATEGORY_RECORD,
  CREATE_CATEGORY_RECORD,
  DELETE_CATEGORY,
  UPDATE_CATEGORY_RECORD
} from '../../../constant/actionTypes/category'

const formFieldValueMap = {
  tenant: '',
  name: ''
}

const initialState = {
  categorysList: null,
  selectedCategory: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateCategorySuccess: false,
  isEditCategorySuccess: false,
  isCategoryDetailSuccess: false,
  isDeleteCategorySuccess: false,
  isDeleteCategoryError: false,
  isCategoryDetailError: false,
  isCreateCategoryError: false,
  isEditCategoryError: false
}

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_CATEGORY:
      return {
        ...state,
        categorysList: null,
        formFieldValueMap: null,
        selectedCategory: null,
        isLoading: false,
        error: null,
        isCreateCategorySuccess: false,
      }
    case FETCH_CATEGORY_LIST.START:
    case FETCH_CATEGORY_RECORD.START:
    case CREATE_CATEGORY_RECORD.START:
    case DELETE_CATEGORY.START:
    case UPDATE_CATEGORY_RECORD.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreateCategorySuccess: false,
        isEditCategorySuccess: false,
        isCategoryDetailSuccess: false,
        isDeleteCategorySuccess: false,
        isDeleteCategoryError: false,
        isCategoryDetailError: false,
        isCreateCategoryError: false

      }
    case FETCH_CATEGORY_LIST.SUCCESS:
      return {
        ...state,
        categorysList: action.payload,
        isLoading: false,
        error: null
      }
    case FETCH_CATEGORY_RECORD.SUCCESS:
      return {
        ...state,
        selectedCategory: action.payload,
        formFieldValueMap: {
          tenant: action?.payload?.tenant?.id ?? '',
          id: action?.payload?.id ?? '',
          name: action?.payload?.name ?? '',
          active: action?.payload?.active ?? true
        },
        isLoading: false,
        error: null,
        isCategoryDetailSuccess: true
      }
    case CREATE_CATEGORY_RECORD.SUCCESS:
      return {
        ...state,
        categorysList: {
          ...state.categorysList,
          content: state?.categorysList?.content
            ? [...state.categorysList.content, action.payload]
            : [action.payload]
        },
        isLoading: false,
        error: null,
        isCreateCategorySuccess: true
      }
    case UPDATE_CATEGORY_RECORD.SUCCESS:
      return {
        ...state,
        categorysList: {
          ...state.categorysList,
          content:
            state?.categorysList?.content.map((category) =>
              category.id === action.payload.id ? action.payload : category
            ) || []
        },
        isLoading: false,
        error: null,
        isEditCategorySuccess: true
      }
    case DELETE_CATEGORY.SUCCESS:
      return {
        ...state,
        categorysList: {
          ...state.categorysList,
          content:
            state?.categorysList?.content?.filter(
              (category) => category.id !== action.payload.categoryId
            ) || []
        },
        isLoading: false,
        error: null,
        isDeleteCategorySuccess: true
      }
    case FETCH_CATEGORY_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case FETCH_CATEGORY_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isCategoryDetailError: true
      }

    case UPDATE_CATEGORY_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        isEditCategoryError: true
      }

    case DELETE_CATEGORY.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDeleteCategoryError: true
      }
    case CREATE_CATEGORY_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isCreateCategoryError: true,
    };
    default:
      return state
  }
}

export default categoryReducer
