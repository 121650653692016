import {
  INIT_AMENITIES,
  FETCH_AMENITIES_LIST,
  FETCH_AMENITIES_RECORD,
  CREATE_AMENITIES_RECORD,
  DELETE_AMENITIES,
  UPDATE_AMENITIES_RECORD,
  GET_COUNT_AMENITIES,
  GET_AMENITIES_ENQUERY_COUNT,
  GET_AMENITIES_INTREST_COUNT
} from '../../../constant/actionTypes/amenities'
const formFieldValueMap = {
  name: '',
  description: '',
  image: null,
  video: null
}

const initialState = {
  amenitiesList: null,
  amenitiesCount: 0,
  amenitiesIntrestCount: 0,
  amenitiesEnqueryCount: 0,
  selectedAmenities: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateAmenitiesSuccess: false,
  isEditAmenitiesSuccess: false,
  isAmenitiesDetailSuccess: false,
  isDeleteAmenitiesSuccess: false,
  isDeleteAmenitiesError: false,
  isAmenitiesDetailError: false,
  isCreateAmenitiesError: false,
  // isAmenitiesDetailError: false,
  isEditAmenitiesError: false
}

const amenitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_AMENITIES:
      return {
        ...state,
        amenitiesList: null,
        productList: null,
        formFieldValueMap: null,
        selectedAmenities: null,
        isLoading: false,
        error: null
      }
    case FETCH_AMENITIES_LIST.START:
    case FETCH_AMENITIES_RECORD.START:
    case CREATE_AMENITIES_RECORD.START:
    case DELETE_AMENITIES.START:
    case UPDATE_AMENITIES_RECORD.START:
    case GET_COUNT_AMENITIES.START:
    case GET_AMENITIES_ENQUERY_COUNT.START:
    case GET_AMENITIES_INTREST_COUNT.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreateAmenitiesSuccess: false,
        isEditAmenitiesSuccess: false,
        isAmenitiesDetailSuccess: false,
        isDeleteAmenitiesSuccess: false,
        isDeleteAmenitiesError: false,
        isAmenitiesDetailError: false,
        isCreateAmenitiesError: false,
        // isAmenitiesDetailError: false,
        isEditAmenitiesError: false
      }
    case GET_COUNT_AMENITIES.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        amenitiesCount: action.payload
      }
    }

    case GET_AMENITIES_ENQUERY_COUNT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        amenitiesEnqueryCount: action.payload
      }
    }
    case GET_AMENITIES_INTREST_COUNT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        amenitiesIntrestCount: action.payload
      }
    }

    case GET_COUNT_AMENITIES.ERROR: {
      return {
        ...state,
        isLoading: false,
        error: null,
        amenitiesCount: 0
      }
    }

    case FETCH_AMENITIES_LIST.SUCCESS:
      return {
        ...state,
        amenitiesList: action.payload,
        isLoading: false,
        error: null
      }
    case FETCH_AMENITIES_RECORD.SUCCESS:
      return {
        ...state,
        selectedAmenities: action.payload,
        formFieldValueMap: {
          ...state.formFieldValueMap,
          tenant: action.payload?.tenant?.id ?? '',  
          active: action?.payload?.active ?? true,
          ...Object?.keys(action.payload)
            .filter(key => key !== 'tenant')  
            .reduce((acc, key) => {
              acc[key] = action.payload[key];
              return acc;
            }, {})
        },
        isLoading: false,
        error: null,
        isAmenitiesDetailSuccess: true
      }
    case CREATE_AMENITIES_RECORD.SUCCESS:
      return {
        ...state,
        // amenitiesList: [...state.amenitiesList.content, action.payload],
        amenitiesList: {
          ...state.amenitiesList,
          content: state?.amenitiesList?.content
            ? [...state.amenitiesList.content, action.payload] 
            : [action.payload] 
        },
        isLoading: false,
        error: null,
        isCreateAmenitiesSuccess: true
      }
    case UPDATE_AMENITIES_RECORD.SUCCESS:
      return {
        ...state,
        amenitiesList: {
          ...state.amenitiesList,
          content: state.amenitiesList?.content.map((amenities) =>
            amenities.id === action.payload.id ? action.payload : amenities
          )
        },
        isLoading: false,
        error: null,
        isEditAmenitiesSuccess: true
      }
    case DELETE_AMENITIES.SUCCESS:
      return {
        ...state,
        ...state.amenitiesList,
        content: state.amenitiesList.content.filter(
          (amenities) => amenities.id !== action.payload.amenitiesId
        ),
        isLoading: false,
        error: null,
        isDeleteAmenitiesSuccess: true
      }
    case FETCH_AMENITIES_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isAmenitiesDetailError: true
      }
    case UPDATE_AMENITIES_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isEditAmenitiesError: true
      }
    case FETCH_AMENITIES_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        amenitiesList: []
      }
    case DELETE_AMENITIES.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDeleteAmenitiesError: true
      }
    case CREATE_AMENITIES_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isCreateAmenitiesError: true
      }
      case GET_AMENITIES_ENQUERY_COUNT.ERROR: {
        return {
          ...state,
          isLoading: false,
          error: true,
          amenitiesEnqueryCount: action.payload.error
        }
      }
      case GET_AMENITIES_INTREST_COUNT.ERROR: {
        return {
          ...state,
          isLoading: false,
          error: true,
          amenitiesIntrestCount: action.payload.error
        }
      }
  
    default:
      return state
  }
}

export default amenitiesReducer
