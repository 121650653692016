import {
  CREATE_PRODUCTS_RECORD,
  UPDATE_PRODUCTS_RECORD,
  FETCH_PRODUCTS_RECORD,
  FETCH_PRODUCTS_LIST,
  INIT_PRODUCTS,
  FETCH_CATEGORIES_LIST,
  DELETE_IMAGE,
  DELETE_PRODUCTS,
  GET_COUNT_PRODUCTS,
  GET_PRODUCTS_ENQUERY_COUNT,
  GET_PRODUCTS_INTREST_COUNT
} from '../../../constant/actionTypes/products'
const formFieldValueMap = {
  name: '',
  description: '',
  image: null,
  video: null,
  icon: null,
  brochure: null,
  categories: '',
  tenant: ''
}

const initialState = {
  productList: [],
  productsCount: 0,
  productsEnqueryCount:0,
  productsIntrestCount:0,
  categoriesList: [],
  selectedProducts: null,
  formFieldValueMap,
  isProductsDetailSuccess: false,
  isDeleteImageSuccess: false,
  isDeleteImageError: false,
  isDeleteproductsSuccess: false,
  isDeleteproductsError: false,
  isCreateProductsSuccess: false,
  isEditProductsSuccess: false,
  isCreateProductsError: false,
  isEditProductsError: false
}

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_PRODUCTS:
      return {
        ...state,
        productList: [],
        formFieldValueMap: null,
        selectedProducts: null,
        isLoading: false,
        error: null
      }

    case FETCH_PRODUCTS_LIST.START:
    case FETCH_PRODUCTS_RECORD.START:
    case CREATE_PRODUCTS_RECORD.START:
    case UPDATE_PRODUCTS_RECORD.START:
    case FETCH_CATEGORIES_LIST.START:
    case DELETE_IMAGE.START:
    case DELETE_PRODUCTS.START:
    case GET_COUNT_PRODUCTS.START:
    case GET_PRODUCTS_ENQUERY_COUNT.START:
    case GET_PRODUCTS_INTREST_COUNT.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        formFieldValueMap,
        isProductsDetailSuccess: false,
        isDeleteImageSuccess: false,
        isCreateProductsSuccess: false,
        isEditProductsSuccess: false,
        isCreateProductsError: false,
        isDeleteImageError: false,
        isEditProductsError: false,
        isDeleteproductsSuccess: false,
        isDeleteproductsError: false
      }

    case FETCH_PRODUCTS_LIST.SUCCESS:
      return {
        ...state,
        productList: action.payload,
        isLoading: false,
        error: null
      }

    case FETCH_PRODUCTS_RECORD.SUCCESS:
      return {
        ...state,
        selectedProducts: action.payload,
        formFieldValueMap: {
          name: action.payload.name,
          description: action.payload.description,
          images: action.payload.images,
          video: action.payload.video,
          icon: action.payload.icon,
          brochure: action.payload.brochure,
          categories: action.payload.category.id,
          tenant: action?.payload?.tenant?.id ?? '',
          active: action?.payload?.active ?? true,
        },
        isLoading: false,
        error: null,
        isProductsDetailSuccess: true
      }

    case CREATE_PRODUCTS_RECORD.SUCCESS:
      return {
        ...state,
        productList: {
          ...state.productList,
          content: state?.productList?.content
            ? [...state.productList.content, action.payload] 
            : [action.payload] 
        },
        isLoading: false,
        error: null,
        isCreateProductsSuccess: true
      }
    case UPDATE_PRODUCTS_RECORD.SUCCESS:
      return {
        ...state,
        productList: {
          ...state.productList,
          content: state.productList?.content?.map((product) =>
            product.id === action.payload.id ? action.payload : product
          )
        },
        isLoading: false,
        error: null,
        isEditProductsSuccess: true
      }
    case FETCH_CATEGORIES_LIST.SUCCESS:
      return {
        ...state,
        categoriesList: action.payload,
        isLoading: false,
        error: null
      }
      case GET_PRODUCTS_INTREST_COUNT.SUCCESS:
      return {
        ...state,
        productsIntrestCount: action.payload,
        isLoading: false,
        error: null
      }
      case GET_PRODUCTS_ENQUERY_COUNT.SUCCESS:
      return {
        ...state,
        productsEnqueryCount: action.payload,
        isLoading: false,
        error: null
      }
    case DELETE_PRODUCTS.SUCCESS:
      return {
        ...state,
        ...state.productList,
        content: state.productList.content.filter(
          (product) => product.id !== action.payload.productId
        ),
        isLoading: false,
        error: null,
        isDeleteproductsSuccess: true
      }
    case DELETE_IMAGE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        isDeleteImageSuccess: true
      }
    case DELETE_IMAGE.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDeleteImageError: true
      }
    case DELETE_PRODUCTS.ERROR:
      return {
        ...state,
        isLoading: false,
        error: null,
        isDeleteproductsError: true
      }
    case FETCH_CATEGORIES_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        categoriesList: []
      }
    case FETCH_PRODUCTS_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        selectedProducts: action.payload,
        isProductsDetailError: true
      }

    case FETCH_PRODUCTS_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        productList: []
      }

    case CREATE_PRODUCTS_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isCreateProductsError: true
      }
    case UPDATE_PRODUCTS_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isEditproductsError: true
      }
    case GET_COUNT_PRODUCTS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        productsCount: action.payload
      }
    }
    case GET_COUNT_PRODUCTS.ERROR: {
      return {
        ...state,
        isLoading: false,
        error: null,
        productsCount: 0
      }
    }
    case GET_PRODUCTS_INTREST_COUNT.ERROR:
      return {
        ...state,
        productsIntrestCount: action.payload,
        isLoading: false,
        error: true
      }
      case GET_PRODUCTS_ENQUERY_COUNT.ERROR:
      return {
        ...state,
        productsEnqueryCount: 0,
        isLoading: false,
        error: true
      }
    default:
      return state
  }
}

export default productsReducer
