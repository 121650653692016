import React, { useEffect, useState } from 'react'
import ContactComponent from '../../components/contact'
import {
  deleteContact,
  fetchContactList,
  fetchContactListByUserId,
  initContact
} from '../../redux/action/contact'
import { connect } from 'react-redux'
import { USER_ROLE } from '../../constant/actionTypes/role'
import { ProgressBar } from 'primereact/progressbar'
import { fetchTenantList, initTenant } from '../../redux/action/tenant'
import contactTableData from './data.json'
import { INIT_TENANT } from '../../constant/actionTypes/tenant'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import { useTranslation } from 'react-i18next'

const ContactScreen = (props) => {
  const {
    fetchContactList,
    fetchTenantList,
    initContact,
    contactsList,
    isLoading,
    isPageLevelError,
    userRole,
    tenantList,
    user,
    deleteContact,
    fetchContactListByUserId,
    isDeleteContactSuccess,
    isDeleteContactError
  } = props
  useEffect(() => {
    initContact()
    initTenant()
    const payload = {
      pageNumber: 0,
      pageSize: 10
    }
    if (userRole === USER_ROLE.SUPER_ADMIN) {
      fetchContactList(payload)
      fetchTenantList()
    } else if (userRole === USER_ROLE.ADMIN) {
      const modifiedPayload = {
        ...payload,
        tenantId: user?.tenant?.id ?? ''
      }
      fetchContactList(modifiedPayload)
    } else {
      const modifiedPayload = {
        ...payload,
        userId: user?.id ?? ''
      }
      fetchContactListByUserId(modifiedPayload)
    }
    // eslint-disable-next-line
  }, [isDeleteContactSuccess, fetchContactList, initContact])

  const { t } = useTranslation()
  const history = useNavigate()
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)
  const [selectedContact, setSelectedContact] = useState(null)

  const handleOnReadRecord = () => {}

  const handleOnDeleteRecord = (data) => {
    setSelectedContact(data)
    setIsDeleteDialogVisible(true)
  }
  const handleOnEditRecord = (data) => {
    history(`${ROUTE_PATH.CONTACT.EDIT.replace(':id', data?.id)}`)
  }

  const handleOnCreatedRecord = () => {
    history(ROUTE_PATH.CONTACT.ADD)
  }

  const confirmDelete = () => {
    if (selectedContact) {
      deleteContact(selectedContact.id)
      setIsDeleteDialogVisible(false)
      setSelectedContact(null)
    }
  }

  const cancelDelete = () => {
    setIsDeleteDialogVisible(false)
    setSelectedContact(null)
  }

  const contactProps = {
    contactsList,
    tenantList,
    fetchContactList,
    isPageLevelError,
    isLoading,
    contactTableData,
    userRole,
    user,
    handleOnReadRecord,
    handleOnDeleteRecord,
    handleOnEditRecord,
    handleOnCreatedRecord
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }

  const getToastProps = () => {
    if (isDeleteContactSuccess) {
      const toastTitle = t('contact.toastDeleteSuccess')
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isDeleteContactError) {
      let toastTitle = t('contact.toastDeleteError')
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }
  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />

      <ContactComponent contactProps={contactProps} />

      <Dialog
        header={`${t('contact.deleteDailogHeader.Confirm')} ${
          selectedContact?.active
            ? t('contact.deleteDailogHeader.Deactivate')
            : t('contact.deleteDailogHeader.Activate')
        }`}
        visible={isDeleteDialogVisible}
        onHide={cancelDelete}
        footer={
          <div>
            <Button
              severity={selectedContact?.active ? 'primary' : ''}
              onClick={confirmDelete}
            >
              {t('contact.yes')}
            </Button>
            <Button severity="secondary" onClick={cancelDelete}>
              {t('contact.no')}
            </Button>
          </div>
        }
      >
        <div className="text-warp">
          {t('contact.deleteConfirmMessage')}
          {selectedContact?.active
            ? t('contact.deleteDailogHeader.Deactivate')
            : t('contact.deleteDailogHeader.Activate')}{' '}
          {t('contact.deleteConfirmMessage1')}
          <span className="text-primary">{selectedContact?.name}</span>
        </div>
      </Dialog>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initContact: () => dispatch(initContact()),
    initTenant: () => dispatch(INIT_TENANT()),
    fetchTenantList: () => dispatch(fetchTenantList()),
    fetchContactList: (payload) => dispatch(fetchContactList(payload)),
    deleteContact: (payload) => dispatch(deleteContact(payload)),
    fetchContactListByUserId: (payload) =>
      dispatch(fetchContactListByUserId(payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isPageLevelError: state.contactReducer.isPageLevelError,
    isLoading: state.contactReducer.isLoading,
    error: state.contactReducer.error,
    contactsList: state.contactReducer.contactsList,
    userRole: state.loginReducer.userRole,
    tenantList: state.tenantReducer.tenantList,
    user: state.userProfileReducer.user,
    isDeleteContactSuccess: state.contactReducer.isDeleteContactSuccess,
    isDeleteContactError: state.contactReducer.isDeleteContactError
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactScreen)
