import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import MzInput from '../../common/MzForm/MzInput'
import { FORM_FIELDS_NAME } from './constant'
import { Button } from 'primereact/button'
import { NavLink } from 'react-router-dom'
import MzCheckbox from '../../common/MzForm/MzCheckbox'
import { SALESLADDERLOGO } from '../../assets/images'
import './index.css'
import { useTranslation } from 'react-i18next'
const LoginComponent = (props) => {
  const { t } = useTranslation()
  const {
    formFieldValueMap,
    login,
    isLoading,
    isPageLevelError,
    isLoginSuccess
  } = props.loginProps
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: { ...formFieldValueMap },
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    if (isLoginSuccess) {
      reset()
      // if navigate then navigate
    }
  }, [isLoginSuccess, reset])
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className="p-error">{t(errors[name].message)}</small>
      )
    )
  }

  const onSubmit = (data) => {
    login(data)
  }

  return (
    <>
      <div className="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div className="flex flex-column align-items-center justify-content-center font-poppins p-2">
          <div
            className="login-backgoud "
            style={{
              borderRadius: '56px',
              padding: '0.3rem'
            }}
          >
            <div
              className="w-full text-center surface-card py-5 md:py-8 px-5 md:px-8 flex flex-column align-items-center "
              style={{ borderRadius: '53px' }}
            >
              <div className="flex justify-content-center align-items-center h-3rem w-3rem  ">
                <img
                  src={SALESLADDERLOGO}
                  className="h-3rem w-3rem"
                  alt="Sales Ladder"
                />
              </div>
              <h1 className="text-900 font-bold text-3xl md:text-5xl mb-2">
                {t('Sign In')}
              </h1>
              <div className="text-600 mb-5">
                {t('Welcome Back to Sales Ladder.')}
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-column text-center  border-round-sm ">
                  <div className="mb-3 w-17rem md:w-20rem">
                    <MzInput
                      control={control}
                      name={FORM_FIELDS_NAME.USERNAME.name}
                      label={FORM_FIELDS_NAME.USERNAME.label}
                      placeholder={FORM_FIELDS_NAME.USERNAME.placeholder}
                      type={FORM_FIELDS_NAME.USERNAME.type}
                      isError={errors[FORM_FIELDS_NAME.USERNAME.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.USERNAME.name
                      )}
                      rules={FORM_FIELDS_NAME.USERNAME.rules}
                      wrapperClass={'p-float-label'}
                    />
                  </div>

                  <div className="mb-3 w-17rem md:w-20rem">
                    <MzInput
                      control={control}
                      name={FORM_FIELDS_NAME.PASSWORD.name}
                      label={FORM_FIELDS_NAME.PASSWORD.label}
                      placeholder={FORM_FIELDS_NAME.PASSWORD.placeholder}
                      type={FORM_FIELDS_NAME.PASSWORD.type}
                      isError={errors[FORM_FIELDS_NAME.PASSWORD.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.PASSWORD.name
                      )}
                      rules={FORM_FIELDS_NAME.PASSWORD.rules}
                      wrapperClass={'p-float-label'}
                    />
                  </div>
                  <div className="mb-3 text-sm flex justify-content-between align-items-center">
                    <MzCheckbox
                      control={control}
                      name={FORM_FIELDS_NAME.REMBERME.name}
                      label={FORM_FIELDS_NAME.REMBERME.label}
                      isError={errors[FORM_FIELDS_NAME.REMBERME.name]}
                      la
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.REMBERME.name
                      )}
                      className="text-sm"
                    />
                    <NavLink
                      to="/forgot-password"
                      style={{ textDecoration: 'none' }}
                      className="text-sm"
                    >
                      {t('Forgot Password?')}
                    </NavLink>
                  </div>

                  <div className="mb-3">
                    <Button
                      type="submit"
                      label={t('Submit')}
                      className="w-full"
                      disabled={isLoading || isPageLevelError}
                    ></Button>
                  </div>
                </div>
              </form>
              <div className="text-sm  justify-content-between align-items-center">
                {t("Don't have an account?")} &nbsp;
                <NavLink to="/signup" style={{ textDecoration: 'none' }}>
                  {t('Sign Up')}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginComponent
