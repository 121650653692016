import {
  INIT_ENQUIRY,
  FETCH_ENQUIRY_LIST,
  FETCH_ENQUIRY_RECORD,
  CREATE_ENQUIRY_RECORD,
  DELETE_ENQUIRY,
  UPDATE_ENQUIRY_RECORD,
  GET_ENQUIRY_COUNT
} from '../../../constant/actionTypes/enquiry'
import { enquiryService } from '../../../services/'

export const initEnquiry = () => ({
  type: INIT_ENQUIRY
})

export const fetchEnquiryListStart = () => ({
  type: FETCH_ENQUIRY_LIST.START
})

export const fetchEnquiryListSuccess = (enquiry) => ({
  type: FETCH_ENQUIRY_LIST.SUCCESS,
  payload: enquiry
})

export const fetchEnquiryListError = (error) => ({
  type: FETCH_ENQUIRY_LIST.ERROR,
  payload: { error }
})

export const fetchEnquiryList = (payload) => {
  return (dispatch) => {
    dispatch(fetchEnquiryListStart(payload))
    enquiryService.fetchEnquiryList(payload).then((enquiryData) => {
      if (!enquiryData.isError) {
        dispatch(fetchEnquiryListSuccess(enquiryData))
      } else {
        dispatch(fetchEnquiryListError(enquiryData))
      }
    })
  }
}

export const fetchEnquiryListByUserId = (payload) => {
  return (dispatch) => {
    dispatch(fetchEnquiryListStart(payload))
    enquiryService.fetchEnquiryListByUserId(payload).then((enquiryData) => {
      if (!enquiryData.isError) {
        dispatch(fetchEnquiryListSuccess(enquiryData))
      } else {
        dispatch(fetchEnquiryListError(enquiryData))
      }
    })
  }
}

export const fetchEnquiryRecordStart = () => ({
  type: FETCH_ENQUIRY_RECORD.START
})

export const fetchEnquiryRecordSuccess = (contact) => ({
  type: FETCH_ENQUIRY_RECORD.SUCCESS,
  payload: contact
})

export const fetchEnquiryRecordError = (error) => ({
  type: FETCH_ENQUIRY_RECORD.ERROR,
  payload: { error }
})

export const fetchEnquiryRecord = (payload) => {
  return (dispatch) => {
    dispatch(fetchEnquiryRecordStart(payload))
    enquiryService.fetchEnquiryRecord(payload).then((enquiryData) => {
      if (!enquiryData.isError) {
        dispatch(fetchEnquiryRecordSuccess(enquiryData))
      } else {
        dispatch(fetchEnquiryRecordError(enquiryData))
      }
    })
  }
}

export const createEnquiryRecordStart = () => ({
  type: CREATE_ENQUIRY_RECORD.START
})

export const createEnquiryRecordSuccess = (newEnquiry) => ({
  type: CREATE_ENQUIRY_RECORD.SUCCESS,
  payload: newEnquiry
})

export const createEnquiryRecordError = (error) => ({
  type: CREATE_ENQUIRY_RECORD.ERROR,
  payload: { error }
})

export const createEnquiryRecord = (payload) => {
  return (dispatch) => {
    dispatch(createEnquiryRecordStart(payload))
    enquiryService.createEnquiryRecord(payload).then((enquiryData) => {
      if (!enquiryData.isError) {
        dispatch(createEnquiryRecordSuccess(enquiryData))
      } else {
        dispatch(createEnquiryRecordError(enquiryData))
      }
    })
  }
}

export const updateEnquiryRecordStart = () => ({
  type: UPDATE_ENQUIRY_RECORD.START
})

export const updateEnquiryRecordSuccess = (newEnquiry) => ({
  type: UPDATE_ENQUIRY_RECORD.SUCCESS,
  payload: newEnquiry
})

export const updateEnquiryRecordError = (error) => ({
  type: UPDATE_ENQUIRY_RECORD.ERROR,
  payload: { error }
})

export const updateEnquiryRecord = (payload) => {
  return (dispatch) => {
    dispatch(updateEnquiryRecordStart(payload))
    enquiryService.updateEnquiryRecord(payload).then((enquiryData) => {
      if (!enquiryData.isError) {
        dispatch(updateEnquiryRecordSuccess(enquiryData))
      } else {
        dispatch(updateEnquiryRecordError(enquiryData))
      }
    })
  }
}

export const deleteEnquiryStart = () => ({
  type: DELETE_ENQUIRY.START
})

export const deleteEnquirySuccess = (contactId) => ({
  type: DELETE_ENQUIRY.SUCCESS,
  payload: { contactId }
})

export const deleteEnquiryError = (error) => ({
  type: DELETE_ENQUIRY.ERROR,
  payload: { error }
})

export const deleteEnquiry = (contactId) => {
  return (dispatch) => {
    dispatch(deleteEnquiryStart())
    enquiryService.deleteEnquiry(contactId).then((enquiryData) => {
      if (!enquiryData.isError) {
        dispatch(deleteEnquirySuccess(enquiryData))
      } else {
        dispatch(deleteEnquiryError(enquiryData))
      }
    })
  }
}

const getEnquiryCountStart = (role, payload) => {
  return {
    type: GET_ENQUIRY_COUNT.START,
    role,
    payload
  }
}

const getEnquiryCountSuccess = (payload) => {
  return {
    type: GET_ENQUIRY_COUNT.SUCCESS,
    payload
  }
}

const getEnquiryCountError = (payload) => {
  return {
    type: GET_ENQUIRY_COUNT.ERROR,
    payload
  }
}

export const getEnquiryCount = (role, payload) => {
  return (dispatch) => {
    dispatch(getEnquiryCountStart(role, payload))
    enquiryService.getEnquiryCount(role, payload).then((enquiryData) => {
      if (!enquiryData.isError) {
        dispatch(getEnquiryCountSuccess(enquiryData))
      } else {
        dispatch(getEnquiryCountError(enquiryData))
      }
    })
  }
}
