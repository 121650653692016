export const FORM_FIELDS_NAME = {
  TENANT: {
    name: 'tenant',
    label: 'product.form.Tenant.label',
    type: 'dropdown',
    rules: {
      required: 'product.error.Tenant.required'
    },
    options: [],
    placeholder: 'product.form.Tenant.placeholder'
  },

  NAME: {
    name: 'name',
    label: 'product.form.name.label',
    type: 'text',
    placeholder: 'product.form.name.placeholder',
    rules: {
      required: 'product.error.name.required',
      minLength: {
        value: 3,
        message: 'product.error.name.minLength'
      },
      maxLength: {
        value: 50,
        message: 'product.error.name.maxLength'
      }
    }
  },
  DESCRIPTION: {
    name: 'description',
    label: 'product.form.description.label',
    type: 'text',
    placeholder: 'product.form.description.placeholder',
    rules: {
      maxLength: {
        value: 4000,
        message: 'Amenities.error.description.maxlength'
      } 
    }
  },
  IMAGE: {
    name: 'images',
    label: 'product.form.Images.label',
    type: 'file',
    placeholder: 'Upload a image',
    rules: {
      required: 'product.error.images.required'
    }
  },
  ICON: {
    name: 'icon',
    label: 'product.form.icon.label',
    type: 'file',

    placeholder: 'Upload a icon'
  },
  BROCHURE: {
    name: 'brochure',
    label: 'product.form.brochure.label',
    type: 'file',
    placeholder: 'Upload a brochure',
    rules: {
      message: 'Upload Pdf '
    }
  },
  VIDEO: {
    name: 'video',
    label: 'product.form.Video.label',
    type: 'file',
    placeholder: 'Upload a Video'
  },
  CATEGORY: {
    name: 'categories',
    label: 'product.form.category.label',
    type: 'dropdown',
    placeholder: 'product.form.category.placeholder',
    options: [],
    rules: {
      required: 'product.error.category.required'
    }
  }
}
