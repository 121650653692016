export const FORM_FIELDS_NAME = {
  TENANT: {
    name: 'tenant',
    label: 'tenant.company',
    type: 'dropdown',
    rules: {
      required: 'tenant.error.companyName.required'
    },
    options: [
      { name: 'tenant.form.companyName.placeholder', value: '' },
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'tenant.form.companyName.placeholder'
  },

  NAME: {
    name: 'name',
    label: 'contact.form.name.label',
    type: 'text',
    placeholder: 'contact.form.name.placeholder',
    rules: {
      required: 'contact.error.name.required',
      maxLength: {
        value: 60,
        message: 'contact.error.name.maxLength'
      }
    }
  },
  PHONE_NUMBER: {
    name: 'phone',
    label: 'contact.form.phone.label',
    type: 'tel',
    placeholder: 'contact.form.phone.placeholder',
    rules: {
      required: 'contact.error.phone.required',
      minLength: {
        value: 8,
        message: 'contact.error.phone.minLength'
      },
      maxLength: {
        value: 14,
        message: 'contact.error.phone.maxLength'
      }
    }
  },
  EMAIL: {
    name: 'email',
    label: 'contact.form.email.label',
    type: 'email',
    placeholder: 'contact.form.email.placeholder',
    rules: {
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        message: 'contact.error.email.invalid'
      }
    }
  },
  ADDRESS: {
    name: 'address',
    label: 'contact.form.address.label',
    type: 'text',
    placeholder: 'contact.form.address.placeholder',
    rules: {
      required: 'contact.error.address.required',
      maxLength: {
        value: 50,
        message: 'contact.error.address.maxLength'
      }
    }
  }
}
