export const LOGIN = {
  START: 'LOGIN_START',
  SUCCESS: 'LOGIN_SUCCESS',
  ERROR: 'LOGIN_ERROR'
}
export const LOGOUT = {
  START: 'LOGOUT_START',
  SUCCESS: 'LOGOUT_SUCCESS',
  ERROR: 'LOGOUT_ERROR'
}

export const REGISTER = {
  START: 'REGISTER_START',
  SUCCESS: 'REGISTER_SUCCESS',
  ERROR: 'REGISTER_ERROR'
}

export const INIT_LOGIN = 'INIT_LOGIN'
export const INIT_REGISTER = 'INIT_REGISTER'
