export const FORM_FIELDS_NAME = {
  USERNAME: {
    name: 'username',
    label: 'Login.form.userName.label',
    type: 'text',
    placeholder: 'Login.form.userName.placeholder',
    rules: {
      required: 'Login.error.userName.required',
      minLength: {
        value: 3,
        message: 'Login.error.userName.minLength'
      },
      maxLength: {
        value: 50,
        message: 'Login.error.userName.maxLength'
      }
    }
  },
  PASSWORD: {
    name: 'password',
    label: 'Login.form.password.label',
    type: 'password',
    placeholder: 'Enter your password',
    rules: {
      required: 'Login.error.password.required',
      minLength: {
        value: 4,
        message: 'Login.error.password.minLength'
      },
      maxLength: {
        value: 40,
        message: 'Login.error.password.maxLength'
      }
    }
  },
  REMBERME: {
    name: 'rememberMe',
    label: 'Login.form.rememberMe.label',
    type: 'checkbox'
  }
}
