import React, { useEffect, useState } from 'react'
import EnquiryComponent from '../../components/enquiry'

import { connect } from 'react-redux'
import { USER_ROLE } from '../../constant/actionTypes/role'
import { ProgressBar } from 'primereact/progressbar'
import { fetchTenantList, initTenant } from '../../redux/action/tenant'
import enquiryTableData from './data.json'
import { INIT_TENANT } from '../../constant/actionTypes/tenant'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import { useTranslation } from 'react-i18next'
import {
  deleteEnquiry,
  fetchEnquiryList,
  fetchEnquiryListByUserId,
  initEnquiry
} from '../../redux/action/enquiry'

const EnquiryScreen = (props) => {
  const {
    fetchEnquiryList,
    fetchTenantList,
    enquiryList,
    isLoading,
    isPageLevelError,
    userRole,
    tenantList,
    user,
    deleteEnquiry,
    isDeleteEnquirySuccess,
    isDeleteEnquiryError
  } = props
  useEffect(() => {
    initTenant()
    const payload = {
      pageNumber: 0,
      pageSize: 10
    }
    if (userRole === USER_ROLE.SUPER_ADMIN) {
      fetchTenantList()
      const modifiedpayload = {
        ...payload
      }
      fetchEnquiryList(modifiedpayload)
    } else if (userRole === USER_ROLE.ADMIN) {
      const modifiedpayload = {
        ...payload,
        tenantId: user?.tenant?.id || ''
      }
      fetchEnquiryList(modifiedpayload)
    }

    // eslint-disable-next-line
  }, [isDeleteEnquirySuccess, fetchEnquiryList, initEnquiry])

  const { t } = useTranslation()
  const history = useNavigate()
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)
  const [selectedEnquiry, setSelectedEnquiry] = useState(null)

  const handleOnReadRecord = () => {}

  const handleOnDeleteRecord = (data) => {
    setSelectedEnquiry(data)
    setIsDeleteDialogVisible(true)
  }
  const handleOnEditRecord = (data) => {
    history(`${ROUTE_PATH.ENQUIRY.EDIT.replace(':id', data?.id)}`)
  }

  const handleOnCreatedRecord = () => {
    history(ROUTE_PATH.ENQUIRY.ADD)
  }

  const confirmDelete = () => {
    if (selectedEnquiry) {
      deleteEnquiry(selectedEnquiry.id)
      setIsDeleteDialogVisible(false)
      setSelectedEnquiry(null)
    }
  }

  const cancelDelete = () => {
    setIsDeleteDialogVisible(false)
    setSelectedEnquiry(null)
  }

  const enquiryProps = {
    enquiryList,
    tenantList,
    fetchEnquiryList,
    isPageLevelError,
    isLoading,
    enquiryTableData,
    userRole,
    user,
    handleOnReadRecord,
    handleOnDeleteRecord,
    handleOnEditRecord,
    handleOnCreatedRecord
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }

  const getToastProps = () => {
    if (isDeleteEnquirySuccess) {
      const toastTitle = t('enquiries.toastDeleteSuccess')
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isDeleteEnquiryError) {
      let toastTitle = t('enquiries.toastDeleteError')
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }
  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />

      <EnquiryComponent enquiryProps={enquiryProps} />

      <Dialog
        header={`${t('.deleteDailogHeader.Confirm')} ${
          selectedEnquiry?.active
            ? t('enquries.deleteDailogHeader.Deactivate')
            : t('enquries.deleteDailogHeader.Activate')
        }`}
        visible={isDeleteDialogVisible}
        onHide={cancelDelete}
        footer={
          <div>
            <Button
              severity={selectedEnquiry?.active ? 'primary' : ''}
              onClick={confirmDelete}
            >
              {t('enquries.yes')}
            </Button>
            <Button severity="secondary" onClick={cancelDelete}>
              {t('enquries.no')}
            </Button>
          </div>
        }
      >
        <div className="text-warp">
          {t('enquries.deleteConfirmMessage')}
          {selectedEnquiry?.active
            ? t('enquries.deleteDailogHeader.Deactivate')
            : t('enquries.deleteDailogHeader.Activate')}{' '}
          {t('product.deleteConfirmMessage1')}
          <span className="text-primary">{selectedEnquiry?.name}</span>
        </div>
      </Dialog>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initEnquiry: () => dispatch(initEnquiry()),
    initTenant: () => dispatch(INIT_TENANT()),
    fetchTenantList: () => dispatch(fetchTenantList()),
    fetchEnquiryList: (payload) => dispatch(fetchEnquiryList(payload)),
    deleteEnquiry: (payload) => dispatch(deleteEnquiry(payload)),
    fetchEnquiryListByUserId: (payload) =>
      dispatch(fetchEnquiryListByUserId(payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isPageLevelError: state.enquiryReducer.isPageLevelError,
    isLoading: state.enquiryReducer.isLoading,
    error: state.enquiryReducer.error,
    enquiryList: state.enquiryReducer.enquiryList,
    userRole: state.loginReducer.userRole,
    tenantList: state.tenantReducer.tenantList,
    user: state.userProfileReducer.user,
    isDeleteEnquirySuccess: state.enquiryReducer.isDeleteEnquirySuccess,
    isDeleteEnquiryError: state.enquiryReducer.isDeleteEnquiryError
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnquiryScreen)
