/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import ProductsComponent from '../../components/product'
import {
  initProducts,
  fetchProductsList,
  deleteProduct
} from '../../redux/action/products'
import { connect } from 'react-redux'
import { USER_ROLE } from '../../constant/actionTypes/role'
import { ProgressBar } from 'primereact/progressbar'
import { fetchTenantList, initTenant } from '../../redux/action/tenant'
import amenitiesTableData from './data.json'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import { useTranslation } from 'react-i18next'
const ProductsScreen = (props) => {
  const [viewAmenities, setViewAmenities] = useState(true)
  // const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)
  // const [selectedAmenities, setSelectedAmenities] = useState(null)
  const [isDeleteProductsDialogVisible, setIsDeleteProductsDialogVisible] =
    useState(false)
  const [selectedProducts, setSelectedProducts] = useState(null)

  const {
    fetchProductsList,
    fetchTenantList,
    initProducts,
    productsList,
    isLoading,
    isPageLevelError,
    userRole,
    tenantList,
    user,
    fetchCategoriesList,
    categoriesList,
    isDeleteproductsSuccess,
    isDeleteproductsError,
    deleteProduct
  } = props
  const { t } = useTranslation()
  useEffect(() => {
    initProducts()
    initTenant()

    const payload = {
      pageNumber: 0,
      pageSize: 10
    }

    if (userRole === USER_ROLE.SUPER_ADMIN) {
    fetchProductsList(payload)
      fetchTenantList()
    } else {
      const modifiedPayload = {
        ...payload,
        tenantId: user?.tenant?.id || ''
      }
      fetchProductsList(modifiedPayload)
    }
  }, [
    userRole,
    fetchProductsList,
    fetchTenantList,
    user,
    isDeleteproductsSuccess,
    initProducts
  ])

  // useEffect(() => {
  //   if(userRole === USER_ROLE.SUPER_ADMIN){
  //     setTimeout(() => {
  //       fetchProductsList()
  //     }, 500) 
  //   }
  //   else
  //   {
  //     setTimeout(() => {
  //       fetchProductsList({tenantId:user?.tenant?.id ?? ''})
  //     }, 500) 
  //   }
  // }, [
  //   isDeleteproductsSuccess,
  //   fetchProductsList,
  //   user
  // ])

  const history = useNavigate()

  const handleOnReadRecord = (data) => {}

  const handleOnDeleteProductsRecord = (data) => {
    setSelectedProducts(data)
    setIsDeleteProductsDialogVisible(true)
  }

  const handleOnEditProductsRecord = (data) => {
    history(`${ROUTE_PATH.PRODUCTS.EDIT.replace(':id', data?.id)}`)
  }

  const handleOnCreatedRecord = () => {
    history(ROUTE_PATH.AMENITIES.ADD)
  }

  const handleOnCreatedProductsRecord = () => {
    history(ROUTE_PATH.PRODUCTS.ADD)
  }

  const confirmDelete = () => {
    if (selectedProducts) {
      deleteProduct(selectedProducts.id)
      setIsDeleteProductsDialogVisible(false)
      setSelectedProducts(null)
    }
  }

  const cancelDelete = () => {
    setIsDeleteProductsDialogVisible(false)
    setSelectedProducts(null)
  }

  const filteredTableData = {
    ...amenitiesTableData,
    tableData: {
      ...amenitiesTableData.tableData,
      columns: Array.isArray(amenitiesTableData?.tableData?.columns)
        ? amenitiesTableData.tableData.columns.filter((column) => {
            if (
              column.dataKey === 'tenant.companyName' &&
              userRole === USER_ROLE.ADMIN
            ) {
              return false
            }
            return true
          })
        : []
    }
  }

  const productsProps = {
    productsList,
    tenantList,
    fetchProductsList,
    isPageLevelError,
    isLoading,
    amenitiesTableData: filteredTableData,
    userRole,
    viewAmenities,
    handleOnReadRecord,
    handleOnDeleteProductsRecord,
    handleOnEditProductsRecord,
    handleOnCreatedRecord,
    handleOnCreatedProductsRecord,
    setViewAmenities,
    categoriesList,
    fetchCategoriesList,
    user
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }

  const getToastProps = () => {
    if (isDeleteproductsSuccess) {
      const toastTitle = t('product.toastDeleteSuccess')
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isDeleteproductsError) {
      let toastTitle = t('product.toastDeleteError')
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />

      <ProductsComponent productsProps={productsProps} />

      <Dialog
        header={`${t('employee.deleteDailogHeader.Confirm')} ${
          selectedProducts?.active
            ? t('employee.deleteDailogHeader.Deactivate')
            : t('employee.deleteDailogHeader.Activate')
        }`}
        visible={isDeleteProductsDialogVisible}
        onHide={cancelDelete}
        footer={
          <div>
            <Button
              severity={selectedProducts?.active ? 'primary' : ''}
              onClick={confirmDelete}
            >
              {t('product.yes')}
            </Button>
            <Button severity="secondary" onClick={cancelDelete}>
              {t('product.no')}
            </Button>
          </div>
        }
      >
        <div className="text-warp">
          {t('employee.deleteConfirmMessage')}
          {selectedProducts?.active
            ? t('employee.deleteDailogHeader.Deactivate')
            : t('employee.deleteDailogHeader.Activate')}{' '}
          {t('product.ThisProduct')}
          <span className="text-primary">{selectedProducts?.name}</span>
        </div>
      </Dialog>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initProducts: () => dispatch(initProducts()),
    initTenant: () => dispatch(initTenant()),
    fetchTenantList: () => dispatch(fetchTenantList()),
    fetchProductsList: (payload) => dispatch(fetchProductsList(payload)),
    deleteProduct: (payload) => dispatch(deleteProduct(payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    productsList: state.productsReducer.productList,
    userRole: state.loginReducer.userRole,
    tenantList: state.tenantReducer.tenantList,
    user: state.userProfileReducer.user,
    isDeleteproductsSuccess: state.productsReducer.isDeleteproductsSuccess,
    isDeleteproductsError: state.productsReducer.isDeleteproductsError
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsScreen)
