export const INIT_PRODUCTS = 'INIT_PRODUCTS'

export const FETCH_PRODUCTS_LIST = {
  START: 'FETCH_PRODUCTS_START',
  SUCCESS: 'FETCH_PRODUCTS_SUCCESS',
  ERROR: 'FETCH_PRODUCTS_ERROR'
}

export const CREATE_PRODUCTS_RECORD = {
  START: 'CREATE_PRODUCTS_RECORD_START',
  SUCCESS: 'CREATE_PRODUCTS_RECORD_SUCCESS',
  ERROR: 'CREATE_PRODUCTS_RECORD_ERROR'
}

export const UPDATE_PRODUCTS_RECORD = {
  START: 'UPDATE_PRODUCTS_RECORD_START',
  SUCCESS: 'UPDATE_PRODUCTS_RECORD_SUCCESS',
  ERROR: 'UPDATE_PRODUCTS_RECORD_ERROR'
}

export const FETCH_PRODUCTS_RECORD = {
  START: 'FETCH_PRODUCTS_RECORD_START',
  SUCCESS: 'FETCH_PRODUCTS_RECORD_SUCCESS',
  ERROR: 'FETCH_PRODUCTS_RECORD_ERROR'
}

export const FETCH_CATEGORIES_LIST = {
  START: 'FETCH_CATEGORIES_START',
  SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
  ERROR: 'FETCH_CATEGORIES_ERROR'
}

export const DELETE_IMAGE = {
  START: 'DELETE_IMAGE_START',
  SUCCESS: 'DELETE_IMAGE_SUCCESS',
  ERROR: 'DELETE_IMAGE_ERROR'
}

export const DELETE_PRODUCTS = {
  START: 'DELETE_PRODUCTS_START',
  SUCCESS: 'DELETE_PRODUCTS_SUCCESS',
  ERROR: 'DELETE_PRODUCTS_ERROR'
}

export const GET_COUNT_PRODUCTS = {
  START: 'GET_COUNT_PRODUCTS_START',
  SUCCESS: 'GET_COUNT_PRODUCTS_SUCCESS',
  ERROR: 'GET_COUNT_PRODUCTS_ERROR'
}

export const GET_PRODUCTS_INTREST_COUNT = {
  START: 'GET_PRODUCTS_INTREST_COUNT_START',
  SUCCESS: 'GET_PRODUCTS_INTREST_COUNT_SUCCESS',
  ERROR: 'GET_PRODUCTS_INTREST_COUNT_ERROR'
}

export const GET_PRODUCTS_ENQUERY_COUNT = {
  START: 'GET_PRODUCTS_ENQUERY_COUNT_START',
  SUCCESS: 'GET_PRODUCTS_ENQUERY_COUNT_SUCCESS',
  ERROR: 'GET_PRODUCTS_ENQUERY_COUNT_ERROR'
}
