import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import AddAmenitiesComponent from '../../../components/amenities/addAmenities'
import { MzToast, TOAST_SEVERITY } from '../../../common/MzToast'
import { ProgressBar } from 'primereact/progressbar'
import {
  createAmenitiesRecord,
  fetchAmenitiesRecord,
  updateAmenitiesRecord
} from '../../../redux/action/amenities'
import { ROUTE_PATH } from '../../../constant/urlConstant'
import { useNavigate } from 'react-router-dom'
import { fetchTenantList } from '../../../redux/action/tenant'
import { USER_ROLE } from '../../../constant/actionTypes/role'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
const AddAmenitiesScreen = (props) => {
  const {
    isCreateAmenitiesSuccess,
    isCreateAmenitiesError,
    isAmenitiesDetailSuccess,
    isAmenitiesDetailError,
    isEditAmenitiesSuccess,
    isEditAmenitiesError,
    isEdit,
    error,
    isLoading,
    createAmenitiesRecord,
    formFieldValueMap,
    isPageLevelError,
    user,
    fetchAmenitiesRecord,
    updateAmenitiesRecord,
    userRole,
    fetchTenantList,
    tenantList
  } = props

  useEffect(() => {
    if (userRole === USER_ROLE.SUPER_ADMIN) {
      fetchTenantList()
    }
    // eslint-disable-next-line
  }, [userRole])
  const { t } = useTranslation()
  const getToastProps = () => {
    if (isCreateAmenitiesSuccess || isEditAmenitiesSuccess) {
      const toastTitle = isEdit
        ? t('Amenities.serviceUpdatedSuccessfully')
        : t('Amenities.serviceCreatedSuccessfully')
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (
      isCreateAmenitiesError ||
      isAmenitiesDetailError ||
      isEditAmenitiesError
    ) {
      let toastTitle = error ? error?.error : t('Amenities.createServiceError')
      if (isEditAmenitiesError) {
        toastTitle = error ? error?.error : t('Amenities.updateServiceError')
        toast.error(error)
      } else if (isAmenitiesDetailError) {
        toastTitle = t('Amenities.operationError')
      }
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }
  const history = useNavigate()
  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }
  const navigatetoAmenities = () => {
    history(ROUTE_PATH.AMENITIES.HOME)
  }
  const addAmenitiesProps = {
    createAmenitiesRecord,
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    isCreateAmenitiesSuccess,
    isEditAmenitiesSuccess,
    isAmenitiesDetailSuccess,
    user,
    error,
    userRole,
    isEdit,
    fetchAmenitiesRecord,
    updateAmenitiesRecord,
    tenantList,
    navigatetoAmenities
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <AddAmenitiesComponent addAmenitiesProps={addAmenitiesProps} />
    </>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    createAmenitiesRecord: (payload) =>
      dispatch(createAmenitiesRecord(payload)),
    updateAmenitiesRecord: (payload, id) =>
      dispatch(updateAmenitiesRecord(payload, id)),
    fetchAmenitiesRecord: (payload) => dispatch(fetchAmenitiesRecord(payload)),
    fetchTenantList: (payload) => dispatch(fetchTenantList(payload))
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    formFieldValueMap: selectFormFieldValueMap(state.amenitiesReducer),
    isPageLevelError: state.amenitiesReducer.isPageLevelError,
    isLoading: state.amenitiesReducer.isLoading,
    error: state.amenitiesReducer.error,
    isCreateAmenitiesSuccess: state.amenitiesReducer.isCreateAmenitiesSuccess,
    isCreateAmenitiesError: state.amenitiesReducer.isCreateAmenitiesError,
    isEditAmenitiesSuccess: state.amenitiesReducer.isEditAmenitiesSuccess,
    isEditAmenitiesError: state.amenitiesReducer.isEditAmenitiesError,
    isAmenitiesDetailSuccess: state.amenitiesReducer.isAmenitiesDetailSuccess,
    isAmenitiesDetailError: state.amenitiesReducer.isAmenitiesDetailError,
    user: state.userProfileReducer.user,
    userRole: state.loginReducer.userRole,
    tenantList: state.tenantReducer.tenantList
  }
}
const selectFormFieldValueMap = (amenitiesReducer) => {
  return amenitiesReducer.formFieldValueMap
}
export default connect(mapStateToProps, mapDispatchToProps)(AddAmenitiesScreen)
