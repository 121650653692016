import React, { useEffect, useState } from 'react'
import TenantComponent from '../../components/Tenant'
import { connect } from 'react-redux'
import { USER_ROLE } from '../../constant/actionTypes/role'
import { ProgressBar } from 'primereact/progressbar'
import {
  deleteTenant,
  fetchPagableTenantList,
  initTenant
} from '../../redux/action/tenant'
import tenantTableData from './data.json'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import { useTranslation } from 'react-i18next'

const TenantScreen = (props) => {
  const {
    initTenant,
    isLoading,
    isPageLevelError,
    userRole,
    error,
    deleteTenant,
    pagableTenantList,
    isDeleteTenantSuccess,
    isDeleteTenantError,
    fetchPagableTenantList
  } = props
  
 useEffect(() => {
    initTenant()

    const payload = {
      pageNumber: 0,
      pageSize: 10
    }
    if (userRole === USER_ROLE.SUPER_ADMIN) {
      fetchPagableTenantList(payload)
    }
    // eslint-disable-next-line
  }, [fetchPagableTenantList,isDeleteTenantSuccess])
  
  const history = useNavigate()
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)
  const [selectedTenant, setSelectedTenant] = useState(null)

  const handleOnReadRecord = (data) => {}

  const handleOnDeleteRecord = (data) => {
    setSelectedTenant(data)
    setIsDeleteDialogVisible(true)
  }
  const handleOnEditRecord = (data) => {
    history(`${ROUTE_PATH.COMPANY.EDIT.replace(':id', data?.id)}`)
  }

  const handleOnCreatedRecord = () => {
    history(ROUTE_PATH.COMPANY.ADD)
  }

  const navigatetoCompany = () => {
    history(ROUTE_PATH.COMPANY.HOME)
  }

  const tenantProps = {
    pagableTenantList,
    fetchPagableTenantList,
    isPageLevelError,
    isLoading,
    tenantTableData,
    userRole,
    navigatetoCompany,
    handleOnReadRecord,
    handleOnDeleteRecord,
    handleOnEditRecord,
    handleOnCreatedRecord
  }

  const { t } = useTranslation()
  const renderProgressBar = () => (
    <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  )

  const confirmDelete = () => {
    if (selectedTenant) {
      deleteTenant(selectedTenant.id)
      setIsDeleteDialogVisible(false)
      setSelectedTenant(null)
    }
  }

  const cancelDelete = () => {
    setIsDeleteDialogVisible(false)
    setSelectedTenant(null)
  }
  const getToastProps = () => {
    if (isDeleteTenantSuccess) {
      const toastTitle = t('tenant.toastDeleteSuccess')
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isDeleteTenantError) {
      let toastTitle = error ? error : t('tenant.toastDeleteError')
      // let toastTitle =t('tenant.toastDeleteError')
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }
  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <TenantComponent tenantProps={tenantProps} />
      <Dialog
        header={t('tenant.deleteDailogHeader')}
        visible={isDeleteDialogVisible}
        onHide={cancelDelete}
        footer={
          <div>
            <Button severity={selectedTenant?.active ? 'primary' : ''} onClick={confirmDelete}>
              {t('tenant.yes')}
            </Button>
            <Button severity="secondary" onClick={cancelDelete}>
              {t('tenant.no')}
            </Button>
          </div>
        }
      >
        <div className="text-warp">
          {t('tenant.deleteConfirmMessage')}
          <span className="text-primary">{selectedTenant?.username}</span>
        </div>
      </Dialog>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  initTenant: () => dispatch(initTenant()),
  fetchPagableTenantList: (payload) =>
    dispatch(fetchPagableTenantList(payload)),
  deleteTenant: (payload) => dispatch(deleteTenant(payload))
})

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  isPageLevelError: state.tenantReducer.isPageLevelError,
  isLoading: state.tenantReducer.isLoading,
  error: state.tenantReducer.error,
  pagableTenantList: state.tenantReducer.pagableTenantList,
  userRole: state.loginReducer.userRole,
  isDeleteTenantSuccess: state.tenantReducer.isDeleteTenantSuccess,
  isDeleteTenantError: state.tenantReducer.isDeleteTenantError
})

export default connect(mapStateToProps, mapDispatchToProps)(TenantScreen)
