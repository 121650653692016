import React from 'react'
import { Controller } from 'react-hook-form'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { Message } from 'primereact/message'
import { Password } from 'primereact/password'
import { useTranslation } from "react-i18next"

const MzInput = ({
  control,
  name,
  disabled,
  type,
  rules,
  labelClassName,
  label,
  onChange,
  errorMsg,
  isError,
  wrapperClass,
  placeholder,
  inputStyle
}) => {
  const getLabelClassName = () => {
    return classNames({
      'p-error': isError,
      labelClassName
    })
  }
  const { t } = useTranslation();

  return (
    <div className="field" style={{ textAlign: 'start' }}>
      <label htmlFor={name} className={getLabelClassName()}>
        {t(label)}
        {rules?.required ? <span className="p-error">*</span> : null}
      </label>
      <span className={wrapperClass}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field, fieldState }) =>
            type !== 'password' ? (
              <InputText
                type={type}
                id={field.name}
                name={field.name}
                onChange={onChange}
                disabled={disabled}
                placeholder={t(placeholder)}
                {...field}
                className={classNames({
                  'p-invalid': fieldState.invalid
                })}
                style={inputStyle ? JSON.parse(inputStyle) : { width: '100%' }}
              />
            ) : (
              <Password
                type={type}
                id={field.name}
                name={field.name}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder}
                toggleMask
                feedback={false}
                {...field}
                className={classNames('', {
                  'p-invalid': fieldState.invalid
                })}
                style={inputStyle ? JSON.parse(inputStyle) : { width: '100%' }}
              />
            )
          }
        />
      </span>
      {errorMsg && (
        <Message
          className="mt-1 flex"
          style={{
            borderWidth: '0 0 0 2px'
          }}
          severity="error"
          content={errorMsg}
        />
      )}
    </div>
  )
}

// MzInput.defaultProps = {
//   labelClassName: "",
//   wrapperClass: "",
//   disabled: false,
//   inputStyle: "",
//   type: "text",
// };

export default MzInput
