import {
  INIT_DEPARTMENT,
  FETCH_DEPARTMENT_LIST,
  FETCH_DEPARTMENT_RECORD,
  CREATE_DEPARTMENT_RECORD,
  DELETE_DEPARTMENT,
  UPDATE_DEPARTMENT_RECORD
} from '../../../constant/actionTypes/department'
import { departmentService } from '../../../services'

export const initDepartment = () => ({
  type: INIT_DEPARTMENT
})

export const fetchDepartmentListStart = () => ({
  type: FETCH_DEPARTMENT_LIST.START
})

export const fetchDepartmentListSuccess = (departments) => ({
  type: FETCH_DEPARTMENT_LIST.SUCCESS,
  payload: departments
})

export const fetchDepartmentListError = (error) => ({
  type: FETCH_DEPARTMENT_LIST.ERROR,
  payload: { error }
})

export const fetchDepartmentList = (payload) => {
  return (dispatch) => {
    dispatch(fetchDepartmentListStart(payload))
    departmentService.fetchDepartmentList(payload).then((departmentData) => {
      if (!departmentData.isError) {
        dispatch(fetchDepartmentListSuccess(departmentData))
      } else {
        dispatch(fetchDepartmentListError(departmentData))
      }
    })
  }
}

export const fetchDepartmentRecordStart = () => ({
  type: FETCH_DEPARTMENT_RECORD.START
})

export const fetchDepartmentRecordSuccess = (department) => ({
  type: FETCH_DEPARTMENT_RECORD.SUCCESS,
  payload: department
})

export const fetchDepartmentRecordError = (error) => ({
  type: FETCH_DEPARTMENT_RECORD.ERROR,
  payload: { error }
})

export const fetchDepartmentRecord = (payload) => {
  return (dispatch) => {
    dispatch(fetchDepartmentRecordStart(payload))
    departmentService.fetchDepartmentRecord(payload).then((departmentData) => {
      if (!departmentData.isError) {
        dispatch(fetchDepartmentRecordSuccess(departmentData))
      } else {
        dispatch(fetchDepartmentRecordError(departmentData))
      }
    })
  }
}

export const createDepartmentRecordStart = () => ({
  type: CREATE_DEPARTMENT_RECORD.START
})

export const createDepartmentRecordSuccess = (newDepartment) => ({
  type: CREATE_DEPARTMENT_RECORD.SUCCESS,
  payload: newDepartment
})

export const createDepartmentRecordError = (error) => ({
  type: CREATE_DEPARTMENT_RECORD.ERROR,
  payload: { error }
})

export const createDepartmentRecord = (payload) => {
  return (dispatch) => {
    dispatch(createDepartmentRecordStart(payload))
    departmentService.createDepartmentRecord(payload).then((departmentData) => {
      if (!departmentData.isError) {
        dispatch(createDepartmentRecordSuccess(departmentData))
      } else {
        dispatch(createDepartmentRecordError(departmentData))
      }
    })
  }
}

export const updateDepartmentRecordStart = () => ({
  type: UPDATE_DEPARTMENT_RECORD.START
})

export const updateDepartmentRecordSuccess = (newDepartment) => ({
  type: UPDATE_DEPARTMENT_RECORD.SUCCESS,
  payload: newDepartment
})

export const updateDepartmentRecordError = (error) => ({
  type: UPDATE_DEPARTMENT_RECORD.ERROR,
  payload: { error }
})

export const updateDepartmentRecord = (payload) => {
  return (dispatch) => {
    dispatch(updateDepartmentRecordStart(payload))
    departmentService.updateDepartmentRecord(payload).then((departmentData) => {
      if (!departmentData.isError) {
        dispatch(updateDepartmentRecordSuccess(departmentData))
      } else {
        dispatch(updateDepartmentRecordError(departmentData))
      }
    })
  }
}

export const deleteDepartmentStart = () => ({
  type: DELETE_DEPARTMENT.START
})

export const deleteDepartmentSuccess = (departmentId) => ({
  type: DELETE_DEPARTMENT.SUCCESS,
  payload: { departmentId }
})

export const deleteDepartmentError = (error) => ({
  type: DELETE_DEPARTMENT.ERROR,
  payload: { error }
})

export const deleteDepartment = (departmentId) => {
  return (dispatch) => {
    dispatch(deleteDepartmentStart())
    departmentService.deleteDepartment(departmentId).then((departmentData) => {
      if (!departmentData.isError) {
        dispatch(deleteDepartmentSuccess(departmentData))
      } else {
        dispatch(deleteDepartmentError(departmentData))
      }
    })
  }
}
