import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import AddDepartmentComponent from '../../../components/department/addDepartment'
import { MzToast, TOAST_SEVERITY } from '../../../common/MzToast'
import { ProgressBar } from 'primereact/progressbar'
import {
  createDepartmentRecord,
  fetchDepartmentList,
  fetchDepartmentRecord,
  initDepartment,
  updateDepartmentRecord
} from '../../../redux/action/department'
import { fetchTenantList, initTenant } from '../../../redux/action/tenant'
import { USER_ROLE } from '../../../constant/actionTypes/role'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../../constant/urlConstant'
import { useTranslation } from 'react-i18next'

const AddDepartmentScreen = (props) => {
  const {
    isCreateDepartmentSuccess,
    isCreateDepartmentError,
    isDepartmentDetailSuccess,
    isDepartmentDetailError,
    isEditDepartmentSuccess,
    isEditDepartmentError,
    isEdit,
    isLoading,
    error,
    createDepartmentRecord,
    formFieldValueMap,
    isPageLevelError,
    user,
    userRole,
    tenantList,
    initDepartment,
    initTenant,
    fetchTenantList,
    fetchDepartmentRecord,
    updateDepartmentRecord
  } = props

  useEffect(() => {
    initDepartment()
    initTenant()
    if (userRole === USER_ROLE.SUPER_ADMIN) {
      fetchTenantList()
    } else {
      fetchDepartmentList(user?.tenant?.id ?? '')
    }
    // eslint-disable-next-line
  }, [userRole])

  const history = useNavigate()
  const { t } = useTranslation()

  const getToastProps = () => {
    if (isCreateDepartmentSuccess || isEditDepartmentSuccess) {
      const toastTitle = isEdit
        ? t('department.DepartmentUpdateSuccessfully')
        : t('department.DepartmentAddSuccessfully')
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }
    if (
      isCreateDepartmentError ||
      isDepartmentDetailError ||
      isEditDepartmentError
    ) {
      let toastTitle = error ? error?.error : t('department.DepartmentAddError')
      if (isEditDepartmentError) {
        toastTitle = error
          ? error?.error
          : t('department.DepartmentUpdateErrror')
      } else if (isDepartmentDetailError) {
        toastTitle =
          'Error while performing operation. Please refresh your browser'
      }
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }
  const navigatetoDepartment = () => {
    history(ROUTE_PATH.DEPARTMENT.HOME)
  }
  const addDepartmentProps = {
    createDepartmentRecord,
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    user,
    isEdit,
    userRole,
    isCreateDepartmentSuccess,
    isEditDepartmentSuccess,
    isDepartmentDetailSuccess,
    fetchDepartmentList,
    tenantList,
    fetchDepartmentRecord,
    updateDepartmentRecord,
    navigatetoDepartment
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <AddDepartmentComponent addDepartmentProps={addDepartmentProps} />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initDepartment: () => dispatch(initDepartment()),
    initTenant: () => dispatch(initTenant()),
    createDepartmentRecord: (payload) =>
      dispatch(createDepartmentRecord(payload)),
    fetchTenantList: (payload) => dispatch(fetchTenantList(payload)),
    fetchDepartmentList: (payload) => dispatch(fetchDepartmentList(payload)),
    fetchDepartmentRecord: (payload) =>
      dispatch(fetchDepartmentRecord(payload)),
    updateDepartmentRecord: (payload) =>
      dispatch(updateDepartmentRecord(payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    formFieldValueMap: selectFormFieldValueMap(state.departmentReducer),
    isPageLevelError: state.departmentReducer.isPageLevelError,
    isLoading: state.departmentReducer.isLoading,
    error: state.departmentReducer.error,
    isCreateDepartmentSuccess:
      state.departmentReducer.isCreateDepartmentSuccess,
    isCreateDepartmentError: state.departmentReducer.isCreateDepartmentError,
    isEditDepartmentSuccess: state.departmentReducer.isEditDepartmentSuccess,
    isEditDepartmentError: state.departmentReducer.isEditDepartmentError,
    isDepartmentDetailError: state.departmentReducer.isDepartmentDetailError,
    isDepartmentDetailSuccess:
      state.departmentReducer.isDepartmentDetailSuccess,
    user: state.userProfileReducer.user,
    userRole: state.loginReducer.userRole,
    tenantList: state.tenantReducer.tenantList
  }
}

const selectFormFieldValueMap = (departmentReducer) => {
  return departmentReducer.formFieldValueMap
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDepartmentScreen)
