import React, { useEffect, useState } from 'react'
import DepartmentComponent from '../../components/department'
import {
  deleteDepartment,
  fetchDepartmentList,
  initDepartment
} from '../../redux/action/department'
import { connect } from 'react-redux'
import { USER_ROLE } from '../../constant/actionTypes/role'
import { ProgressBar } from 'primereact/progressbar'
import departmentTableData from './data.json'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { fetchTenantList } from '../../redux/action/tenant'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import { useTranslation } from 'react-i18next'

const DepartmentScreen = (props) => {
  const {
    fetchDepartmentList,
    initDepartment,
    departmentsList,
    isLoading,
    isPageLevelError,
    userRole,
    error,
    user,
    tenantList,
    deleteDepartment,
    isDeleteDepartmentSuccess,
    isDeleteDepartmentError
  } = props
  useEffect(() => {
    initDepartment()

    const payload = {
      pageNumber: 0,
      pageSize: 10
    }

    if (userRole === USER_ROLE.SUPER_ADMIN) {
      fetchDepartmentList(payload)
      fetchTenantList()
    } else {
      const modifiedPayload = {
        ...payload,
        tenantId: user?.tenant?.id ?? ''
      }
      fetchDepartmentList(modifiedPayload)
    }
  }, [
    isDeleteDepartmentSuccess,
    fetchDepartmentList,
    initDepartment,
    user?.tenant?.id,
    userRole
  ])

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)
  const [selectedDepartment, setSelectedDepartment] = useState(null)
  const { t } = useTranslation()
  const history = useNavigate()
  const handleOnReadRecord = () => {}

  const handleOnDeleteRecord = (data) => {
    setSelectedDepartment(data)
    setIsDeleteDialogVisible(true)
  }
  const handleOnEditRecord = (data) => {
    history(`${ROUTE_PATH.DEPARTMENT.EDIT.replace(':id', data?.id)}`)
  }

  const handleOnCreatedRecord = () => {
    history(ROUTE_PATH.DEPARTMENT.ADD)
  }

  const confirmDelete = () => {
    if (selectedDepartment) {
      deleteDepartment(selectedDepartment.id)
      setIsDeleteDialogVisible(false)
      setSelectedDepartment(null)
    }
  }

  const cancelDelete = () => {
    setIsDeleteDialogVisible(false)
    setSelectedDepartment(null)
  }

  const filteredTableData = {
    ...departmentTableData,
    tableData: {
      ...departmentTableData.tableData,
      columns: Array.isArray(departmentTableData?.tableData?.columns)
        ? departmentTableData.tableData.columns.filter((column) => {
            if (
              column.dataKey === 'tenant.companyName' &&
              userRole === USER_ROLE.ADMIN
            ) {
              return false
            }
            return true
          })
        : []
    }
  }

  const departmentProps = {
    departmentsList,
    fetchDepartmentList,
    isPageLevelError,
    isLoading,
    departmentTableData: filteredTableData,
    userRole,
    tenantList,
    user,
    fetchTenantList,
    handleOnReadRecord,
    handleOnDeleteRecord,
    handleOnEditRecord,
    handleOnCreatedRecord
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }

  const getToastProps = () => {
    if (isDeleteDepartmentSuccess) {
      const toastTitle = t('department.toastDeleteSuccess')
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isDeleteDepartmentError) {
      let toastTitle = error ? error?.error : t('department.toastDeleteError')
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <DepartmentComponent departmentProps={departmentProps} />
      <Dialog
        header={`${t('department.deleteDialogHeader')} ${selectedDepartment?.active ? t('department.Deactivate') : t('department.Activate')}`}
        visible={isDeleteDialogVisible}
        onHide={cancelDelete}
        footer={
          <div>
            <Button
              severity={selectedDepartment?.active ? 'primary' : ''}
              onClick={confirmDelete}
            >
              {t('department.yes')}
            </Button>
            <Button severity="secondary" onClick={cancelDelete}>
              {t('department.no')}
            </Button>
          </div>
        }
      >
        <div className="text-warp">
          {t('department.deleteConfirmMessage')}
          {selectedDepartment?.active
            ? t('department.Deactivate')
            : t('department.Activate')}{' '}
          {t('department.deleteConfirmMessage1')}
          <span className="text-primary">{selectedDepartment?.name}</span>
        </div>
      </Dialog>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initDepartment: () => dispatch(initDepartment()),
    fetchDepartmentList: (payload) => dispatch(fetchDepartmentList(payload)),
    deleteDepartment: (payload) => dispatch(deleteDepartment(payload)),
    fetchTenantList: () => dispatch(fetchTenantList())
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isPageLevelError: state.departmentReducer.isPageLevelError,
    isLoading: state.departmentReducer.isLoading,
    error: state.departmentReducer.error,
    departmentsList: state.departmentReducer.departmentsList,
    userRole: state.loginReducer.userRole,
    tenantList: state.tenantReducer.tenantList,
    user: state.userProfileReducer.user,
    isDeleteDepartmentSuccess:
      state.departmentReducer.isDeleteDepartmentSuccess,
    isDeleteDepartmentError: state.departmentReducer.isDeleteDepartmentError
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentScreen)
