export const INIT_CATEGORY = 'INIT_CATEGORY'

export const FETCH_CATEGORY_LIST = {
  START: 'FETCH_CATEGORY_START',
  SUCCESS: 'FETCH_CATEGORY_SUCCESS',
  ERROR: 'FETCH_CATEGORY_ERROR'
}

export const FETCH_CATEGORY_RECORD = {
  START: 'FETCH_CATEGORY_RECORD_START',
  SUCCESS: 'FETCH_CATEGORY_RECORD_SUCCESS',
  ERROR: 'FETCH_CATEGORY_RECORD_ERROR'
}

export const CREATE_CATEGORY_RECORD = {
  START: 'CREATE_CATEGORY_RECORD_START',
  SUCCESS: 'CREATE_CATEGORY_RECORD_SUCCESS',
  ERROR: 'CREATE_CATEGORY_RECORD_ERROR'
}
export const UPDATE_CATEGORY_RECORD = {
  START: 'UPDATE_CATEGORY_RECORD_START',
  SUCCESS: 'UPDATE_CATEGORY_RECORD_SUCCESS',
  ERROR: 'UPDATE_CATEGORY_RECORD_ERROR'
}

export const DELETE_CATEGORY = {
  START: 'DELETE_CATEGORY_START',
  SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  ERROR: 'DELETE_CATEGORY_ERROR'
}
