import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { ProgressBar } from 'primereact/progressbar'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constant/urlConstant'
import {
  fetchOrganizationRecord,
  initOrganization
} from '../../redux/action/organization'
import OrganizationComponent from '../../components/organization'
import { fetchAmenitiesList } from '../../redux/action/amenities'
import { fetchProductsList } from '../../redux/action/products'

const OrgnizationScreen = (props) => {
  const {
    isLoading,
    isPageLevelError,
    userRole,
    user,
    selectedOrganization,
    initOrganization,
    fetchOrganizationRecord,
    amenitiesList,
    fetchAmenitiesList,
    fetchProductsList,
    productsList
  } = props

  const tenantId = user?.tenant?.id ?? ''

  const payload = useMemo(() => ({ tenantId }), [tenantId])

  useEffect(() => {
    initOrganization()
    fetchOrganizationRecord(user?.tenant?.id ?? '')
    fetchAmenitiesList(payload)
    fetchProductsList(payload)
    /// eslint-disable-next-line
  }, [
    user?.tenant?.id,
    fetchOrganizationRecord,
    fetchAmenitiesList,
    initOrganization,
    payload,
    fetchProductsList
  ])

  const history = useNavigate()
  const handleOnReadRecord = () => {}

  const handleOnEditRecord = (data) => {
    history(`${ROUTE_PATH.ORGANIZATION.EDIT.replace(':id', data?.id)}`)
  }

  const orgnizationProps = {
    isPageLevelError,
    isLoading,
    userRole,
    selectedOrganization,
    user,
    amenitiesList,
    handleOnReadRecord,
    handleOnEditRecord,
    productsList
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <OrganizationComponent orgnizationProps={orgnizationProps} />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initOrganization: () => dispatch(initOrganization()),
    fetchOrganizationRecord: (payload) =>
      dispatch(fetchOrganizationRecord(payload)),
    fetchAmenitiesList: (payload) => dispatch(fetchAmenitiesList(payload)),
    fetchProductsList: (payload) => dispatch(fetchProductsList(payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isPageLevelError: state.tenantReducer.isPageLevelError,
    isLoading: state.tenantReducer.isLoading,
    error: state.tenantReducer.error,
    userRole: state.loginReducer.userRole,
    user: state.userProfileReducer.user,
    selectedOrganization: state.organizationReducer.selectedOrganization,
    amenitiesList: state.amenitiesReducer.amenitiesList?.content ?? [],
    productsList: state.productsReducer.productList?.content ?? []
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgnizationScreen)
