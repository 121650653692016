import {
  INIT_PRODUCTS,
  FETCH_PRODUCTS_LIST,
  CREATE_PRODUCTS_RECORD,
  UPDATE_PRODUCTS_RECORD,
  FETCH_PRODUCTS_RECORD,
  DELETE_IMAGE,
  DELETE_PRODUCTS,
  GET_COUNT_PRODUCTS,
  GET_PRODUCTS_ENQUERY_COUNT,
  GET_PRODUCTS_INTREST_COUNT
} from '../../../constant/actionTypes/products'
import { productsService } from '../../../services'

export const initProducts = () => ({
  type: INIT_PRODUCTS
})

export const fetchProductsListStart = (payload) => ({
  type: FETCH_PRODUCTS_LIST.START,
  payload
})

export const fetchProductsListSuccess = (products) => ({
  type: FETCH_PRODUCTS_LIST.SUCCESS,
  payload: products
})

export const fetchProductsListError = (error) => ({
  type: FETCH_PRODUCTS_LIST.ERROR,
  payload: { error }
})

export const fetchProductsList = (payload) => {
  return (dispatch) => {
    dispatch(fetchProductsListStart(payload))
    productsService.fetchProductsList(payload).then((productsData) => {
      if (!productsData.isError) {
        dispatch(fetchProductsListSuccess(productsData))
      } else {
        dispatch(fetchProductsListError(productsData))
      }
    })
  }
}

export const createProductsRecordStart = () => ({
  type: CREATE_PRODUCTS_RECORD.START
})

export const createProductsRecordSuccess = (newProducts) => ({
  type: CREATE_PRODUCTS_RECORD.SUCCESS,
  payload: newProducts
})

export const createProductsRecordError = (error) => ({
  type: CREATE_PRODUCTS_RECORD.ERROR,
  payload: { error }
})

export const createProductsRecord = (payload) => {
  return (dispatch) => {
    dispatch(createProductsRecordStart(payload))
    productsService.createProductRecord(payload).then((productsData) => {
      if (!productsData.isError) {
        dispatch(createProductsRecordSuccess(productsData))
      } else {
        dispatch(createProductsRecordError(productsData))
      }
    })
  }
}

export const updateProductsRecordStart = (payload, id) => ({
  type: UPDATE_PRODUCTS_RECORD.START,
  payload: payload,
  id: id
})

export const updateProductsRecordSuccess = (productsData) => ({
  type: UPDATE_PRODUCTS_RECORD.SUCCESS,
  payload: productsData
})

export const updateProductsRecordError = (error) => ({
  type: UPDATE_PRODUCTS_RECORD.ERROR,
  payload: { error }
})

export const updateProductsRecord = (payload, id) => {
  return (dispatch) => {
    dispatch(updateProductsRecordStart(payload, id))
    productsService.updateProductRecord(payload, id).then((productsData) => {
      if (!productsData.isError) {
        dispatch(updateProductsRecordSuccess(productsData))
      } else {
        dispatch(updateProductsRecordError(productsData))
      }
    })
  }
}

export const fetchProductsRecordStart = () => ({
  type: FETCH_PRODUCTS_RECORD.START
})

export const fetchProductsRecordSuccess = (products) => ({
  type: FETCH_PRODUCTS_RECORD.SUCCESS,
  payload: products
})

export const fetchProductsRecordError = (error) => ({
  type: FETCH_PRODUCTS_RECORD.ERROR,
  payload: { error }
})

export const fetchProductsRecord = (payload) => {
  return (dispatch) => {
    dispatch(fetchProductsRecordStart(payload))
    productsService.fetchProductRecord(payload).then((productsData) => {
      if (!productsData.isError) {
        dispatch(fetchProductsRecordSuccess(productsData))
      } else {
        dispatch(fetchProductsRecordError(productsData))
      }
    })
  }
}

export const deleteImageStart = () => ({
  type: DELETE_IMAGE.START
})

export const deleteImageSuccess = (imageId) => ({
  type: DELETE_IMAGE.SUCCESS,
  payload: { imageId }
})

export const deleteImageError = (error) => ({
  type: DELETE_IMAGE.ERROR,
  payload: { error }
})

export const deleteImage = (imageId) => {
  return (dispatch) => {
    dispatch(deleteImageStart())
    productsService.deleteProductImage(imageId).then((imageData) => {
      if (!imageData.isError) {
        dispatch(deleteImageSuccess(imageData))
      } else {
        dispatch(deleteImageError(imageData))
      }
    })
  }
}

export const deleteProductsStart = () => ({
  type: DELETE_PRODUCTS.START
})

export const deleteProductsSuccess = (productsId) => ({
  type: DELETE_PRODUCTS.SUCCESS,
  payload: { productsId }
})

export const deleteProductsError = (error) => ({
  type: DELETE_PRODUCTS.ERROR,
  payload: { error }
})

export const deleteProduct = (productsId) => {
  return (dispatch) => {
    dispatch(deleteProductsStart())
    productsService.deleteProduct(productsId).then((productsData) => {
      if (!productsData.isError) {
        dispatch(deleteProductsSuccess(productsData))
      } else {
        dispatch(deleteProductsError(productsData))
      }
    })
  }
}

export const getProductsCountStart = (role, payload) => {
  return {
    type: GET_COUNT_PRODUCTS.START,
    role,
    payload
  }
}
export const getProductsCountSuccess = (payload) => {
  return {
    type: GET_COUNT_PRODUCTS.SUCCESS,
    payload
  }
}
export const getProductsCountError = (payload) => {
  return {
    type: GET_COUNT_PRODUCTS.ERROR,
    payload
  }
}

export const getProductsCount = (role, payload) => {
  return (dispatch) => {
    dispatch(getProductsCountStart(role, payload))
    productsService.getProductsCount(role, payload).then((productsData) => {      
      if (!productsData.isError) {
        dispatch(getProductsCountSuccess(productsData))
      } else {
        dispatch(getProductsCountError(productsData))
      }
    })
  }
}

export const getProductsEnqueryCountStart = (payload) => {
  return {
    type: GET_PRODUCTS_ENQUERY_COUNT.START,
    payload
  }
}
export const getProductsEnqueryCountSuccess = (payload) => {
  return {
    type: GET_PRODUCTS_ENQUERY_COUNT.SUCCESS,
    payload
  }
}
export const getProductsEnqueryCountError = (payload) => {
  return {
    type: GET_PRODUCTS_ENQUERY_COUNT.ERROR,
    payload
  }
}

export const getProductsEnqueryCount = (role,payload) => {
  return (dispatch) => {
    dispatch(getProductsEnqueryCountStart(payload))
    productsService.getProductsEnqueryCount(role,payload).then((productsData) => {
      if (!productsData.isError) {
        dispatch(getProductsEnqueryCountSuccess(productsData))
      } else {
        dispatch(getProductsEnqueryCountError(productsData))
      }
    })
  }
}


export const getProductsIntrestCountStart = (payload) => {
  return {
    type: GET_PRODUCTS_INTREST_COUNT.START,
    payload
  }
}
export const getProductsIntrestCountSuccess = (payload) => {
  return {
    type: GET_PRODUCTS_INTREST_COUNT.SUCCESS,
    payload
  }
}
export const getProductsIntrestCountError = (payload) => {
  return {
    type: GET_PRODUCTS_INTREST_COUNT.ERROR,
    payload
  }
}

export const getProductsIntrestCount = (role,payload) => {
  return (dispatch) => {
    dispatch(getProductsIntrestCountStart(payload))
    productsService.getProductsIntrestCount(role,payload).then((productsData) => {      
      if (!productsData.isError) {
        dispatch(getProductsIntrestCountSuccess(productsData))
      } else {
        dispatch(getProductsIntrestCountError(productsData))
      }
    })
  }
}



