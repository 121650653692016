export const FORM_FIELDS_NAME = {
  TENANT: {
    name: 'tenant',
    label: 'Amenities.form.Tenant.label',
    type: 'dropdown',
    rules: {
      required: 'Amenities.error.Tenant.required'
    },
    options: [
      { name: 'Select tenant', value: '' },
      { name: 'Red', value: '#FF0000' },
      { name: 'Green', value: '#00FF00' },
      { name: 'Blue', value: '#0000FF' }
    ],
    placeholder: 'Amenities.form.Tenant.placeholder'
  },

  NAME: {
    name: 'name',
    label: 'Amenities.form.name.label',
    type: 'text',
    placeholder: 'Amenities.form.name.placeholder',
    rules: {
      required: 'Amenities.error.name.required',
      minLength: {
        value: 3,
        message: 'Amenities.error.name.minLength'
      },
      maxLength: {
        value: 50,
        message: 'Amenities.error.name.maxLength'
      }
    }
  },
  DESCRIPTION: {
    name: 'description',
    label: 'Amenities.form.description.label',
    type: 'text',
    placeholder: 'Amenities.form.description.placeholder',
    rules: {
      maxLength: {
        value: 4000,
        message: 'Amenities.error.description.maxlength'
      }
    }
  },
  IMAGE: {
    name: 'image',
    label: 'Amenities.form.Images.label',
    type: 'file',
    placeholder: 'Upload a image'
  },
  ICON: {
    name: 'icon',
    label: 'Amenities.form.icon.label',
    type: 'file',
    placeholder: 'Upload a icon'
  },
  BROCHURE: {
    name: 'brochure',
    label: 'Amenities.form.brochure.label',
    type: 'file',
    placeholder: 'Upload a brochure'
  },
  VIDEO: {
    name: 'video',
    label: 'Amenities.form.Video.label',
    type: 'file',
    placeholder: 'Upload a Video'
  }
}
