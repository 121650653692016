import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import AddContactComponent from '../../../components/contact/addContact'
import { MzToast, TOAST_SEVERITY } from '../../../common/MzToast'
import { ProgressBar } from 'primereact/progressbar'
import {
  createContactRecord,
  fetchContactRecord,
  updateContactRecord,
  createContactRecordBysuperAdmin,
  updateContactRecordBysuperAdmin
} from '../../../redux/action/contact'
import { USER_ROLE } from '../../../constant/actionTypes/role'
import { fetchTenantList } from '../../../redux/action/tenant'
import { ROUTE_PATH } from '../../../constant/urlConstant'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const AddContactScreen = (props) => {
  const {
    isCreateContactSuccess,
    isCreateContactError,
    isContactDetailSuccess,
    isContactDetailError,
    isEditContactSuccess,
    isEditContactError,
    isEdit,
    isLoading,
    createContactRecord,
    formFieldValueMap,
    isPageLevelError,
    user,
    error,
    fetchContactRecord,
    updateContactRecord,
    fetchTenantList,
    userRole,
    tenantList,
    updateContactRecordBysuperAdmin,
    createContactRecordBysuperAdmin
  } = props

  useEffect(() => {
    if (userRole === USER_ROLE.SUPER_ADMIN) {
      fetchTenantList()
    }
    // eslint-disable-next-line
  }, [userRole])
  const getToastProps = () => {
    if (isCreateContactSuccess || isEditContactSuccess) {
      const toastTitle = isEdit
        ? t('contact.toastUpdateSuccess')
        : t('contact.toastCreateSuccess')
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isCreateContactError || isContactDetailError || isEditContactError) {
      let toastTitle = error ? error?.error : 'Error while Creating Contact'
      if (isEditContactError) {
        toastTitle = error ? error?.error : 'Error while updating Contact'
        toast.error(error)
      } else if (isContactDetailError) {
        toastTitle =
          'Error while performing operation. Please refresh your browser'
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  const { t } = useTranslation()
  const history = useNavigate()
  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }

  const navigatetoContact = () => {
    history(ROUTE_PATH.CONTACT.HOME)
  }
  const addContactProps = {
    createContactRecord,
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    isCreateContactSuccess,
    isEditContactSuccess,
    isContactDetailSuccess,
    user,
    isEdit,
    error,
    fetchContactRecord,
    updateContactRecord,
    navigatetoContact,
    userRole,
    tenantList,
    createContactRecordBysuperAdmin,
    updateContactRecordBysuperAdmin
  }
  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <AddContactComponent addContactProps={addContactProps} />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    createContactRecord: (payload) => dispatch(createContactRecord(payload)),
    updateContactRecord: (payload) => dispatch(updateContactRecord(payload)),
    fetchContactRecord: (payload) => dispatch(fetchContactRecord(payload)),
    fetchTenantList: (payload) => dispatch(fetchTenantList(payload)),
    createContactRecordBysuperAdmin: (payload) =>
      dispatch(createContactRecordBysuperAdmin(payload)),
    updateContactRecordBysuperAdmin: (payload) =>
      dispatch(updateContactRecordBysuperAdmin(payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    formFieldValueMap: selectFormFieldValueMap(state.contactReducer),
    isPageLevelError: state.contactReducer.isPageLevelError,
    isLoading: state.contactReducer.isLoading,
    error: state.contactReducer.error,
    isCreateContactSuccess: state.contactReducer.isCreateContactSuccess,
    isCreateContactError: state.contactReducer.isCreateContactError,
    isEditContactSuccess: state.contactReducer.isEditContactSuccess,
    isEditContactError: state.contactReducer.isEditContactError,
    isContactDetailSuccess: state.contactReducer.isContactDetailSuccess,
    isContactDetailError: state.contactReducer.isContactDetailError,
    user: state.userProfileReducer.user,
    tenantList: state.tenantReducer.tenantList,
    userRole: state.loginReducer.userRole
  }
}

const selectFormFieldValueMap = (contactReducer) => {
  return contactReducer.formFieldValueMap
}

export default connect(mapStateToProps, mapDispatchToProps)(AddContactScreen)
