import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import axios from 'axios'
import { Badge } from 'primereact/badge'
import { Card } from 'primereact/card'

import { Chart } from 'primereact/chart'
import { Button } from 'primereact/button'
import html2canvas from 'html2canvas'
import { USER_ROLE } from '../../constant/actionTypes/role'
import { ProgressSpinner } from 'primereact/progressspinner'
import { baseUrl } from '../../services/PostAPI'
import './index.css'
import '../../style/variables.css'
import { useTranslation } from 'react-i18next'

const DashboardComponent = (props) => {
  const {
    amenitiesCount,
    contactCount,
    employeeCount,
    user,
    productsCount,
    amenitiesIntrestCount,
    amenitiesEnqueryCount,
    productsEnqueryCount,
    productsIntrestCount,
    userRole
  } = props.dashbaordProps

  const { t, i18n } = useTranslation()

  const [userData, setUserData] = useState(null)
  const [barGraph, setBarGraph] = useState([])
  const [chartData, setChartData] = useState({})
  const [chartOptions, setChartOptions] = useState({})
  const [loading, setLoading] = useState(true)

  const pieChartRef1 = useRef(null)
  const lineChartRef = useRef(null)
  const pieChartRef2 = useRef(null)
  const barChartRef = useRef(null)

  const tenantId = user?.tenant?.id ?? ''
  const token = sessionStorage.getItem('token')

  const colors = useMemo(() => {
    const rootStyle = getComputedStyle(document.documentElement)
    return {
      productsCountColor: rootStyle
        .getPropertyValue('--secondary-gradient-color')
        .trim(),
      amenitiesCountColor: rootStyle
        .getPropertyValue('--primary-gradient-color')
        .trim(),
      ternaryColor: rootStyle
        .getPropertyValue('--ternary-gradient-color')
        .trim(),
      productsEnquiryColor: rootStyle
        .getPropertyValue('--primary-gradient-color')
        .trim(),
      amenitiesEnquiryCountColor: rootStyle
        .getPropertyValue('--secondary-gradient-color')
        .trim(),
      productsIntrestColor: rootStyle
        .getPropertyValue('--primary-gradient-color-light')
        .trim(),
      amenitiesIntrestColor: rootStyle
        .getPropertyValue('--secondary-gradient-color-light')
        .trim(),
      contactColor: rootStyle
        .getPropertyValue('--primary-gradient-color')
        .trim(),
      enquiryColor: rootStyle
        .getPropertyValue('--secondary-gradient-color')
        .trim(),
      likeColor: rootStyle.getPropertyValue('--ternary-gradient-color').trim(),
      textColor: rootStyle.getPropertyValue('--text-color').trim(),
      textColorSecondary: rootStyle
        .getPropertyValue('--text-color-secondary')
        .trim(),
      surfaceBorder: rootStyle.getPropertyValue('--surface-border').trim()
    }
  }, [])

  const data = useMemo(
    () => ({
      labels: [t('Products'), t('Services')],
      datasets: [
        {
          data: [productsCount || 0, amenitiesCount || 0],
          backgroundColor: [
            colors.productsCountColor,
            colors.amenitiesCountColor
          ],
          hoverBackgroundColor: [
            colors.productsCountColor,
            colors.amenitiesCountColor
          ]
        }
      ]
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      productsCount,
      amenitiesCount,
      colors.productsCountColor,
      colors.amenitiesCountColor,
      t,
      i18n?.language
    ]
  )

  const options = useMemo(
    () => ({
      responsive: true,
      plugins: {
        legend: { position: 'top' },
        tooltip: {
          callbacks: {
            label: (tooltipItem) => `${tooltipItem.label}: ${tooltipItem.raw}`
          }
        }
      }
    }),
    []
  )

  const enquiryData = useMemo(
    () => ({
      labels: [
        t('enquiry.Products Enquiry'),
        t('interests.Products Interest'),
        t('enquiry.Services Enquiry'),
        t('interests.Services Interest')
      ],
      datasets: [
        {
          data: [
            productsEnqueryCount ?? 0,
            productsIntrestCount ?? 0,
            amenitiesEnqueryCount ?? 0,
            amenitiesIntrestCount ?? 0
          ],
          backgroundColor: [
            colors.amenitiesEnquiryCountColor,
            colors.amenitiesIntrestColor,
            colors.productsEnquiryColor,
            colors.productsIntrestColor
          ],
          hoverBackgroundColor: [
            colors.amenitiesEnquiryCountColor,
            colors.amenitiesIntrestColor,
            colors.productsEnquiryColor,
            colors.productsIntrestColor
          ]
        }
      ]
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      productsEnqueryCount,
      productsIntrestCount,
      amenitiesEnqueryCount,
      amenitiesIntrestCount,
      colors.amenitiesEnquiryCountColor,
      colors.amenitiesIntrestColor,
      colors.productsEnquiryColor,
      colors.productsIntrestColor,
      t,
      i18n
    ]
  )

  const enquiryOptions = useMemo(
    () => ({
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          labels: { font: { size: 8 } }
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem) => `${tooltipItem.label}: ${tooltipItem.raw}`
          }
        }
      }
    }),
    []
  )

  const chartBarData = useMemo(() => {
    const labels = (barGraph || []).map((user) =>
      userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.USER
        ? `${user.firstName} ${user.lastName}`
        : user.tenantName
    )

    const like = (barGraph || []).map((user) =>
      userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.USER
        ? user.productLikeCount + user.serviceLikeCount
        : user.productLikeCounts + user.serviceLikeCounts
    )

    const employees = (barGraph || []).map((user) =>
      userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.USER
        ? user.contactCount || 0
        : user.userCount || 0
    )

    return {
      labels,
      datasets: [
        {
          label:
            userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.USER
              ? t('Contacts')
              : t('Users'),
          data: employees,
          backgroundColor: colors.contactColor
        },
        {
          label: t('Enquiry'),
          data: (barGraph || []).map((user) => user.inquiryCount || 0),
          backgroundColor: colors.enquiryColor
        },
        {
          label: t('Like'),
          data: like,
          backgroundColor: colors.likeColor
        }
      ]
    }
    // eslint-disable-next-line
  }, [
    barGraph,
    userRole,
    colors.contactColor,
    colors.enquiryColor,
    colors.likeColor,
    t,
    i18n
  ])

  const chartBarOptions = useMemo(
    () => ({
      responsive: true,
      plugins: {
        legend: { position: 'top' },
        tooltip: {
          callbacks: {
            label: (tooltipItem) =>
              `${tooltipItem.dataset.label}: ${tooltipItem.raw}`
          }
        }
      },
      scales: {
        x: {
          ticks: { font: { weight: 500 } },
          beginAtZero: true,
          grid: { display: false, drawBorder: false }
        },
        y: {
          beginAtZero: true,
          grid: { borderDash: [2, 2] },
          ticks: { stepSize: 5 }
        }
      }
    }),
    []
  )

  const fetchData = async () => {
    if (!token) {
      console.error('No token found')
      return
    }
    try {
      const url =
        userRole === USER_ROLE.SUPER_ADMIN
          ? `${baseUrl}/dashboard/tenant-count`
          : `${baseUrl}/dashboard/user-counts/${tenantId}`

      const tenantResponse = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      setBarGraph(tenantResponse.data.slice(0, 5))
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole, tenantId])

  useEffect(() => {
    const data = {
      labels: [
        t('days.monday'),
        t('days.tuesday'),
        t('days.wednesday'),
        t('days.thursday'),
        t('days.friday'),
        t('days.saturday'),
        t('days.sunday')
      ],
      datasets: [
        {
          label: t('Enquiry'),
          data: [20, 20, 27, 44, 50, 52, 54],
          fill: false,
          borderColor: colors.productsEnquiryColor,
          tension: 0.4
        },
        {
          label: t('interests.Interests'),
          data: [28, 44, 52, 52, 66, 87, 90],
          fill: false,
          borderColor: colors.amenitiesEnquiryCountColor,
          tension: 0.4
        }
      ]
    }

    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: colors.textColor
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: colors.textColorSecondary
          },
          grid: {
            color: colors.surfaceBorder
          }
        },
        y: {
          ticks: {
            color: colors.textColorSecondary
          },
          grid: {
            color: colors.surfaceBorder
          }
        }
      }
    }

    setChartData(data)
    setChartOptions(options)
    // eslint-disable-next-line
  }, [t, i18n])

  useEffect(() => {
    const fetchUserData = async () => {
      if (!token) return

      const url = `${baseUrl}/analytics/?userId=${user?.id}&summary=true&details=true${userRole !== USER_ROLE.SUPER_ADMIN ? `&tenantId=${tenantId}` : ''}`
      try {
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        setUserData(response.data)
      } catch (error) {
        console.error('Error fetching user data:', error)
      }
    }
    fetchUserData()
  }, [user, tenantId, token, userRole])

  const handleDownload = useCallback((sectionRef, filename) => {
    if (sectionRef.current) {
      const sectionElement = sectionRef.current
      const buttons = sectionElement.querySelectorAll('.download-button')
      buttons.forEach((button) => (button.style.display = 'none'))

      html2canvas(sectionElement)
        .then((canvas) => {
          const link = document.createElement('a')
          link.href = canvas.toDataURL('image/png')
          link.download = `${filename}.png`
          link.click()
          buttons.forEach((button) => (button.style.display = ''))
        })
        .catch((error) => {
          console.error('Error generating image:', error)
          buttons.forEach((button) => (button.style.display = ''))
        })
    } else {
      console.error('Section reference is null.')
    }
  }, [])

  return (
    <div>
      <div className="grid ">
        <div className="col-12 md:col-6 lg:col-4 ">
          <div className="surface-0 shadow-2 p-2 md:p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-2 md:mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">
                  {t('Employee')}
                </span>
                <div className="text-900 font-medium text-xl">
                  {employeeCount}
                </div>
              </div>
              <div
                className="primary flex align-items-center justify-content-center border-round"
                style={{ width: '2.5rem', height: '2.5rem' }}
              >
                <i className="pi pi-user text-white text-xl"></i>
              </div>
            </div>
            {/* <span className="text-green-500 font-medium">24 new </span>
            <span className="text-500">since last visit</span> */}
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-4 ">
          <div className="surface-0 shadow-2 p-2 md:p-3  border-1 border-50 border-round">
            <div className="flex justify-content-between mb-2 md:mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">
                  {t('Services')}
                </span>
                <div className="text-900 font-medium text-xl">
                  {amenitiesCount}
                </div>
              </div>
              <div
                className="flex align-items-center justify-content-center primary border-round"
                style={{ width: '2.5rem', height: '2.5rem' }}
              >
                <i className="pi pi-info text-white text-xl"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div className="surface-0 shadow-2 p-2 md:p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-2 md:mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">
                  {t('Contacts')}
                </span>
                <div className="text-900 font-medium text-xl">
                  {contactCount}
                </div>
              </div>
              <div
                className="flex align-items-center justify-content-center primary border-round"
                style={{ width: '2.5rem', height: '2.5rem' }}
              >
                <i className="pi pi-address-book text-white text-xl"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" border-1 border-200 border-round-md  p-2 bg-white mt-2 col-12 md:col-12 lg:col-12">
        <div className="grid grid-nogutter justify-content-center align-items-center">
          <div className="col-12 md:col-3 p-2" ref={pieChartRef1}>
            <div>
              <Button
                icon="pi pi-download"
                text
                className="text-xs no-outline align-self-start border-0 download-button"
                onClick={() => handleDownload(pieChartRef1, 'Pie Chart')}
              />
              <Chart type="pie" data={data} options={options} />
            </div>
          </div>
          <div className="col-12 md:col-6 p-2" ref={lineChartRef}>
            <div>
              <Button
                icon="pi pi-download"
                text
                className="text-xs no-outline align-self-start border-0 download-button"
                onClick={() => handleDownload(lineChartRef, 'Line Chart')}
              />
              <Chart
                type="line"
                data={chartData}
                options={chartOptions}
                className="h-20rem"
              />
            </div>
          </div>
          <div className="col-12 md:col-3 p-2" ref={pieChartRef2}>
            <div>
              <Button
                icon="pi pi-download"
                text
                className="text-xs no-outline align-self-start border-0 download-button"
                onClick={() => handleDownload(pieChartRef2, 'Pie Chart')}
              />
              <Chart type="pie" data={enquiryData} options={enquiryOptions} />
            </div>
          </div>
          {loading ? (
            <div className="card flex justify-content-center">
              <ProgressSpinner />
            </div>
          ) : (
            !loading &&
            barGraph.length > 0 && (
              <div className="col-12 w-full p-2 mt-5" ref={barChartRef}>
                <div>
                  <Button
                    icon="pi pi-download"
                    text
                    className="text-xs no-outline align-self-end border-0 download-button"
                    onClick={() => handleDownload(barChartRef, 'Bar Chart')}
                  />
                  <Chart
                    type="bar"
                    data={chartBarData}
                    options={chartBarOptions}
                    className="h-full w-full"
                  />
                </div>
              </div>
            )
          )}
        </div>
      </div>

      <div className=" p-2 bg-white mt-2 col-12  border-1 border-200 border-round-md ">
        {user && userData && (
          <Card className="shadow-none border-0">
            <div className=" grid p-align-center p-justify-between">
              <div className="col-4 text-center ">
                <i className="pi pi-download"></i>
                <Badge
                  value={userData?.cardDownloadCount ?? 0}
                  className="quaternary p-mt-2 "
                />
                <p className="text-sm">{t('DashboardCard.Downloads')}</p>
              </div>
              <div className="col-4 text-center">
                <i className="pi pi-refresh"></i>
                <Badge
                  value={userData?.cardExchangesCount ?? 0}
                  className="secondary p-mt-2 "
                />
                <p className="text-sm">{t('DashboardCard.Exchanges')}</p>
              </div>
              <div className="col-4 text-center">
                <i className="pi pi-eye"></i>
                <Badge value={userData?.cardViewCount ?? 0} />
                <p className="text-sm">{t('DashboardCard.Views')}</p>
              </div>
            </div>
          </Card>
        )}
      </div>
    </div>
  )
}

export default DashboardComponent
