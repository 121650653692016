import React, { useEffect, useState } from 'react'
import MzTable from '../../common/MzTable'
import ErrorPage from '../../common/Error'
import { BreadCrumb } from 'primereact/breadcrumb'
import { AutoComplete } from 'primereact/autocomplete'
import { USER_ROLE } from '../../constant/actionTypes/role'
import { Button } from 'primereact/button'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const ProductsComponent = (props) => {
  const {
    productsList,
    tenantList,
    isPageLevelError,
    amenitiesTableData,
    handleOnReadRecord,
    handleOnDeleteProductsRecord,
    handleOnEditProductsRecord,
    isLoading,
    userRole,
    fetchProductsList,
    handleOnCreatedProductsRecord,
    user
  } = props.productsProps

  const [value, setValue] = useState('')
  const [filteredTenants, setFilteredTenants] = useState([])

  const [paginationInfo, setPaginationInfo] = useState({
    pageSize: 10,
    pageNumber: 0,
    totalRecords: 0,
    totalPages: 0
  })
  const { t } = useTranslation()
  const shouldRenderFullPageError = () => isPageLevelError
  const shouldRenderUserTransactionList = () =>
    amenitiesTableData?.tableData?.columns?.length > 0
  const shouldRenderNotFoundView = () =>
    !shouldRenderFullPageError && !shouldRenderUserTransactionList
  const history = useNavigate()
  const filters = {
    global: { value: null },
    userName: { filterKey: 'username', value: null },
    email: { filterKey: 'email', value: null }
  }

  const loadLazyData = (event) => {
    const { pageNumber, pageSize } = event

    setPaginationInfo({
      pageNumber,
      pageSize
    })
    if (userRole === USER_ROLE.SUPER_ADMIN) {
      fetchProductsList({
        pageNumber,
        pageSize
      })
    } else {
      fetchProductsList({
        pageNumber,
        pageSize,
        tenantId: user?.tenant?.id ?? ''
      })
    }
  }

  const productsTableProps = {
    value: productsList?.content ?? [],
    loading: isLoading,
    columns: amenitiesTableData?.tableData?.columns,
    paginationInfo: {
      ...paginationInfo,
      totalRecords: productsList?.totalElements ?? 0
    },
    screenPermission: amenitiesTableData?.tableData?.screenPermission,
    loadLazyData,
    emptyMessage: t('product.noRecord'),
    filters,
    sortField: null,
    showMoreAction: false,
    onReadRecord: handleOnReadRecord,
    onEditRecord: handleOnEditProductsRecord,
    onDeleteRecord: handleOnDeleteProductsRecord,
    scrollable: true,
    responsiveLayout: 'scroll',
    paginator: true
  }

  useEffect(() => {
    if (tenantList) {
      setFilteredTenants(
        tenantList.map((tenant) => ({
          label: tenant.companyName,
          id: tenant.id
        }))
      )
    }
  }, [tenantList])

  const searchTenants = (event) => {
    let filtered = tenantList.filter((tenant) =>
      tenant.companyName.toLowerCase().includes(event.query.toLowerCase())
    )
    setFilteredTenants(
      filtered.map((tenant) => ({
        label: tenant.companyName,
        id: tenant.id
      }))
    )
  }

  const handleTenantChange = (e) => {
    e.preventDefault()
    const selectedTenantId = e.value
    setValue(selectedTenantId)
    fetchProductsList({ tenantId: selectedTenantId.id })
  }

  const handleToCreateProducts = () => {
    handleOnCreatedProductsRecord()
  }

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      {
        label: t('product.Products'),
        command: () => history(ROUTE_PATH.PRODUCTS.HOME)
      }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }

    return (
      <div className="flex align-items-center border-1 border-200 border-round bg-white w-full py-1 ">
        <div className="flex-grow-1">
          <BreadCrumb
            model={breadCrumbItems}
            home={home}
            className="border-0"
          />
        </div>
        <div className="flex flex-grow-1 justify-content-end align-items-center gap-3 ">
          {userRole === USER_ROLE.SUPER_ADMIN && (
            <div className="flex-grow-1" style={{ maxWidth: '210px' }}>
              <div>
                <AutoComplete
                  value={value}
                  suggestions={filteredTenants}
                  completeMethod={searchTenants}
                  field="label"
                  onChange={handleTenantChange}
                  dropdown
                  placeholder={t('product.translation')}
                  className="w-full"
                />
              </div>
            </div>
          )}
          <div>
            <Button
              onClick={() => handleToCreateProducts()}
              className="p-button mr-2"
            >
              {t('product.Add Product')}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div>
        {renderPageHeaderSection()}
        {shouldRenderFullPageError() && (
          <div>
            <ErrorPage />
          </div>
        )}
        <div className="border-1 border-200 border-round mt-3 p-2 bg-white">
          <MzTable {...productsTableProps} />
        </div>
        {shouldRenderNotFoundView() && <div>No record to show</div>}
      </div>
    </div>
  )
}

export default ProductsComponent
