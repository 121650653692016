import axios from 'axios'
import { API_PATH } from '../../constant/urlConstant'
import { handleAPIError } from '../common/errorHandler'
import { baseUrl } from '../PostAPI'
import { getDecoratedUrl } from '../common/urlService'

export const fetchTenantList = async () => {
  const url = `${baseUrl}${API_PATH.TENANT.FETCH}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.data.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}

export const fetchPagableTenantList = async (payload) => {
  const { pageSize, pageNumber } = payload ?? {}

  const url = `${baseUrl}${API_PATH.TENANT.FETCHPAGE}`

  let finalUrl = getDecoratedUrl({
    url,
    payload: { pageSize, pageNumber }
  })

  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(finalUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.data.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }

    return result?.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}

export const fetchTenantRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.TENANT.FETCH}/${payload}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}

export const createTenantRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.TENANT.ADD}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    if (result?.data?.error || result.status !== 201) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error.response.data.detail)
  }
}

export const updateTenantRecord = async (payload, id) => {
  const url = `${baseUrl}${API_PATH.TENANT.EDIT}/${id}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.put(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error.response.data.detail)
  }
}

export const deleteTenant = async (tenantId) => {
  const url = `${baseUrl}${API_PATH.TENANT.DELETE}/${tenantId}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 204) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error.response.data.detail)
  }
}
