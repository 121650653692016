import {
  INIT_EMPLOYEE,
  FETCH_EMPLOYEE_LIST,
  FETCH_EMPLOYEE_RECORD,
  CREATE_EMPLOYEE_RECORD,
  DELETE_EMPLOYEE,
  UPDATE_EMPLOYEE_RECORD,
  GET_EMPLOYEE_COUNT
} from '../../../constant/actionTypes/employee'

const formFieldValueMap = {
  tenant: '',
  username: '',
  email: '',
  password: '',
  authorities: '',
  department: '',
  profilePicture: null
}

const initialState = {
  employeesList:null,
  employeeCount: 0,
  selectedEmployee: null,
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateEmployeeSuccess: false,
  isEditEmployeeSuccess: false,
  isEmployeeDetailSuccess: false,
  isDeleteEmployeeSuccess: false,
  isDeleteEmployeeError: false,
  isEmployeeDetailError: false,
  isCreateEmployeeError: false,
  isEditEmployeeError: false
}

const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_EMPLOYEE:
      return {
        ...state,
        formFieldValueMap: null,
        employeesList: null,
        selectedEmployee: null,
        isLoading: false,
        error: null
      }
    case FETCH_EMPLOYEE_LIST.START:
    case FETCH_EMPLOYEE_RECORD.START:
    case CREATE_EMPLOYEE_RECORD.START:
    case DELETE_EMPLOYEE.START:
    case UPDATE_EMPLOYEE_RECORD.START:
    case GET_EMPLOYEE_COUNT.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreateEmployeeSuccess: false,
        isEditEmployeeSuccess: false,
        isEmployeeDetailSuccess: false,
        isDeleteEmployeeSuccess: false,
        isDeleteEmployeeError: false,
        isEmployeeDetailError: false,
        isCreateEmployeeError: false,
        isEditEmployeeError: false
      }
    case FETCH_EMPLOYEE_LIST.SUCCESS:
      return {
        ...state,
        employeesList: action.payload,
        isLoading: false,
        error: null
      }
    case GET_EMPLOYEE_COUNT.SUCCESS: {
      return {
        ...state,
        isLoading: true,
        error: null,
        employeeCount: action.payload
      }
    }
    case GET_EMPLOYEE_COUNT.ERROR: {
      return {
        ...state,
        isLoading: true,
        error: null,
        employeeCount: 0
      }
    }
    case FETCH_EMPLOYEE_RECORD.SUCCESS:
      return {
        ...state,
        selectedEmployee: action.payload,
        formFieldValueMap: {
          id: action?.payload?.id ?? '',
          tenant: action?.payload?.tenant?.id ?? '',
          username: action?.payload?.username ?? '',
          email: action?.payload?.email ?? '',
          password: action?.payload?.password ?? '',
          authorities: action?.payload?.authorities[0]?.name ?? '',
          department: action?.payload?.department?.id ?? '',
          profilePicture: action?.payload?.profilePicture ?? '',
          firstName: action?.payload?.firstName ?? '',
          lastName: action?.payload?.lastName ?? '',
          mobileNumber: action?.payload?.mobileNumber ?? '',
          active: action?.payload?.active ?? true
        },
        isLoading: false,
        error: null,
        isEmployeeDetailSuccess: true
      }
    case CREATE_EMPLOYEE_RECORD.SUCCESS:
      return {
        ...state,
        employeesList: {
          ...state.employeesList,
          content: state?.employeesList?.content
            ? [...state.employeesList.content, action.payload] 
            : [action.payload] 
        },
        isLoading: false,
        error: null,
        isCreateEmployeeSuccess: true
      }
    case UPDATE_EMPLOYEE_RECORD.SUCCESS:
      return {
        ...state,
        employeesList: {
          ...state.employeesList,
          content: state?.employeesList?.content?.map((employee) =>
            employee.id === action.payload.id ? action?.payload : employee
          )
        },
        isLoading: false,
        error: null,
        isEditEmployeeSuccess: true
      }
    case DELETE_EMPLOYEE.SUCCESS:
      return {
        ...state,
        ...state.employeesList,
        content: state.employeesList?.content?.filter(
          (employee) => employee.id !== action?.payload?.employeeId
        ),
        isLoading: false,
        error: null,
        isDeleteEmployeeSuccess: true
      }
    case FETCH_EMPLOYEE_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error
      }
    case FETCH_EMPLOYEE_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error,
        isEmployeeDetailError: true
      }
    case CREATE_EMPLOYEE_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isCreateEmployeeError: true
      }

    case UPDATE_EMPLOYEE_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isEditEmployeeError: true
      }
    case DELETE_EMPLOYEE.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error,
        isDeleteEmployeeError: true
      }

    default:
      return state
  }
}

export default employeeReducer
