import {
  INIT_USER_PROFILE,
  FETCH_USER_PROFILE_RECORD
} from '../../../constant/actionTypes/userProfile'

const getInitialStateFromLocalStorage = () => {
  const userProfile = sessionStorage.getItem('userProfile')
  const parsedUserProfile = userProfile ? JSON.parse(userProfile) : null

  return {
    user: parsedUserProfile,
    error: '',
    isLoading: false,
    isPageLevelError: false,
    isLoadingPage: false,
    isUserDetialsSuccess: false,
    isUserDetialsError: false
  }
}

const initialState = getInitialStateFromLocalStorage()

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_USER_PROFILE:
      return {
        ...state
      }
    case FETCH_USER_PROFILE_RECORD.START:
      return {
        ...state,
        isLoading: true
      }
    case FETCH_USER_PROFILE_RECORD.SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        isUserDetialsSuccess: true,
        isUserDetialsError: false
      }
    case FETCH_USER_PROFILE_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        isUserDetialsSuccess: false,
        isUserDetialsError: true,
        error: action.payload
      }
    default:
      return state
  }
}

export default userProfileReducer
