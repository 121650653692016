import React, { useEffect, useState } from 'react'
import MzTable from '../../common/MzTable'
import ErrorPage from '../../common/Error'
import { BreadCrumb } from 'primereact/breadcrumb'
import { AutoComplete } from 'primereact/autocomplete'
import { USER_ROLE } from '../../constant/actionTypes/role'
import { Button } from 'primereact/button'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const EmployeeComponent = (props) => {
  const [paginationInfo, setPaginationInfo] = useState({
    pageSize: 10,
    pageNumber: 0,
    totalRecords: 0,
    totalPages: 0
  })

  const [value, setValue] = useState('')
  const [filteredTenants, setFilteredTenants] = useState([])
  const { t } = useTranslation()

  const {
    employeesList,
    tenantList,
    isPageLevelError,
    employeeTableData,
    handleOnReadRecord,
    handleOnDeleteRecord,
    handleOnEditRecord,
    isLoading,
    user,
    userRole,
    fetchEmployeeList,
    handleOnCreatedRecord
  } = props.employeeProps

  const history = useNavigate()
  useEffect(() => {
    loadLazyData({
      pageNumber: paginationInfo.pageNumber,
      pageSize: paginationInfo.pageSize
    })
    // eslint-disable-next-line
  }, [paginationInfo.pageNumber, paginationInfo.pageSize])
  const loadLazyData = (event) => {
    const { pageNumber, pageSize } = event
    setPaginationInfo({
      pageNumber,
      pageSize
    })
    if (userRole === USER_ROLE.SUPER_ADMIN) {
      fetchEmployeeList({
        pageNumber,
        pageSize
      })
    } else {
      fetchEmployeeList({
        pageNumber,
        pageSize,
        tenantId: user?.tenant?.id ?? ''
      })
    }
  }

  const shouldRenderFullPageError = () => isPageLevelError
  const shouldRenderUserTransactionList = () =>
    employeeTableData?.tableData?.columns?.length > 0
  const shouldRenderNotFoundView = () =>
    !shouldRenderFullPageError && !shouldRenderUserTransactionList

  const filters = {
    global: { value: null },
    userName: { filterKey: 'username', value: null },
    email: { filterKey: 'email', value: null }
  }

  const tableProps = {
    value: employeesList?.content ?? [],
    loading: isLoading,
    columns: employeeTableData?.tableData?.columns,
    paginationInfo: {
      ...paginationInfo,
      totalRecords: employeesList?.totalElements ?? 0
    },
    screenPermission: employeeTableData?.tableData?.screenPermission,
    loadLazyData,
    emptyMessage: t('employee.noRecord'),
    filters,
    sortField: null,
    showMoreAction: false,
    onReadRecord: handleOnReadRecord,
    onEditRecord: handleOnEditRecord,
    onDeleteRecord: handleOnDeleteRecord,
    scrollable: true,
    responsiveLayout: 'scroll',
    paginator: true
  }

  useEffect(() => {
    if (tenantList) {
      setFilteredTenants(
        tenantList.map((tenant) => ({
          label: tenant.companyName,
          id: tenant.id
        }))
      )
    }
  }, [tenantList])

  const searchTenants = (event) => {
    let filtered = tenantList.filter((tenant) =>
      tenant.companyName.toLowerCase().includes(event.query.toLowerCase())
    )
    setFilteredTenants(
      filtered.map((tenant) => ({
        label: tenant.companyName,
        id: tenant.id
      }))
    )
  }

  const handleTenantChange = (e) => {
    e.preventDefault()
    const selectedTenantId = e.value
    setValue(selectedTenantId)
    fetchEmployeeList({ tenantId: selectedTenantId.id })
  }

  const handleToCreateEmployee = () => {
    handleOnCreatedRecord()
  }

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      {
        label: t('employee.employeeItem'),
        command: () => history(ROUTE_PATH.EMPOLYEE.HOME)
      }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }

    return (
      <div className="flex align-items-center border-1 border-200 border-round bg-white w-full py-1 ">
        <div className="flex-grow-1">
          <BreadCrumb
            model={breadCrumbItems}
            home={home}
            className="border-0 text-sm"
          />
        </div>
        <div className="flex flex-grow-1 justify-content-end align-items-center gap-3 ">
          {userRole === USER_ROLE.SUPER_ADMIN && (
            <div className="flex-grow-1" style={{ maxWidth: '210px' }}>
              <AutoComplete
                value={value}
                suggestions={filteredTenants}
                completeMethod={searchTenants}
                field="label"
                onChange={handleTenantChange}
                dropdown
                placeholder={t('employee.searchPlaceholder')}
                className="w-full"
              />
            </div>
          )}
          <div>
            <Button
              onClick={() => handleToCreateEmployee()}
              className="p-button mr-2"
            >
              {t('employee.addEmployee')}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {renderPageHeaderSection()}
      {shouldRenderFullPageError() && (
        <div>
          <ErrorPage />
        </div>
      )}
      {shouldRenderUserTransactionList() && (
        <div className="border-1 border-200 border-round mt-3 p-2 bg-white">
          <MzTable {...tableProps} />
        </div>
      )}
      {shouldRenderNotFoundView() && <div>No record to show</div>}
    </div>
  )
}

export default EmployeeComponent
