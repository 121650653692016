export const INIT_EMPLOYEE = 'INIT_EMPLOYEE'

export const FETCH_EMPLOYEE_LIST = {
  START: 'FETCH_EMPLOYEE_START',
  SUCCESS: 'FETCH_EMPLOYEE_SUCCESS',
  ERROR: 'FETCH_EMPLOYEE_ERROR'
}

export const FETCH_EMPLOYEE_RECORD = {
  START: 'FETCH_EMPLOYEE_RECORD_START',
  SUCCESS: 'FETCH_EMPLOYEE_RECORD_SUCCESS',
  ERROR: 'FETCH_EMPLOYEE_RECORD_ERROR'
}

export const CREATE_EMPLOYEE_RECORD = {
  START: 'CREATE_EMPLOYEE_RECORD_START',
  SUCCESS: 'CREATE_EMPLOYEE_RECORD_SUCCESS',
  ERROR: 'CREATE_EMPLOYEE_RECORD_ERROR'
}

export const UPDATE_EMPLOYEE_RECORD = {
  START: 'UPDATE_EMPLOYEE_RECORD_START',
  SUCCESS: 'UPDATE_EMPLOYEE_RECORD_SUCCESS',
  ERROR: 'UPDATE_EMPLOYEE_RECORD_ERROR'
}

export const DELETE_EMPLOYEE = {
  START: 'DELETE_EMPLOYEE_START',
  SUCCESS: 'DELETE_EMPLOYEE_SUCCESS',
  ERROR: 'DELETE_EMPLOYEE_ERROR'
}

export const GET_EMPLOYEE_COUNT = {
  START: 'GET_EMPLOYEE_COUNT_START',
  SUCCESS: 'GET_EMPLOYEE_COUNT_SUCCESS',
  ERROR: 'GET_EMPLOYEE_COUNT_ERROR'
}
