import axios from 'axios'
import { API_PATH } from '../../constant/urlConstant'
import { baseUrl, postApiLoginAsyn } from '../PostAPI'
import { handleAPIError } from '../common/errorHandler'

export const postLogin = async (payload) => {
  const url = `${API_PATH.AUTH.LOGIN}`
  try {
    let result = await postApiLoginAsyn(url, payload)
    if (result?.error) {
      return handleAPIError(result.message)
    }
    return result
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const postRegister = async (payload) => {
  const url = `${baseUrl}${API_PATH.AUTH.REGISTER}`
  try {
    const result = await axios.post(url, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result?.data?.message)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const postLogout = async () => {
  try {
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('userProfile')
    sessionStorage.removeItem('userRole')
    sessionStorage.removeItem('isLoggedIn')
    let result = 'Logout Successfully'
    return result
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}
