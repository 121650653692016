import { configureStore } from '@reduxjs/toolkit'
import rootReducer from '../Reducers/rootReducers'
import { logout } from '../action/auth/login'
import { toast } from 'react-toastify'

const inactivityTimeout = 30 * 60 * 1000

let inactivityTimer = undefined

const isUserLoggedIn = () => {
  return sessionStorage.getItem('isLoggedIn') === 'true'
}

const resetInactivityTimer = (store) => {
  if (inactivityTimer) {
    clearTimeout(inactivityTimer)
  }
  inactivityTimer = setTimeout(() => {
    if (isUserLoggedIn()) {
      store.dispatch(logout())
      toast.error('Session expired due to inactivity. Please log in again.')
    }
  }, inactivityTimeout)
}

const inactivitymiddleware = (store) => (next) => (action) => {
  if (isUserLoggedIn()) {
    resetInactivityTimer(store)
  }
  return next(action)
}

const logger = (store) => (next) => (action) => {
  let result = next(action)
  return result
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(logger, inactivitymiddleware)
})
export default store
