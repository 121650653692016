import axios from 'axios'
import { API_PATH } from '../../constant/urlConstant'
import { handleAPIError } from '../common/errorHandler'
import { baseUrl } from '../PostAPI'
import { getDecoratedUrl } from '../common/urlService'

export const fetchCategoryList = async (payload) => {
  const { tenantId, pageSize, pageNumber } = payload ?? {};
  let url
  if (!tenantId) {
    url = `${baseUrl}${API_PATH.CATEGORY.FETCH}`
  } else {
    url = `${baseUrl}${API_PATH.CATEGORY.FETCH_BY_TENANT}/${tenantId}`
  }
  const finalUrl = getDecoratedUrl({
    url,
    payload: { pageSize, pageNumber },
    shouldSort: false,
    shouldFilter: false
  })
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(finalUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const fetchCategoryRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.CATEGORY.FETCH}/${payload}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const createCategoryRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.CATEGORY.ADD}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.post(url, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const updateCategoryRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.CATEGORY.EDIT}/${payload.id}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.patch(url, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}

export const deleteCategory = async (caegoryId) => {
  const url = `${baseUrl}${API_PATH.CATEGORY.DELETE}/${caegoryId}`
  const token = sessionStorage.getItem('token')
  try {
    const result = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result?.data?.error || result.status !== 204) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(
      error?.response?.data?.title ?? 'Internal Server Error'
    )
  }
}
