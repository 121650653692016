import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import AddCategoryComponent from '../../../components/category/addCategory'
import { MzToast, TOAST_SEVERITY } from '../../../common/MzToast'
import { ProgressBar } from 'primereact/progressbar'
import {
  createCategoryRecord,
  fetchCategoryList,
  fetchCategoryRecord,
  initCategory,
  updateCategoryRecord
} from '../../../redux/action/category'
import { fetchTenantList, initTenant } from '../../../redux/action/tenant'
import { USER_ROLE } from '../../../constant/actionTypes/role'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../../constant/urlConstant'
import { useTranslation } from 'react-i18next'
const AddCategoryScreen = (props) => {
  const {
    isCreateCategorySuccess,
    isCreateCategoryError,
    isCategoryDetailSuccess,
    isCategoryDetailError,
    isEditCategorySuccess,
    isEditCategoryError,
    isEdit,
    isLoading,
    error,
    createCategoryRecord,
    formFieldValueMap,
    isPageLevelError,
    user,
    userRole,
    tenantList,
    initCategory,
    initTenant,
    fetchTenantList,

    fetchCategoryRecord,
    updateCategoryRecord
  } = props

  useEffect(() => {
    initCategory()
    initTenant()
    if (userRole === USER_ROLE.SUPER_ADMIN) {
      fetchTenantList()
    } else {
      fetchCategoryList(user?.tenant?.id ?? '')
    }
    // eslint-disable-next-line
  }, [userRole])

  const history = useNavigate()
  const { t } = useTranslation()
  const getToastProps = () => {
    if (isCreateCategorySuccess || isEditCategorySuccess) {
      const toastTitle = isEdit
        ? t('category.CategoryUpdateSuccessfully')
        : t('category.CategoryAddSuccessfully')
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isCreateCategoryError || isCategoryDetailError || isEditCategoryError) {
      let toastTitle = error
        ? error?.error
        : t('category.errorCreatingCategory')
      if (isEditCategoryError) {
        toastTitle = error ? error?.error : t('category.errorUpdatingCategory')
      } else if (isCategoryDetailError) {
        toastTitle = t('category.errorPerformingOperation')
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }
  const navigatetoCategory = () => {
    history(ROUTE_PATH.CATEGORY.HOME)
  }
  const addCategoryProps = {
    createCategoryRecord,
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    user,
    isEdit,
    userRole,
    isCreateCategorySuccess,
    isEditCategorySuccess,
    isCategoryDetailSuccess,
    fetchCategoryList,
    tenantList,
    fetchCategoryRecord,
    updateCategoryRecord,

    navigatetoCategory
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <AddCategoryComponent addCategoryProps={addCategoryProps} />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initCategory: () => dispatch(initCategory()),
    initTenant: () => dispatch(initTenant()),
    createCategoryRecord: (payload) => dispatch(createCategoryRecord(payload)),
    fetchTenantList: (payload) => dispatch(fetchTenantList(payload)),
    fetchCategoryList: (payload) => dispatch(fetchCategoryList(payload)),
    fetchCategoryRecord: (payload) => dispatch(fetchCategoryRecord(payload)),
    updateCategoryRecord: (payload) => dispatch(updateCategoryRecord(payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    formFieldValueMap: selectFormFieldValueMap(state.categoryReducer),
    isPageLevelError: state.categoryReducer.isPageLevelError,
    isLoading: state.categoryReducer.isLoading,
    error: state.categoryReducer.error,
    isCreateCategorySuccess: state.categoryReducer.isCreateCategorySuccess,
    isCreateCategoryError: state.categoryReducer.isCreateCategoryError,
    isEditCategorySuccess: state.categoryReducer.isEditCategorySuccess,
    isEditCategoryError: state.categoryReducer.isEditCategoryError,
    isCategoryDetailError: state.categoryReducer.isCategoryDetailError,
    isCategoryDetailSuccess: state.categoryReducer.isCategoryDetailSuccess,
    user: state.userProfileReducer.user,
    userRole: state.loginReducer.userRole,
    tenantList: state.tenantReducer.tenantList
  }
}

const selectFormFieldValueMap = (categoryReducer) => {
  return categoryReducer.formFieldValueMap
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCategoryScreen)
