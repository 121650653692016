import axios from 'axios'
import React, { useEffect, useState } from 'react'
import AccessDeniedPage from '../../common/Access'
import { baseUrl } from '../../services/PostAPI'
import CardComponent from '../../components/card'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import MzDialog from '../../common/MzDialog'
import { ProgressBar } from 'primereact/progressbar'
import { useSelector } from 'react-redux'
import { Dialog } from 'primereact/dialog' 

const CardScreen = () => {
  const { id } = useParams()
  const location = useLocation()
  const [serviceId, setServiceId] = useState(null)
  const [userCard, setUserCard] = useState(null)
  const [accessDenied, setAccessDenied] = useState(false)
  const [loading, setLoading] = useState(true)
  const history = useNavigate()
  const [visible, setVisible] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const extractedServiceId = searchParams.get('serviceId')
    setServiceId(extractedServiceId)
  }, [id, location])

  useEffect(() => {
    const fetchUserCards = async () => {
      try {
        const response = await axios.get(`${baseUrl}/public/user/${id}`)
        setUserCard(response.data)
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setAccessDenied(true)
        } else {
          setAccessDenied(true)
          console.error('An error occurred:', error)
        }
      } finally {
        setLoading(false)
      }
    }

    fetchUserCards()
  }, [id])

  const cancelRead = () => {
    history('/')
  }

  const aminitList = useSelector(
    (state) => state.amenitiesReducer?.amenitiesList ?? null
  )

  if (accessDenied) {
    return <AccessDeniedPage />
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }

  const handleNavigation = (url) => {
    window.open(url, '_blank')
  }

  let otherLinks = {}
  try {
    const otherLinksString = userCard?.tenant?.otherLinks || '{}'
    otherLinks = JSON.parse(otherLinksString)
  } catch (error) {
    console.error('Error parsing otherLinks:', error)
  }
 
   
  const showDialog = (data = false) => {
    setSelectedImage(data)
    setVisible(true)
  } 
  const hideDialog = () => {
    setVisible(false)
    setSelectedImage(null)
  }
  const headerTemplate = () => {
    return (
      <>
        {userCard && (
          <div className="flex justify-content-center align-items-center w-full">
            <div style={{ minWidth: '360px', maxWidth: '360px' }}>
              <div className="w-full mb-2  flex align-items-center  ">
              {userCard.tenant?.companyLogo ? (
                <img
                  src={userCard.tenant?.companyLogo}
                  className="w-2rem h-2rem  border-circle cursor-pointer "
                  alt="Logo"
                  onClick={() =>
                    showDialog(userCard.tenant?.companyLogo || null)
                  }
                />) : null} 
                
                <div className="text-sm ml-2">
                  <span>{userCard?.tenant?.companyName ?? ''}</span>
                </div>
              </div>

              <div className="flex ml-2 mt-3 w-full  gap-3">
                <i
                  className="pi pi-facebook"
                  style={{
                    cursor: !otherLinks.facebook ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.facebook && handleNavigation(otherLinks.facebook)
                  }
                />
                <i
                  className="pi pi-instagram"
                  style={{
                    cursor: !otherLinks.instagram ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.instagram &&
                    handleNavigation(otherLinks.instagram)
                  }
                />
                <i
                  className="pi pi-twitter"
                  style={{
                    cursor: !otherLinks.twitter ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.twitter && handleNavigation(otherLinks.twitter)
                  }
                />

                <i
                  className="pi pi-linkedin "
                  style={{
                    cursor: !otherLinks.linkedin ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.linkedin && handleNavigation(otherLinks.linkedin)
                  }
                />
                <i
                  className="pi pi-globe"
                  style={{
                    cursor: !otherLinks.website ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.website && handleNavigation(otherLinks.website)
                  }
                />
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <div className="h-screen w-screen">
        {loading && renderProgressBar()}
        {userCard && (
          <MzDialog
            header={() => headerTemplate()}
            visible={true}
            onHide={cancelRead}
            children={
              <>
                <CardComponent
                  selectedEmployee={userCard}
                  serviceId={serviceId}
                  aminitList={aminitList}
                />
              </>
            }
          ></MzDialog>
        )}
      </div> 
      <Dialog visible={visible} onHide={hideDialog} style={{ maxWidth: '300px' }}>
        <div className="text-center w-full">
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full cursor-pointer"
            />
          )}
        </div>
      </Dialog>
    </>
  )
}

export default CardScreen
